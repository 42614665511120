import "../../styles/pagelayout.scss";
import "./PartnerProfile.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/header/NavBar";
import BreadCrumb from "../components/header/BreadCrumb";
import { GetUserProfile } from "../../API/endpoints/User";
import {
  GetPartner,
  GetPartnerServicesList,
} from "../../API/endpoints/Partner";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";

const PartnerProfile = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [partnerData, setPartnerData] = useState<any>({});
  const [serviceCapabalities, setServiceCapabalities] = useState<any>([]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID?.toString());

      if (roleDetails?.data?.type === "partner") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "12"
        );
        console.log(response);
        setPermissions(response?.data);
      } else if (roleDetails?.data?.type === "administrative") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "4"
        );
        console.log(response);
        setPermissions(response?.data);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    (async () => {
      try {
        const response = await GetPartner(params.partnerId);
        setPartnerData(response.data);
      } catch (error) {}
      try {
        const res = await GetPartnerServicesList(params.partnerId);
        setServiceCapabalities(res.data?.services);
      } catch (error) {}
    })();
    console.log("params", params.partnerId);
  }, [params]);

  const breadcrumbData = [
    {
      name: "Partner Management",
      url: "/PartnerManagement",
      active: false,
    },
    {
      name: partnerData.company_name || "",
      url: `/partner/${params.partnerId}`,
      active: true,
    },
  ];

  return (
    <Container fluid className="pge-layout">
      <Row>
        <Col className="sidebar">
          <Sidebar active="partner" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <BreadCrumb breadcrumbData={breadcrumbData} />
          <div className="partner-cover-img">
            <div className="cover-img"></div>
          </div>
          <Container fluid className="partner-profile">
            <Row className="row-1">
              <Col md={3} lg={3} className="col-profile">
                <img
                  className="img-fluid img-thumbnail profile-pic"
                  src="../images/noimage.jpg"
                  alt="profilepic"
                />
                <div className="name-status">
                  <span className="font16-medium gray-800 mr8px">
                    {partnerData.company_name}
                  </span>
                  <span
                    className={
                      partnerData?.status === "Activated"
                        ? "success-status font12-medium"
                        : "blue-status font12-medium"
                    }
                  >
                    {partnerData?.status}
                  </span>
                </div>
                {partnerData?.website && (
                  <div className="mt8px">
                    <img
                      className="mr6px"
                      width="16px"
                      height="16px"
                      src="../svgs/globe.svg"
                      alt="globe"
                    />
                    <a
                      className="font12-regular"
                      href={`https://${partnerData.website}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {partnerData.website}
                    </a>
                  </div>
                )}
                {partnerData?.partner_categories?.length !== 0 && (
                  <div className="focus-cat mt16px">
                    {partnerData?.partner_categories?.map((item: any) => {
                      return (
                        <div key={item.id} className="cat">
                          <span className="font12-regular gray-600">
                            {item.categories.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </Col>
              <Col className="col-basicinfo">
                <table>
                  <tbody>
                    <tr>
                      <td className="font12-regular gray-500">
                        Annual Revenue
                      </td>
                      <td className="font12-regular gray-500 cln">:</td>
                      <td className="font12-medium gray-800">
                        {partnerData?.revenue_of_cus
                          ? `$${partnerData?.revenue_of_cus}`
                          : "Empty"}
                      </td>
                    </tr>
                    <tr>
                      <td className="font12-regular gray-500">
                        Years of Experience
                      </td>
                      <td className="font12-regular gray-500 cln">:</td>
                      <td className="font12-medium gray-800">
                        {partnerData?.years_of_experiences?.name}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {permissions[0]?.sub_modules[5]?.permissions_actions[1]
                  ?.permission[0]?.permission === true && (
                  <div>
                    <a
                      className="font12-regular"
                      href={`/partner-filesettings/${params.partnerId}`}
                      rel="noreferrer"
                    >
                      <span>Files </span>
                      <img src="../svgs/redirect.svg" alt="redirect" />
                    </a>
                  </div>
                )}
              </Col>
              <Col className="col-services" xs={12}>
                <div className="font14-medium gray-800 mb12px">
                  Service Capabilities
                </div>
                <div className="service-cntr">
                  {serviceCapabalities.length !== 0 &&
                    serviceCapabalities.map((item: any) => {
                      console.log("item", item);
                      return (
                        <div key={item.id} className="service-card">
                          <div className="country">
                            {item.regions.country_img && (
                              <img
                                width={22}
                                height={16}
                                src={item.regions.country_img}
                                alt="flag"
                                className="mr12px"
                              />
                            )}
                            <span className="font14-regular gray-800">
                              {item.regions.name}
                            </span>
                          </div>
                          <div className="service font12-regular gray-600">
                            {item.pre_launch_services.regulatory_compliance && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/regulatory.svg"
                                  alt="regulatory"
                                />
                                <span>Regulatory Compliance</span>
                              </div>
                            )}
                            {item.pre_launch_services.importation_clearance && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/importation.svg"
                                  alt="importation"
                                />
                                <span>Importation & Clearance</span>
                              </div>
                            )}
                            {item.post_launch_services.ecom_setup_mgmt && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/ecomm-management.svg"
                                  alt="ecomm"
                                />
                                <span>Ecommerce Setup & Management</span>
                              </div>
                            )}
                            {item.post_launch_services.retail_distribution && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/retail-distribution.svg"
                                  alt="retail"
                                />
                                <span>Retail Distribution</span>
                              </div>
                            )}
                            {item.pre_launch_services.warehousing_storage && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/warehouse.svg"
                                  alt="warehouse"
                                />
                                <span>Warehousing & Storage</span>
                              </div>
                            )}
                            {item.post_launch_services.order_fulfilment && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/order-fulfillment.svg"
                                  alt="order-ful"
                                />
                                <span>Order Fulfillment</span>
                              </div>
                            )}
                            {item.post_launch_services.marketing && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/marketing.svg"
                                  alt="marketing"
                                />
                                <span>Marketing</span>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default PartnerProfile;
