import "./AdminDashboard.scss";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/header/NavBar";
import AegInfoCard from "../components/cards/AegInfoCard";
import TodoList from "../components/list/TodoList";
import ActivityFeed from "../components/feed/ActivityFeed";
import Resource from "../components/resources/Resource";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  GetActivity,
  MarkAsRead,
  GetDashboardCounts,
  MarkAllAsRead,
} from "../../API/endpoints/Dashboard";
import { GetUserProfile } from "../../API/endpoints/User";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Auth from "@aws-amplify/auth";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [userType, setUserType] = useState<string | undefined>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [activies, setActivies] = useState<any>([]);
  const [dashboardCounts, setCount] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();

    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });

    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
          setUserProfile(response?.data);
          setUserType(response?.data?.user_type);
        }
      })();
    }
  }, [navigate, userID]);

  useEffect(() => {
    if (userType === "partner") {
      navigate("/PartnerDashboard");
    } else if (userType === "organization") {
      navigate("/BrandDashboard");
    }
  }, [userType, navigate]);

  useEffect(() => {
    const getActiLIst = async () => {
      const response = await GetActivity();
      setActivies(response.data.rows);
      setActivies(response.data.rows);
    };
    const GetCount = async () => {
      const response = await GetDashboardCounts();
      setCount(response.data);
    };
    getActiLIst();
    GetCount();
  }, []);

  const refresh = async () => {
    setLoading(true);
    setLoading(true);
    const response = await GetActivity();
    setActivies(response.data.rows);
    setLoading(false);
  };

  const markRead = async (id: Number) => {
    await MarkAsRead(id);
    await MarkAsRead(id);
    const activity = await GetActivity();
    setActivies(activity.data.rows);
  };

  const markAllRead = async () => {
    const response = await MarkAllAsRead();
    if (response.msg === "Activities marked as read") {
      const activity = await GetActivity();
      setActivies(activity.data.rows);
      setActivies(activity.data.rows);
    }
  };

  return (
    <Container fluid className="admin-dashboard">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="dashboard" />
        </Col>
        <Col className="p-0 col-content">
          <Navbar userProfile={userProfile} />
          <AegInfoCard dashboardCounts={dashboardCounts} />
          <Container fluid className="details-section">
            <Row className="row-details-section">
              <Col lg={6} className="col-todolist">
                <div className="todo-header">To-Do List</div>
                <div className="todo">
                  <TodoList userType="admin" userProfile={userProfile} />
                </div>
              </Col>
              <Col lg={6} className="col-feedresouce">
                <Container fluid className="cont-feedresouce">
                  <Row>
                    <Col lg={12} className="col-feed">
                      <div className="feedtitle">
                        <div className="font16-medium gray-800">
                          App Activity Feed
                        </div>
                        {!loading && (
                          <Button className="refresh-btn" onClick={refresh}>
                            <img src="../svgs/refresh.svg" alt="refresh" />
                          </Button>
                        )}
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        <div className="font16-medium gray-800">
                          App Activity Feed
                        </div>
                        {!loading && (
                          <Button className="refresh-btn" onClick={refresh}>
                            <img src="../svgs/refresh.svg" alt="refresh" />
                          </Button>
                        )}
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                      </div>
                      <div className="feed">
                        <ActivityFeed
                          activies={activies}
                          markRead={markRead}
                          markAllRead={markAllRead}
                        />
                      </div>
                    </Col>
                    <Col lg={12} className="col-resouce">
                      <div className="font16-medium gray-800 rsc-title">
                        Resources
                      </div>
                      <div className="resource">
                        <Resource />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
