import { getWith, postWith, deleteWith, patchWith } from "../axios";
import {
  ProductDataInputDTO,
  ProductSkuDataInput,
} from "../../pages/dtos/product.dtos";

export async function GetProducts(
  listPage: number,
  searchParam: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/fetch-products/${
      listPage ? listPage + 1 : 1
    }/${searchParam ? searchParam : ""}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetProductsFromAssignedBrands(
  user_id: string | undefined,
  listPage: number,
  searchParam: string
): Promise<any> {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/role/prods-assigned-to-user/${user_id}/${listPage ? listPage + 1 : 1}/${
      searchParam ? searchParam : ""
    }`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetOrgProducts(
  listPage: number,
  searchParam: string,
  orgID: any
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/fetch-products/${
      listPage ? listPage + 1 : 1
    }/${searchParam ? searchParam : ""}`;
    return (await postWith<any, any>(url, { org_id: orgID })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function SaveProductInfo(
  product: ProductDataInputDTO
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/`;
    return (await postWith<any, any>(url, product)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateProductInfo(
  product: ProductDataInputDTO,
  productid: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/${productid}`;
    return (await patchWith<any, any>(url, product)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetProductInfo(
  productid: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/${productid}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteProduct(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/` + id;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function SaveProductSku(
  productSku: ProductSkuDataInput
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/product-sku`;
    return (await postWith<any, any>(url, productSku)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetProductSKU(
  productid: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/product-sku/${productid}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateProductSKU(
  product: any,
  productid: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/product-sku/${productid}`;
    return (await patchWith<any, any>(url, product)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteProductSKU(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products//product-sku/` + id;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UploadProductImage(data: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/image`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllProductSku(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/product-sku/fetch-skus/1/${id}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetProductImages(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/products/image/${id}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
