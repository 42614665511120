import React, { useEffect, useState } from "react";
import "./../CreateBrand.scss";
import {
  EditBrandInfo,
  GetBrand,
  SaveBrandInfo,
} from "../../../../API/endpoints/Brands";
import BrandBasicInfoForm from "../../../components/forms/brandBasicInfoForm";
import {
  BrandDataInputDTO,
  categoriesDTO,
  organizationsDTO,
  yearsOfOperationDTO,
} from "../../../dtos/brand.dtos";
import { CreateActivity } from "../../../../API/endpoints/Dashboard";
import { useNavigate } from "react-router-dom";
// import { CreateTodo } from "../../../../API/endpoints/Todos";

//DTOs
interface BrandInfoDTO {
  categories: categoriesDTO[];
  years: yearsOfOperationDTO[];
  org: organizationsDTO[];
  brand?: string;
  setBrandID: (brandID: string) => void;
  setDefaultTab: (tab: string) => void;
  handleNotification: (type: string, msg: string) => void;
  permissions: any;
}

function BrandInfo(props: BrandInfoDTO) {
  const [isError, setisError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [data, setData] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [defaultCategories, setDefaultCategory] = useState([]);
  const [validateMessage, setValidateMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (props?.permissions[0]?.permission_type.toString() === "1") {
      setIsSuperAdmin(true);
    }
  }, [props]);

  useEffect(() => {
    if (props?.brand) {
      let brand = props.brand;
      const getBrandData = async () => {
        const response = await GetBrand(brand);
        if (response) {
          setData({
            organization: response.data.org_id,
            year: response.data.years_of_operation,
            brandName: response.data.brand_name,
            category: response.data.brand_categories.map((itm: any) => {
              return {
                id: itm.focus_category,
                name: itm.categories.name,
              };
            }),
            revenue: response.data.current_revenue,
          });
        }
      };

      getBrandData();
    }
  }, [props]);

  const onSubmit = (data: BrandDataInputDTO) => {
    console.log(data);
    if (selectedCategory.length === 0) {
      setValidateMessage("* This field is required");
      return;
    }
    if (selectedCategory.length === 0) {
      setValidateMessage("* This field is required");
      return;
    }
    setSubmitting(true);
    let brandInfo: any = {
      org_id: data.organization!,
      brand_name: data.brandName!,
      owner: "8ce0a2ee-ce3f-4bb0-8ed1-79f3af3e5f1d",
      // focus_categories: [data.category!],
      focus_categories: selectedCategory,
    };
    if (data.revenue) brandInfo.current_revenue = data.revenue!;
    if (data.year) brandInfo.years_of_operation = data.year!;
    if (!props.brand) {
      SaveBrandInfo(brandInfo)
        .then(async (response) => {
          setSubmitting(false);
          setValidateMessage("");
          setValidateMessage("");
          props.setBrandID(response.data.id);
          if (
            props?.permissions[0]?.sub_modules[1]?.permissions_actions[1]
              ?.permission[0]?.permission === true
          ) {
            props?.setDefaultTab("social-info");
          } else if (
            props?.permissions[0]?.sub_modules[2]?.permissions_actions[1]
              ?.permission[0]?.permission === true
          ) {
            props?.setDefaultTab("market-info");
          } else {
            navigate("/BrandManagement");
          }
          // const todoData = {
          //   id: `addplan:${response.data.id}`,
          //   brand_id: response.data.id,
          //   message: `New brand <b>${data.brandName}</b> has been added.`,
          //   url: `/editbrand/${response.data.id}&&subscriptions-info`,
          //   url_action: "Click to add subscription plans for brand.",
          //   admin: true,
          // };
          // const res2 = await CreateTodo(todoData);
          // console.log(res2);
          if (isSuperAdmin) {
            const activity = await CreateActivity(
              `Admin added brand ${data?.brandName}.`
            );
            console.log(activity);
          } else {
            const activity = await CreateActivity(
              `New Brand Added : ${data?.brandName}.`
            );
            console.log(activity);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          setisError(true);
          seterrorMessage(err.message);
          console.log("Error => ", err);
        });
    } else {
      EditBrandInfo(brandInfo, props.brand)
        .then(async (response) => {
          console.log(response);
          setSubmitting(false);
          setValidateMessage("");
          setValidateMessage("");
          props.handleNotification("success", "Updated successfully");
          const activity = await CreateActivity(
            `Updated brand: ${data?.brandName}.`
          );
          console.log(activity);
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
          setisError(true);
          seterrorMessage(err.message);
          //   console.log("Error => ", err);
        });
    }
  };

  const handleCategoryChange = (idList: any) => {
    setSelectedCategory(idList);
  };

  return (
    <>
      <BrandBasicInfoForm
        submitting={submitting}
        errorMessage={errorMessage}
        defaultData={data}
        isError={isError}
        onSubmit={onSubmit}
        org={props.org}
        categories={props.categories}
        years={props.years}
        brand={props.brand}
        handleCategory={handleCategoryChange}
        setDefaultCategory={setDefaultCategory}
        defaultCategories={defaultCategories}
        setSelectedCategory={setSelectedCategory}
        categoryMessage={validateMessage}
        permissions={props.permissions}
      />
      <div> </div>
    </>
  );
}

export default BrandInfo;
