import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import "./AddOrganization.scss";
import "../../styles/global.scss";
import "../../styles/pagelayout.scss";
// @ts-ignore
import Cookies from "js-cookie";
// Components
import Sidebar from "../components/sidebar/Sidebar";
import AddTeamMember from "../components/modalForms/addOrganizationTeam/AddTeamMember";
import TableMUI from "../components/TableMUI/TableMUI";
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import OrgInfoForm from "../components/forms/organizationInfoForm";

// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
  orgDataDTO,
  orgDataItem,
  orgInfoSubmit,
  orgUserListRows,
} from "../dtos/organization.dtos";
import {
  GetOrg,
  DeleteOrgUser,
  CreateOrganization,
  GetOrgUsers,
  UpdateOrganization,
} from "../../API/endpoints/Organization";
import { GetAllCountries } from "../../API/endpoints/Common";
import { orgUserTableColumns } from "../components/TableMUI/TableColumns";
import { CreateActivity } from "../../API/endpoints/Dashboard";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import BreadCrumb from "../components/header/BreadCrumb";
import SuccessModal from "../components/Modal/SuccessModal";
import ToastMessage from "../components/toasts/ToastMessage";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";

function AddOrganization() {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any>([]);
  const [flag, setFlag] = useState(false);
  const [userID, setUserID] = useState("");
  const [user, setUser] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [defaultTab, setDefaultTab] = useState<any>("organization-info");
  const [showDelete, setShowDelete] = useState(false);
  const [countries, setCountries] = useState(null);
  const [orgID, setOrgID] = useState(params?.orgid ? params.orgid : "");
  const [orgData, setOrgData] = useState<orgDataDTO>({});
  const [uID, setUID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [email, setEmail] = useState<string | undefined>("");
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const [errors, setErrors] = useState("");
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const navigate = useNavigate();

  const handleShow = () => {
    setUserID("");
    setShow(true);
  };
  const handleClose = () => {
    setUserID("");
    setUser({});
    setFlag(false);
    setShow(false);
    setIsEdit(false);
    setFlag(true);
  };
  const handleCloseDeletePopup = () => {
    setFlag(false);
    setShowDelete(false);
    setFlag(true);
  };

  useEffect(() => {
    setUID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (uID) {
      (async () => {
        const response = await GetUserProfile(uID);
        if (response?.data) {
          setUserProfile(response?.data);
          setRoleID(response.data?.user_roles[0]?.roles.id);
        }
      })();
    }
  }, [navigate, uID]);

  useEffect(() => {
    (async () => {
      console.log(roleID);
      const roleDetails = await GetRoleById(roleID.toString());
      console.log("ROLE DETAILS: ", roleDetails);
      if (roleDetails?.data?.type === "organization") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "0"
        );
        console.log(response);
        setPermissions(response?.data);
      } else if (roleDetails?.data?.type === "administrative") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "1"
        );
        console.log(response);
        setPermissions(response?.data);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    if (
      permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("organization-info");
    } else if (
      permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("team-info");
    }
  }, [permissions]);

  let rows: orgUserListRows[] = [];
  if (data) {
    rows = data.rows?.map((dataItem: orgDataItem) => {
      return {
        id: dataItem.id,
        name:
          dataItem.first_name +
          " " +
          (dataItem.last_name ? dataItem.last_name : ""),
        designation: dataItem.designation,
        role: dataItem.user_roles[0]?.roles?.role_name,
        email: dataItem.email_id,
        phone: dataItem.contact_number,
        is_active: dataItem.is_active,
      };
    });
  }

  const columns = orgUserTableColumns(
    setUserID,
    setShow,
    setUser,
    setIsEdit,
    setShowDelete,
    setEmail,
    setLinkSent,
    setNotification,
    permissions
  );

  useEffect(() => {
    if (
      permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      orgID &&
        (async () => {
          setData([]);
          const orgUsers = await GetOrgUsers(orgID);
          // console.log(orgUsers);
          console.log(orgUsers?.data);
          setData(orgUsers?.data);
        })();
    }
  }, [flag, orgID, permissions]);

  useEffect(() => {
    (async () => {
      const countries = await GetAllCountries(-1);
      setCountries(countries.data);
    })();

    const getOrganizationData = async () => {
      if (params?.orgid) {
        const response = await GetOrg(params.orgid);
        console.log(response.data);
        if (response)
          setOrgData({
            company_name: response.data.company_name,
            company_address_line1: response.data.company_address_line1,
            company_address_line2: response.data.company_address_line2
              ? response.data.company_address_line2
              : "",
            company_registration_number:
              response.data.company_registration_number,
            company_address_postal_code: response.data
              .company_address_postal_code
              ? response.data.company_address_postal_code
              : "",
            country_of_origin: response.data.country_of_origin,
            website: response.data.website ? response.data.website : "",
            is_active: response.data.is_active,
          });
      }
    };

    getOrganizationData();
  }, [params.orgid, permissions]);

  async function handleDelete(id: string) {
    setFlag(true);
    await DeleteOrgUser(id);
    setFlag(false);
  }

  const onSubmit = (data: orgInfoSubmit) => {
    let org: any = {
      company_name: data.organizationName,
      company_address_line1: data.address1,
      company_registration_number: data.registrationNumber,
      country_of_origin: data.country,
    };

    if (data.address2) org.company_address_line2 = data.address2;
    if (data.pinCode) org.company_address_postal_code = data.pinCode;
    if (data.website) org.website = data.website;

    // try {
    if (!params?.orgid) {
      (async () => {
        try {
          setErrors("");
          const res = await CreateOrganization(org);
          const activity = await CreateActivity(
            `Admin added org: ${data.organizationName}.`
          );
          console.log(activity);
          if (
            permissions[0]?.sub_modules[1]?.permissions_actions[1]
              ?.permission[0]?.permission === true
          ) {
            setDefaultTab("team-info");
          } else {
            navigate("/OrganizationManagement");
          }
          setOrgID(res.data.id);
          setFlag(!flag);
        } catch (ex: any) {
          if (ex === "Company registration number already exists") {
            setErrors(ex);
          }
        }
      })();
    } else {
      (async () => {
        try {
          setErrors("");
          await UpdateOrganization(orgID, org);
          setNotification({
            type: "success",
            msg: "Updated successfully",
          });
          const activity = await CreateActivity(
            `Org ${data.organizationName} updated.`
          );
          console.log(activity);
        } catch (ex: any) {
          if (ex === "Company registration number already exists") {
            setErrors(ex);
          }
        }
      })();
    }
    // } catch (error) {}
  };
  const breadcrumbData =
    [
      {
        name: "BRAND MANAGEMENT",
        url: "/OrganizationManagement",
        active: false,
      },
      {
        name: params?.orgid
          ? orgData.company_name?.toLocaleUpperCase()
          : "ORGANIZATIONS",
        url: "",
        active: false,
      },
      {
        name: params?.orgid ? "EDIT ORGANIZATION" : "ADD NEW ORGANIZATION",
        url: "",
        active: true,
      },
    ] || [];
  const handleNotification = (type: string, msg: string) => {
    setNotification({
      type: type,
      msg: msg,
    });
  };
  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });
  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="brand-org" />
        </Col>
        <Col className="content-section p-0">
          <Navbar userProfile={userProfile} />
          <BreadCrumb breadcrumbData={breadcrumbData} />

          <div className="tabs-wraper">
            <Tabs
              // activeKey={params?.orgid ? undefined : defaultTab}
              activeKey={defaultTab}
              onSelect={(k) => {
                if (params?.orgid) {
                  setDefaultTab(k);
                }
              }}
              id="fill-tab-example"
              className="tabs-global"
              fill
            >
              {permissions[0]?.sub_modules[0]?.permissions_actions[1]
                ?.permission[0]?.permission === true && (
                <Tab
                  eventKey="organization-info"
                  title={
                    <React.Fragment>
                      <div>Basic Organization Info</div>
                    </React.Fragment>
                  }
                >
                  <OrgInfoForm
                    onSubmit={onSubmit}
                    org={orgData}
                    countries={countries}
                    error={errors}
                    permissions={permissions}
                  />
                </Tab>
              )}

              {permissions[0]?.sub_modules[1]?.permissions_actions[1]
                ?.permission[0]?.permission === true && (
                <Tab
                  eventKey="team-info"
                  title={
                    <React.Fragment>
                      <div>Organization's Team Info</div>
                    </React.Fragment>
                  }
                >
                  {permissions[0]?.sub_modules[1]?.permissions_actions[0]
                    ?.permission[0]?.permission === true &&
                    (!data?.count || orgData.is_active) && (
                      <div className="d-flex justify-content-end mt16px">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={handleShow}
                          className="btn-primary12"
                        >
                          Add Team Member
                        </Button>
                      </div>
                    )}
                  <div className="TableMUIcountainer mt16px">
                    <TableMUI columns={columns} rows={rows} />
                    <AddTeamMember
                      isModalOpen={show}
                      onCancel={handleClose}
                      showDelete={showDelete}
                      setShowDelete={setShowDelete}
                      setFlag={setFlag}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                      orgID={orgID}
                      userID={userID}
                      setDefaultTab={setDefaultTab}
                      user={user}
                      setUser={setUser}
                      setUserID={setUserID}
                      data={data}
                      handleNotification={handleNotification}
                    />
                    <PopupTemplate
                      popupHeader="Confirm Deletion."
                      popupBody="Are you sure you want to delete ?"
                      handleFunction={handleDelete}
                      isModalOpen={showDelete}
                      onCancel={handleCloseDeletePopup}
                      id={userID}
                      setFlag={setFlag}
                    />
                    <SuccessModal
                      isModalOpen={linkSent}
                      handleCloseModal={() => {
                        setEmail("");
                        setLinkSent(false);
                      }}
                      email={email}
                      isAdmin={true}
                      btn={true}
                      link=""
                      linkText=""
                      title="Code Sent"
                      bodyText={`A code to reset your password has been sent to
          ${email?.replace(/(\w{1})(.*)(\w{1})@(.*)/, "$1******$3@$4")}`}
                    />
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AddOrganization;
