import "../../../../styles/modals/modal700.scss";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Multiselect } from "multiselect-react-dropdown";
import { fileCategoriesDTO, fileTypesDTO } from "../../../dtos/file.dtos";
import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import { fileSettingsDataInputDTO } from "../../../dtos/file.dtos";

export interface filesProps {
    isModalOpen: boolean;
    onCancel: () => void;
    fileCategories: fileCategoriesDTO[];
    fileTypes: fileTypesDTO[];
    getFileFormats: (FileTypeId: string) => void;
    fileFormats: any;
    onSubmit: (data: fileSettingsDataInputDTO) => void;
    defaultData: fileSettingsDataInputDTO;
    submitting: boolean;
    fileId: string;
}
const AddFileSettingsModal: React.FunctionComponent<filesProps> = ({
    isModalOpen,
    onCancel,
    fileCategories,
    fileTypes,
    getFileFormats,
    fileFormats,
    onSubmit,
    defaultData,
    submitting,
    fileId
}) => {
    const { register, formState, handleSubmit, reset } = useForm({
        defaultValues: defaultData,
    });
    const { errors } = formState;
    const [selectedFormats, setSelectedFormats] = useState<any[]>([]);
    const [fileFormatError, setFileFormatError] = useState("");
    const multiselectRef = useRef<any>([]);
    const handleClose = () => {
        onCancel();
    }
    const onHandleSubmit = (data: any) => {
        if (selectedFormats.length === 0) {
            setFileFormatError("* This field is required");
            return
        } else {
            setFileFormatError("");
        }
        data.file_formats = selectedFormats;
        onSubmit(data);
    };
    useEffect(() => {
        if (defaultData) {
            const defaults = {
                name: defaultData.name,
                category_id: defaultData.category_id,
                file_type_id: defaultData.file_type_id,
                admin_notes: defaultData.admin_notes
            }
            reset(defaults);
            if (defaultData.file_formats.length !== 0) {
                handleFormatChange(defaultData.file_formats);
            }
        }
    }, [reset, defaultData])

    const handleFormatChange = (selectedList: any) => {
        if (selectedList.length !== 0) {
            setFileFormatError("");
        }
        let listItems = selectedList.map((item: any) => {
            return item.id;
        });
        setSelectedFormats(listItems);
    }
    const handleRemove = (selectedList: any) => {
        let listItems = selectedList.map((item: any) => {
            return item.id;
        });
        setSelectedFormats(listItems);
    }
    const fileTypeChanged = (fileTypeId: string) => {
        if (fileTypeId) {
            getFileFormats(fileTypeId);
        }
        multiselectRef.current.resetSelectedValues();
    }
    return (
        <Container fluid>
            <Row>
                <Col>
                    <Modal
                        show={isModalOpen}
                        onHide={handleClose}
                        size="lg"
                        className="modal700"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title
                                className="font18-medium gray-800"
                            >
                                {fileId ? "Update File Settings" : "Add New File Settings"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form
                                className="form"
                                onSubmit={handleSubmit((data) => onHandleSubmit(data))}
                            >
                                <Container fluid>
                                    <Row
                                        className="mt24px mb24px row-body"
                                    >
                                        <Col
                                            style={{ padding: "0 10px 0 0" }}
                                        >
                                            <Form.Group
                                                className="formgroup"
                                                controlId="filecategory"
                                            >
                                                <Form.Label>Category</Form.Label>
                                                <Form.Select
                                                    {...register("category_id", {
                                                        required: "* This field is required",
                                                    })}
                                                >
                                                    <option value="">Select</option>
                                                    {fileCategories?.map((item: any) => {
                                                        return (
                                                            <option
                                                                key={item.id}
                                                                value={item.id}
                                                            >{item.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                <Form.Text muted>
                                                    <span>
                                                        {errors?.category_id && (
                                                            <p className="error-form">
                                                                {String(errors.category_id?.message)}
                                                            </p>
                                                        )}
                                                    </span>
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col
                                            style={{ padding: "0 0 0 10px" }}
                                        >
                                            <Form.Group
                                                className="formgroup"
                                                controlId="filetype"
                                            >
                                                <Form.Label>File Type</Form.Label>
                                                <Form.Select
                                                    {...register("file_type_id", {
                                                        required: "* This field is required",
                                                    })}
                                                    onChange={(e) => {
                                                        // console.log("changed", e.target.value)
                                                        fileTypeChanged(e.target.value)
                                                    }}
                                                >
                                                    <option value="">Select</option>
                                                    {fileTypes?.map((item: any) => {
                                                        return (
                                                            <option
                                                                key={item.id}
                                                                value={item.id}
                                                            >{item.type_name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                <Form.Text muted>
                                                    <span>
                                                        {errors?.file_type_id && (
                                                            <p className="error-form">
                                                                {String(errors.file_type_id?.message)}
                                                            </p>
                                                        )}
                                                    </span>
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb24px row-body">
                                        <Col
                                            style={{ padding: "0 10px 0 0" }}>
                                            <Form.Group
                                                className="formgroup"
                                                controlId="filename"
                                            >
                                                <Form.Label>File Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter file name"
                                                    {...register("name", {
                                                        required: "* This field is required",
                                                    })}
                                                />
                                                <Form.Text muted>
                                                    <span>
                                                        {errors?.name && (
                                                            <p className="error-form">
                                                                {String(errors.name?.message)}
                                                            </p>
                                                        )}
                                                    </span>
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col
                                            style={{ padding: "0 0 0 10px" }}>
                                            <Form.Group
                                                className="formgroup"
                                                controlId="fileformat"
                                            >
                                                <Form.Label>File Format</Form.Label>
                                                <Multiselect
                                                    className="rounded-0"
                                                    showArrow
                                                    options={fileFormats}
                                                    displayValue="format"
                                                    showCheckbox={true}
                                                    placeholder="Select"
                                                    closeIcon="cancel"
                                                    disable={fileFormats.length === 0}
                                                    selectedValues={defaultData?.file_formats}
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                        handleFormatChange(e);
                                                    }}
                                                    onRemove={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                        handleRemove(e);
                                                    }}
                                                    ref={multiselectRef}
                                                />
                                                <Form.Text muted>
                                                    <span>
                                                        {fileFormatError && (
                                                            <p className="error-form">
                                                                {fileFormatError}
                                                            </p>
                                                        )}
                                                    </span>
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb24px row-body">
                                        <Col
                                            className="p-0"
                                        >
                                            <Form.Group
                                                className="formgroup"
                                                controlId="notes"
                                            >
                                                <Form.Label>Admin Notes</Form.Label>
                                                <Form.Control
                                                    style={{ minHeight: "80px" }}
                                                    as="textarea"
                                                    placeholder="Add note"
                                                    {...register("admin_notes", {
                                                        required: "",
                                                    })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="p-0 bt-gray200">
                                            <div className="btn-footer">
                                                <Button
                                                    className="btn-secondary12"
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className="btn-primary12"
                                                    type="submit"
                                                    disabled={submitting ? true : false}
                                                >
                                                    {fileId ? "Update" : "Add"}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>
        </Container>
    )
}

export default AddFileSettingsModal