import { getWith, deleteWith, postWith, patchWith } from "../axios";

export async function GetAllOrg(
  listPage?: number,
  searchParam?: string
): Promise<any> {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/organization/fetch-organizations/${listPage ? listPage + 1 : 1}/${
      searchParam ? searchParam : ""
    }`;
    // console.log(url, searchParam);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllOrgDataRestriction(
  user_id: string | undefined,
  listPage?: number,
  searchParam?: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/org-data-restriction/${user_id}/${listPage}/${searchParam}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllOrgFilterDeleted(
  listPage?: number,
  searchParam?: string
): Promise<any> {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/organization/fetch-organizations-filtered/${
      listPage ? listPage + 1 : 1
    }/${searchParam ? searchParam : ""}`;
    return (await getWith<any>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetOrg(orgID: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/organization/` + orgID;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetOrgUsers(orgID: string | undefined): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/organization/fetch-org-users/` +
      orgID +
      "/1";
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetUserProfile(userID: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/` + userID;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreateOrganization(org: {
  company_name: string;
  company_address_line1: string;
  company_address_line2?: string;
  company_address_postal_code?: string;
  company_registration_number: string;
  website?: string;
  country_of_origin: string;
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/organization`;
    return (await postWith<any, any>(url, org)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateOrganization(
  orgID: string,
  org: {
    company_name: string;
    company_address_line1: string;
    company_address_line2?: string;
    company_address_postal_code?: string;
    company_registration_number: string;
    website?: string;
    country_of_origin: string;
  }
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/organization/` + orgID;

    return (await patchWith<any, any>(url, org)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function SendInvite(orgID: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/organization/invite-organization`;
    console.log(orgID);
    return (await postWith<any, any>(url, { org_id: orgID })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteOrgUser(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/` + id;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreateOrgUser(teamMember: {
  org_id: any;
  first_name: string;
  last_name: string;
  email_id: string;
  contact_number: string;
  contact_number_code: string;
  designation: string;
  user_type: string;
  role: number[];
  brands?: any[];
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/org-user`;
    return (await postWith<any, any>(url, teamMember)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateOrganizationUser(
  userID: string,
  teamMember: {
    org_id: any;
    first_name: string;
    last_name: string;
    email_id: string;
    contact_number: string;
    contact_number_code: string;
    designation: string;
    user_type: string;
    role: number[];
    brands?: any[];
  }
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/` + userID;
    console.log(url);
    console.log(userID);
    console.log(teamMember);
    return (await patchWith<any, any>(url, teamMember)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteOrganization(id: string | undefined) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/organization/` + id;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function SoftDeleteOrganization(
  orgID: string | undefined
): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/organization/soft-delete/organization/` +
      orgID;
    return (await patchWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function RestoreOrganization(
  orgID: string | undefined
): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/organization/restore/organization/` +
      orgID;
    return (await patchWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
