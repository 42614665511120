import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
import "./brandAgreement.scss";
import { AcceptTAndC } from "../../../../API/endpoints/Brands";
import { CreateActivity } from "../../../../API/endpoints/Dashboard";
import { MarkTodoDone } from "../../../../API/endpoints/Todos";

function BrandAgreementPopUp(props: {
  isModalOpen: boolean;
  onCancel: () => void;
  brand_id: string;
  brandName: string;
  setFlag: any;
}) {
  const { onCancel, brand_id, setFlag } = props;
  const onHandleSubmit = () => {
    (async () => {
      const res = await AcceptTAndC(brand_id);
      console.log(res);
      const activity = await CreateActivity(
        `Brand ${props.brandName} accepted the agreement and is active.`
      );
      console.log(activity);
      try {
        const todoDone = await MarkTodoDone(`brandKeyTerms:${brand_id}`);
        console.log(todoDone);
      } catch (ex: any) {
        console.log(ex);
      }
      Cookies.set("IsActivated", "true");
      handleClose();
    })();
  };

  const handleClose = () => {
    setFlag((prev: boolean) => !prev);
    onCancel();
  };

  return (
    <>
      <Modal
        className="addteam-member"
        show={props.isModalOpen}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="font18-medium">
            Key Platform Terms
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            We acknowledge that the access to iTradehub.io is subject to all
            terms and conditions mentioned in the MOU signed with AsiaEcommGroup
            Pte Ltd. We acknowledge the following key terms and conditions as we
            activate our access within iTradehub.io.
          </p>
          <p className="heading">Non-Circumvention</p>
          <p>
            The brand is not permitted to enter into any business relationship
            with partners listed on AEG's platform once they are introduced
            through the platform. The MoU prohibits the Brand from circumventing
            the MoU, directly or indirectly, such as by contacting any Partners
            without AEG's knowledge and approval, or entering into any
            agreements with Partners without AEG's approval, or undertaking any
            activities with any Partners without AEG's approval.
          </p>
          <p className="heading">Breach of Non-Circumvention</p>
          <p>
            In the event that the Brand circumvents the MoU, the brand will be
            responsible for paying an amount equal to 30% of all revenue
            generated by the brand through the partner, 2 times the damages
            caused to AEG, and/or an amount equal to SGD 15,000. AEG reserves
            the right to terminate the MoU and all brand service agreements.
          </p>

          <p className="agreementFooter">
            By accepting this, I/We agree to the above terms of the platform.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-secondary12"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="btn-primary12"
            variant="primary"
            type="submit"
            form="addTeamMemberForm"
            onClick={onHandleSubmit}
          >
            I Agree
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BrandAgreementPopUp;
