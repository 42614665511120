import "./ToastMessage.scss";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function ToastMessage(
    props: {
        notification: {
            type: string,
            msg: string
        }
        onCancel: () => void;
    }) {
    if (props.notification.msg !== "") {
        setTimeout(props.onCancel, 6000);
    }
    const toastCntr = () => {
        if (props.notification.type === "success") {
            return "toast-cntnr"
        } else if (props.notification.type === "error") {
            return "toast-cntnr error-border"
        } else if(props.notification.type === "warning") {
            return "toast-cntnr warning-border"
        }
    }
    const toast = () => {
        if (props.notification.type === "success") {
            return "toast"
        } else if (props.notification.type === "error") {
            return "toast errorbg"
        } else if(props.notification.type === "warning") {
            return "toast warningbg"
        }
    }
    const toastBody = () => {
        if (props.notification.type === "success") {
            return "success-600 font12-medium"
        } else if (props.notification.type === "error") {
            return "error-700 font12-medium"
        } else if(props.notification.type === "warning") {
            return "warning-700 font12-medium"
        }
    }
    return (
        <ToastContainer
            className={toastCntr()}
            position="bottom-center"
        >
            <Toast className={toast()} show={props.notification.msg ? true : false}>
                <Toast.Body className={toastBody()}>
                    {props.notification.type === "success" &&
                        <img src="../svgs/check-circle.svg" alt="check-circle" />}
                    {props.notification.type === "error" &&
                        <img src="../svgs/alert-triangle-error700.svg" alt="error700" />}
                    {props.notification.type === "warning" &&
                        <img src="../svgs/alert-circle-warning700.svg" alt="war700" />}
                    <span>{props.notification.msg}</span>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}
export default ToastMessage;
