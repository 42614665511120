import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./brandMarketPresenceForm.scss";
import {
  countriesDTO,
  EngagementModelDTO,
  MarketPreferenceDTO,
} from "../../dtos/brand.dtos";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Multiselect } from "multiselect-react-dropdown";
// @ts-ignore
import { v4 as uuid } from "uuid";
import { Link, useParams } from "react-router-dom";

export interface BrandMarketPresenceFormProps {
  submitting: boolean;
  countries: countriesDTO[];
  allCountries: countriesDTO[];
  engagementModelList: EngagementModelDTO[];
  aspiredMarket: string;
  regulatoryCompliance: boolean;
  importClearance: boolean;
  warehousingAndStorage: boolean;
  ecomSetupMgmt: boolean;
  retailDistribution: boolean;
  orderFullfilment: boolean;
  marketing: boolean;
  marketPreference: MarketPreferenceDTO[];
  isDisabled: boolean;
  fundRequired: boolean;
  preferenceErrorMsg: string;
  addPreference: () => void;
  setAspiredMarket: (market: string) => void;
  setRegulatoryCompliance: (item: boolean) => void;
  setImportClearance: (item: boolean) => void;
  setWarehousingAndStorage: (item: boolean) => void;
  setEcomSetupMgmt: (item: boolean) => void;
  setRetailDistribution: (item: boolean) => void;
  setOrderFullfilment: (item: boolean) => void;
  setMarketing: (item: boolean) => void;
  setIsDisabled: (item: boolean) => void;
  setFundRequired: (required: boolean) => void;
  setPreferenceErrorMsg: (message: string) => void;
  handleEditButtonClick: (region: string) => void;
  handleConfirmEdit: (preference: MarketPreferenceDTO) => void;
  handleDelete: (region: string) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  setDisabled: (required: boolean) => void;
  disabled: boolean;
  handleEngementModal: (idList: any) => void;
  defaultModals: any;
  engagementMessage: string;
  permissions: any;
}

const BrandMarketPresenceForm: React.FunctionComponent<
  BrandMarketPresenceFormProps
> = ({
  countries,
  allCountries,
  engagementModelList,
  aspiredMarket,
  regulatoryCompliance,
  importClearance,
  warehousingAndStorage,
  ecomSetupMgmt,
  retailDistribution,
  orderFullfilment,
  marketing,
  marketPreference,
  isDisabled,
  fundRequired,
  preferenceErrorMsg,
  addPreference,
  setAspiredMarket,
  setRegulatoryCompliance,
  setImportClearance,
  setWarehousingAndStorage,
  setEcomSetupMgmt,
  setRetailDistribution,
  setOrderFullfilment,
  setMarketing,
  setIsDisabled,
  setFundRequired,
  setPreferenceErrorMsg,
  handleEditButtonClick,
  handleConfirmEdit,
  handleDelete,
  onSubmit,
  setDisabled,
  disabled,
  handleEngementModal,
  defaultModals,
  engagementMessage,
  permissions,
}) => {
  const params = useParams();

  const handleEngmdlChange = (modals: any) => {
    console.log("modals: ", modals);
    let idList = modals.map((item: any) => {
      return item.id;
    });
    console.log("idList: ", idList);
    handleEngementModal(idList);
  };
  const handleRemove = (modals: any) => {
    console.log("modals: ", modals);
    let idList = modals.map((item: any) => {
      return item.id;
    });
    console.log("idList: ", idList);
    handleEngementModal(idList);
  };
  return (
    <Form onSubmit={onSubmit} className="form-mrkt-presense">
      <Container fluid>
        <div>
          <Row className="row-add-mrkt">
            <Col lg={10} sm={10} xl={10} className="col-input">
              <Row>
                <Col xs={4}>
                  <Form.Group
                    className="formgroup"
                    controlId="formAspiredMarket"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setAspiredMarket(e.target.value);
                      setPreferenceErrorMsg("");
                    }}
                  >
                    <Form.Label>Select Aspired Market *</Form.Label>
                    <Form.Select defaultValue={aspiredMarket}>
                      {countries &&
                        countries.map((country) => {
                          return (
                            <option value={country.id} key={country.id}>
                              {country.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {/* <div className="formRowNew"> */}
                <Col>
                  <div className="primary-blue mt-3 mb-3">
                    Select all required services
                  </div>
                </Col>
                <Col xs={12}>
                  <Form.Group controlId="formPreLaunchServices">
                    <Row>
                      <Col sm={6} lg={4} xl={3}>
                        <Form.Check
                          type="checkbox"
                          id="regulatorycompliance"
                          className="font14-regular gray-700"
                          label="Regulatory Compliance"
                          onChange={() => {
                            setRegulatoryCompliance(!regulatoryCompliance);
                          }}
                        />
                        <Form.Check
                          type="checkbox"
                          id="importClearance"
                          className="font14-regular gray-700"
                          label="Importation And Clearance"
                          onChange={() => {
                            setImportClearance(!importClearance);
                          }}
                        />
                      </Col>
                      <Col sm={6} lg={4} xl={3}>
                        <Form.Check
                          type="checkbox"
                          id="ecommerceSetup"
                          className="font14-regular gray-700"
                          label="Ecommerce Setup & Management"
                          onChange={() => {
                            setEcomSetupMgmt(!ecomSetupMgmt);
                          }}
                        />
                        <Form.Check
                          type="checkbox"
                          id="retail"
                          className="font14-regular gray-700"
                          label="Retail Distribution"
                          onChange={() => {
                            setRetailDistribution(!retailDistribution);
                          }}
                        />
                      </Col>
                      <Col sm={6} lg={4} xl={3}>
                        <Form.Check
                          type="checkbox"
                          id="marketing"
                          className="font14-regular gray-700"
                          label="Marketing"
                          onChange={() => {
                            setMarketing(!marketing);
                          }}
                        />
                        <Form.Check
                          type="checkbox"
                          id="orderFullfilment"
                          className="font14-regular gray-700"
                          label="Order Fulfillment"
                          onChange={() => {
                            setOrderFullfilment(!orderFullfilment);
                          }}
                        />
                      </Col>
                      <Col xs={12} sm={6} md={4} lg={3}>
                        <Form.Check
                          type="checkbox"
                          id="warehousingandstorage"
                          className="font14-regular gray-700"
                          label="Warehousing And Storage"
                          onChange={() => {
                            setWarehousingAndStorage(!warehousingAndStorage);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                {/* </div> */}
              </Row>
            </Col>
            <Col lg={2} sm={2} xl={2} className="align-self-center">
              <div className="d-flex justify-content-center ">
                <Button
                  variant="primary"
                  className="btn-primary12"
                  onClick={() => {
                    setDisabled(true);
                    addPreference();
                  }}
                  disabled={preferenceErrorMsg !== "" || disabled}
                >
                  ADD
                </Button>
              </div>
            </Col>
          </Row>
          <Form.Text muted>
            <span>
              {preferenceErrorMsg && (
                <p className="error-form">{preferenceErrorMsg}</p>
              )}
            </span>
          </Form.Text>
        </div>
        {marketPreference[0] &&
          marketPreference.map((preference) => {
            return (
              <div key={uuid()} className="mt-3 display-result">
                <Row>
                  <Col xs={12} sm={2} className="col-country">
                    <div className="font14-medium">
                      {
                        allCountries.filter(
                          (country) =>
                            country.id.toString() === preference.region
                        )[0]?.name
                      }
                    </div>
                  </Col>
                  <Col lg={9} sm={9} xl={9} className="col-services">
                    <p className="primary-blue font14-medium label-service">
                      Services
                    </p>

                    <Form.Group
                      className="d-flex justify-content-between"
                      controlId="formServices"
                    >
                      <div>
                        <Form.Check
                          type="checkbox"
                          id="regulatorycompliance"
                          className="font14-regular gray-700"
                          label="Regulatory Compliance"
                          defaultChecked={preference.regulatory_compliance}
                          disabled={isDisabled}
                          onChange={(e) => {
                            preference.regulatory_compliance =
                              !preference.regulatory_compliance;
                          }}
                        />
                        <Form.Check
                          type="checkbox"
                          id="importClearance"
                          disabled={isDisabled}
                          className="font14-regular gray-700"
                          defaultChecked={preference.importation_clearance}
                          label="Importation And Clearance"
                          onChange={(e) => {
                            preference.importation_clearance =
                              !preference.importation_clearance;
                          }}
                        />
                      </div>
                      <div>
                        <Form.Check
                          type="checkbox"
                          id="ecommerceSetup"
                          disabled={isDisabled}
                          className="font14-regular gray-700"
                          defaultChecked={preference.ecom_setup_mgmt}
                          label="Ecommerce Setup & Management"
                          onChange={(e) => {
                            preference.ecom_setup_mgmt =
                              !preference.ecom_setup_mgmt;
                          }}
                        />
                        <Form.Check
                          type="checkbox"
                          id="retail"
                          disabled={isDisabled}
                          className="font14-regular gray-700"
                          defaultChecked={preference.retail_distribution}
                          label="Retail Distribution"
                          onChange={(e) => {
                            preference.retail_distribution =
                              !preference.retail_distribution;
                          }}
                        />
                      </div>
                      <div>
                        <Form.Check
                          type="checkbox"
                          id="marketing"
                          disabled={isDisabled}
                          className="font14-regular gray-700"
                          defaultChecked={preference.marketing}
                          label="Marketing"
                          onChange={(e) => {
                            preference.marketing = !preference.marketing;
                          }}
                        />
                        <Form.Check
                          type="checkbox"
                          id="orderFullfilment"
                          disabled={isDisabled}
                          className="font14-regular gray-700"
                          defaultChecked={preference.order_fulfilment}
                          label="Order Fulfillment"
                          onChange={(e) => {
                            preference.order_fulfilment =
                              !preference.order_fulfilment;
                          }}
                        />
                      </div>
                      <div>
                        <Form.Check
                          type="checkbox"
                          id="warehousingandstorage"
                          disabled={isDisabled}
                          className="font14-regular gray-700"
                          defaultChecked={preference.warehousing_storage}
                          label="Warehousing And Storage"
                          onChange={(e) => {
                            preference.warehousing_storage =
                              !preference.warehousing_storage;
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg={1} sm={1} md={1} className="col-actions">
                    <div className="iconsHolder">
                      {isDisabled && (
                        <OverlayTrigger
                          key="editbutton"
                          placement="left"
                          overlay={
                            <Tooltip id="tooltip-top" className="tooltip">
                              Edit Selection
                            </Tooltip>
                          }
                        >
                          <img
                            className="listicon"
                            src="../svgs/pencil.svg"
                            alt="editbutton"
                            onClick={() => {
                              setIsDisabled(false);
                              handleEditButtonClick(preference.region);
                            }}
                          />
                        </OverlayTrigger>
                      )}
                      {!isDisabled && (
                        <OverlayTrigger
                          key="confirmbutton"
                          placement="left"
                          overlay={
                            <Tooltip id="tooltip-top" className="tooltip">
                              Confirm Edit
                            </Tooltip>
                          }
                        >
                          <img
                            className="listicon"
                            src="../svgs/check.svg"
                            alt="confirmbutton"
                            onClick={() => {
                              setIsDisabled(true);
                              handleConfirmEdit(preference);
                            }}
                          />
                        </OverlayTrigger>
                      )}
                      <img
                        src="../svgs/trash.svg"
                        alt="trashbutton"
                        className="listicon"
                        onClick={() => {
                          handleDelete(preference.region);
                          setPreferenceErrorMsg("");
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        <Row className="mt-3 input-box-row">
          {/* <Col md={3} className="p-0 ">
            <Form.Group
              className="formgroup mr12px"
              controlId="formParentOrganization"
            >
              <Form.Label>Select Engagement Model *</Form.Label>
              <Multiselect
                id="css_custom"
                customCloseIcon={
                  <>
                    <img src="../svgs/close-btn.svg" alt="close-btn" />
                  </>
                }
                showArrow
                options={engagementModelList}
                selectedValues={defaultModals}
                displayValue="display_name"
                showCheckbox={true}
                placeholder="Select"
                closeIcon="cancel"
                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleEngmdlChange(e);
                }}
                onRemove={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleRemove(e);
                }}
              />
              <Form.Text muted>
                <span>
                  {engagementMessage && (
                    <p className="error-form">{engagementMessage}</p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col> */}
          <Col md={3} className="p-0">
            <Form.Group
              className="formgroup"
              controlId="formParentOrganization"
              onChange={(e: any) => {
                setFundRequired(e.target.value === "true" ? true : false);
              }}
            >
              <Form.Label>Fund Required *</Form.Label>
              <Form.Select value={fundRequired.toString()}>
                <option value={"true"}>Yes</option>
                <option value={"false"}>No</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3 input-box-row">
          <Col md={3} className="p-0 input-box m-0">
            <Form.Group
              className="formgroup mr12px focus-category"
              controlId="formParentOrganization"
            >
              <Form.Label>Select Engagement Model *</Form.Label>
              <Multiselect
                id="css_custom"
                customCloseIcon={
                  <>
                    <img src="../svgs/close-btn.svg" alt="close-btn" />
                  </>
                }
                showArrow
                options={engagementModelList}
                selectedValues={defaultModals}
                displayValue="display_name"
                showCheckbox={true}
                placeholder="Select"
                closeIcon="cancel"
                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleEngmdlChange(e);
                }}
                onRemove={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleRemove(e);
                }}
              />
              <Form.Text muted>
                <span>
                  {engagementMessage && (
                    <p className="error-form">{engagementMessage}</p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Container fluid className="footer-container">
        <Row className="justify-content-end mb-3 mt-4">
          <Col className="d-flex justify-content-end p-0">
            <Link to="/BrandManagement" className="m-1">
              <Button variant="secondary" className="btn-secondary12">
                Cancel
              </Button>
            </Link>
            {permissions[0]?.sub_modules[2]?.permissions_actions[0]
              ?.permission[0]?.permission === true &&
              !params.brandid && (
                <Button
                  variant="primary"
                  className="btn-primary12 m-1"
                  type="submit"
                >
                  Save and continue
                </Button>
              )}

            {permissions[0]?.sub_modules[2]?.permissions_actions[2]
              ?.permission[0]?.permission === true &&
              params.brandid && (
                <Button
                  variant="primary"
                  className="btn-primary12 m-1"
                  type="submit"
                >
                  Update
                </Button>
              )}
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default BrandMarketPresenceForm;
