import "../../styles/pagelayout.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/header/NavBar";
import AssignUser from "../components/assignUser/AssignUser";
import ToastMessage from "../components/toasts/ToastMessage";
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import { GetUserProfile } from "../../API/endpoints/User";
import {
    GetRolesWithDataRestriction,
    GetUsersByRole,
    GetPermissionsByRoleAndModuleID,
    GetRoleById
} from "../../API/endpoints/RolesAndPermissions/Roles";
import {
    AssignTeam,
    GetAssignedUsers,
    DeleteAssignedTeam
} from "../../API/endpoints/RolesAndPermissions/AssignTeam";
import { GetMarketPresenceCountries } from "../../API/endpoints/Common";

const AssignPartnerTeam = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [userID, setUserID] = useState<string | undefined>("");
    const [userProfile, setUserProfile] = useState<any>({});
    const [roles, setRoles] = useState([]);
    const [teamMembers, setTeamMembers] = useState<any>([]);
    const [marketPresence, setMarketPresence] = useState<any>([]);
    const [selectedMarkets, setSelectedMarkets] = useState([]);
    const [users, setUsers] = useState<any>([]);
    const [marketValiate, setMarketValiate] = useState("")
    const [notification, setNotification] = useState({ type: "", msg: "" });
    const [flag, setFlag] = useState(false);
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [deleteId, setDeleteId] = useState<number>(0)
    const [roleID, setRoleID] = useState<number>(0);
    const [permissions, setPermissions] = useState<any[]>([]);

    useEffect(() => {
        setUserID(Cookies.get("userID"));
    }, []);

    useEffect(() => {
        (async () => {
            Auth.currentSession()
                .then((session) => {
                    if (session.isValid()) {
                    } else navigate("/");
                })
                .catch((err) => {
                    navigate("/");
                });
            await Auth.currentAuthenticatedUser()
                .then((response) => { })
                .catch((error) => {
                    navigate("/");
                });
        })();
        if (userID) {
            (async () => {
                const response = await GetUserProfile(userID);
                if (response?.data) {
                    setUserProfile(response?.data);
                    setRoleID(response?.data?.user_roles[0]?.roles.id);
                }
            })();
        }
    }, [userID, navigate])

    useEffect(() => {
        (async () => {
            try {
                const response = await GetRolesWithDataRestriction("administrative");
                setRoles(response.data);
            } catch (error) {

            }
            try {
                const response = await GetMarketPresenceCountries();
                setMarketPresence(response.data);
            } catch (error) {

            }
        })()
    }, [params])

    useEffect(() => {
        if (marketPresence.length !== 0) {
            (async () => {
                try {
                    const response = await GetAssignedUsers("partner", params.partnerid);
                    setUsers(response.data);
                } catch (error) {

                }
            })()
            setFlag(false);
        }
    }, [params, marketPresence, flag])

    useEffect(() => {
        (async () => {
            try {
                const roleDetails = await GetRoleById(roleID.toString());
                if (roleDetails?.data?.type === "administrative") {
                    try {
                        const response = await GetPermissionsByRoleAndModuleID(
                            roleID.toString(),
                            "4"
                        );
                        setPermissions(response?.data);
                    } catch (error) {

                    }
                } else if (roleDetails?.data?.type === "partner") {
                    try {
                        const response = await GetPermissionsByRoleAndModuleID(
                            roleID.toString(),
                            "12"
                        );
                        setPermissions(response?.data);
                    } catch (error) {

                    }
                } else {}
            } catch (error) {

            }
        })()
    }, [roleID])

    const handleRoleChange = async (roleid: any) => {
        try {
            const response = await GetUsersByRole(roleid);
            setTeamMembers(response.data);
        } catch (error) {

        }
    }

    const handleMarketChange = (markets: any) => {
        let idList = markets.map((market: any) => market.id.toString())
        setSelectedMarkets(idList);
    }

    const handleRemove = (markets: any) => {
        let idList = markets.map((market: any) => market.id.toString())
        setSelectedMarkets(idList);
    }

    const handleCloseNotification = () =>
        setNotification({
            type: "",
            msg: "",
        });

    const goBack = () => {
        navigate("/PartnerManagement");
    }

    const onSubmit = async (data: any) => {
        // console.log("data: ",data)
        let formData = {
            user_id: data.user_id,
            org_type: "partner",
            countries: selectedMarkets
        }
        if (selectedMarkets.length === 0) {
            setMarketValiate("* This field is required")
            return;
        }
        try {
            const response = await AssignTeam(formData, params.partnerid);
            if (response.msg === "User assigned to Partner successfully.") {
                setNotification({ type: "success", msg: response.msg });
                setMarketValiate("");
                setFlag(true);
                setSelectedMarkets([]);
            } else {
                setNotification({ type: "warning", msg: response.msg });
            }
        } catch (ex: any) {
            setNotification({ type: "error", msg: ex.msg });
        }
    }

    const deleteAssignedUser = (id: number) => {
        setDeleteId(id);
        setShowDeleteUser(true);
    }
    const deleteFunction = async () => {
        try {
            const response = await DeleteAssignedTeam(deleteId);
            if (response.msg === "Assigned user removed successfully.") {
                setNotification({ type: "success", msg: response.msg });
                setFlag(true);
                setDeleteId(0);
            }
        } catch (ex: any) {
            setNotification({ type: "error", msg: ex.msg });
        }
    }
    const handleCloseDeleteUser = () => {
        setShowDeleteUser(false);
    }

    const breadcrumbData = [
        {
            name: "Partner Management",
            url: "/PartnerManagement",
            active: false,
        },
        {
            name: "Assign Team",
            url: `/assignpartnerteam/${params.partnerid}`,
            active: true,
        }
    ];
    return (
        <Container fluid className="pge-layout">
            <Row>
                <Col className="sidebar">
                    <Sidebar active="partner" />
                </Col>
                <Col className="content-section">
                    <Navbar userProfile={userProfile} />
                    <AssignUser
                        breadcrumbData={breadcrumbData}
                        roles={roles}
                        teamMembers={teamMembers}
                        marketPresence={marketPresence}
                        users={users}
                        marketValiate={marketValiate}
                        roleChange={handleRoleChange}
                        MarketChange={handleMarketChange}
                        handleMarketRemove={handleRemove}
                        onSubmit={onSubmit}
                        deleteAssignedUser={deleteAssignedUser}
                        flag={flag}
                        BackNavigation={goBack}
                        permissions={permissions}
                    />
                    <ToastMessage
                        notification={notification}
                        onCancel={handleCloseNotification}
                    />
                    <PopupTemplate
                        popupHeader="Confirm Deletion."
                        popupBody={`Are you sure you want to delete?`}
                        handleFunction={deleteFunction}
                        isModalOpen={showDeleteUser}
                        onCancel={handleCloseDeleteUser}
                        id=""
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default AssignPartnerTeam;