import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import InputGroup from "react-bootstrap/InputGroup";
import { ProductSkuDataInput } from "../../dtos/product.dtos";
import {
  GetProductSKU,
  UpdateProductSKU,
} from "../../../API/endpoints/Products";

export interface AddProductSkuModalProps {
  onCancel: () => void;
  onSubmitSku: (data: ProductSkuDataInput) => void;
  isEdit: boolean;
  selectedProduct: any;
  handleNotification: (type: string, msg: string) => void;
}

const AddProductSkuForm: React.FunctionComponent<AddProductSkuModalProps> = ({
  onCancel,
  onSubmitSku,
  isEdit,
  selectedProduct,
  handleNotification
}) => {
  const [defaultData, setDefaultData] = useState<any>({
    id: "",
    product_id: "",
    sku_number: "",
    sku_title: "",
    sku_description: "",
    width: undefined,
    height: undefined,
    length: undefined,
    gross_weight: undefined,
    net_weight: undefined,
    cost_price: undefined,
    sell_price: undefined,
    product: {
      id: "",
      title: "",
    },
  });
  const { register, formState, handleSubmit, reset } = useForm({
    defaultValues: defaultData,
  });
  const { errors } = formState;
  const onHandleClose = () => onCancel();

  useEffect(() => {
    if (selectedProduct) {
      (async () => {
        const res = await GetProductSKU(selectedProduct);
        console.log(res.data);
        setDefaultData(res.data);
      })();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (defaultData) {
      const defaults = {
        id: defaultData.id,
        product_id: defaultData.product_id,
        sku_number: defaultData.sku_number,
        sku_title: defaultData.sku_title,
        sku_description: defaultData.sku_description,
        width: defaultData.width,
        height: defaultData.height,
        length: defaultData.length,
        gross_weight: defaultData.gross_weight,
        net_weight: defaultData.net_weight,
        cost_price: defaultData.cost_price,
        sell_price: defaultData.sell_price,
        product: {
          id: defaultData.product.id,
          title: defaultData.product.title,
        },
      };

      reset(defaults);
    }
  }, [defaultData, reset]);

  const onHandleSubmit = (data: ProductSkuDataInput) => {
    if (isEdit) {
      UpdateProductSKU(data, selectedProduct);
      handleNotification("success", "Updated successfully");
      onCancel();
    } else {
      onSubmitSku(data);
    }
  };

  return (
    <Form onSubmit={handleSubmit((data) => onHandleSubmit(data))}>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="">
              <Form.Label>SKU Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter SKU Number"
                {...register("sku_number", {
                  required: "* This field is required",
                })}
                name="sku_number"
              />
              <Form.Text muted>
                <span>
                  {errors?.sku_number && (
                    <p className="error-form">
                      {String(errors.sku_number?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="">
              <Form.Label>Product SKU Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Variant Title"
                {...register("sku_title", {
                  required: "* This field is required",
                })}
                name="sku_title"
              />
              <Form.Text muted>
                <span>
                  {errors?.sku_title && (
                    <p className="error-form">
                      {String(errors.sku_title?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="">
              <Form.Label>Product SKU Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Variant Descrption"
                {...register("sku_description", {
                  required: "* This field is required",
                })}
                name="sku_description"
              />
              <Form.Text muted>
                <span>
                  {errors?.sku_description && (
                    <p className="error-form">
                      {String(errors.sku_description?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Form.Group controlId="">
              <Form.Label>Width</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter Width in cm"
                  {...register("width", {
                    required: "* This field is required",
                  })}
                  name="width"
                  aria-describedby="width"
                />
                <InputGroup.Text id="width">cm</InputGroup.Text>
              </InputGroup>
              <Form.Text muted>
                <span>
                  {errors?.width && (
                    <p className="error-form">
                      {String(errors.width?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId="">
              <Form.Label>Height</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter Height in cm"
                  {...register("height", {
                    required: "* This field is required",
                  })}
                  name="height"
                  aria-describedby="height"
                />
                <InputGroup.Text id="height">cm</InputGroup.Text>
              </InputGroup>
              <Form.Text muted>
                <span>
                  {errors?.height && (
                    <p className="error-form">
                      {String(errors.height?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId="">
              <Form.Label>Length</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter Length in cm"
                  {...register("length", {
                    required: "* This field is required",
                  })}
                  name="length"
                  aria-describedby="length"
                />
                <InputGroup.Text id="length">cm</InputGroup.Text>
              </InputGroup>
              <Form.Text muted>
                <span>
                  {errors?.length && (
                    <p className="error-form">
                      {String(errors.length?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Form.Group controlId="">
              <Form.Label>Gross Weight</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter Gross Weight in kg"
                  {...register("gross_weight", {
                    required: "* This field is required",
                  })}
                  name="gross_weight"
                  aria-describedby="gross_weight"
                />
                <InputGroup.Text id="gross_weight">kg</InputGroup.Text>
              </InputGroup>
              <Form.Text muted>
                <span>
                  {errors?.gross_weight && (
                    <p className="error-form">
                      {String(errors.gross_weight?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId="">
              <Form.Label>Net Weight</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter Net Weight in kg"
                  {...register("net_weight", {
                    required: "* This field is required",
                  })}
                  name="net_weight"
                  aria-describedby="net_weight"
                />
                <InputGroup.Text id="net_weight">kg</InputGroup.Text>
              </InputGroup>
              <Form.Text muted>
                <span>
                  {errors?.net_weight && (
                    <p className="error-form">
                      {String(errors.net_weight?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="">
              <Form.Label>Cost Price</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Cost Price"
                {...register("cost_price", {
                  required: "* This field is required",
                })}
                name="cost_price"
              />
              <Form.Text muted>
                <span>
                  {errors?.cost_price && (
                    <p className="error-form">
                      {String(errors.cost_price?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId="">
              <Form.Label>Sale Price</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Sale Price"
                {...register("sell_price", {
                  required: "* This field is required",
                })}
                name="sell_price"
              />
              <Form.Text muted>
                <span>
                  {errors?.sell_price && (
                    <p className="error-form">
                      {String(errors.sell_price?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-end mb-3 mt-4">
          <Col className="d-flex justify-content-end">
            <Button className="m-1" onClick={onHandleClose}>
              Cancel
            </Button>
            <Button type="submit" className="m-1">
              {isEdit ? "Update Product" : "Add Product"}
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default AddProductSkuForm;
