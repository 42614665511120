import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/global.scss";
// import Home from './pages/home/Home';
// import List from './pages/list/List';
import Login from "./pages/login/Login";
// import New from './pages/new/New';
// import Single from './pages/single/Single';
import awsconfig from "./aws-exports";
import { Amplify } from "aws-amplify";
import BrandsList from "./pages/Brands/BrandsList";
import OrgList from "./pages/Organization/OrgList";
import AdminUsersList from "./pages/adminUsers/AdminUsersList";
import AddAdmin from "./pages/adminUsers/AddAdmin";
import PartnerList from "./pages/Partner/PartnerList";
import AddOrganization from "./pages/Organization/AddOrganization";
import CreateBrand from "./pages/Brands/createBrand/CreateBrand";
import AddPartner from "./pages/Partner/AddPartner";
import DocumentSettings from "./pages/Documents/documentSettings";
import Success from "./pages/stripe/success";
import ProductDocuments from "./pages/Documents/ProductDocuments";
import ProductList from "./pages/Products/ProductList";
import AddProduct from "./pages/Products/addProduct/AddProduct";
import BrandDocuments from "./pages/Documents/BrandDocuments";
import AddPartnerDocuments from "./pages/Documents/PartnerDocuments";
import PartnerDiscoveryManagement from "./pages/DiscoveryManagement/PartnerDiscoveryManagement";
import AcceptRequest from "./pages/DiscoveryManagement/BrandAcceptRequest";
import BrandDiscoveryManagement from "./pages/DiscoveryManagement/BrandDiscoveryManagement";
import AdminDiscoveryManagement from "./pages/DiscoveryManagement/AdminDiscoveryManagement";
import RecommendPartner from "./pages/DiscoveryManagement/RecommendPartner";
import AdvisoryManagement from "./pages/AdvisoryManagement/AdvisoryManagement";
import SuccessAdvisory from "./pages/stripe/success-advisory";
import SettingsList from "./pages/Settings/SettingsList";
import AdvisoryReportList from "./pages/Reports/AdvisoryReportList";
import AdminDashboard from "./pages/dashboard/AdminDashboard";
import PartnerDashboard from "./pages/dashboard/PartnerDashboard";
import BrandDashboard from "./pages/dashboard/BrandDashboard";
import CountriesList from "./pages/PlatformSettings/CountriesList";
import ViewBrandPayments from "./pages/Brands/createBrand/ViewBrandPayments";
import ForgotPassword from "./pages/forgotpassword/ForgotPassword";
import BrandProfile from "./pages/Brands/brand/BrandProfile";
import FileList from "./pages/FileSettings/FileList";
import SettingFileLIst from "./pages/files/brand/SettingFileLIst";
import BrandFileList from "./pages/files/brand/FileList";
import ProductSettingsFileList from "./pages/files/product/SettingFileList";
import ProductFileList from "./pages/files/product/FileList";
import PartnerSettingsFileList from "./pages/files/partner/PartnerSettingsFileList";
import PartnerFileList from "./pages/files/partner/PartnerFileList";
import RolsList from "./pages/RolesAndPermissions/RolsList";
import AddRole from "./pages/RolesAndPermissions/AddRole";
import AddPermission from "./pages/RolesAndPermissions/AddPermission";
import FileAccess from "./pages/RolesAndPermissions/FileAccess";
import AssignBrandTeam from "./pages/Brands/team/AssignBrandTeam";
import AssignPartnerTeam from "./pages/Partner/AssignPartnerTeam";
import PartnerKeyTerms from "./pages/Partner/PartnerKeyTermsPage";
import PartnerProfile from "./pages/Partner/PartnerProfile";

// import EditBrand from "./pages/EditBrand";
Amplify.configure(awsconfig);

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/">
            <Route index element={<Login />}></Route>
            <Route path="/SetPassword" element={<Login />} />
            <Route path="/AdminUsersList" element={<AdminUsersList />} />
            <Route path="/AddAdminUser" element={<AddAdmin />} />
            <Route path="/EditAdminUser/:id" element={<AddAdmin />} />
            <Route path="/BrandManagement" element={<BrandsList />} />
            <Route path="/OrganizationManagement" element={<OrgList />} />
            <Route path="/PartnerManagement" element={<PartnerList />} />
            <Route path="/addneworganization" element={<AddOrganization />} />
            <Route
              path="/editorganization/:orgid"
              element={<AddOrganization />}
            />
            <Route path="/addpartner" element={<AddPartner />} />
            <Route
              path="/editpartner/:partnerid&&:defaultTab"
              element={<AddPartner />}
            />
            <Route path="/editpartner/:partnerid" element={<AddPartner />} />
            <Route path="addnewbrand" element={<CreateBrand />} />
            <Route
              path="editbrand/:brandid&&:defaultTab"
              element={<CreateBrand />}
            />
            <Route path="editbrand/:brandid" element={<CreateBrand />} />
            <Route path="/documentsettings" element={<DocumentSettings />} />
            <Route
              path="/product-documents/:id&&:type"
              element={<ProductDocuments />}
            />
            <Route
              path="/brand-documents/:id&&:type"
              element={<BrandDocuments />}
            />
            <Route
              path="/partner-documents/:id&&:type"
              element={<AddPartnerDocuments />}
            />
            <Route path="/Stripe/Success" element={<Success />} />
            <Route path="/Advisory-Success" element={<SuccessAdvisory />} />
            <Route path="/ProductList" element={<ProductList />} />
            <Route path="/AddProduct" element={<AddProduct />} />
            <Route path="/EditProduct/:productid" element={<AddProduct />} />
            <Route
              path="/discoverymanagement/org&&:usertype"
              element={<BrandDiscoveryManagement />}
            />
            <Route
              path="/discoverymanagement/:id&&:usertype"
              element={<BrandDiscoveryManagement />}
            />
            <Route
              path="/discoverymanagement/partner"
              element={<PartnerDiscoveryManagement />}
            />
            <Route
              path="/discoverymanagement/admin"
              element={<AdminDiscoveryManagement />}
            />
            <Route
              path="/discoverymanagement-brands/:id&&:brandName"
              element={<AcceptRequest />}
            />
            <Route
              path="/discoverymanagement-recommend/:id&&:brandName&&:defaultTab"
              element={<RecommendPartner />}
            />
            <Route
              path="/discoverymanagement-recommend/:id&&:brandName"
              element={<RecommendPartner />}
            />
            <Route
              path="/advisorymanagement/:usertype"
              element={<AdvisoryManagement />}
            />
            <Route path="/SettingsList" element={<SettingsList />} />
            <Route
              path="/AdvisoryReportList"
              element={<AdvisoryReportList />}
            />
            <Route path="/AdminDashboard" element={<AdminDashboard />} />
            <Route path="/PartnerDashboard" element={<PartnerDashboard />} />
            <Route path="/BrandDashboard" element={<BrandDashboard />} />
            <Route path="/CountriesList" element={<CountriesList />} />
            <Route
              path="/ViewBrandPayments/:brandid"
              element={<ViewBrandPayments />}
            />
            <Route path="/ResetPassword" element={<ForgotPassword />} />
            <Route path="/brand/:id" element={<BrandProfile />} />
            <Route path="/FileList" element={<FileList />} />
            <Route
              path="/brand-filesettings/:brandid"
              element={<SettingFileLIst />}
            />
            <Route
              path="/brand-files/:brandid&&:fileSettingsId"
              element={<BrandFileList />}
            />

            <Route
              path="/partner-filesettings/:partnerid"
              element={<PartnerSettingsFileList />}
            />
            <Route
              path="/partner-files/:partnerid&&:fileSettingsId"
              element={<PartnerFileList />}
            />

            <Route
              path="/product-filesettings/:productid"
              element={<ProductSettingsFileList />}
            />

            <Route
              path="/product-files/:productid&&:fileSettingsId"
              element={<ProductFileList />}
            />
            <Route path="/RolesList" element={<RolsList />} />
            <Route path="/AddRole" element={<AddRole />} />
            <Route path="/EditRole/:roleid" element={<AddRole />} />
            <Route
              path="/AddPermission/:typeid&&:roleid"
              element={<AddPermission />}
            />
            <Route
              path="/EditPermission/:typeid&&:roleid&&:edit"
              element={<AddPermission />}
            />
            <Route
              path="/FileAccess/:typeid&&:roleid"
              element={<FileAccess />}
            />
            <Route
              path="/EditFileAccess/:typeid&&:roleid&&:edit"
              element={<FileAccess />}
            />
            <Route
              path="/assignbrandteam/:brandid"
              element={<AssignBrandTeam />}
            />
            <Route
              path="/assignpartnerteam/:partnerid"
              element={<AssignPartnerTeam />}
            />
            <Route path="/partneragreement" element={<PartnerKeyTerms />} />
            <Route path="/partner/:partnerId" element={<PartnerProfile />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
