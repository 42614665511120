import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./DiscoveryManagement.scss";
import "../../styles/global.scss";
import "../../styles/pagelayout.scss";
// @ts-ignore
import Cookies from "js-cookie";
// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Auth from "@aws-amplify/auth";

// Components
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import TableMUI from "../components/TableMUI/TableMUI";
import { BrandDiscoveryTableColumns } from "../components/TableMUI/TableColumns";
import Sidebar from "../components/sidebar/Sidebar";

// Functions
import AddPartnerRequest from "../components/modalForms/DiscoveryManagement/PartnerRequest";
import DetailsDisplayModal from "../components/modalForms/DiscoveryManagement/DetailsDisplayModal";
import {
  GetAllBrandRequests,
  GetBrandRequestsDataRestriction,
  GetBrandRequestsForOrg,
  GetOrgBrands,
} from "../../API/endpoints/Discovery";
import { PartnerRequestsDTO } from "../dtos/discovery";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";
import { GetPublicFiles } from "../../API/endpoints/fileupload/Files";

function BrandDiscoveryManagement() {
  const navigate = useNavigate();
  const params = useParams();
  const routeToForm = () => {
    setShowPartnerModal(true);
  };

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [flag, setFlag] = useState(false);
  const [showDeleteBrand, setShowDeleteBrand] = useState(false);
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [brandsList, setBrandsList] = useState<any[]>([]);
  const [data, setData] = useState<PartnerRequestsDTO>();
  const [dataCount, setDataCount] = useState(0);
  const [listPage, setListPage] = useState(0);
  const [selectedRequestID, setSelectedRequestID] = useState<string>("");
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [searchParam, setSearchParam] = useState<string>("");
  const [selectedRequestBrandID, setSelectedRequestBrandID] =
    useState<string>("");
  const [usertype, setUsertype] = useState<string | undefined>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [dataRestriction, setDataRestriction] = useState(false);
  const [orgId, setOrgId] = useState<string | undefined>("");
  const [roleID, setRoleID] = useState<string>("");
  const [permissions, setPermissions] = useState<any[]>([]);
  const [publicFiles, setPublicFiles] = useState<any>([]);
  const handleClose = () => {
    setFlag((prev) => !prev);
    setShowPartnerModal(false);
  };
  const handleCloseDelete = () => setShowDeleteBrand(false);
  const handleCloseDetailsModal = () => setIsDetailsModalOpen(false);

  const columns = BrandDiscoveryTableColumns(
    navigate,
    setIsDetailsModalOpen,
    setSelectedRequestID,
    setSelectedRequest,
    setSelectedRequestBrandID,
    usertype,
    permissions
  );

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setOrgId(response.data?.organization?.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID?.toString());
      setDataRestriction(roleDetails?.data?.data_restriction);

      if (roleDetails?.data?.type === "organization") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID?.toString(),
          "10"
        );
        console.log(response);
        setPermissions(response?.data);
      } else if (roleDetails?.data?.type === "administrative") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID?.toString(),
          "5"
        );
        console.log(response);
        setPermissions(response?.data);
      }
    })();
  }, [roleID]);

  let rows: {
    id: string;
    brand_id: string | undefined;
    brandName: string;
    country: string | undefined;
    postedDate: string;
    match_completion_date: string;
    suitable_partners_count: number;
    no_of_req: number;
    confirmedPartners: number;
    status: string;
  }[] = [];

  const dateFormatter = (date: any) => {
    return `${date?.toString().substring(8, 10)}/${date
      ?.toString()
      .substring(5, 7)}/${date?.toString().substring(0, 4)}`;
  };

  if (data?.rows) {
    rows = data.rows?.map((item) => {
      const dataItem = item.brandRequest;
      let confirmedCount = 0;
      if (dataItem?.discovery_partners?.length > 0) {
        dataItem.discovery_partners.forEach((element) => {
          if (
            element.status === "Completed" ||
            element.status === "In progress" ||
            element.status === "In Progress"
          )
            confirmedCount = confirmedCount + 1;
        });
      }
      return {
        id: dataItem?.id,
        brand_id: dataItem?.brand_id,
        brandName: dataItem?.brand?.brand_name,
        country: dataItem?.country?.name,
        postedDate: dataItem?.created_at
          ? dateFormatter(dataItem?.created_at)
          : "Not Available",
        match_completion_date: dataItem?.match_completion_date
          ? dateFormatter(dataItem?.match_completion_date)
          : "Not Available",
        no_of_req: dataItem?.discovery_partners?.length,
        confirmedPartners: confirmedCount,
        status: dataItem?.status,
        suitable_partners_count: item?.suitable_partners_count,
      };
    });
  }

  useEffect(() => {
    if (
      usertype === "brand" &&
      permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission
    ) {
      (async () => {
        const res = await GetOrgBrands(orgId);
        console.log(res);
        setBrandsList([]);
        res.data.rows.map((brand: { id: any }) =>
          setBrandsList((prevState) => [...prevState, brand.id])
        );
      })();
    } else if (
      usertype === "admin" &&
      permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission
    ) {
      if (dataRestriction) {
        (async () => {
          const res = await GetBrandRequestsDataRestriction(
            userID,
            listPage,
            searchParam
          );
          console.log(res);
          setDataCount(res.data.count);
          setData(res.data);
        })();
      } else {
        (async () => {
          const res = await GetAllBrandRequests(listPage, searchParam);
          console.log(res);
          setDataCount(res.data.count);
          setData(res.data);
        })();
      }
    }
  }, [
    orgId,
    listPage,
    usertype,
    flag,
    showPartnerModal,
    permissions,
    dataRestriction,
    userID,
    searchParam,
  ]);

  useEffect(() => {
    setUsertype(params.usertype);
  }, [params]);

  useEffect(() => {
    (async () => {
      if (brandsList && brandsList.length) {
        const res = await GetBrandRequestsForOrg({ brand_ids: brandsList });
        setDataCount(res.data.length);
        setData({ rows: res.data });
      }
    })();
  }, [navigate, flag, params, brandsList, showPartnerModal]);

  useEffect(() => {
    if (isDetailsModalOpen) {
      (async () => {
        try {
          const response = await GetPublicFiles(
            "brand",
            selectedRequestBrandID
          );
          setPublicFiles(response.data);
        } catch (error) {}
      })();
    }
  }, [isDetailsModalOpen, selectedRequestBrandID]);

  const deleteFunction = async () => {
    setFlag(false);
    setFlag(true);
  };

  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="discovery" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item>
              DISCOVERY MANAGEMENT
              {/* {usertype === "brand" && (
                <Link to="/discoverymanagement/org">DISCOVERY MANAGEMENT</Link>
              )}
              {usertype === "admin" && (
                <Link to="/discoverymanagement/admin">
                  DISCOVERY MANAGEMENT
                </Link>
              )} */}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>PARTNER MATCHING REQUESTS</Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button size="sm" className="btn-primary12 addfilter">
              Add Filter
            </Button> */}
            {permissions[0]?.sub_modules[0]?.permissions_actions[0]
              ?.permission[0]?.permission && (
              <Button onClick={routeToForm} className="btn-primary12 adduser">
                Add New Partner Request
              </Button>
            )}
          </div>
          <div className="table-mui">
            <div className="TableMUIcountainer">
              <TableMUI
                columns={columns}
                rows={rows}
                dataCount={dataCount}
                setListPage={setListPage}
                listPage={listPage}
              />
            </div>
            <PopupTemplate
              popupHeader="Confirm Deletion."
              popupBody={`Are you sure you want to delete brand`}
              handleFunction={deleteFunction}
              isModalOpen={showDeleteBrand}
              onCancel={handleCloseDelete}
              id={"1"}
              setFlag={setFlag}
            />
            <AddPartnerRequest
              onCancel={handleClose}
              setFlag={setFlag}
              flag={flag}
              isModalOpen={showPartnerModal}
              dataRestriction={dataRestriction}
            />
            <DetailsDisplayModal
              isModalOpen={isDetailsModalOpen}
              onCancel={handleCloseDetailsModal}
              selectedRequestID={selectedRequestID}
              selectedRequest={selectedRequest}
              selectedRequestBrandID={selectedRequestBrandID}
              publicFiles={publicFiles}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default BrandDiscoveryManagement;
