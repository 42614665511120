import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./AdvisoryManagement.scss";
import "../../styles/global.scss";

// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// @ts-ignore
import Cookies from "js-cookie";

// Components
import TableMUI from "../components/TableMUI/TableMUI";
import { AdvisoryManagementTableColumns } from "../components/TableMUI/TableColumns";
import Sidebar from "../components/sidebar/Sidebar";

// Functions
import RequestNewReport from "./components/RequestNewReport";
import {
  GetAdvisoryRequestsDataRestriction,
  GetAllReportRequests,
  GetReportRequestsForOrg,
} from "../../API/endpoints/Advisory";
import { AdvisoryManagementTableDTO } from "../dtos/advisory.dtos";
import AdvisoryReportUploadModal from "../components/modalForms/AdvisoryManagement/AdvisoryReportUpload";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";

function AdvisoryManagement() {
  const [flag, setFlag] = useState(false);
  const [rows, setRows] = useState<AdvisoryManagementTableDTO[]>([]);
  const [data, setData] = useState<AdvisoryManagementTableDTO[]>([]);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [usertype, setUsertype] = useState<string | undefined>("");
  const [selectedRow, setSelectedRow] = useState<any>();
  const [orgID, setOrgID] = useState<string | undefined>("");
  const [listPage, setListPage] = useState(0);
  const [searchParam, setSearchParam] = useState<string>("");
  const [dataCount, setDataCount] = useState(20);
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [dataRestriction, setDataRestriction] = useState(false);
  const [roleID, setRoleID] = useState<string | undefined>("");
  const [permissions, setPermissions] = useState<any>([]);
  const params = useParams();
  const columns = AdvisoryManagementTableColumns(
    setSelectedRow,
    usertype,
    setShowUploadModal,
    permissions
  );

  const dateFormatter = (date: any) => {
    return `${date?.toString().substring(8, 10)}/${date
      ?.toString()
      .substring(5, 7)}/${date?.toString().substring(0, 4)}`;
  };

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setOrgID(response.data?.organization?.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID]);

  useEffect(() => {
    if (roleID) {
      (async () => {
        const roleDetails = await GetRoleById(roleID.toString());
        setDataRestriction(roleDetails?.data?.data_restriction);

        if (roleDetails?.data?.type === "organization") {
          setUsertype("brand");
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "11"
          );
          console.log(response);
          setPermissions(response?.data);
        } else if (roleDetails?.data?.type === "administrative") {
          setUsertype("admin");

          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "6"
          );
          console.log(response);
          setPermissions(response?.data);
        }
      })();
    }
  }, [roleID]);

  useEffect(() => {
    if (data.length > 0) {
      setRows(
        data.map(
          (dataItem: {
            id?: string;
            brand?: any;
            brand_id?: string;
            brand_organization?: any;
            advisory_image?: any;
            report_name?: any;
            report_category_name?: any;
            report?: any;
            report_price?: any;
            created_at?: any;
            lead_time?: string;
            status?: string;
          }) => ({
            id: dataItem?.id,
            brand: dataItem?.brand_id,
            brandname: dataItem?.brand?.brand_name,
            document: dataItem?.advisory_image[0]
              ? dataItem?.advisory_image[0]
              : {},
            reportcategory: dataItem?.report?.reports_category_id,
            reportcategoryname: dataItem?.report_category_name,
            reportname: dataItem?.report_name,
            reportprice: dataItem?.report?.report_price,
            listeddate: dateFormatter(dataItem?.created_at),
            leadtime: dataItem?.lead_time + " days",
            status: dataItem?.status,
            report: dataItem?.report,
          })
        )
      );
    }
  }, [data]);

  useEffect(() => {
    const GetOrgReportRequests = async () => {
      if (usertype === "brand") {
        (async () => {
          const response = await GetReportRequestsForOrg(
            orgID,
            listPage,
            searchParam
          );

          if (response) {
            setData([]);
            console.log("HERE", response);
            setData((prevData) => [...prevData, ...response.data]);
          } else console.log("Cannot get Brands Data");
        })();
      } else if (usertype === "admin") {
        if (dataRestriction) {
          (async () => {
            setData([]);
            const response = await GetAdvisoryRequestsDataRestriction(
              userID,
              listPage,
              ""
            );
            if (response) {
              console.log(response);
              const newResponse = response?.data?.filter(
                (res: { status: string }) =>
                  res.status === "Paid" || res.status === "Document Uploaded"
              );
              console.log(newResponse);
              setData(newResponse);
              setDataCount(newResponse?.length);
            } else console.log("Cannot get Brands Data");
          })();
        } else {
          (async () => {
            setData([]);
            const response = await GetAllReportRequests(listPage, "");
            if (response) {
              console.log(response);
              setDataCount(response.data.count);
              const newResponse = response?.data?.rows?.filter(
                (res: { status: string }) =>
                  res.status === "Paid" || res.status === "Document Uploaded"
              );
              console.log(newResponse);
              setData(newResponse);
            } else console.log("Cannot get Brands Data");
          })();
        }
      }
    };

    if (
      permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission
    ) {
      GetOrgReportRequests();
    }
  }, [
    usertype,
    flag,
    listPage,
    orgID,
    permissions,
    userID,
    dataRestriction,
    searchParam,
  ]);

  const handleClose = () => {
    setShowRequestModal(false);
    setShowUploadModal(false);
  };

  const routeToForm = () => {
    if (params.usertype === "admin") {
      console.log("admin");
    }
    if (params.usertype === "brand") {
      setShowRequestModal(true);
    }
  };

  return (
    <Container fluid className="adminusers">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="advisory" />
        </Col>
        <Col className="adminusers-content p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Link
              to={
                params.usertype === "brand"
                  ? "/advisorymanagement/brand"
                  : "/advisorymanagement/admin"
              }
            >
              ADVISORY MANAGEMENT /
            </Link>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button size="sm" className="btn-primary12 addfilter">
              Add Filter
            </Button> */}
            {params.usertype === "brand" &&
              permissions[0]?.sub_modules[0]?.permissions_actions[0]
                ?.permission[0]?.permission && (
                <Button onClick={routeToForm} className="btn-primary12 adduser">
                  {params.usertype === "brand" && "Request New Report"}
                </Button>
              )}
          </div>
          <div className="adminusertable">
            <div className="TableMUIcountainer">
              <TableMUI
                columns={columns}
                rows={rows}
                setListPage={setListPage}
                dataCount={dataCount}
                listPage={listPage}
              />
            </div>

            <RequestNewReport
              isModalOpen={showRequestModal}
              onCancel={handleClose}
              setFlag={setFlag}
              id={""}
              flag={flag}
              orgID={orgID}
            />

            <AdvisoryReportUploadModal
              isModalOpen={showUploadModal}
              onCancel={handleClose}
              setFlag={setFlag}
              flag={flag}
              advisory_id={selectedRow?.id}
              selectedDocument={selectedRow}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AdvisoryManagement;
