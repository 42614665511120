import { getWith, postWith } from "../axios";

export async function GetActivity(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/activity/`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreateActivity(message: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/activity/`;
    const msg = { message: message };
    return (await postWith<any, any>(url, msg)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function MarkAsRead(id: Number): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/activity/` + id;
    return (await postWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetDashboardCounts(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/dashboard/counts`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function MarkAllAsRead(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/activity/read-all`;
    return (await postWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}