import { postWith } from "../axios";

export async function SendMatchingPartnerEmail(data: {
  serviceList: string[];
  brandName: string;
  market: number;
  brand_id: any;
  discovery_brands_id?: any;
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/email/send/discovery-find-partner`;
    return (await postWith<any, any>(url, data)) as any;
    // console.log(data, url);
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function SendReportUploadConfirmationEmail(data: {
  advisory_id: any;
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/email/send/report-upload-confirmation`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function sendNewReportNotificationEmail(data: {
  advisory_id: any;
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/email/send/new-report-notification`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function sendPartnerInterestExpressedEmail(data: {
  discovery_brands_id: any;
  partner_id: any;
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/email/send/partner-interest-expressed`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function sendRecommendationNotification(data: {
  brand_id: string;
  partner_id: string;
  discovery_brands_id: number;
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/email/send/recommendation-notification`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
