import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import ImageUpload from "../../fileInput/ImageUpload";

export interface addCountryProps {
  onCancel: () => void;
  submitting: boolean;
  onSubmit: (data: any) => void;
  defaultData: any;
}

const AddCountryForm: React.FunctionComponent<addCountryProps> = ({
  onCancel,
  submitting,
  onSubmit,
  defaultData,
}) => {
  const { register, formState, handleSubmit, reset } = useForm({
    defaultValues: defaultData,
  });
  const { errors } = formState;
  const [market_presence, setMarketPresence] = useState<boolean>(false);
  const [all, setAll] = useState(false);
  const [file, setFile] = useState<any>([]);
  const onHandleSubmit = (
    name: string,
    country__alpha_code: string,
    country_code: string
  ) => {
    const contact_number = true;
    const data = new FormData();
    if (file[0]) {
      data.append("file", file[0]);
    }
    data.append("name", name);
    data.append("country__alpha_code", country__alpha_code);
    data.append("country_code", country_code);
    data.append("market_presence", market_presence.toString());
    data.append("contact_number", contact_number.toString());
    onSubmit(data);
  };
  const onHandleClose = () => {
    onCancel();
  };
  const handleImageUpload = (event: any) => {
    if (event.length === 1) {
      setFile(event);
    }
  };
  useEffect(() => {
    if (defaultData) {
      const defaults = {
        name: defaultData?.name,
        country__alpha_code: defaultData?.country__alpha_code,
        country_code: defaultData?.country_code,
      };
      reset(defaults);
      setMarketPresence(defaultData?.market_presence);
      setAll(defaultData?.all);
    }
  }, [reset, defaultData]);
  useEffect(() => {}, [market_presence, all]);
  return (
    <Form
      onSubmit={handleSubmit((data) =>
        onHandleSubmit(data.name, data.country__alpha_code, data.country_code)
      )}
    >
      <Container fluid>
        <Row>
          <Col className="p-0">
            <div className="d-flex">
              <div className="font14-medium gray-700 mr28px">
                Market Presence ?
              </div>
              <Form.Group className="mr48px" controlId="yes">
                <Form.Check
                  id="yes"
                  label="Yes"
                  type="radio"
                  name="group1"
                  className="font14-medium gray-700"
                  // defaultChecked={market_presence === true ? true : false}
                  checked={market_presence === true ? true : false}
                  onChange={() => {
                    setMarketPresence(true);
                    setAll(true);
                  }}
                />
              </Form.Group>
              <Form.Group controlId="no">
                <Form.Check
                  id="no"
                  label="No"
                  type="radio"
                  name="group1"
                  className="font14-medium gray-700"
                  // defaultChecked={market_presence === false ? true : false}
                  checked={market_presence === false ? true : false}
                  onChange={() => {
                    setMarketPresence(false);
                    setAll(false);
                  }}
                />
              </Form.Group>
            </div>
          </Col>
          {/* <Col className="p-0">
                    </Col>
                    <Col className="p-0">
                    </Col> */}
        </Row>
        <Row className="mt20px">
          <Col className="p-0">
            <Form.Group className="formgroup mr12px" controlId="reportcategory">
              <Form.Label>Country Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Country Name"
                {...register("name", {
                  required: "* This field is required",
                })}
              />
              <Form.Text muted>
                <span>
                  {errors?.name && (
                    <p className="error-form">{String(errors.name?.message)}</p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col className="p-0">
            <Form.Group className="formgroup" controlId="formFile">
              <Form.Label>Country Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Country Code"
                {...register("country__alpha_code", {
                  required: "* This field is required",
                })}
              />
              <Form.Text muted>
                <span>
                  {errors?.country__alpha_code && (
                    <p className="error-form">
                      {String(errors.country__alpha_code?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt20px">
          <Col xs={12} className="p-0">
            <Form.Group className="formgroup" controlId="formFile">
              <Form.Label>Telephone Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Telephone Code"
                {...register("country_code", {
                  required: "* This field is required",
                })}
              />
              <Form.Text muted>
                <span>
                  {errors?.country_code && (
                    <p className="error-form">
                      {String(errors.country_code?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt20px">
          <Col className="p-0">
            <Form.Group className="formgroup" controlId="formFile">
              <Form.Label>Upload Country Flag Icon</Form.Label>
            </Form.Group>
            <ImageUpload
              handleImageUpload={handleImageUpload}
              requirement="SVG, PNG or JPG (28px x 20px )"
              fileAccept="image/*"
            />
          </Col>
          {file.length !== 0 && (
            <Col xs={12} className="mt-2 p-0 mb-2">
              <img
                height={50}
                width={50}
                src={URL.createObjectURL(file[0])}
                alt="product_image"
              />
            </Col>
          )}
        </Row>
        <Row className="justify-content-end bt-gray200">
          <Col className="d-flex justify-content-end p-0 mt-3">
            <Button className="m-1 btn-secondary12" onClick={onHandleClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              className="m-1 btn-primary12"
              disabled={submitting ? true : false}
            >
              Update
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default AddCountryForm;
