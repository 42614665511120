import "./EmptyContent.scss";
export interface PropsEmpty {
    message: string;
}

const EmptyContent: React.FunctionComponent<PropsEmpty> = ({
    message
}) => {
    return (
        <div className="empty-content">
            <div
                className="illustration"
            >
                <img
                    src="../images/illustration-empty.png"
                    alt="empty"
                />
                <div>
                    <span className="font14-regular gray-600">
                        It’s empty here.
                        <span className="primary-blue"> {message}</span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default EmptyContent;