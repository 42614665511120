import "./Url.scss";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import { useState, useRef } from "react";
export interface FileProps {
    url_type_id: number;
    url: string;
    url_notes: string;
    urlTypes: any;
    urlId: any;
    handleSelection: (checked: boolean, Id: any) => void;
    isChecked: boolean;
}
const File: React.FunctionComponent<FileProps> = ({
    url_type_id,
    url,
    url_notes,
    urlTypes,
    urlId,
    handleSelection,
    isChecked
}) => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event: any) => {
        setShow(!show);
        setTarget(event.target);
    };
    const handleClose = () => {
        setShow(!show);
    }
    const handleChange = (e: any, urlId: any) => {
        handleSelection(e, urlId);
    }
    return (
        <div className="url-list">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <Form.Check
                                type="checkbox"
                                label=""
                                checked={isChecked}
                                onChange={(e) => {
                                    handleChange(e.target.checked, urlId)
                                }}
                            />
                        </td>
                        <td>
                            <Form.Select
                                disabled
                                // onChange={(e) => {
                                //     handleurlTypeChanged(e.target.value, index)
                                // }}
                                value={url_type_id}
                            >
                                <option value="" >select</option>
                                {urlTypes?.map((item: any) => {
                                    return (
                                        <option
                                            key={item.id}
                                            value={item.id}
                                        >{item.url_type_name}</option>
                                    )
                                })}
                            </Form.Select>
                        </td>
                        <td>
                            <div className="urlnote-wraper">
                                <div className="url-tag">
                                    <a
                                        href={url}
                                        rel="noreferrer"
                                        target="_blank">
                                        {url}
                                    </a>
                                </div>
                                <div className="url-note" ref={ref}>
                                    <Button onClick={handleClick} className="note-btn">
                                        <img src="../svgs/note.svg" alt="note" />
                                    </Button>

                                    <Overlay
                                        show={show}
                                        target={target}
                                        placement="top"
                                        container={ref}
                                        containerPadding={20}
                                    >
                                        <Popover>
                                            <Popover.Header>
                                                <div>Notes</div>
                                                <img
                                                    src="../svgs/close-btn.svg"
                                                    alt="close"
                                                    onClick={handleClose}
                                                />
                                            </Popover.Header>
                                            <Popover.Body>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Type here..."
                                                    disabled
                                                    value={url_notes}
                                                // onChange={(e) => {
                                                //     handleNoteChanged(e.target.value, index)
                                                // }}
                                                />
                                            </Popover.Body>
                                        </Popover>
                                    </Overlay>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default File;