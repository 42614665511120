import Table from "react-bootstrap/Table";
import "./ActivityFeed.scss";
export interface activityFeedProps {
  activies: any,
  markRead: (id: Number) => void;
  markAllRead: () => void;
}

const ActivityFeed: React.FunctionComponent<activityFeedProps> = ({
  activies,
  markRead,
  markAllRead
}) => {
  const dateFormatter = (d: any) => {
    const createdAt = new Date(d)
    let createdDate = createdAt.getDate() + "-" + (createdAt.getMonth()+1) + "-" + createdAt.getFullYear()
    let createdTme = createdAt.toLocaleTimeString('en',
    { timeStyle: 'short', hour12: false, timeZone: 'UTC' })
    return "Created at " + createdDate + " " + createdTme
  };
  const markAsRowRead = (id: any) => {
    markRead(id)
  }
  const ReadAll =  () => {
    markAllRead();
  }
  return (
    <div className="feed-wraper">
      <div className="markasread">
        <button
        className="blue-600 font12-regular btn-masr"
        onClick={ReadAll}
        >
          mark all as read
        </button>
      </div>
      <Table hover borderless responsive className="activity-table">
        <tbody>
          {activies.map((row: any, index: any) => {
            return (
              <tr key={index}
                onClick={() => {
                  markAsRowRead(row.id)
                }}
              >
                <td width={36}>
                  {row.read_status === "Unread" && <div className="read-status"></div>}
                  <img src="../svgs/feedicon.svg" alt="feedicon" />
                </td>
                <td>
                  <div>
                    <span className="font12-regular gray-600">
                      {row.message}
                    </span>
                  </div>
                  <div className="font10-regular gray-500">{dateFormatter(row.created_at)}</div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ActivityFeed;
