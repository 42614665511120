import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import AddProductSkuForm from "../../forms/addProductSkuForm";
import { ProductSkuDataInput } from "../../../dtos/product.dtos";

export interface ProductSkuProps {
  isModalOpen: boolean;
  onCancel: () => void;
  onSubmitSku: (data: ProductSkuDataInput) => void;
  isEdit: boolean;
  selectedProduct: any;
  handleNotification: (type: string, msg: string) => void;
}

const AddProductSkuModal: React.FunctionComponent<ProductSkuProps> = ({
  isModalOpen,
  onCancel,
  onSubmitSku,
  isEdit,
  selectedProduct,
  handleNotification
}) => {
  const handleClose = () => onCancel();
  return (
    <Container fluid>
      <Row>
        <Col className="p-0">
          <Modal show={isModalOpen} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add New Variant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddProductSkuForm
                onCancel={onCancel}
                onSubmitSku={onSubmitSku}
                isEdit={isEdit}
                selectedProduct={selectedProduct}
                handleNotification={handleNotification}
              />
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default AddProductSkuModal;
