import "./login.scss";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
// @ts-ignore
import Cookies from "js-cookie";
import LoginForm from "../components/forms/loginForm";
import { useNavigate } from "react-router-dom";
import OtpVerificationForm from "../components/forms/otpVerificationForm";
import PasswordForm from "../components/forms/passwordForm";
import { getWith } from "../../API/axios";
import { Container, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { GetOrgBrands } from "../../API/endpoints/Brands";
import { GetPartner } from "../../API/endpoints/Partner";
import {
  activateOrganization,
  activateUser,
  getUserByEmailId,
  GetUserDetails,
} from "../../API/endpoints/User";
import SendResetLinkModal from "../components/modalForms/forgotPassword/SendResetLinkModal";
import SuccessModal from "../components/Modal/SuccessModal";
import { GetOrg } from "../../API/endpoints/Organization";

const Login = () => {
  const isLoginPage = useLocation().pathname === "/" ? true : false;
  const [isError, setisError] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showOtpVerification, setShowOtpVerification] = useState(true);
  const [showPasswordPage, setShowPasswordPage] = useState(false);
  const [user, setUser] = useState({});
  const [resetLinkModal, setResetLinkModal] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const navigate = useNavigate();
  const handlelogin = async (email: string, password: string) => {
    setSubmitting(true);
    Auth.signIn(email, password)
      .then(async (response) => {
        setSubmitting(false);
        const { attributes } = await Auth.currentAuthenticatedUser();
        const url =
          `${process.env.REACT_APP_API_URL}/user/getUserByCognitoId/` +
          attributes["custom:cognito_id"];
        const UserData = await getWith<any>(url);
        console.log(UserData);
        const UserType = UserData.data?.user_type;
        const orgID = UserData.data?.org_id;
        Cookies.set("userID", UserData.data?.id);
        if (UserType === "administrative") {
          Cookies.set("IsActivated", "true");
          navigate("/AdminDashboard");
        } else if (UserType === "partner") {
          const partnerID = UserData.data?.partner_id;
          const res = await GetPartner(partnerID);
          if (res.data?.is_deleted) {
            Auth.signOut();
            setisError(true);
            seterrorMessage("Partner is temporarily deleted.");
          } else {
            Cookies.set("PartnerID", UserData.data.partner_id);
            if (res.data?.status === "Activated") {
              navigate("/PartnerDashboard");
              Cookies.set("IsActivated", "true");
              navigate("/PartnerDashboard");
            } else {
              Cookies.set("IsActivated", "false");
              navigate("/partneragreement");
            }
          }
        } else if (UserType === "organization") {
          const org = await GetOrg(orgID);
          if (org.data?.is_deleted) {
            setisError(true);
            seterrorMessage("Org is temporarily deleted.");
            Auth.signOut();
          } else {
            const data = await GetOrgBrands(orgID);
            const response = data?.data?.rows?.filter(
              (item: any) => item.status === "Activated"
            );
            if (response.length > 0) {
              Cookies.set("IsActivated", "true");
            } else {
              Cookies.set("IsActivated", "false");
            }
            navigate("/BrandDashboard");
          }
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        setSubmitting(false);
        setisError(true);
        seterrorMessage(err.message);
        console.log("Error => ", err);
      });
  };

  const handleVerifyEmail = (email: string, code: string) => {
    setSubmitting(true);
    setEmail(email);
    Auth.signIn(email, code)
      .then((cognitoUser) => {
        console.log(cognitoUser);
        const attributes = cognitoUser?.challengeParam?.userAttributes;
        (async () => {
          const userData = await GetUserDetails(attributes);
          const orgID = userData?.data?.org_id;
          console.log(orgID, userData);
          if (orgID !== null && userData?.data?.user_type === "organization") {
            const res = await activateOrganization(orgID);
            console.log(res);
          }
          setSubmitting(false);
          setUser(cognitoUser);
          setShowOtpVerification(false);
          setShowPasswordPage(true);
        })();
      })
      .catch((err) => {
        setSubmitting(false);
        setisError(true);
        seterrorMessage(err.message);
        console.log("Error => ", err);
      });
  };

  const handleSetNewPassword = (password: string) => {
    setSubmitting(true);
    Auth.completeNewPassword(user, password)
      .then(async (success) => {
        console.log(success);
        const user = await getUserByEmailId(email);
        await activateUser(user.data.id);
        setSubmitting(false);
        Auth.signOut().then(() => {
          Cookies.remove("userID");
          navigate("/");
        });
      })
      .catch((err) => {
        setSubmitting(false);
        setisError(true);
        seterrorMessage(err.message);
        console.log("Error => ", err);
      });
  };

  const handleShowResetLinkModal = () => setResetLinkModal(true);
  const handleCloseResetLinkModal = () => {
    setEmail("");
    setResetLinkModal(false);
    seterrorMessage("");
  };
  const handleCloseSuccessModal = () => {
    setEmail("");
    setLinkSent(false);
  };
  const handleSendtLinkTomail = (email: string) => {
    setEmail(email);
    Auth.forgotPassword(email)
      .then((data) => {
        console.log(data);
        setLinkSent(true);
        setResetLinkModal(false);
      })
      .catch((err) => {
        console.log(err.message);
        seterrorMessage("Invalid Email Address");
      });
  };
  return (
    <Container fluid className="login-container">
      <Row>
        <Col className="left-content">
          <Container fluid>
            <Row>
              <Col xs={12} className="col-1">
                <img src="../svgs/aeglogo.svg" alt="aeglogo.svg" />
              </Col>
              <Col xs={12} className="col-2">
                <div className="unlockyour">
                  Unlock <span>your</span>
                </div>
                <div className="growth-journey">Asian Growth Journey</div>
                <div className="font16-medium gray-200 text-signup">
                  Sign up for access invite :
                </div>
                <Button
                  className="signup-btn btn-mr"
                  onClick={() => {
                    window.open(
                      `https://form.typeform.com/to/AIb1wwAG?typeform-source=www.google.com`,
                      "_blank"
                    );
                  }}
                >
                  Brands
                </Button>
                <Button
                  className="signup-btn"
                  onClick={() => {
                    window.open(
                      `https://form.typeform.com/to/nZhIEs2M?typeform-source=www.google.com`,
                      "_blank"
                    );
                  }}
                >
                  Partners
                </Button>
                <div className="learnmore">
                  <span className="font14-regular gray-200">
                    Learn more at :{" "}
                  </span>
                  <a
                    className="font14-regular gray-200"
                    href="https://www.asiaecommgroup.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.asiaecommgroup.com
                  </a>
                </div>
              </Col>
              <Col xs={12} className="col-3">
                <div>
                  <div className="font14-medium white">Connect with us :</div>
                  <img
                    src="../svgs/facebook.svg"
                    alt="facebook"
                    onClick={() => {
                      window.open(
                        `https://www.facebook.com/asiaecommgroup`,
                        "_blank"
                      );
                    }}
                  />
                  <img
                    src="../svgs/instagram.svg"
                    alt="instagram"
                    onClick={() => {
                      window.open(
                        `https://www.instagram.com/asiaecommgroup/?hl=en`,
                        "_blank"
                      );
                    }}
                  />
                  <img
                    src="../svgs/linkedin.svg"
                    alt="linkedin"
                    onClick={() => {
                      window.open(
                        `https://www.linkedin.com/company/asiaecommgroup/`,
                        "_blank"
                      );
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col className="right-content">
          {isLoginPage ? (
            <LoginForm
              submitting={submitting}
              onSubmit={handlelogin}
              isError={isError}
              errorMessage={errorMessage}
              handleShowResetLinkModal={handleShowResetLinkModal}
            />
          ) : (
            <div className="opt-setpassword-wraper">
              {showOtpVerification && (
                <OtpVerificationForm
                  otpSubmitting={submitting}
                  onSubmit={handleVerifyEmail}
                  isOtpError={isError}
                  otpErrorMessage={errorMessage}
                />
              )}
              {showPasswordPage && (
                <PasswordForm
                  forgotSubmitting={submitting}
                  onSubmit={handleSetNewPassword}
                  email={email}
                  isForgotError={isError}
                  ForgotErrorMessage={errorMessage}
                />
              )}
            </div>
          )}
          <SendResetLinkModal
            resetLinkModal={resetLinkModal}
            handleCloseModal={handleCloseResetLinkModal}
            onSubmit={handleSendtLinkTomail}
            errorMessage={errorMessage}
          />
          <SuccessModal
            isModalOpen={linkSent}
            handleCloseModal={handleCloseSuccessModal}
            email={email}
            btn={true}
            link=""
            linkText=""
            title="Code Sent"
            bodyText={`A code to reset your password has been sent to
          ${email.replace(/(\w{1})(.*)(\w{1})@(.*)/, "$1******$3@$4")}`}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
