import Button from "react-bootstrap/Button";
import "./forgotPasswordForm.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import PasswordStrengthMeter from "../../passwordStrengthMeter/passwordStrengthMeter";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export interface forgotPasswordFormProps {
  onSubmit: any;
  email: string;
}

const ForgotPasswordForm: React.FunctionComponent<forgotPasswordFormProps> = ({
  onSubmit,
  email,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
    },
  });

  useEffect(() => {
    let defaults = {
      email: email,
    };
    reset(defaults);
  }, [reset, email]);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [showNewpass, setShowNewpass] = useState("password");
  const [showpassword, setShowPassword] = useState("password");
  const [errorMessage, setErrorMessage] = useState("");

  const handleShowNewPass = () => {
    if (showNewpass === "password") {
      setShowNewpass("text");
    } else if (showNewpass === "text") {
      setShowNewpass("password");
    }
  };
  const handleShowPass = () => {
    if (showpassword === "password") {
      setShowPassword("text");
    } else if (showpassword === "text") {
      setShowPassword("password");
    }
  };
  const onHandleSubmit = (data: any) => {
    console.log(data);
    if (data.confirmPassword !== data.password) {
      setErrorMessage("Passwords do not match");
      return;
    } else {
      onSubmit(data);
    }
  };
  return (
    <Form
      className="forgotPasswordForm"
      onSubmit={handleSubmit((data) => onHandleSubmit(data))}
    >
      <div>
        <div className="font20-medium primaryPalette-900 mb6px">
          Set a New Password
        </div>
        <div className="font14-regular gray-500 mb24px">
          Create a strong password for your account
        </div>
        {/* <div className="font12-regular mb40px">
          <span className="gray-500">Your password should contain - </span>
          <span className="gray-800 font12-medium">
            an uppercase letter,a lowercase letter, a number and special
            character.
          </span>
        </div> */}
      </div>
      <div className="input-email mb24px">
        <Form.Label>Email</Form.Label>
        <InputGroup>
          <InputGroup.Text id="basic-addon1">
            <img src="../svgs/mail.svg" alt="mail" />
          </InputGroup.Text>
          <Form.Control
            placeholder="sample@mail.com"
            aria-label="sample@mail.com"
            aria-describedby="basic-addon1"
            {...register("email", {
              required: "* This field is required",
            })}
          />
        </InputGroup>
        <Form.Text muted>
          <span>
            {errors?.email && (
              <p className="error-form">{String(errors.email?.message)}</p>
            )}
          </span>
        </Form.Text>
      </div>
      <div className="formgroup mb24px">
        <Form.Label>Code</Form.Label>
        <InputGroup>
          <Form.Control
            aria-label="passcode"
            aria-describedby="basic-addon2"
            placeholder="Code"
            {...register("code", {
              required: "* This field is required",
            })}
          />
        </InputGroup>
        <Form.Text muted>
          <span>
            {errors?.code && (
              <p className="error-form">{String(errors.code?.message)}</p>
            )}
          </span>
        </Form.Text>
      </div>
      <Form.Group className="input-password" controlId="newpassword">
        <Form.Label>New Password</Form.Label>
        <InputGroup>
          <Form.Control
            className="password-input"
            type={showNewpass}
            placeholder="Password"
            aria-describedby="basic-addon2"
            {...register("password", {
              required: "* This field is required",
            })}
            onChange={(e) => {
              setPasswordStrength(e.target.value);
            }}
          />
          <InputGroup.Text id="basic-addon2">
            {showNewpass === "password" && (
              <img
                onClick={handleShowNewPass}
                className="curserpointer"
                src="../svgs/VisibilityOFF.svg"
                alt="VisibilityOFF"
              />
            )}
            {showNewpass === "text" && (
              <img
                onClick={handleShowNewPass}
                className="curserpointer"
                src="../svgs/VisibilityON.svg"
                alt="VisibilityOFF"
              />
            )}
          </InputGroup.Text>
        </InputGroup>
        <Form.Text muted>
          <span>
            {errors?.password && (
              <p className="error-form">{String(errors.password?.message)}</p>
            )}
          </span>
        </Form.Text>
      </Form.Group>
      <PasswordStrengthMeter password={passwordStrength} />
      <Form.Group className="input-password mt29px mb32px" controlId="password">
        <Form.Label>Confirm New Password</Form.Label>
        <InputGroup>
          <Form.Control
            className="password-input"
            type={showpassword}
            placeholder="Password"
            aria-describedby="basic-addon3"
            {...register("confirmPassword", {
              required: "* This field is required",
            })}
          />
          <InputGroup.Text id="basic-addon3">
            {showpassword === "password" && (
              <img
                src="../svgs/VisibilityOFF.svg"
                alt="VisibilityOFF"
                className="curserpointer"
                onClick={handleShowPass}
              />
            )}
            {showpassword === "text" && (
              <img
                src="../svgs/VisibilityON.svg"
                alt="VisibilityON"
                className="curserpointer"
                onClick={handleShowPass}
              />
            )}
          </InputGroup.Text>
        </InputGroup>
        {/* <Form.Text muted>
              <span>
                {errors?.password && (
                  <p className="error-form">
                    {String(errors.password?.message)}
                  </p>
                )}
              </span>
            </Form.Text> */}
        <Form.Text muted>
          <span>
            {errors?.confirmPassword && (
              <p className="error-form">
                {String(errors.confirmPassword?.message)}
              </p>
            )}
          </span>
        </Form.Text>
        <Form.Text muted>
          <span>
            {errorMessage && <p className="error-form">{errorMessage}</p>}
          </span>
        </Form.Text>
      </Form.Group>
      <Button type="submit" className="btn-primary12 w-100">
        Set Password
      </Button>
    </Form>
  );
};

export default ForgotPasswordForm;
