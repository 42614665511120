import "./TableMUI.scss";
import { DataGrid } from "@mui/x-data-grid";
// @ts-ignore
// import { GearFill } from "react-bootstrap-icons";
import { useEffect, useState } from "react";
import GearIcon from "./GearIcon";

function TableMUI(props: {
  rows: any;
  columns: any;
  dataCount?: number;
  setListPage?: any;
  listPage?: number;
  height?: string;
}) {
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(props.listPage ? props.listPage : 1);
  const rowCountState = props.dataCount ? props.dataCount : 20;

  useEffect(() => {
    if (props.setListPage) {
      props.setListPage(page);
    }
  }, [page, props]);

  return (
    <div
      className="tableDimensions"
      style={{ height: props.height ? props.height : "79vh" }}
    >
      <DataGrid
        className="tablemui-custom"
        checkboxSelection={false}
        rowCount={rowCountState}
        pagination
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={(newPage: any) => setPage(newPage)}
        rows={props.rows ? props.rows : []}
        columns={props.columns}
        disableSelectionOnClick
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          MoreActionsIcon: GearIcon,
        }}
      />
    </div>
  );
}

export default TableMUI;
