import Carousel from "react-bootstrap/Carousel";
import "./CarouselDashboard.scss";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
export interface carouselProps {
  carouselData: any
}

const CarouselDashboard: React.FunctionComponent<carouselProps> = ({
  carouselData
}) => {
  const navigate = useNavigate();
  return (
    <Carousel controls={false} className="carsel">
      {carouselData?.map((data: any, index: any) => {
        return (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src="../svgs/map.svg"
              alt="First slide"
            />
            <Carousel.Caption>
              <div className="font16-regular white text-uppercase">
                {data.text1}
              </div>
              <div className="font20-semibold white text-uppercase">
                {data.text2}
              </div>
              <div className="font16-regular white text-uppercase">
                {data.text3}
              </div>
              {data.link && <Button
                className="btn-primary12"
                onClick={() => {
                  navigate(data.link);
                }}
              >
                Purchase Report
              </Button>}
            </Carousel.Caption>
          </Carousel.Item>
        )
      })}
    </Carousel>
  );
}

export default CarouselDashboard;
