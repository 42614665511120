import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import "./discoverymanagement.scss";
import "../../../../styles/global.scss";
import {
  AcceptPartnerInterest,
  RecommendPartnerInterest,
} from "../../../../API/endpoints/Discovery";
import { sendRecommendationNotification } from "../../../../API/endpoints/Email";
import { CreateTodo, MarkTodoDone } from "../../../../API/endpoints/Todos";
import { GetBrand } from "../../../../API/endpoints/Brands";
import { CreateActivity } from "../../../../API/endpoints/Dashboard";
import { GetPartner } from "../../../../API/endpoints/Partner";
import ToastMessage from "../../toasts/ToastMessage";

function BrandApprovalPartner(props: {
  onCancel: () => void;
  setFlag: any;
  flag: boolean | undefined;
  isModalOpen: boolean | undefined;
  title: string;
  buttonText: string;
  userType: string;
  partnerID?: string;
  subtitle: string;
  reqDetails: any;
}) {
  const reqDetails = props.reqDetails;
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [regulatoryCompliance, setRegulatoryCompliance] = useState(false);
  const [importClearance, setImportClearance] = useState(false);
  const [warehousingAndStorage, setWarehousingAndStorage] = useState(false);
  const [ecomSetupMgmt, setEcomSetupMgmt] = useState(false);
  const [retailDistribution, setRetailDistribution] = useState(false);
  const [brandName, setBrandName] = useState<string>("");
  const [orderFullfilment, setOrderFullfilment] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const handleClose = () => {
    setSelectedServices([]);
    setWarehousingAndStorage(false);
    setOrderFullfilment(false);
    setEcomSetupMgmt(false);
    setMarketing(false);
    setRetailDistribution(false);
    setRegulatoryCompliance(false);
    setImportClearance(false);
    props.onCancel();
  };

  useEffect(() => {
    setSelectedServices([]);
    if (regulatoryCompliance === true) {
      setSelectedServices((prevState) => [...prevState, 1]);
    }
    if (importClearance === true) {
      setSelectedServices((prevState) => [...prevState, 2]);
    }
    if (warehousingAndStorage === true) {
      setSelectedServices((prevState) => [...prevState, 3]);
    }
    if (ecomSetupMgmt === true) {
      setSelectedServices((prevState) => [...prevState, 4]);
    }
    if (retailDistribution === true) {
      setSelectedServices((prevState) => [...prevState, 5]);
    }
    if (orderFullfilment === true) {
      setSelectedServices((prevState) => [...prevState, 6]);
    }
    if (marketing === true) {
      setSelectedServices((prevState) => [...prevState, 7]);
    }
  }, [
    ecomSetupMgmt,
    importClearance,
    marketing,
    orderFullfilment,
    regulatoryCompliance,
    retailDistribution,
    warehousingAndStorage,
  ]);

  useEffect(() => {
    (async () => {
      const brand = await GetBrand(props?.reqDetails?.brandid);
      if (brand) {
        console.log(brand);
        setBrandName(brand.data.brand_name);
      } else console.log("Cannot get Brands Data");
    })();
  }, [props.reqDetails]);

  useEffect(() => {
    if (props.userType === "Admin") {
      console.log(reqDetails?.partner_services);
      reqDetails?.partner_services?.map((service: any) => {
        if (
          service.service_id === 1 &&
          service.recommendation_status === "Recommended"
        ) {
          setRegulatoryCompliance(true);
        }
        if (
          service.service_id === 2 &&
          service.recommendation_status === "Recommended"
        ) {
          setImportClearance(true);
        }
        if (
          service.service_id === 3 &&
          service.recommendation_status === "Recommended"
        ) {
          setWarehousingAndStorage(true);
        }
        if (
          service.service_id === 4 &&
          service.recommendation_status === "Recommended"
        ) {
          setEcomSetupMgmt(true);
        }
        if (
          service.service_id === 5 &&
          service.recommendation_status === "Recommended"
        ) {
          setRetailDistribution(true);
        }
        if (
          service.service_id === 6 &&
          service.recommendation_status === "Recommended"
        ) {
          setOrderFullfilment(true);
        }
        if (
          service.service_id === 7 &&
          service.recommendation_status === "Recommended"
        ) {
          setMarketing(true);
        }
        return true;
      });
    }
  }, [reqDetails, props]);

  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });

  const onFormSubmit = async () => {
    props.setFlag(true);

    const formData = {
      discovery_brands_id: props.reqDetails.discovery_brands_id,
      discovery_partners_id: props.reqDetails.id,
      partner_id: props.reqDetails.partnerId,
      services: selectedServices,
    };

    const formData2 = {
      status: "Recommended",
      serviceList: selectedServices,
    };

    if (props.userType === "Brand") {
      try {
        console.log(formData);
        props.setFlag(true);
        console.log("AcceptPartnerInterest");
        const res = await AcceptPartnerInterest(formData);
        const partner = await GetPartner(formData.partner_id);
        const partnerName = partner.data.company_name;
        props.setFlag(false);
        try {
          const activity = await CreateActivity(
            `Brand: ${brandName} has accepted service request from partner: ${partnerName}.`
          );
          console.log(activity);
        } catch (err: any) {
          console.log(err);
        }
        try {
          const todoDone1 = await MarkTodoDone(
            `partner-request-created:${formData.discovery_brands_id}`
          );
          console.log(todoDone1);
        } catch (ex: any) {
          console.log(ex);
        }

        try {
          const todoDone2 = await MarkTodoDone(
            `partner-interest:${formData.discovery_partners_id}`
          );
          console.log(todoDone2);
        } catch (ex: any) {
          console.log(ex);
        }

        try {
          const todoDone3 = await MarkTodoDone(
            `partner-interest-admin:${formData.discovery_partners_id}`
          );
          console.log(todoDone3);
        } catch (ex: any) {
          console.log(ex);
        }

        try {
          const todoDone4 = await MarkTodoDone(
            `partner-interest-partner:${formData.discovery_partners_id}`
          );
          console.log(todoDone4);
        } catch (ex: any) {
          console.log(ex);
        }

        try {
          const todoDone5 = await MarkTodoDone(
            `partner-recommended:${formData.partner_id}&&${formData.discovery_brands_id}`
          );
          console.log(todoDone5);
        } catch (ex: any) {
          console.log(ex);
        }

        try {
          const todoDone6 = await MarkTodoDone(
            `partner-recommended-admin:${formData.partner_id}&&${formData.discovery_brands_id}`
          );
          console.log(todoDone6);
        } catch (ex: any) {
          console.log(ex);
        }

        console.log(res);
        handleClose();
      } catch (err: any) {
        console.log(err);
        setNotification({
          type: "error",
          msg: err.msg,
        });
      }
    } else if (props.userType === "Admin") {
      //  console.log(formData2);
      props.setFlag(true);
      await RecommendPartnerInterest(props.reqDetails.id, formData2);
      props.setFlag(false);
      const partner = await GetPartner(formData.partner_id);
      const partnerName = partner.data.company_name;
      try {
        await sendRecommendationNotification({
          brand_id: props.reqDetails.brandid,
          partner_id: props.reqDetails.partnerId,
          discovery_brands_id: props.reqDetails.discovery_brands_id,
        });
        const todoData = {
          id: `partner-recommended:${props.reqDetails.partnerId}&&${props.reqDetails.discovery_brands_id}`,
          brand_id: props.reqDetails.brandid,
          partner_id: props.reqDetails.partnerId,
          message: `AEG has recommended <b>${partnerName}</b> to your request for <b>${props.reqDetails?.focusmarket}</b>.`,
          url: `/discoverymanagement-brands/${props.reqDetails.discovery_brands_id}&&${brandName}`,
          url_action: "Please review.",
          permission_submodule_id: 31,
          admin: false,
          brand: true,
        };
        const todoData2 = {
          id: `partner-recommended-admin:${props.reqDetails.partnerId}&&${props.reqDetails.discovery_brands_id}`,
          brand_id: props.reqDetails.brandid,
          partner_id: props.reqDetails.partnerId,
          message: `AEG Admin has recommended <b>${partnerName}</b> for <b>${brandName}</b> request in <b>${props.reqDetails?.focusmarket}</b>.`,
          url: `/discoverymanagement-recommend/${props.reqDetails.discovery_brands_id}&&${brandName}&&recommendation`,
          url_action: "Click to view details.",
          permission_submodule_id: 15,
          admin: true,
        };

        const response = await CreateTodo(todoData);
        const response2 = await CreateTodo(todoData2);
        console.log(response);
        console.log(response2);
        const activity = await CreateActivity(
          `Admin has recommended ${props.reqDetails?.partnerName} for ${brandName} request in ${props.reqDetails?.focusmarket}.`
        );
        console.log(activity);
      } catch (ex: any) {
        console.log(ex);
      }

      handleClose();
    }
    props.setFlag(false);
  };

  return (
    <Modal
      show={props.isModalOpen}
      onHide={handleClose}
      size="lg"
      className="brandpartnerapproval"
    >
      <Modal.Header closeButton>
        <Modal.Title className="font18-medium gray-800">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            onFormSubmit();
          }}
        >
          <Row className="subtitle-row">
            <Col>
              <div className="font14-regular gray-500">{props.subtitle}</div>
            </Col>
          </Row>
          {props.reqDetails?.servicerequestIds?.includes(1) && (
            <Row className="checkbox-row">
              <Col>
                <input
                  type="checkbox"
                  onChange={() => {
                    setRegulatoryCompliance((prevState) => !prevState);
                  }}
                  checked={regulatoryCompliance}
                />
                <label className="gray-800 font12-regular">
                  Regulatory Compliance&emsp;
                </label>

                {props.reqDetails?.servicerequests?.filter((req: any) => {
                  return req.name === "Requlatory Compliance";
                })[0]?.status === "Recommended" && (
                  <img src="../svgs/check-circle-fill.svg" alt="svg" />
                )}
              </Col>
            </Row>
          )}
          {props.reqDetails?.servicerequestIds?.includes(2) && (
            <Row className="checkbox-row">
              <Col>
                <input
                  type="checkbox"
                  onChange={() => {
                    setImportClearance((prevState) => !prevState);
                  }}
                  checked={importClearance}
                />
                <label className="gray-800 font12-regular">
                  Importation And Clearance&emsp;
                </label>
                {props.reqDetails?.servicerequests?.filter((req: any) => {
                  return req.name === "Importation and Clearance";
                })[0]?.status === "Recommended" && (
                  <img
                    src="../svgs/check-circle-fill.svg"
                    alt="svg"
                    onClick={() => {}}
                  />
                )}
              </Col>
            </Row>
          )}
          {props.reqDetails?.servicerequestIds?.includes(3) && (
            <Row className="checkbox-row">
              <Col>
                <input
                  type="checkbox"
                  onChange={() => {
                    setWarehousingAndStorage((prevState) => !prevState);
                  }}
                  checked={warehousingAndStorage}
                />
                <label className="gray-800 font12-regular">
                  Warehousing And Storage&emsp;
                </label>
                {props.reqDetails?.servicerequests?.filter((req: any) => {
                  return req.name === "Warehousing And Storage";
                })[0]?.status === "Recommended" && (
                  <img
                    src="../svgs/check-circle-fill.svg"
                    alt="svg"
                    onClick={() => {}}
                  />
                )}
              </Col>
            </Row>
          )}
          {props.reqDetails?.servicerequestIds?.includes(4) && (
            <Row className="checkbox-row">
              <Col>
                <input
                  type="checkbox"
                  onChange={() => {
                    setEcomSetupMgmt((prevState) => !prevState);
                  }}
                  checked={ecomSetupMgmt}
                />
                <label className="gray-800 font12-regular">
                  Ecommerce Setup And Management&emsp;
                </label>
                {props.reqDetails?.servicerequests?.filter((req: any) => {
                  return req.name === "Ecommerce Setup & Management";
                })[0]?.status === "Recommended" && (
                  <img
                    src="../svgs/check-circle-fill.svg"
                    alt="svg"
                    onClick={() => {}}
                  />
                )}
              </Col>
            </Row>
          )}
          {props.reqDetails?.servicerequestIds?.includes(5) && (
            <Row className="checkbox-row">
              <Col>
                <input
                  type="checkbox"
                  onChange={() => {
                    setRetailDistribution((prevState) => !prevState);
                  }}
                  checked={retailDistribution}
                />
                <label className="gray-800 font12-regular">
                  Retail Distribution&emsp;
                </label>
                {props.reqDetails?.servicerequests?.filter((req: any) => {
                  return req.name === "Retail Distribution";
                })[0]?.status === "Recommended" && (
                  <img
                    src="../svgs/check-circle-fill.svg"
                    alt="svg"
                    onClick={() => {}}
                  />
                )}
              </Col>
            </Row>
          )}
          {props.reqDetails?.servicerequestIds?.includes(6) && (
            <Row className="checkbox-row">
              <Col>
                <input
                  type="checkbox"
                  onChange={() => {
                    setOrderFullfilment((prevState) => !prevState);
                  }}
                  checked={orderFullfilment}
                />
                <label className="gray-800 font12-regular">
                  Order Fulfillment&emsp;
                </label>
                {props.reqDetails?.servicerequests?.filter((req: any) => {
                  return req.name === "Order Fulfillment";
                })[0]?.status === "Recommended" && (
                  <img
                    src="../svgs/check-circle-fill.svg"
                    alt="svg"
                    onClick={() => {}}
                  />
                )}
              </Col>
            </Row>
          )}
          {props.reqDetails?.servicerequestIds?.includes(7) && (
            <Row className="checkbox-row">
              <Col>
                <input
                  type="checkbox"
                  onChange={() => {
                    setMarketing((prevState) => !prevState);
                  }}
                  checked={marketing}
                />
                <label className="gray-800 font12-regular">
                  Marketing&emsp;
                </label>
                {props.reqDetails?.servicerequests?.filter((req: any) => {
                  return req.name === "Marketing";
                })[0]?.status === "Recommended" && (
                  <img
                    src="../svgs/check-circle-fill.svg"
                    alt="svg"
                    onClick={() => {}}
                  />
                )}
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-secondary12"
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          className="btn-primary12"
          variant="primary"
          type="submit"
          onClick={() => {
            onFormSubmit();
          }}
        >
          {props.buttonText}
        </Button>
      </Modal.Footer>

      <ToastMessage
        notification={notification}
        onCancel={handleCloseNotification}
      />
    </Modal>
  );
}

export default BrandApprovalPartner;
