import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import {
  GetReportRequestByID,
  UploadAdvisoryReport,
} from "../../../../API/endpoints/Advisory";
import { SendReportUploadConfirmationEmail } from "../../../../API/endpoints/Email";
import { CreateTodo, MarkTodoDone } from "../../../../API/endpoints/Todos";
import { CreateActivity } from "../../../../API/endpoints/Dashboard";
import { GetBrand } from "../../../../API/endpoints/Brands";

function AdvisoryReportUploadModal(props: {
  onCancel: () => void;
  setFlag: (arg0: boolean) => void;
  flag: boolean | undefined;
  isModalOpen: boolean | undefined;
  advisory_id: string | undefined;
  selectedDocument: any;
}) {
  const [data, setData] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [reportRequest, setReportRequest] = useState<any>();
  const formSubmitData: any = new FormData();
  const myRefname: React.MutableRefObject<any> = useRef(null);
  const handleClick = () => {
    myRefname.current.click();
  };

  useEffect(() => {
    if (props?.advisory_id) {
      (async () => {
        const res = await GetReportRequestByID(props.advisory_id);
        console.log(res);
        setReportRequest(res);
      })();
    }
  }, [props]);

  const { handleSubmit } = useForm({
    defaultValues: {
      document_name: "",
      document_type: "",
      file: undefined,
    },
  });

  const handleClose = () => {
    props.onCancel();
    setData([]);
  };

  const onFormSubmit = async (formData: {
    category_id?: undefined;
    sub_category_id?: undefined;
    document_name: any;
    document_type?: string;
    is_mandatory?: undefined;
    file?: any;
  }) => {
    console.log(data);

    data.map(async (item: any) => {
      await formSubmitData.append("file", item);
      return true;
    });

    await formSubmitData.append(
      "file_name",
      props?.selectedDocument?.report_name
    );
    await formSubmitData.append("advisory_id", props.advisory_id);
    (async () => {
      const res = await UploadAdvisoryReport(formSubmitData);
      if (res) {
        await SendReportUploadConfirmationEmail({
          advisory_id: props.advisory_id,
        });
        const brandData = await GetBrand(reportRequest?.data?.brand_id);
        const brandName = brandData.data.brand_name;
        const activity = await CreateActivity(
          `Admin has published report: ${reportRequest?.data?.report?.report_name} requested by brand ${brandName}.`
        );
        console.log(activity);
        try {
          const todoDone = await MarkTodoDone(
            `advisory-request:${reportRequest?.data?.id}`
          );
          console.log(todoDone);
        } catch (ex: any) {
          console.log(ex);
        }

        try {
          const todoData = {
            id: `advisory-uploaded:${reportRequest?.data?.id}`,
            brand_id: reportRequest?.data?.brand_id,
            message: `AEG has published your requested advisory report: <b>${reportRequest?.data?.report?.report_name}</b>.`,
            url: "/advisorymanagement/brand",
            url_action: "Please view and download the copy.",
            visibility: "active users",
            permission_submodule_id: 33,
            admin: false,
            brand: true,
          };
          const res = await CreateTodo(todoData);
          console.log(res);
        } catch (e) {
          console.log(e);
        }
      }
      props.setFlag(!props.flag);
      handleClose();
      setData([]);
    })();
  };

  const uploadHandler = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    file.isUploading = true;
    setData([...data, file]);
    console.log(file);
    if (file.type !== "application/pdf") {
      setErrorMessage("The file is not a PDF");
    }
  };

  const deleteFile = (filename: string) => {
    setData(data.filter((file: { name: any }) => file.name !== filename));
    setErrorMessage("");
  };

  return (
    <Modal show={props.isModalOpen} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Upload Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit((data) => {
            onFormSubmit(data);
          })}
        >
          <Row>
            <Col>
              <div className="file-card" onClick={handleClick}>
                <div className="file-inputs">
                  <input
                    type="file"
                    ref={myRefname}
                    onChange={uploadHandler}
                    disabled={data.length > 0}
                    accept="image/png,.pdf"
                  />

                  <div className="file-upload-img">
                    <img
                      className="headerPlusIcon"
                      src="../svgs/cloud-arrow-up-fill.svg"
                      alt=""
                    />
                  </div>
                </div>

                <>
                  <p className="main">Click to upload</p>
                  <p className="info">or drag and drop</p>
                  <p className="info">Supported file type: PDF</p>
                </>
              </div>
            </Col>
          </Row>
          <Row>
            <ul className="file-list">
              {data &&
                data.map((file: { name: string }) => (
                  <>
                    <li className="file-item" key={file.name}>
                      <p>{file.name}</p>
                      <div className="actions">
                        <img
                          src="../svgs/trash.svg"
                          alt=""
                          onClick={() => {
                            deleteFile(file.name);
                          }}
                        />
                      </div>
                    </li>
                    <Form.Text muted>
                      <span>
                        {errorMessage && (
                          <p className="error-form">{errorMessage}</p>
                        )}
                      </span>
                    </Form.Text>
                  </>
                ))}
            </ul>
          </Row>
        </Form>
      </Modal.Body>
      {data[0] && !errorMessage && (
        <Modal.Footer className="footer">
          <Button
            className="primaryButton"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="primaryButton"
            variant="primary"
            type="submit"
            onClick={handleSubmit((data) => {
              onFormSubmit(data);
            })}
          >
            Add
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default AdvisoryReportUploadModal;
