import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect, useState } from "react";
import "./addPartnerTeam.scss";
import {
  CreatePartnerUser,
  GetPartner,
  UpdatePartnerUser,
} from "../../../../API/endpoints/Partner";
import { CreateActivity } from "../../../../API/endpoints/Dashboard";
import { GetUserProfile } from "../../../../API/endpoints/User";
import { GetRolesByType } from "../../../../API/endpoints/RolesAndPermissions/Roles";

function AddPartnerTeam(props: {
  onCancel: () => void;
  partnerID: any;
  setFlag: (arg0: boolean) => void;
  setNext: (arg0: boolean) => void;
  isModalOpen: boolean | undefined;
  isSuperAdmin: boolean | undefined;
  isBrandEmployee: boolean | undefined;
  countryCodes: any[];
  user: any;
  handleNotification: (type: string, msg: string) => void;
}) {
  const { user } = props;
  const [rolesList, setRolesList] = useState<any[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      code: "",
      phone: "",
      designation: "",
      role: "",
    },
  });

  useEffect(() => {
    (async () => {
      let userProfile = await GetUserProfile(user?.id);
      console.log(userProfile);
      let defaults = {
        firstName: userProfile ? userProfile.data.first_name : "",
        lastName: userProfile ? userProfile.data.last_name : "",
        email: user ? user.email : "",
        code: user ? user.code : "",
        phone: user ? user.phone : "",
        designation: user ? user.designation : "",
        role: userProfile ? userProfile.data.user_roles[0]?.roles?.id : "",
      };
      console.log(user);
      reset(defaults);
    })();
    (async () => {
      const roles_list = await GetRolesByType("partner");
      console.log(roles_list);
      setRolesList(roles_list?.data);
    })();
  }, [user, reset]);

  const handleClose = () => {
    props.onCancel();
  };

  // const getRole = (role: string) => {
  //   switch (role) {
  //     case "Admin":
  //       return "6";
  //     case "Employee":
  //       return "5";
  //     default:
  //       return "";
  //   }
  // };

  const handleSubmitForm = async (data: {
    firstName: string;
    lastName: string;
    email: string;
    code: string;
    phone: string;
    designation: string;
    role: string;
  }) => {
    let teamMember: any = {
      partner_id: props.partnerID,
      first_name: data.firstName,
      email_id: data.email,
      user_type: "partner",
      role: data?.role ? [data.role] : [4],
      //role: [4],
    };
    if (data.lastName) teamMember.last_name = data.lastName;
    if (data.phone) teamMember.contact_number = data.phone;
    if (data.phone) teamMember.contact_number_code = data.code;
    if (data.designation) teamMember.designation = data.designation;
    const partner = await GetPartner(props.partnerID);
    console.log(partner);
    const partnerName = partner?.data?.company_name;
    console.log(partner, partnerName, teamMember);
    if (props.user?.id) {
      (async () => {
        try {
          props.setFlag(false);
          const newTeamMember = { ...teamMember, region: [2], department: 1 };
          await UpdatePartnerUser(user.id, newTeamMember);
          props.handleNotification("success", "Updated successfully");
          const activity = await CreateActivity(
            `User: ${data.firstName}  of partner: ${partnerName} updated.`
          );
          console.log(activity);
          handleClose();
          props.setFlag(true);
        } catch (ex: any) {
          validate(ex);
        }
      })();
    } else {
      (async () => {
        try {
          props.setFlag(false);
          await CreatePartnerUser(teamMember);
          try {
            const activity = await CreateActivity(
              `Team member: ${data?.firstName} added to partner: ${partnerName}.`
            );
            console.log(activity);
          } catch (err) {
            console.error(err);
          }
          handleClose();
          props.setFlag(true);
          props.setNext(true);
        } catch (ex: any) {
          validate(ex);
        }
      })();
    }
  };
  const validate = (ex: any) => {
    if (ex.param === "first_name") {
      setError("firstName", { type: "custom", message: ex.msg });
    } else if (ex.param === "last_name") {
      setError("lastName", { type: "custom", message: ex.msg });
    } else if (ex === "Email already exists") {
      setError("email", { type: "custom", message: ex });
    } else if (ex === "Contact number already exists") {
      setError("phone", { type: "custom", message: ex });
    } else if (ex.param === "designation") {
      setError("designation", { type: "custom", message: ex.msg });
    } else {
      console.log("Undefined validation: ", ex);
    }
  };

  return (
    <>
      <Modal
        show={props.isModalOpen}
        onHide={handleClose}
        size="lg"
        className="modal-layout"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font16-medium gray-800">
            {props.user ? "Edit TeamMember" : "Add TeamMember"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="addPartnerForm"
            onSubmit={handleSubmit((data) => {
              handleSubmitForm(data);
            })}
          >
            <Row>
              <Col>
                <Form.Group className="formgroup mb-3" controlId="firstname">
                  <Form.Label>First Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    defaultValue={user ? user.name.split(" ")[0] : ""}
                    {...register("firstName", {
                      required: "* This field is required",
                    })}
                  />
                  <Form.Text muted>
                    <span>
                      {errors?.firstName && (
                        <p className="error-form">
                          {String(errors.firstName?.message)}
                        </p>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="formgroup mb-3" controlId="lastname">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    defaultValue={user ? user.name.split(" ")[1] : ""}
                    {...register("lastName")}
                  />
                  <Form.Text muted>
                    <span>
                      {errors?.lastName && (
                        <p className="error-form">
                          {String(errors.lastName?.message)}
                        </p>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="formgroup mb-3" controlId="email">
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    defaultValue={user ? user.email : ""}
                    {...register("email", {
                      required: "* This field is required",
                    })}
                  />
                  <Form.Text muted>
                    <span>
                      {errors?.email && (
                        <p className="error-form">
                          {String(errors.email?.message)}
                        </p>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="formgroup mb-3" controlId="phone">
                  <Form.Label>Phone *</Form.Label>
                  <InputGroup>
                    <Form.Select
                      {...register("code")}
                      aria-label="20"
                      className="countrycode"
                      key={"country" + Math.floor(Math.random() * 100)}
                    >
                      {props.countryCodes[0] &&
                        props.countryCodes
                          ?.filter((code) => {
                            return code.country_code !== "";
                          })
                          .map((code) => {
                            return (
                              <option
                                key={Math.random()}
                                value={code.country_code}
                              >
                                {code.country_code}
                              </option>
                            );
                          })}
                    </Form.Select>

                    <Form.Control
                      {...register("phone", {
                        required: "* This field is required",
                      })}
                      type="text"
                      defaultValue={user ? user.phone : ""}
                      placeholder="Enter Phone number"
                    />
                  </InputGroup>
                  <Form.Text muted>
                    <span>
                      {errors?.phone && (
                        <p className="error-form">
                          {String(errors.phone?.message)}
                        </p>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="formgroup mb-3" controlId="designation">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    {...register("designation")}
                    type="text"
                    defaultValue={user ? user.designation : ""}
                    placeholder="Enter designation"
                  />
                  <Form.Text muted>
                    <span>
                      {errors?.designation && (
                        <p className="error-form">
                          {String(errors.designation?.message)}
                        </p>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="formgroup mb-3" controlId="Role">
                  <Form.Label>Role *</Form.Label>
                  <Form.Select
                    aria-label="20"
                    {...register("role", {
                      required: "* This field is required",
                    })}
                    key={Math.random()}
                  >
                    <option value="">Select user role</option>
                    {/* <option value={88}>Partner Admin</option> */}
                    {/* <option value={5}>Admin</option>
                    <option value={6}>Employee</option> */}
                    {rolesList &&
                      rolesList.map((role) => {
                        return (
                          <option key={Math.random()} value={role.id}>
                            {role.role_name}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <Form.Text muted>
                    <span>
                      {errors?.role && (
                        <p className="error-form">
                          {String(errors.role?.message)}
                        </p>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-secondary12"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="btn-primary12"
            variant="primary"
            type="submit"
            form="addPartnerForm"
          >
            {props.user ? "Update" : "Add TeamMember"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AddPartnerTeam;
