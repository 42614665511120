import { getWith, deleteWith, postWith, patchWith } from "../axios";
import { adminInputDTO } from "../../pages/dtos/admin.dtos";

export interface GetCurrentUserResponse {}

export interface APIAddAdminUserRequestType {
  first_name: string;
  last_name: string;
  region: any;
  email_id: string;
  contact_number_code: string;
  contact_number: string;
  designation: string;
  department: any;
  role: any;
}

export async function GetAdminUsers(
  listPage: number,
  searchParam: string
): Promise<any> {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/user/fetch-users/administrative/${listPage ? listPage + 1 : 1}/${
      searchParam ? searchParam : ""
    }`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetDepartments(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-departments/administrative`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetRolesList(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/filter/administrative`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteAdmin(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/` + id;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function addAdminUser(data: adminInputDTO) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function getAdminUser(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/` + id;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function updateAdmin(
  data: any,
  adminId: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/` + adminId;
    return (await patchWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
