import { Container, Row, Col } from "react-bootstrap";
import "./ImageUpload.scss";
import React, { useRef } from "react";

export interface uploadProps {
    handleDocumentUpload: (data: any) => void;
    requirement: string;
    fileAccept: string;
}

const DocumentUpload: React.FunctionComponent<uploadProps> = ({
    handleDocumentUpload,
    requirement,
    fileAccept
}) => {
    const inputRef = useRef<any>([]);
    const onChangeHandler = (event: any) => {
        handleDocumentUpload(event.target.files)
    };
    const handleDragOver = (event: any) => {
        event.preventDefault();
    };
    const handleDrop = (event: any) => {
        event.preventDefault();
        handleDocumentUpload(event.dataTransfer.files)
    };
    return (
        <Container fluid className="upload-cntr">
            <Row className="drag-drop-row">
                <Col
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => inputRef.current.click()}
                    className="drag-drop-col"
                >
                    <img src="../svgs/upload_icon.svg" alt="upload_icon" />
                    <div>
                        <span className="font14-regular primary-blue">
                            Click to upload{" "}
                        </span>
                        <span className="font14-regular gray-500">or drag & drop</span>
                    </div>
                    <div className="font14-regular gray-500">{requirement}</div>
                    <input
                        type="file"
                        id="imageFile"
                        accept={fileAccept}
                        multiple
                        onChange={onChangeHandler}
                        hidden
                        ref={inputRef}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default DocumentUpload;