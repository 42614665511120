import { useState, useEffect } from "react";
import {
  GetAdminTodosByUserID,
  GetOrgTodosByUserID,
  GetPartnerTodosByUserID,
} from "../../../API/endpoints/Todos";
import parse from "html-react-parser";
import "./TodoList.scss";
import { Link } from "react-router-dom";
export interface todoListProps {
  userType: string;
  userProfile: any;
  partnerID?: string | undefined;
}

const TodoList: React.FunctionComponent<todoListProps> = (props) => {
  const [todos, setTodos] = useState<any[]>([]);
  console.log(props.userProfile);
  const orgID = props.userProfile?.org_id;
  const userID = props.userProfile?.id;
  const IsActivated = props.userProfile?.is_active;
  const dateFormatter = (date: any) => {
    return `${date?.toString().substring(8, 10)}/${date
      ?.toString()
      .substring(5, 7)}/${date?.toString().substring(0, 4)}`;
  };

  useEffect(() => {
    if (props.userType === "admin") {
      (async () => {
        const res = await GetAdminTodosByUserID(userID);
        console.log(res);
        setTodos(res.data);
      })();
    } else if (props.userType === "brand") {
      (async () => {
        const res = await GetOrgTodosByUserID(orgID, userID);
        console.log(res, IsActivated);
        if (!IsActivated) {
          const commonTodos = res?.data?.filter(
            (todo: any) => todo.visibility === "all"
          );
          setTodos(commonTodos);
        } else {
          setTodos(res.data);
        }
      })();
    } else if (props.userType === "partner") {
      (async () => {
        const res = await GetPartnerTodosByUserID(props?.partnerID, userID);
        console.log(res);
        if (!IsActivated) {
          const commonTodos = res?.data?.filter(
            (todo: any) => todo.visibility === "all"
          );
          setTodos(commonTodos);
        } else {
          setTodos(res.data);
        }
      })();
    }
  }, [orgID, props, IsActivated, userID]);

  return (
    <div className="todo-wraper">
      {todos &&
        todos[0] &&
        todos.map((todo) => {
          return (
            <div className="list-card" key={todo.id}>
              <div
                className={
                  todo.status === "Completed"
                    ? "status-complete"
                    : "status-pending"
                }
              ></div>
              <div className="content">
                <div className="font14-medium gray-800">
                  {parse(todo.message)}
                </div>
                {todo.status === "Completed" ? null : (
                  <div className="font14-medium">
                    <Link to={todo.url ? todo.url : "#"}>
                      {todo.url_action ? todo.url_action : ""}
                    </Link>
                  </div>
                )}
                {todo.status === "Completed" ? (
                  <div className="font12-regular gray-500">
                    <span>Task completed on </span>
                    <span>
                      <img
                        width={16}
                        height={16}
                        src="../svgs/calander.svg"
                        alt="calander"
                      />
                    </span>
                    <span>
                      {" "}
                      {dateFormatter(
                        todo.completed_at ? todo.completed_at : ""
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="font12-regular">
                    <span className="gray-500">Task Status : </span>
                    <span className="warning-500">Pending</span>
                  </div>
                )}
              </div>
              <div className="arrow">
                <img src="../svgs/rightarrow.svg" alt="rightarrow" />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TodoList;
