import { getWith, postWith, deleteWith, patchWith } from "../axios";

export async function GetCountries(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-countries/all`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllCountries(
  listpage: number,
  searchparam?: string
): Promise<any> {
  try {
    let url = "";
    if (searchparam) {
      url = `${process.env.REACT_APP_API_URL}/common/fetch-countries/all/${
        listpage ? listpage + 1 : 1
      }/${searchparam}`;
    } else if (listpage >= 0) {
      url = `${process.env.REACT_APP_API_URL}/common/fetch-countries/all/${
        listpage ? listpage + 1 : 1
      }`;
    } else {
      url = `${process.env.REACT_APP_API_URL}/common/fetch-countries/all`;
    }
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetMarketPresenceCountries(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-countries/market_presence`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetYearsOfOperation(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-years-of-operations`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetCountryCodes(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-country-codes`;
    const codes = await getWith<any[]>(url);
    return codes;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function AddCountryApi(data: {
  name: string;
  country__alpha_code: string;
  country_code: string;
  all: any;
  market_presence: any;
  contact_number: any;
  file: any;
}) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/add-country`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function UpdateCountryApi(
  data: {
    name: string;
    country__alpha_code: string;
    country_code: string;
    all: any;
    market_presence: any;
    contact_number: any;
    file?: any;
  },
  country_id: string
) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/country/${country_id}`;
    console.log(url, data);
    return (await patchWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function DeleteCountry(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/country/${id}`;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetCountry(id: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-country/${id}`;
    console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetCategories(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-categories`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}