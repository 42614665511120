import React, { useState, useEffect } from "react";

// @ts-ignore
import Cookies from "js-cookie";

// React Bootstrap components
import Button from "react-bootstrap/Button";
import { Container, Row, Col, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
// Components
import "./documentSettings.scss";
import TableMUI from "../components/TableMUI/TableMUI";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import AddDocumentSettingsPopup from "./AddDocumentSettingsPopup";
import { documentSettingsTableColumns } from "../components/TableMUI/TableColumns";
import {
  DeleteDocumentSetting,
  GetDocumentSettings,
} from "../../API/endpoints/document";
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import Navbar from "../components/header/NavBar";
import Auth from "@aws-amplify/auth";
import { GetUserProfile } from "../../API/endpoints/User";
import { useNavigate } from "react-router-dom";

function DocumentSettings() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [showDeleteSetting, setShowDeleteSetting] = useState(false);
  const [settingID, setSettingID] = useState();
  const [documentSettings, setDocumentSettings] = useState<{ data?: any }>({});
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [listPage, setListPage] = useState(0);
  const [searchParam, setSearchParam] = useState<string>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});

  useEffect(() => {
    const userType = Cookies.get("UserType");
    setUserID(Cookies.get("userID"));
    if (userType === "administrative") {
      setIsSuperAdmin(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();

    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });

    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
        }
      })();
    }
  }, [navigate, userID]);

  useEffect(() => {
    (async () => {
      const documentSettingsList = await GetDocumentSettings("0", searchParam);
      setDocumentSettings(documentSettingsList);
      documentSettingsList.data?.count &&
        setDataCount(documentSettingsList.data?.count);
    })();
  }, [flag, searchParam]);

  let rows: any = [];

  documentSettings.data?.rows &&
    documentSettings.data?.rows.map((dataItem: any) => {
      let category: string = "";
      let subcategory: string = "";
      switch (dataItem.category_id) {
        case 1:
          category = "Brand";
          break;
        case 2:
          category = "Partner";
          break;
        case 3:
          category = "Product";
          break;

        default:
          break;
      }
      switch (dataItem.sub_category_id) {
        case 1:
          subcategory = "Not Applicable";
          break;

        default:
          break;
      }
      const newItem = {
        documentname: dataItem.document_name,
        documentcategory: category,
        documentsubcategory: subcategory,
        documenttype: dataItem.document_type,
        id: dataItem.id,
      };
      rows = [...rows, newItem];
      return true;
    });

  const routeToForm = () => {
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const handleCloseDelete = () => setShowDeleteSetting(false);
  const deleteFunction = async (id: string) => {
    await DeleteDocumentSetting(id);
    setFlag(!flag);
  };

  const columns = documentSettingsTableColumns(
    setSettingID,
    setShowDeleteSetting,
    setShow
  );

  return (
    <Container fluid className="adminusers">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="adminusers-content p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item active>
              <Link to="/documentsettings"> DOCUMENT SETTINGS </Link>{" "}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button className="btn-primary12 addfilter">Add Filter</Button> */}
            {isSuperAdmin && (
              <div className="adduserBtn">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={routeToForm}
                  className="buttonHover btn-primary12"
                >
                  {isSuperAdmin ? "Add Document Settings" : "Add Document"}
                </Button>
              </div>
            )}
            <AddDocumentSettingsPopup
              isModalOpen={show}
              onCancel={handleClose}
              setFlag={setFlag}
              id={settingID}
              flag={flag}
            />
          </div>
          <div className="adminusertable">
            <div className="TableMUIcountainer">
              <TableMUI
                columns={columns}
                rows={rows}
                dataCount={dataCount}
                setListPage={setListPage}
                listPage={listPage}
              />
            </div>
            <PopupTemplate
              popupHeader="Confirm Deletion."
              popupBody="Are you sure you want to delete ?"
              handleFunction={deleteFunction}
              isModalOpen={showDeleteSetting}
              onCancel={handleCloseDelete}
              id={settingID}
              setFlag={setFlag}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DocumentSettings;
