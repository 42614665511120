import { postWith, getWith, deleteWith, patchWith } from "../axios";

export async function GetDocumentSettings(
  categoryID?: string,
  searchParam?: string
): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/document/settings/1/` +
      categoryID +
      `/` +
      searchParam;
    const documentsettings = await getWith<any[]>(url);
    return documentsettings;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetDocuments(ID?: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document/1/` + ID;
    const documentsettings = await getWith<any[]>(url);
    return documentsettings;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function FetchDocumentFromID(ID?: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document/id/` + ID;
    const documentsettings = await getWith<any[]>(url);
    return documentsettings;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetCategories(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document/categories`;
    const categories = await getWith<any[]>(url);
    return categories;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetSubCategories(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document/sub-categories/1`;
    const subcategories = await getWith<any[]>(url);
    return subcategories;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreateDocumentSetting(data: {
  category_id: number | undefined;
  sub_category_id: number | undefined;
  document_name: string;
  document_type: string;
  is_mandatory: boolean | undefined;
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document/settings`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateDocumentSetting(
  formSubmitData: any,
  id: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document/settings/` + id;
    return (await patchWith<any, any>(url, formSubmitData)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UploadDocument(data: {
  file: any;
  filename: any;
  document_settings_id: string | undefined;
  partner_id?: string | undefined;
  brand_id: string | undefined;
  uploaded_by: string | undefined;
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteDocumentSetting(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document/settings/` + id;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetDocumentSetting(id: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document/settings/id/` + id;
    const subcategories = await getWith<any[]>(url);
    return subcategories;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
