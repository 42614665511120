import { getWith } from "../axios";

export interface GetCurrentUserResponse {}

export async function GetRegionsList(): Promise<any> {
    try {
        const url = `${process.env.REACT_APP_API_URL}/common/fetch-countries/contact_number`;
        return (await getWith<any[]>(url)) as any;
    } catch (ex: any) {
        throw ex.response.data;
    }
}