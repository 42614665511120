import { getWith, postWith, deleteWith } from "../../axios";

export async function AssignTeam(data: any, id: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/assign-team/${id}`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}
export async function GetAssignedUsers(
  userType: string,
  id: any
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/assign-team/${userType}&&${id}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteAssignedTeam(id: number): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/assign-team/${id}`;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAssignedOrgsForUser(
  type: string,
  userId: string | undefined,
  listPage?: any,
  searchParam?: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/assigned-orgs/${type}&&${userId}/${listPage}/${searchParam}`;
    const permissions = await getWith<any[]>(url);
    return permissions;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
