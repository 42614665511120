import { getWith, deleteWith, postWith, patchWith } from "../../axios";
import { roleDataInputDTO } from "../../../pages/dtos/roles.dtos";

export async function CreateRole(data: roleDataInputDTO) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function UpdateRole(data: roleDataInputDTO, roleId: string) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/${roleId}`;
    return (await patchWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function DeleteRole(roleId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/${roleId}`;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetRoles(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetRolesByType(type: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/filter/${type}`;
    const roles = await getWith<any[]>(url);
    return roles;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetRoleById(roleId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/${roleId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPermissionsByRoleAndModuleID(
  roleId: string,
  moduleId: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/permissions/module/${roleId}&&${moduleId}`;
    const permissions = await getWith<any[]>(url);
    return permissions;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPermissionsByRoleID(roleId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/permissions/${roleId}`;
    const permissions = await getWith<any[]>(url);
    return permissions;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetRolesWithDataRestriction(
  UserType: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/restricted/${UserType}`;
    const permissions = await getWith<any[]>(url);
    return permissions;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetUsersByRole(roleId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/users/${roleId}`;
    const permissions = await getWith<any[]>(url);
    return permissions;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
