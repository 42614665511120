import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./BrandsList.scss";
import "../../styles/global.scss";
import "../../styles/pagelayout.scss";

// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// @ts-ignore
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";

// Components
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import TableMUI from "../components/TableMUI/TableMUI";
import { brandTableColumns } from "../components/TableMUI/TableColumns";
import Sidebar from "../components/sidebar/Sidebar";

// Functions
import {
  GetAllBrands,
  GetOrgBrands,
  DeleteBrand,
  RestoreBrand,
  SoftDeleteBrand,
} from "../../API/endpoints/Brands";
import { CreateActivity } from "../../API/endpoints/Dashboard";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import { GetDocumentSettings } from "../../API/endpoints/document";
import BrandAgreementPopUp from "../components/modalForms/Brand Agreement/brandAgreement";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";
import { GetAssignedOrgsForUser } from "../../API/endpoints/RolesAndPermissions/AssignTeam";

function BrandsList() {
  const navigate = useNavigate();

  const routeToForm = () => {
    navigate("/addnewbrand");
  };

  type dataInput = {
    rows?: {
      agreement_status: string;
      id: string;
      brand_name: string;
      brand_organization: { company_name: string };
      brand_categories: { categories: { name: string } }[];
      status: string;
      documents: any;
      is_deleted: boolean;
    }[];
  };

  type restrictedDataInput = {
    data?: {
      rows: {
        brand: any;
        agreement_status: { brand: { agreement_status: string } };
        id: { brand: { id: string } };
        brand_name: { brand: { brand_name: string } };
        brand_organization: {
          brand: { brand_organization: { company_name: string } };
        };
        brand_categories: { brand: { categories: { name: string } }[] };
        status: { brand: { status: string } };
        documents: any;
        is_deleted: { brand: { is_deleted: boolean } };
      }[];
    };
  };

  const [data, setData] = useState<dataInput>({});
  const [flag, setFlag] = useState(false);
  const [showDeleteBrand, setShowDeleteBrand] = useState(false);
  const [brandID, setBrandID] = useState("");
  const [brandName, setBrandName] = useState("");
  const [orgID, setOrgID] = useState<string | undefined>("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [listPage, setListPage] = useState(0);
  const [searchParam, setSearchParam] = useState<string>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [docTotal, setDocTotal] = useState(0);
  const [popupType, setPopupType] = useState<string>("");
  const [popupHeader, setPopupHeader] = useState<string>("");
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [agreementPopup, setAgreementPopup] = useState<boolean>(false);
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [dataRestriction, setDataRestriction] = useState(false);
  const [restrictedData, setRestrictedData] = useState<restrictedDataInput>({});

  // const handleVisibility = useCallback(() => {
  //   setFlag((prev) => !prev);
  // }, [setFlag]);
  // document.addEventListener("visibilitychange", handleVisibility);

  const handleClose = () => {
    setShowDeleteBrand(false);
    setPopupHeader("");
    setPopupMessage("");
  };

  const handleCloseAgreementPopup = () => {
    setAgreementPopup(false);
  };

  const columns = brandTableColumns(
    setBrandID,
    setShowDeleteBrand,
    navigate,
    setBrandName,
    setPopupType,
    setAgreementPopup,
    isSuperAdmin,
    userProfile,
    permissions
  );

  let rows: {
    id: string;
    brandname: string;
    organization: string;
    agreement_status: string;
    status: string;
    documents: string;
    is_deleted: boolean;
    files: string;
  }[] = [];

  if (data.rows) {
    rows = data.rows.map((dataItem) => ({
      id: dataItem.id,
      brandname: dataItem.brand_name,
      organization: dataItem.brand_organization?.company_name || "NA",
      // mou_status: dataItem.mou_status,
      // payment_status: dataItem.payment_status,
      // plans_status: dataItem.plans_status,
      agreement_status: dataItem.agreement_status,
      status: dataItem.status || "NA",
      documents: `${dataItem.documents.length}/${docTotal}`,
      is_deleted: dataItem.is_deleted,
      files: "Manage Files",
    }));
  }

  if (restrictedData?.data) {
    console.log("restrictedData: ", restrictedData);
    rows = restrictedData?.data?.rows?.map((dataItem: any) => ({
      id: dataItem?.brand.id,
      brandname: dataItem.brand.brand_name,
      organization: dataItem.brand.brand_organization?.company_name || "NA",
      agreement_status: dataItem.brand.agreement_status,
      status: dataItem.brand.status || "NA",
      documents: `${dataItem.brand.documents.length}/${docTotal}`,
      is_deleted: dataItem.brand.is_deleted,
      files: "Manage Files",
    }));
  }

  const deleteFunction = async () => {
    setFlag(false);
    await SoftDeleteBrand(brandID);
    await CreateActivity(
      `Brand: ${
        data?.rows?.filter((brand: any) => {
          return brand.id === brandID;
        })[0].brand_name
      } deleted temporarily.`
    );
    setFlag(true);
  };

  const restoreFunction = async () => {
    setFlag(false);
    await RestoreBrand(brandID);
    await CreateActivity(
      `Brand: ${
        data?.rows?.filter((brand: any) => {
          return brand.id === brandID;
        })[0].brand_name
      } restored.`
    );

    setFlag(true);
  };

  const premanentDeleteFunction = async () => {
    setFlag(false);
    await DeleteBrand(brandID);
    await CreateActivity(
      `Brand: ${
        data?.rows?.filter((brand: any) => {
          return brand.id === brandID;
        })[0].brand_name
      } deleted permanently.`
    );

    setFlag(true);
  };

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setOrgID(response.data?.organization?.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID?.toString());
      setDataRestriction(roleDetails?.data?.data_restriction);

      if (roleDetails?.data?.type === "organization") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "8"
        );
        console.log(response);
        setPermissions(response?.data);
      } else if (roleDetails?.data?.type === "administrative") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "2"
        );
        console.log(response);
        setPermissions(response?.data);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "1") {
      setIsSuperAdmin(true);
    }
  }, [permissions]);

  useEffect(() => {
    if (popupType === "restore") {
      setPopupHeader("Confirm Restore");
      setPopupMessage(
        "Are you sure you want to restore brand " + brandName + " ?"
      );
    } else if (popupType === "delete") {
      setPopupHeader("Confirm Deletion");
      setPopupMessage(
        "Are you sure you want to delete brand " + brandName + "?"
      );
    } else if (popupType === "delete-permanently") {
      setPopupHeader("Confirm Deletion");
      setPopupMessage(
        "Are you sure you want to permanently delete brand " + brandName + "?"
      );
    }
  }, [popupType, brandID, brandName]);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });

    if (
      permissions[0]?.sub_modules[4]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      if (dataRestriction) {
        (async () => {
          const response = await GetAssignedOrgsForUser(
            "brand",
            userID || "",
            listPage + 1,
            searchParam
          );
          console.log(response);
          setDataCount(response.data.count);
          setRestrictedData(response);
        })();
      } else {
        if (isSuperAdmin) {
          (async () => {
            const res = await GetAllBrands(listPage, searchParam);
            console.log(res);
            setDataCount(res.data.count);
            setData(res.data);
          })();
        } else if (orgID) {
          (async () => {
            const res = await GetOrgBrands(orgID, listPage, searchParam);
            console.log(res);
            setDataCount(res.data.count);
            setData(res.data);
          })();
        }
      }
    }
    (async () => {
      const response = await GetDocumentSettings("1", "");
      if (response?.data?.count) {
        setDocTotal(response.data.count);
      }
    })();
  }, [
    isSuperAdmin,
    navigate,
    orgID,
    flag,
    listPage,
    searchParam,
    permissions,
    dataRestriction,
    userID,
  ]);

  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="brand" flag={flag} />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item>BRAND MANAGEMENT </Breadcrumb.Item>
            <Breadcrumb.Item active>BRANDS</Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>

            {permissions[0]?.sub_modules[4]?.permissions_actions[0]
              ?.permission[0]?.permission === true &&
              permissions[0]?.sub_modules[0]?.permissions_actions[1]
                ?.permission[0]?.permission === true &&
              permissions[0]?.sub_modules[0]?.permissions_actions[0]
                ?.permission[0]?.permission === true && (
                <Button onClick={routeToForm} className="btn-primary12 adduser">
                  Add New Brand
                </Button>
              )}
          </div>
          <div className="table-mui">
            <div className="TableMUIcountainer">
              <TableMUI
                columns={columns}
                rows={rows}
                dataCount={dataCount}
                setListPage={setListPage}
                listPage={listPage}
              />
            </div>
            <PopupTemplate
              popupHeader={popupHeader}
              popupBody={popupMessage}
              handleFunction={
                popupType === "restore"
                  ? restoreFunction
                  : popupType === "delete"
                  ? deleteFunction
                  : premanentDeleteFunction
              }
              isModalOpen={showDeleteBrand}
              onCancel={handleClose}
              id={brandID}
              setFlag={setFlag}
              isRestore={popupType === "restore" ? true : false}
            />
            {/* <Subscription
            {/* <Subscription
              isModalOpen={showSubscriptionModal}
              handleCloseModal={handleCloseSubscriptionModal}
              brandID={brandID}
              setFlag={setFlag}
              planStatus={planStatus}
            /> */}
            {/* <ViewAndSignMou
              setFlag={setFlag}
              brandID={brandIdMou}
              mouStatus={mouStatus}
              resetMouStatus={resetMouStatus}
            /> */}
            <BrandAgreementPopUp
              isModalOpen={agreementPopup}
              onCancel={handleCloseAgreementPopup}
              brand_id={brandID}
              brandName={brandName}
              setFlag={setFlag}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default BrandsList;
