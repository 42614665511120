import AddFileSettingsModal from "../components/modalForms/addFileSettings/AddFileSettingsModal";
import { fileCategoriesDTO, fileTypesDTO, fileSettingsDataInputDTO  } from "../dtos/file.dtos";

export interface filesProps {
    isModalOpen: boolean;
    onCancel: () => void;
    fileCategories: fileCategoriesDTO[];
    fileTypes: fileTypesDTO[];
    getFileFormats: (FileTypeId: string) => void;
    fileFormats: any;
    onSubmit: (data: fileSettingsDataInputDTO) => void;
    defaultData: fileSettingsDataInputDTO;
    submitting: boolean;
    fileId: string;
}

const AddNewFileSettings: React.FunctionComponent<filesProps> = ({
    isModalOpen,
    onCancel,
    fileCategories,
    fileTypes,
    getFileFormats,
    fileFormats,
    onSubmit,
    defaultData,
    submitting,
    fileId
}) => {
    return (
        <div>
            <AddFileSettingsModal
                isModalOpen={isModalOpen}
                onCancel={onCancel}
                fileCategories={fileCategories}
                fileTypes={fileTypes}
                getFileFormats={getFileFormats}
                fileFormats={fileFormats}
                onSubmit={onSubmit}
                defaultData={defaultData}
                submitting={submitting}
                fileId={fileId}
            />
        </div>
    )
}

export default AddNewFileSettings;