//import "../../Organization/AddOrganization.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { countryDTO, orgDataDTO } from "../../dtos/organization.dtos";

const OrgInfoForm = (props: {
  onSubmit: any;
  org: orgDataDTO;
  countries: countryDTO[] | null;
  error: string;
  permissions: any;
}) => {
  const { onSubmit, org, countries } = props;
  const params = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      organizationName: "",
      address1: "",
      country: 2,
      address2: "",
      website: "",
      registrationNumber: "",
      pinCode: "",
    },
  });

  useEffect(() => {
    let defaults = {
      organizationName: org.company_name ? org.company_name : "",
      address1: org.company_address_line1 ? org.company_address_line1 : "",
      country: org.country_of_origin,
      address2: org.company_address_line2 ? org.company_address_line2 : "",
      website: org.website ? org.website : "",
      registrationNumber: org.company_registration_number
        ? org.company_registration_number
        : "",
      pinCode: org.company_address_postal_code
        ? org.company_address_postal_code
        : "",
    };
    reset(defaults);
  }, [org, reset]);

  const onHandleSubmit = (data: FieldValues) => {
    onSubmit(data);
  };

  useEffect(() => {
    setError("registrationNumber", { type: "custom", message: props.error });
  }, [props.error, setError]);

  return (
    <Form
      onSubmit={handleSubmit((data) => {
        onHandleSubmit(data);
      })}
    >
      <Container fluid>
        <Row className="mt-32px">
          <Col sm={6} md={5} lg={4} className="p-0">
            <Form.Group
              className="formgroup mr12px"
              controlId="formCompanyName"
            >
              <Form.Label>Organization Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Org Name"
                {...register("organizationName", {
                  required: "* This field is required",
                })}
              />

              <Form.Text muted>
                <span>
                  {errors?.organizationName && (
                    <p className="error-form">
                      {String(errors.organizationName?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col sm={6} md={5} lg={4} className="p-0">
            <Form.Group className="formgroup" controlId="formCompanyRegNo">
              <Form.Label>Business Registration Number *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Registration Number"
                {...register("registrationNumber", {
                  required: "* This field is required",
                })}
              />
              <Form.Text muted>
                <span>
                  {errors?.registrationNumber && (
                    <p className="error-form">
                      {String(errors.registrationNumber?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt24px">
          <Col sm={6} md={5} lg={4} className="p-0">
            <Form.Group
              className="formgroup mr12px"
              controlId="formCompanyCountry"
              key={"country" + Math.floor(Math.random() * 100)}
            >
              <Form.Label>Country Of Origin *</Form.Label>
              <Form.Select
                {...register("country", {
                  required: "* This field is required",
                })}
              >
                {countries?.map((country: { id: string; name: string }) => {
                  return (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Text muted>
                <span>
                  {errors?.country && (
                    <p className="error-form">
                      {String(errors.country?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col sm={6} md={5} lg={4} className="p-0">
            <Form.Group className="formgroup" controlId="formCompanyWebsite">
              <Form.Label>Organization Website</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter website address"
                {...register("website")}
              />
              <Form.Text muted>
                <span>
                  {errors?.website && (
                    <p className="error-form">
                      {String(errors.website?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt24px">
          <Col sm={6} md={5} lg={4} className="p-0">
            <Form.Group
              className="formgroup mr12px"
              controlId="formCompanyAddress1"
            >
              <Form.Label>Address Line 1 *</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter address"
                {...register("address1", {
                  required: "* This field is required",
                })}
              />
              <Form.Text muted>
                <span>
                  {errors?.address1 && (
                    <p className="error-form">
                      {String(errors.address1?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col sm={6} md={5} lg={4} className="p-0">
            <Form.Group className="formgroup" controlId="formCompanyAddress2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="address line 2"
                {...register("address2")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt24px">
          <Col sm={6} md={5} lg={4} className="p-0">
            <Form.Group className="formgroup mr12px" controlId="formCompanyPIN">
              <Form.Label>Zip Code/Postal Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Postal code"
                {...register("pinCode")}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bt-gray200 mt40px">
        <Row className="justify-content-end mb-3 mt-4">
          <Col className="d-flex justify-content-end">
            <Link to="/OrganizationManagement" className="m-1">
              <Button variant="secondary" className="btn-secondary12">
                Cancel
              </Button>
            </Link>

            {props.permissions[0]?.sub_modules[0]?.permissions_actions[2]
              ?.permission[0]?.permission === true &&
              params.orgid && (
                <Button
                  variant="primary"
                  className="btn-primary12 m-1"
                  type="submit"
                >
                  Update
                </Button>
              )}
            {props.permissions[0]?.sub_modules[0]?.permissions_actions[0]
              ?.permission[0]?.permission === true &&
              !!!params.orgid && (
                <Button
                  variant="primary"
                  className="btn-primary12 m-1"
                  type="submit"
                >
                  Save and continue
                </Button>
              )}
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default OrgInfoForm;
