import { getWith, postWith, patchWith } from "../../axios";

export async function AssignFoldersToRole(data: any, roleId: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/folders/${roleId}`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function UpdateFoldersOfRole(data: any, roleId: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/folders/${roleId}`;
    return (await patchWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function GetFolderList(roleId: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/folders/${roleId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}