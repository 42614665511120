import "./InfoSideBar.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";

export interface InfoSideBarProps {
  showFileInfo: boolean;
  onClose: () => void;
  files: any;
  fileTypeId: any;
}
const InfoSideBar: React.FunctionComponent<InfoSideBarProps> = ({
  showFileInfo,
  onClose,
  files,
  fileTypeId,
}) => {
  const [selectedFile, setSelectedFile] = useState<any>({});
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setSelectedFile(files?.find((itm: any) => itm?.isChecked === true));
  }, [files]);

  const downloadFile = () => {
    window.open(selectedFile?.path);
    // fetch(selectedFile?.path).then(response => response.blob()).then(blob => {
    //     const blobURL = window.URL.createObjectURL(new Blob([blob]))
    //     const file_name = selectedFile?.path.split('/').pop();
    //     const aTag = document.createElement("a");
    //     aTag.href = blobURL;
    //     aTag.setAttribute('download', file_name);
    //     document.body.appendChild(aTag);
    //     aTag.click();
    //     aTag.remove();
    // })
  };

  const dateFormatter = (d: any) => {
    const createdAt = new Date(d);
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let createdDate =
      month[createdAt.getMonth()] +
      " " +
      createdAt.getDate() +
      " " +
      createdAt.getFullYear();
    let createdTme = createdAt.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return "Last updated at " + createdDate + " " + createdTme;
  };
  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  return (
    <Offcanvas
      show={showFileInfo}
      onHide={handleClose}
      placement="end"
      className="info-sidebar"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="font18-medium gray-800">
          File Info
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="info-wraper">
          <div className="file-pic">
            {fileTypeId === 1 && (
              <img src="../svgs/file-pic.svg" alt="file-pic.svg" />
            )}
            {fileTypeId === 2 && (
              <img
                width={40}
                height={41}
                src="../svgs/image-icon.svg"
                alt="icon"
              />
            )}
          </div>
          <div className="info">
            <div className="filename">{selectedFile?.original_name}</div>
            <div className="filesize ">
              {formatBytes(selectedFile?.file_size)}
            </div>
            <div className="updated-info">
              <img src="../svgs/time.svg" alt="time" />
              <span>{dateFormatter(selectedFile?.updated_at)}</span>
            </div>
          </div>
        </div>
        <Button className="download-btn" onClick={downloadFile}>
          <img src="../svgs/download.svg" alt="download" />
          <span>Download File</span>
        </Button>
        <div className="b-gray200"></div>
        {selectedFile?.notes && <div className="note-heding">Notes</div>}
        {selectedFile?.notes && (
          <div className="note-content">{selectedFile?.notes}</div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default InfoSideBar;
