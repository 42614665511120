import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { Multiselect } from "multiselect-react-dropdown";

import {
  BrandDataInputDTO,
  categoriesDTO,
  organizationsDTO,
  yearsOfOperationDTO,
} from "../../dtos/brand.dtos";
import { GetAllOrg, GetOrg } from "../../../API/endpoints/Organization";
import Cookies from "js-cookie";
import { GetBrand } from "../../../API/endpoints/Brands";
import { GetUserProfile } from "../../../API/endpoints/User";

export interface BrandBasicInfoFormProps {
  submitting: boolean;
  onSubmit: (data: BrandDataInputDTO) => void;
  errorMessage: string;
  defaultData?: BrandDataInputDTO;
  isError: boolean;
  org?: organizationsDTO[];
  categories?: categoriesDTO[];
  years?: yearsOfOperationDTO[];
  brand?: string;
  handleCategory: (idList: any) => void;
  setDefaultCategory: (categories: any) => void;
  defaultCategories: any;
  setSelectedCategory: any;
  categoryMessage: string;
  permissions: any;
}

const BrandBasicInfoForm: React.FunctionComponent<BrandBasicInfoFormProps> = ({
  submitting,
  onSubmit,
  errorMessage,
  defaultData,
  isError,
  org,
  categories,
  years,
  brand,
  handleCategory,
  setDefaultCategory,
  defaultCategories,
  setSelectedCategory,
  categoryMessage,
  permissions,
}) => {
  const [orgID, setOrgID] = useState<string | undefined>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [organizations, setOrganizations] = useState<any>([]);
  const [selectedOrg, setSelectedOrg] = useState<{ id: string; name: string }>({
    id: "",
    name: "",
  });
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [orgError, setOrgError] = useState<string>("");
  const params = useParams();

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setOrgID(response.data?.organization?.id);
        }
      })();
    }
  }, [userID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "1") {
      setIsSuperAdmin(true);
    }
  }, [permissions]);

  useEffect(() => {
    const GetAllOrganizationData = async () => {
      const response = await GetAllOrg();
      if (response) {
        setOrganizations(
          response?.data?.rows.map(
            (dataItem: { id: any; company_name: any }) => {
              return {
                value: dataItem.id,
                label: dataItem.company_name,
              };
            }
          )
        );
      } else console.log("Cannot get Org data");
    };

    const getOrganizationDetails = async () => {
      const response = await GetOrg(orgID);
      if (response) {
        console.log("org data ", response);
        setSelectedOrg({
          id: response.data.id,
          name: response.data.company_name,
        });
      }
    };

    if (isSuperAdmin) {
      getOrganizationDetails();
      GetAllOrganizationData();
    } else {
      getOrganizationDetails();
    }
  }, [isSuperAdmin, orgID]);

  const filterOrgs = (inputValue: string) => {
    if (isSuperAdmin) {
      const GetAllOrganizationData = async () => {
        const response = await GetAllOrg(0, inputValue);
        if (response) {
          setOrganizations(
            response?.data?.rows.map(
              (dataItem: { id: any; company_name: any }) => {
                return {
                  value: dataItem.id,
                  label: dataItem.company_name,
                };
              }
            )
          );
        } else console.log("Cannot get Org data");
      };
      GetAllOrganizationData();
      return organizations.filter((i: { label: string }) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<any>((resolve) => {
      setTimeout(() => {
        resolve(filterOrgs(inputValue));
      }, 10);
    });

  const { register, formState, handleSubmit, reset } = useForm({
    defaultValues: defaultData,
  });
  const { errors } = formState;

  const onHandleSubmit = (data: BrandDataInputDTO) => {
    if (selectedOrg.id === "") {
      setOrgError("This field is required");
    } else {
      data.organization = selectedOrg.id;
      console.log(data);
      onSubmit(data);
    }
  };

  useEffect(() => {
    if (params?.brandid) {
      (async () => {
        const response = await GetBrand(params?.brandid);
        const data = response.data;
        setOrgID(data?.org_id);
        const defaults = {
          brandName: data?.brand_name || "",
          revenue: data?.current_revenue || "",
          year: data?.years_of_operation || "",
          // category: data?.brand_categories
          //   ? data?.brand_categories[0]?.focus_category
          //   : "",
          // category: data?.brand_categories
          //   ? data?.brand_categories[0]?.focus_category
          //   : "",
          organization: defaultData ? defaultData.organization : "",
        };

        if (data.brand_categories.length !== 0) {
          setDefaultCategory(
            data.brand_categories.map((itm: any) => {
              return {
                id: itm.focus_category,
                name: itm.categories.name,
              };
            })
          );
          setSelectedCategory(
            data.brand_categories.map((itm: any) => itm.focus_category)
          );
        }
        if (data.brand_categories.length !== 0) {
          setDefaultCategory(
            data.brand_categories.map((itm: any) => {
              return {
                id: itm.focus_category,
                name: itm.categories.name,
              };
            })
          );
          setSelectedCategory(
            data.brand_categories.map((itm: any) => itm.focus_category)
          );
        }
        reset(defaults);
      })();
    }
  }, [
    defaultData,
    params?.brandid,
    reset,
    setDefaultCategory,
    setSelectedCategory,
  ]);

  // useEffect(() => {
  //   if(defaultData?.category?.length !== 0) {
  //     handleCategoryChange(defaultData?.category)
  //   }
  // },[defaultData])

  const handleCategoryChange = (categories: any) => {
    let idList = categories.map((item: any) => {
      return item.id;
    });
    handleCategory(idList);
  };

  const handleRemove = (categories: any) => {
    let idList = categories.map((item: any) => {
      return item.id;
    });
    handleCategory(idList);
  };

  return (
    <Form
      onSubmit={handleSubmit((data) => {
        onHandleSubmit(data);
      })}
      className="mt40px"
    >
      <Container fluid>
        <Row className="input-box-row">
          <Col sm={6} md={4} lg={3} className="p-0 input-box">
            <Form.Group
              className="formgroup mr12px"
              controlId="formParentOrganization"
            >
              <Form.Label>Select Parent Organization *</Form.Label>
              {isSuperAdmin ? (
                <>
                  <AsyncSelect
                    className="asynselect"
                    cacheOptions
                    isDisabled={params?.brandid ? true : false}
                    onChange={(e: any) => {
                      setOrgID(e.value);
                      setOrgError("");
                    }}
                    value={{ value: selectedOrg.id, label: selectedOrg.name }}
                    defaultOptions={organizations}
                    loadOptions={promiseOptions}
                  />
                  <Form.Text muted>
                    <span>
                      {orgError && <p className="error-form">{orgError}</p>}
                    </span>
                  </Form.Text>
                </>
              ) : (
                <>
                  <Form.Control
                    type="text"
                    placeholder="Organization Name"
                    value={selectedOrg.name}
                    disabled
                    name="organization"
                  />
                </>
              )}
            </Form.Group>
          </Col>
          <Col sm={6} md={4} lg={3} className="p-0 input-box">
            <Form.Group className="formgroup" controlId="formYearsOperation">
              <Form.Label>Years of Operation</Form.Label>
              <Form.Select {...register("year")} name="year">
                <option value={""}>Select years of operation</option>
                {years?.length &&
                  years.map((year) => {
                    return (
                      <option value={year.id} key={year.id}>
                        {year.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt24px input-box-row">
          <Col sm={6} md={4} lg={3} className="p-0 input-box">
            <Form.Group className="formgroup mr12px" controlId="formBrandName">
              <Form.Label>Brand Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Brand Name"
                {...register("brandName", {
                  required: "* This field is required",
                  pattern: {
                    value: /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/,
                    message: "Name can only contain Characters or numbers",
                  },
                })}
                name="brandName"
              />
              <Form.Text muted>
                <span>
                  {errors?.brandName && (
                    <p className="error-form">
                      {String(errors.brandName?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          {/* <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group className="formgroup" controlId="formFocusCategory">
              <Form.Label>Focus Category *</Form.Label>
              <Multiselect
                id="css_custom"
                customCloseIcon={
                  <>
                    <img src="../svgs/close-btn.svg" alt="close-btn" />
                  </>
                }
                showArrow
                options={categories}
                displayValue="name"
                showCheckbox={true}
                placeholder="Select"
                closeIcon="cancel"
                selectedValues={defaultCategories}
                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleCategoryChange(e);
                }}
                onRemove={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleRemove(e);
                }}
              />
              <Form.Text muted>
                <span>
                  {categoryMessage && (
                    <p className="error-form">{categoryMessage}</p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col> */}

          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group
              className="formgroup mr12px"
              controlId="formCurrentRevenue"
            >
              <Form.Label>Current Revenue</Form.Label>
              <Form.Control
                type="text"
                placeholder="1000.00"
                {...register("revenue")}
                name="revenue"
              />
              <Form.Text muted>
                <span>
                  {errors?.revenue && (
                    <p className="error-form">
                      {String(errors.revenue?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt24px input-box-row">
          <Col sm={6} md={4} lg={3} className="p-0 input-box">
            <Form.Group
              className="formgroup focus-category"
              controlId="formFocusCategory"
            >
              <Form.Label>Focus Category *</Form.Label>
              <Multiselect
                id="css_custom"
                customCloseIcon={
                  <>
                    <img src="../svgs/close-btn.svg" alt="close-btn" />
                  </>
                }
                showArrow
                options={categories}
                displayValue="name"
                showCheckbox={true}
                placeholder="Select"
                closeIcon="cancel"
                selectedValues={defaultCategories}
                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleCategoryChange(e);
                }}
                onRemove={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleRemove(e);
                }}
              />
              <Form.Text muted>
                <span>
                  {categoryMessage && (
                    <p className="error-form">{categoryMessage}</p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>

          <Col className="columnflex2"></Col>
        </Row>
      </Container>
      <Container fluid className="bt-gray200 mt40px">
        <Row className="justify-content-end mb-3 mt-4">
          <Col className="d-flex justify-content-end">
            <Link to="/BrandManagement" className="m-1">
              <Button variant="secondary" className="btn-secondary12">
                Cancel
              </Button>
            </Link>
            {permissions[0]?.sub_modules[0]?.permissions_actions[0]
              ?.permission[0]?.permission === true &&
              typeof brand !== "string" && (
                <Button
                  variant="primary"
                  className="btn-primary12 m-1"
                  type="submit"
                  disabled={submitting ? true : false}
                >
                  Save and continue
                </Button>
              )}
            {permissions[0]?.sub_modules[0]?.permissions_actions[2]
              ?.permission[0]?.permission === true &&
              typeof brand === "string" && (
                <Button
                  variant="primary"
                  className="btn-primary12 m-1"
                  type="submit"
                  disabled={submitting ? true : false}
                >
                  Update
                </Button>
              )}
          </Col>
        </Row>
        <Form.Text muted>
          <span>{isError && <p className="error-form">{errorMessage}</p>}</span>
        </Form.Text>
      </Container>
    </Form>
  );
};

export default BrandBasicInfoForm;
