import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../../styles/modals/modal400.scss";
import "./SendResetLinkModal.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useForm } from "react-hook-form";

export interface SendResetLinkModalProps {
  resetLinkModal: boolean;
  handleCloseModal: () => void;
  onSubmit: (email: string) => void;
  errorMessage: string;
}

const SendResetLinkModal: React.FunctionComponent<SendResetLinkModalProps> = ({
  resetLinkModal,
  handleCloseModal,
  onSubmit,
  errorMessage,
}) => {
  const { register, formState, handleSubmit } = useForm();
  const { errors } = formState;
  const handleClose = () => {
    handleCloseModal();
  };
  const onHandleSubmit = (email: string) => {
    onSubmit(email);
  };
  return (
    <>
      <Modal
        show={resetLinkModal}
        onHide={handleClose}
        className="modal400 SendResetLinkModal"
        centered
      >
        <Modal.Header closeButton={false}>
          <div className="alert-icon">
            <img src="../svgs/red-alert-triangle .svg" alt="alert-triangle" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="title-text font20-medium gray-800">
            Reset Password
          </div>
          <div className="font14-regular gray-500 mb20px">
            Enter your registered email, and we’ll send you a code to reset your
            password.
          </div>
          <Form
            onSubmit={handleSubmit((data) => {
              onHandleSubmit(data.email);
            })}
          >
            <div className="input-email">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <img src="../svgs/mail.svg" alt="mail" />
                </InputGroup.Text>
                <Form.Control
                  type="email"
                  placeholder="sample@mail.com"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon1"
                  {...register("email", {
                    required: "* This field is required",
                  })}
                />
              </InputGroup>
            </div>
            <Form.Text muted>
              <span>
                {errors?.email && (
                  <p className="error-form">{String(errors.email?.message)}</p>
                )}
              </span>
            </Form.Text>
            <Form.Text muted>
              <span>
                {errorMessage && (
                  <p className="error-form">{String(errorMessage)}</p>
                )}
              </span>
            </Form.Text>
            <div className="footer-buttons">
              <Button className="cancelbtn" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="submitbtn" type="submit">
                Send code to email
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SendResetLinkModal;
