import AddCountryModal from "../components/modalForms/addCountry/AddCountryModal";
import { useState, useEffect } from "react";
import { UpdateCountryApi, GetCountry } from "../../API/endpoints/Common";
export interface addCountryProps {
  isModalOpen: boolean;
  onCancel: () => void;
  setFlag: (val: boolean) => void;
  countryId: string;
}
const AddCountry: React.FunctionComponent<addCountryProps> = ({
  isModalOpen,
  onCancel,
  setFlag,
  countryId,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [editData, setEditData] = useState<any>();
  const onSubmit = async (data: any) => {
    setSubmitting(true);
    if (countryId) {
      const response = await UpdateCountryApi(data, countryId);
      if (response.msg === "Country updated successfully") {
        onCancel();
        setFlag(true);
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (countryId) {
      (async () => {
        const response = await GetCountry(countryId);
        setEditData(response.data);
      })();
    } else {
      setEditData({
        name: "",
        country__alpha_code: "",
        country_code: "",
        all: true,
        market_presence: false,
      });
    }
  }, [countryId]);
  return (
    <div>
      <AddCountryModal
        isModalOpen={isModalOpen}
        onCancel={onCancel}
        submitting={submitting}
        onSubmit={onSubmit}
        defaultData={editData}
      />
    </div>
  );
};

export default AddCountry;
