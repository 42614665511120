import { Container, Row, Col } from "react-bootstrap";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import "./CountriesList.scss";
import { useState, useEffect } from "react";
import AddCountry from "./AddCountry";
import TableMUI from "../components/TableMUI/TableMUI";
import { CountryTableColumns } from "../components/TableMUI/TableColumns";
import { DeleteCountry, GetAllCountries } from "../../API/endpoints/Common";
import Auth from "@aws-amplify/auth";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import { GetPermissionsByRoleAndModuleID } from "../../API/endpoints/RolesAndPermissions/Roles";

const CountriesList = () => {
  const navigate = useNavigate();
  type dataInput = {
    data?: {
      id: Number;
      name: string;
      country__alpha_code: string;
      country_code: string;
      country_img: string;
    }[];
  };

  const [userID, setUserID] = useState<string | undefined>("");
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [countryList, setCountryList] = useState<dataInput>({});
  const [userProfile, setUserProfile] = useState<any>({});
  const [countryId, setCountryId] = useState("");
  const [showDeleteCountry, setShowDeleteCountry] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [listPage, setListPage] = useState(0);
  const [searchParam, setSearchParam] = useState<string>("");

  const handleClose = () => {
    setShow(false);
    setCountryId("");
  };

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const response = await GetPermissionsByRoleAndModuleID(
        roleID.toString(),
        "7"
      );
      console.log(response);
      setPermissions(response?.data);
    })();
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "2") {
      navigate("/OrganizationManagement");
    } else if (permissions[0]?.permission_type.toString() === "3") {
      navigate("/PartnerManagement");
    }
  }, [permissions, navigate]);

  useEffect(() => {
    (async () => {
      setCountryList({});
      const response = await GetAllCountries(listPage, searchParam);
      console.log(response);
      setCountryList(response);
      const response2 = await GetAllCountries(-1);
      setDataCount(response2.data.length);
    })();
    setFlag(false);
  }, [flag, searchParam, listPage]);

  const columns = CountryTableColumns(
    setCountryId,
    setShowDeleteCountry,
    setShow,
    permissions
  );
  const handleCloseDelete = () => setShowDeleteCountry(false);
  const deleteFunction = async () => {
    await DeleteCountry(countryId);
    setCountryId("");
    setFlag(true);
  };

  let rows: {
    id: Number;
    name: string;
    country__alpha_code: string;
    country_code: string;
    country_img: string;
  }[] = [];

  if (
    countryList?.data &&
    permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    rows = countryList?.data?.map((dataItem) => {
      return {
        id: dataItem?.id,
        name: dataItem?.name,
        country__alpha_code: dataItem?.country__alpha_code,
        country_code: dataItem?.country_code,
        country_img: dataItem?.country_img,
      };
    });
  }

  return (
    <Container fluid className="CountriesList">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="contries-content p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item href="/SettingsList">SETTINGS</Breadcrumb.Item>
            <Breadcrumb.Item href="/ServiceCountriesList" active>
              PLATFORM SETTINGS
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button onClick={handleShow} className="btn-primary12 add-btn">
              Add New
            </Button> */}
          </div>
          <AddCountry
            isModalOpen={show}
            onCancel={handleClose}
            setFlag={setFlag}
            countryId={countryId}
          />
          <div className="countrytable TableMUIcountainer">
            <TableMUI
              columns={columns}
              rows={rows}
              setListPage={setListPage}
              listPage={listPage}
              dataCount={dataCount}
            />
            <PopupTemplate
              popupHeader="Confirm Deletion."
              popupBody={`Are you sure you want to delete Country`}
              handleFunction={deleteFunction}
              isModalOpen={showDeleteCountry}
              onCancel={handleCloseDelete}
              id={countryId}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default CountriesList;
