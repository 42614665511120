import "../../styles/centeredlayout.scss";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/header/NavBar";
import ToastMessage from "../components/toasts/ToastMessage";
import AdminForm from "../components/forms/adminForm";
import RolePermissionsModal from "../components/modalForms/addAdminUser/RolePermissionsModal";
import { useState, useEffect } from "react";
import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { adminInputDTO } from "../dtos/admin.dtos";
import { GetUserProfile } from "../../API/endpoints/User";
import {
  GetDepartments,
  GetRolesList,
  addAdminUser,
  getAdminUser,
  updateAdmin,
} from "../../API/endpoints/Admin";
import { GetRegionsList } from "../../API/endpoints/Regions";
import { GetRoleById } from "../../API/endpoints/RolesAndPermissions/Roles";
import { GetAssignedPermissionsByRoleId } from "../../API/endpoints/RolesAndPermissions/Permissions";

const AddAdminUser = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [regionList, setRegionList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [adminData, setAdminData] = useState<any>({});
  // const [isEdit, setEdit] = useState(false);
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const [roleData, setRoleData] = useState<any>({});
  const [rolePermissions, setRolePermissions] = useState([]);
  const [showRolePermissions, setShowRolePermissions] = useState(false);
  const [errors, setErrors] = useState({ field: "", msg: "" });
  const [roleID, setRoleID] = useState<number>(0);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID?.toString());
      if (roleDetails?.data?.type === "partner") {
        navigate("/PartnerManagement");
      } else if (roleDetails?.data?.type === "organization") {
        navigate("/OrganizationManagement");
      }
    })();
  }, [roleID, navigate]);

  useEffect(() => {
    (async () => {
      try {
        const response = await GetRolesList();
        setRolesList(response.data);
      } catch (error) {}
      try {
        const response = await GetRegionsList();
        setRegionList(response.data);
      } catch (error) {}
      try {
        const response = await GetDepartments();
        setDepartmentsList(response.data);
      } catch (error) {}
      if (params.id) {
        try {
          const response = await getAdminUser(params.id);
          setAdminData({
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            email_id: response.data.email_id,
            contact_number: response.data.contact_number,
            contact_number_code: response.data.contact_number_code,
            department: response.data.departments.id,
            designation: response.data.designation,
            role: response.data.user_roles[0].roles.id,
          });
          handleRoleChange(response.data.user_roles[0].roles.id);
        } catch (error) {}
      }
    })();
  }, [params]);

  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });

  const onSubmit = async (data: adminInputDTO) => {
    if (params?.id) {
      try {
        setSubmitting(true);
        const response = await updateAdmin(data, params.id);
        if (response.msg === "User profile updated successfully") {
          setNotification({ type: "success", msg: response.msg });
          navigate("/AdminUsersList");
        }
        setSubmitting(false);
      } catch (error: any) {
        setSubmitting(false);
        if (error?.msg) {
          setNotification({ type: "error", msg: error.msg });
        } else {
          setNotification({ type: "error", msg: error });
          if (error === "Email already exists") {
            setErrors({ field: "email_id", msg: error });
          } else if (error === "Contact number already exists") {
            setErrors({ field: "contact_number", msg: error });
          } else {
          }
        }
      }
    } else {
      try {
        setSubmitting(true);
        const response = await addAdminUser(data);
        if (response.msg === "User created successfully") {
          setNotification({ type: "success", msg: response.msg });
          navigate("/AdminUsersList");
        }
        setSubmitting(false);
      } catch (error: any) {
        setSubmitting(false);
        if (error?.msg) {
          setNotification({ type: "error", msg: error.msg });
        } else {
          setNotification({ type: "error", msg: error });
          if (error === "Email already exists") {
            setErrors({ field: "email_id", msg: error });
          } else if (error === "Contact number already exists") {
            setErrors({ field: "contact_number", msg: error });
          } else {
          }
        }
      }
    }
  };
  const handleRoleChange = async (roleId: any) => {
    try {
      const res = await GetRoleById(roleId);
      setRoleData(res.data);
    } catch (error) {}

    try {
      const res = await GetAssignedPermissionsByRoleId(roleId);
      const response = {
        data: res?.data?.map((item: any) => {
          return item.permissions_list;
        }),
      };
      console.log(response.data);
      if (response.data.length !== 0) {
        const formatedList = response.data.map((module: any) => {
          return {
            ...module,
            permission: module.sub_modules.find((sub: any) =>
              sub.permissions_actions.find(
                (action: any) => action.permission[0].permission === true
              )
            )
              ? true
              : false,
            sub_modules: module.sub_modules.map((sub: any) => {
              return {
                ...sub,
                permission: sub.permissions_actions.find(
                  (action: any) => action.permission[0].permission === true
                )
                  ? true
                  : false,
                permissions_actions: sub.permissions_actions.map(
                  (action: any) => {
                    return {
                      ...action,
                      permission:
                        action.permission[0].permission === true ? true : false,
                    };
                  }
                ),
              };
            }),
          };
        });
        setRolePermissions(formatedList);
      } else {
        setRolePermissions(response.data);
      }
    } catch (error) {}
  };
  const handleClose = () => {
    setShowRolePermissions(false);
  };

  const resetErrors = (arg1: string, arg2: string) => {
    setErrors({ field: arg1, msg: arg2 });
  };

  return (
    <Container fluid className="centered-layout">
      <Row>
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <div className="content-wraper">
            <div className="content">
              <Breadcrumb className="font12-medium breadcrumbnav">
                <Breadcrumb.Item href="/SettingsList">Settings</Breadcrumb.Item>
                <Breadcrumb.Item href="/AdminUsersList">
                  Manage users
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  href={
                    params?.id
                      ? "/EditAdminUser/" + params?.id
                      : "/AddAdminUser"
                  }
                  active
                >
                  {params?.id ? "Edit User" : "Add New User"}
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="headerbody-cntr">
                <div className="content-header">
                  <Button
                    className="backarrow-btn mr16px"
                    onClick={() => {
                      navigate("/AdminUsersList");
                    }}
                  >
                    <img src="../svgs/arrows-left.svg" alt="arrow" />
                  </Button>
                  <span className="font18-medium gray-800">
                    {params?.id ? "Edit User" : "Add New User"}
                  </span>
                </div>
                <div className="hr-line">
                  <div />
                </div>
                <div className="content-body">
                  <AdminForm
                    regionList={regionList}
                    departmentsList={departmentsList}
                    rolesList={rolesList}
                    onSubmit={onSubmit}
                    submitting={submitting}
                    defaultData={adminData}
                    isEdit={params?.id ? true : false}
                    roleChange={handleRoleChange}
                    setShowRolePermissions={setShowRolePermissions}
                    roleData={roleData}
                    error={errors}
                    resetErrors={resetErrors}
                  />
                </div>
              </div>
            </div>
          </div>
          <RolePermissionsModal
            isModalOpen={showRolePermissions}
            onCancel={handleClose}
            roleData={roleData}
            rolePermissions={rolePermissions}
          />
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AddAdminUser;
