import { getWith, deleteWith, postWith, patchWith } from "../../axios";
import { fileSettingsDataInputDTO } from "../../../pages/dtos/file.dtos";

export async function CreateFileSettings(data: fileSettingsDataInputDTO) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/file-settings`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function UpdateFileSettings(
  data: fileSettingsDataInputDTO,
  fileId: string
) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/file-settings/${fileId}`;
    return (await patchWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function DeleteFileSettings(fileId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/file-settings/${fileId}`;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllFiles(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/list-file-settings/all`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetFile(fileId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/file-settings/${fileId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetFileCategories(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document/categories`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetFileTypes(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/filetypes`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetFileFormats(filetypeId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/fileformats/${filetypeId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllFileFormats(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/all-file-formats`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetFilesByCategory(category: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/list-file-settings/${category}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetFilesSettingsForRole(roleID: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/folders/${roleID}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
