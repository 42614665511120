import { getWith, postWith, patchWith, deleteWith } from "../axios";
import { AdvisoryReportInputDTO } from "../../pages/dtos/advisory.dtos";

export async function GetReportCategories(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/report-categories`;
    console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetReportNamesForCategory(
  category_id: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/reports/${category_id}`;
    console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreateReportRequest(data: {
  brand_id: string | undefined;
  reports_id: number;
  lead_time: number;
}) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/report-request`;
    console.log(url, data);
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetReportRequestsForOrg(
  org_id: string | undefined,
  listPage?: number,
  searchParam?: string
): Promise<any> {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/advisory/report-request/${org_id}/${
      listPage ? listPage + 1 : 1
    }/${searchParam}`;
    console.log("URL", url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetReportRequestByID(
  id: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/report-request/get-report`;
    console.log(url);
    return (await postWith<any, any>(url, { id: id })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllReportRequests(
  listPage?: number,
  statusSearch?: string
): Promise<any> {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/advisory/all-report-request?status=${statusSearch}&limit=20&offset=${
      listPage ? listPage + 20 : 0
    }`;
    console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAdvisoryRequestsDataRestriction(
  user_id: string | undefined,
  listPage?: number,
  statusSearch?: string
): Promise<any> {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/role/advisory-req-data-restriction/${user_id}?status=${statusSearch}&limit=20&offset=${
      listPage ? listPage + 20 : 0
    }`;
    console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function MakeAdvisoryPayment(data: {
  items: any[];
  advisory_id: number;
}) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/stripe/create-advisory-checkout-session`;
    console.log(url, data);
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function UpdateReportRequestStatus(
  requestid: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/report-request-status/${requestid}`;
    return (await patchWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UploadAdvisoryReport(data: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/document`;
    console.log(url, data);
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function AddReportWithCategory(data: AdvisoryReportInputDTO) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/add-report-with-category`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function AddReport(data: AdvisoryReportInputDTO) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/add-report`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function UpdateReport(
  id: string | undefined,
  data: AdvisoryReportInputDTO
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/report/${id}`;
    return (await patchWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllReports(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/reports`;
    console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetReport(id: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/fetch-report/${id}`;
    console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteReport(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/advisory/report/${id}`;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
