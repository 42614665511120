import "../../styles/pagelayout.scss";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";
import Navbar from "../components/header/NavBar";
import BreadCrumb from "../components/header/BreadCrumb";
import Sidebar from "../components/sidebar/Sidebar";
import AddNewFileSettings from "./AddNewFileSettings";
import { GetUserProfile } from "../../API/endpoints/User";
import {
  GetAllFiles,
  GetFileCategories,
  GetFileTypes,
  GetFileFormats,
  CreateFileSettings,
  UpdateFileSettings,
  GetFile,
  DeleteFileSettings,
  GetAllFileFormats,
} from "../../API/endpoints/fileupload/FileSettings";
import { fileSettingsDataInputDTO } from "../dtos/file.dtos";
import TableMUI from "../components/TableMUI/TableMUI";
import { FilesTableColumns } from "../components/TableMUI/TableColumns";
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import ToastMessage from "../components/toasts/ToastMessage";
import { CreateActivity } from "../../API/endpoints/Dashboard";
import { GetPermissionsByRoleAndModuleID } from "../../API/endpoints/RolesAndPermissions/Roles";

const FileList = () => {
  const navigate = useNavigate();
  type dataInput = {
    data?: {
      id: number;
      name: string;
      category: { id: number; name: string };
      file_type: { id: number; type_name: string };
      file_formats: number[];
      public_access: boolean;
    }[];
  };

  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [fileCategories, setFileCategories] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [fileFormats, setFileFormats] = useState([]);
  const [allFileFormats, setAllFileFormats] = useState([]);
  const [files, setFiles] = useState<dataInput>({});
  const [fileId, setFileId] = useState("");
  const [flag, setFlag] = useState(false);
  const [showDeleteFile, setShowDeleteFile] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const [fileData, setFileData] = useState({
    name: "",
    category_id: "",
    file_type_id: "",
    admin_notes: "",
    file_formats: [{ format: "", id: "", file_type_id: "" }],
    public_access: false,
  });
  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const response = await GetPermissionsByRoleAndModuleID(
        roleID.toString(),
        "7"
      );
      console.log(response);
      setPermissions(response?.data);
    })();
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "2") {
      navigate("/OrganizationManagement");
    } else if (permissions[0]?.permission_type.toString() === "3") {
      navigate("/PartnerManagement");
    }
  }, [permissions, navigate]);

  useEffect(() => {
    (async () => {
      try {
        const resCategories = await GetFileCategories();
        setFileCategories(resCategories?.data);
      } catch (error) {}
      try {
        const resFileTypes = await GetFileTypes();
        setFileTypes(resFileTypes?.data);
      } catch (error) {}
      try {
        const response = await GetAllFileFormats();
        setAllFileFormats(response?.data);
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await GetAllFiles();
        setFiles(response);
      } catch (error) {}
    })();
    setFlag(false);
  }, [flag]);

  useEffect(() => {
    if (fileId && show) {
      (async () => {
        try {
          const response = await GetFile(fileId);
          const res = await GetFileFormats(response.data.file_type.id);
          setFileFormats(res?.data);
          setFileData({
            name: response.data.name,
            category_id: response.data.category.id,
            file_type_id: response.data.file_type.id,
            admin_notes: response.data.admin_notes,
            file_formats: response?.data?.file_formats?.map((itm: any) => {
              return allFileFormats?.find((format: any) => format.id === itm);
            }),
            public_access: response.data.public_access,
          });
        } catch (error) {}
      })();
    }
  }, [fileId, show, allFileFormats]);

  const getFileFormats = async (FileTypeId: string) => {
    try {
      const response = await GetFileFormats(FileTypeId);
      setFileFormats(response?.data);
    } catch (error) {}
  };

  const deleteFunction = async () => {
    try {
      const response = await DeleteFileSettings(fileId);
      setFlag(true);
      if (response.msg === "File upload settings deleted successfully.") {
        setNotification({ type: "success", msg: response.msg });
        setFileId("");
        setFileData({
          name: "",
          category_id: "",
          file_type_id: "",
          admin_notes: "",
          file_formats: [{ format: "", id: "", file_type_id: "" }],
          public_access: false,
        });
      }
    } catch (error) {
      setFileId("");
    }
  };

  const onSubmit = async (data: fileSettingsDataInputDTO) => {
    if (fileId) {
      try {
        setSubmitting(true);
        const response = await UpdateFileSettings(data, fileId);
        if (response.msg === "File upload settings updated successfully.") {
          setShow(false);
          const activity = await CreateActivity(
            `File upload settings for folder ${data.name} updated.`
          );
          console.log(activity);
          setFileId("");
          setFileData({
            name: "",
            category_id: "",
            file_type_id: "",
            admin_notes: "",
            file_formats: [{ format: "", id: "", file_type_id: "" }],
            public_access: false,
          });
          setFileFormats([]);
          setFlag(true);
          setNotification({ type: "success", msg: response.msg });
        }
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    } else {
      try {
        setSubmitting(true);
        const response = await CreateFileSettings(data);
        if (response.msg === "File upload settings created successfully") {
          setShow(false);
          setFlag(true);
          setNotification({ type: "success", msg: response.msg });
          const activity = await CreateActivity(
            `File upload folder: ${data.name} created.`
          );
          console.log(activity);
          setFileData({
            name: "",
            category_id: "",
            file_type_id: "",
            admin_notes: "",
            file_formats: [{ format: "", id: "", file_type_id: "" }],
            public_access: false,
          });
          setFileFormats([]);
        }
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    }
  };

  let rows: {
    id: number;
    name: string;
    category: string;
    file_type: string;
    file_formats: any;
    public_access: boolean;
  }[] = [];

  if (
    files?.data &&
    permissions[0]?.sub_modules[4]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    console.log(files.data);
    rows = files?.data?.map((dataItem) => {
      return {
        id: dataItem?.id,
        name: dataItem?.name,
        category: dataItem?.category?.name,
        file_type: dataItem?.file_type?.type_name,
        file_formats: dataItem?.file_formats?.map((itm: any) => {
          return allFileFormats?.find((format: any) => format.id === itm);
        }),
        public_access: dataItem?.public_access,
      };
    });
  }

  const columns = FilesTableColumns(
    setShow,
    setFileId,
    setShowDeleteFile,
    permissions
  );

  const breadcrumbData = [
    {
      name: "settings",
      url: "/SettingsList",
      active: false,
    },
    {
      name: "Manage Files",
      url: "/files",
      active: true,
    },
  ];
  const handleShow = () => setShow(true);
  const handleCloseDeleteFile = () => setShowDeleteFile(false);
  const handleClose = () => {
    setShow(false);
    setFileId("");
    setFileData({
      name: "",
      category_id: "",
      file_type_id: "",
      admin_notes: "",
      file_formats: [{ format: "", id: "", file_type_id: "" }],
      public_access: false,
    });
    setFileFormats([]);
  };
  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });
  return (
    <Container fluid className="pge-layout">
      <Row>
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <BreadCrumb breadcrumbData={breadcrumbData} />
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
              />
            </InputGroup>
            {permissions[0]?.sub_modules[4]?.permissions_actions[0]
              ?.permission[0]?.permission === true && (
              <Button className="btn-primary12 addbtn" onClick={handleShow}>
                Add New Settings
              </Button>
            )}
          </div>
          <AddNewFileSettings
            isModalOpen={show}
            onCancel={handleClose}
            fileCategories={fileCategories}
            fileTypes={fileTypes}
            getFileFormats={getFileFormats}
            fileFormats={fileFormats}
            onSubmit={onSubmit}
            defaultData={fileData}
            submitting={submitting}
            fileId={fileId}
          />
          <div className="table-mui">
            <TableMUI columns={columns} rows={rows} />
            <PopupTemplate
              popupHeader="Confirm Deletion."
              popupBody={`Are you sure you want to delete File?`}
              handleFunction={deleteFunction}
              isModalOpen={showDeleteFile}
              onCancel={handleCloseDeleteFile}
              id={fileId}
              setFlag={setFlag}
            />
          </div>
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FileList;
