import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddProductSkuModal from "../../../components/modalForms/addProductSku/AddProductSkuModal";
import { ProductSkuDataInput } from "../../../dtos/product.dtos";
import {
  SaveProductSku,
  GetAllProductSku,
  DeleteProductSKU,
} from "../../../../API/endpoints/Products";
import TableMUI from "../../../components/TableMUI/TableMUI";
import { productSkuTableColumns } from "../../../components/TableMUI/TableColumns";
import "../../../../styles/global.scss";
import "./ProductSku.scss";
import { useParams } from "react-router-dom";
import PopupTemplate from "../../../components/PopupTemplate/PopupTemplate";
export interface AddProductProps {
  productId: string;
  handleNotification: (type: string, msg: string) => void;
  permissions?: any;
}

const ProductSku: React.FunctionComponent<AddProductProps> = ({
  productId,
  handleNotification,
  permissions,
}) => {
  type dataInput = {
    rows?: {
      id: string;
      sku_title: string;
      sku_number: string;
    }[];
  };

  const params = useParams();
  const [show, setShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState<dataInput>({});
  const [dataCount, setDataCount] = useState(0);
  const [selectedProductVariant, setSelectedProductVariant] = useState<any>();
  const [showDeleteProductSKU, setShowDeleteProductSKU] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setIsEdit(false);
    setShow(false);
    setFlag((prevState) => !prevState);
  };
  const productID = params.productid ? params.productid : productId;
  const columns = productSkuTableColumns(
    setShow,
    setSelectedProduct,
    setSelectedProductVariant,
    setIsEdit,
    setShowDeleteProductSKU,
    permissions
  );

  const onSubmitSku = (data: ProductSkuDataInput) => {
    setFlag(false);
    const formData = {
      product_id: productID,
      sku_number: data.sku_number,
      sku_title: data.sku_title,
      sku_description: data.sku_description,
      width: data.width,
      height: data.height,
      length: data.length,
      gross_weight: data.gross_weight,
      net_weight: data.net_weight,
      cost_price: data.cost_price,
      sell_price: data.sell_price,
    };

    //    console.log(formData);
    SaveProductSku(formData)
      .then((response) => {
        console.log(response.data);
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteFunction = async () => {
    await DeleteProductSKU(selectedProductVariant?.id);
    setFlag((prevState) => !prevState);
  };

  const handleCloseDeleteProductSKU = () => setShowDeleteProductSKU(false);

  useEffect(() => {
    console.log("productId", productID);
    if (productID) {
      GetAllProductSku(productID)
        .then((response) => {
          console.log(response.data);
          setData(response.data);
          setDataCount(response?.data?.count);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [productID, flag]);

  let rows: {
    id: string;
    sku_title: string;
    sku_number: string;
  }[] = [];
  if (
    data.rows &&
    permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
      ?.permission
  ) {
    rows = data.rows.map((dataItem) => {
      return {
        id: dataItem?.id,
        sku_title: dataItem?.sku_title,
        sku_number: dataItem?.sku_number,
      };
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col className="pt-5">
          {permissions[0]?.sub_modules[2]?.permissions_actions[0]?.permission[0]
            ?.permission && (
            <Button onClick={handleShow} className="btn-add-sku">
              Click Here to Add a New Variant
            </Button>
          )}
          <AddProductSkuModal
            isModalOpen={show}
            onCancel={handleClose}
            onSubmitSku={onSubmitSku}
            isEdit={isEdit}
            selectedProduct={selectedProduct}
            handleNotification={handleNotification}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="mb-3 primary-blue font14-medium">Variants</div>
          <TableMUI columns={columns} rows={rows} dataCount={dataCount} />
          <PopupTemplate
            popupHeader="Confirm Deletion."
            popupBody={`Are you sure you want to delete the product variant ${selectedProductVariant?.sku_title}`}
            handleFunction={deleteFunction}
            isModalOpen={showDeleteProductSKU}
            onCancel={handleCloseDeleteProductSKU}
            id={productId}
            setFlag={setFlag}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductSku;
