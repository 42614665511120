import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetBrand,
  UpdateBrandPaymentStatus,
  UpdateBrandStatus,
} from "../../API/endpoints/Brands";
import { CreateActivity } from "../../API/endpoints/Dashboard";
import { MarkTodoDone } from "../../API/endpoints/Todos";
import { UpdateTransactionStatus } from "../../API/endpoints/transactions";

function Success() {
  // eslint-disable-next-line no-restricted-globals
  const id = new URLSearchParams(window.location.search).get("id");
  const name = new URLSearchParams(window.location.search).get("name");
  const amount = new URLSearchParams(window.location.search).get("amount");
  const payment_id = new URLSearchParams(window.location.search).get(
    "paymentID"
  );

  const navigate = useNavigate();
  console.log(id, name, amount);

  useEffect(() => {
    if (id) {
      (async () => {
        await UpdateBrandPaymentStatus(id, "paid");
        try {
          const todoDone = await MarkTodoDone(`createplan:${id}`);
          console.log(todoDone);
        } catch (ex: any) {
          console.log(ex);
        }
        const updateTransactionStatus = await UpdateTransactionStatus(
          payment_id,
          "Completed"
        );
        console.log(updateTransactionStatus);
        const brandData = await GetBrand(id);
        const activity = await CreateActivity(
          `Brand ${brandData?.data?.brand_name} has completed payment.`
        );
        console.log(activity);

        try {
          const todoDone = await MarkTodoDone(`subPayment:${id}`);
          console.log(todoDone);
        } catch (ex: any) {
          console.log(ex);
        }
        await UpdateBrandStatus(id, "Activated");
        Cookies.set("IsActivated", "true");
        navigate("/BrandManagement");
      })();
    }
  }, [id, navigate, amount, name, payment_id]);
  return <div></div>;
}

export default Success;
