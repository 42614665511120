import { getWith, patchWith, postWith } from "../axios";

export async function GetBrandTransactions(
  brand_id: string | undefined,
  page: number
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/transactions/brand/${brand_id}/BrandPlan/${page}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreateTransaction(
  data: any,
  id: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/transactions/brand/${id}`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateTransactionStatus(
  payment_id: string | null,
  status: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/transactions/${payment_id}`;
    return (await patchWith<any, any>(url, { payment_id, status })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
