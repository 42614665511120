import "./UrlSelector.scss";
import Button from "react-bootstrap/Button";
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import { useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export interface UploadedFileProps {
    urlTypes: any;
    index: any;
    item: any;
    urlTypeChanged: (urlTypeId: any, index: any) => void;
    urlChanged: (url: any, index: any) => void;
    urlNotesChanged: (note: any, index: any) => void;
}
const UrlSelector: React.FunctionComponent<UploadedFileProps> = ({
    urlTypes,
    index,
    item,
    urlTypeChanged,
    urlChanged,
    urlNotesChanged
}) => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event: any) => {
        setShow(!show);
        setTarget(event.target);
    };
    const handleurlTypeChanged = (e: any, index: any) => {
        urlTypeChanged(e, index);
    }
    const handleurlChanged = (e: any, index: any) => {
        urlChanged(e, index);
    }
    const handleNoteChanged = (e: any, index: any) => {
        urlNotesChanged(e, index)
    }
    const handleClose = () => {
        setShow(!show);
    }
    return (
        <div className="url-selector-wraper">
            <div className="url-selector">
                <Form.Select
                    onChange={(e) => {
                        handleurlTypeChanged(e.target.value, index)
                    }}
                >
                    <option value="" >select</option>
                    {urlTypes?.map((item: any) => {
                        return (
                            <option
                                key={item.id}
                                value={item.id}
                            >{item.url_type_name}</option>
                        )
                    })}
                </Form.Select>
                <InputGroup>
                    <Form.Control
                        aria-label="Example text with button addon"
                        aria-describedby="basic-addon1"
                        className="url-textbox"
                        type="text"
                        placeholder="add url"
                        value={item.url}
                        onChange={(e) => {
                            handleurlChanged(e.target.value, index)
                        }}
                    />
                    <div ref={ref}>
                        <Button onClick={handleClick} className="note-btn">
                            <img src="../svgs/note.svg" alt="note" />
                        </Button>

                        <Overlay
                            show={show}
                            target={target}
                            placement="top"
                            container={ref}
                            containerPadding={20}
                        >
                            <Popover>
                                <Popover.Header>
                                    <div>Notes</div>
                                    <img
                                        src="../svgs/close-btn.svg"
                                        alt="close"
                                        onClick={handleClose}
                                    />
                                </Popover.Header>
                                <Popover.Body>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Type here..."
                                        value={item.url_notes}
                                        onChange={(e) => {
                                            handleNoteChanged(e.target.value, index)
                                        }}
                                    />
                                </Popover.Body>
                            </Popover>
                        </Overlay>
                    </div>
                </InputGroup>
            </div>
        </div>
    )
}

export default UrlSelector;