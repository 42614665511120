import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./DiscoveryManagement.scss";
import "../../styles/global.scss";
import "../../styles/pagelayout.scss";

// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// @ts-ignore
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";

// Components
import TableMUI from "../components/TableMUI/TableMUI";
import { PartnerDiscoveryTableColumns } from "../components/TableMUI/TableColumns";
import Sidebar from "../components/sidebar/Sidebar";

// Functions
import DetailsDisplayModal from "../components/modalForms/DiscoveryManagement/DetailsDisplayModal";
import { GetReqForPartner } from "../../API/endpoints/Discovery";
import { GetPartnerServicesList } from "../../API/endpoints/Partner";
import PartnerApproval from "../components/modalForms/DiscoveryManagement/PartnerApproval";
import Navbar from "../components/header/NavBar";
import { GetUserProfile } from "../../API/endpoints/User";
import { GetPermissionsByRoleAndModuleID } from "../../API/endpoints/RolesAndPermissions/Roles";
import { GetPublicFiles } from "../../API/endpoints/fileupload/Files";

function PartnerDiscoveryManagement() {
  const navigate = useNavigate();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [flag, setFlag] = useState(false);
  const [showApprovePartnerModal, setShowApprovePartnerModal] = useState(false);
  const [data, setData] = useState<any>([]);
  const [dataCount, setDataCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [partnerID, setPartnerID] = useState<string | undefined>("");
  const [roleID, setRoleID] = useState<string>("");
  const [permissions, setPermissions] = useState<any[]>([]);
  const [listPage, setListPage] = useState<number>(0);
  const [searchParam, setSearchParam] = useState<string>("");
  const [selectedRequestID, setSelectedRequestID] = useState<string>("");
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [selectedRequestBrandID, setSelectedRequestBrandID] =
    useState<string>("");
  const [servicesProvided, setServicesProvided] = useState<
    {
      service_id: number;
      country_id: number;
    }[]
  >([]);
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [publicFiles, setPublicFiles] = useState<any>([]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setPartnerID(response.data?.partner_id);
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const response = await GetPermissionsByRoleAndModuleID(
        roleID?.toString(),
        "13"
      );
      console.log(response);
      setPermissions(response?.data);
    })();
  }, [roleID]);

  const handleCloseApprovePartner = () => setShowApprovePartnerModal(false);
  const handleCloseDetailsModal = () => setIsDetailsModalOpen(false);
  const columns = PartnerDiscoveryTableColumns(
    navigate,
    setIsDetailsModalOpen,
    setSelectedRequestID,
    setSelectedRequest,
    setSelectedRequestBrandID,
    setShowApprovePartnerModal,
    setSelectedRow,
    permissions
  );

  let rows: {
    id?: string;
    brandId?: string;
    brandname?: string;
    brand_services?: any;
    postedDate?: string;
    preferredmarket?: any;
    preferredservices?: any;
    status?: string;
  }[] = [];

  const dateFormatter = (date: any) => {
    return `${date?.toString().substring(8, 10)}/${date
      ?.toString()
      .substring(5, 7)}/${date?.toString().substring(0, 4)}`;
  };

  if (
    data &&
    permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
      ?.permission
  ) {
    rows = data.map(
      (dataItem: {
        id?: string;
        brand_id?: string;
        brand_services?: any;
        brand?: { brand_name: string };
        country?: { name: string };
        discovery_partners?: any;
        created_at?: { toString: () => string };
        status?: any;
      }) => ({
        id: dataItem.id,
        brandId: dataItem.brand_id,
        brandname: dataItem?.brand?.brand_name,
        brand_services: dataItem?.brand_services,
        postedDate: dataItem?.created_at
          ? dateFormatter(dataItem?.created_at)
          : "Not Available",
        preferredmarket: dataItem.country?.name,
        preferredserviceIDs: dataItem.brand_services.map(
          (item: { service_id: any }) => {
            return item.service_id;
          }
        ),
        preferredservices: dataItem.brand_services.map(
          (item: { service: { display_name: any } }) => {
            return item.service.display_name;
          }
        ),
        status:
          dataItem?.status === "Open" ||
          dataItem?.status === "Interest Accepted"
            ? dataItem?.status
            : dataItem?.status === "Interest Expressed" ||
              dataItem?.status === "Matching In Progress"
            ? dataItem?.discovery_partners[0]
              ? dataItem?.discovery_partners[0]?.status
              : "Open"
            : "Open",
      })
    );
  }

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });

    (async () => {
      const response = await GetPartnerServicesList(partnerID);
      console.log(partnerID);
      setServicesProvided([]);
      response.data.services.map(
        (service: {
          regions: { id: any };
          post_launch_services: any;
          pre_launch_services: any;
        }) => {
          const regionID = service.regions.id;
          const servicesAllotter = (servicename: string) => {
            if (servicename === "ecom_setup_mgmt") {
              setServicesProvided((prevState) => [
                ...prevState,
                {
                  service_id: 4,
                  country_id: regionID,
                },
              ]);
            } else if (servicename === "marketing") {
              setServicesProvided((prevState) => [
                ...prevState,
                {
                  service_id: 7,
                  country_id: regionID,
                },
              ]);
            } else if (servicename === "order_fulfilment") {
              setServicesProvided((prevState) => [
                ...prevState,
                {
                  service_id: 6,
                  country_id: regionID,
                },
              ]);
            } else if (servicename === "retail_distribution") {
              setServicesProvided((prevState) => [
                ...prevState,
                {
                  service_id: 5,
                  country_id: regionID,
                },
              ]);
            } else if (servicename === "importation_clearance") {
              setServicesProvided((prevState) => [
                ...prevState,
                {
                  service_id: 2,
                  country_id: regionID,
                },
              ]);
            } else if (servicename === "regulatory_compliance") {
              setServicesProvided((prevState) => [
                ...prevState,
                {
                  service_id: 1,
                  country_id: regionID,
                },
              ]);
            } else if (servicename === "warehousing_storage") {
              setServicesProvided((prevState) => [
                ...prevState,
                {
                  service_id: 3,
                  country_id: regionID,
                },
              ]);
            }
          };

          for (const item in service.pre_launch_services) {
            if (service.pre_launch_services[item] === true) {
              servicesAllotter(item);
            }
          }

          for (const item in service.post_launch_services) {
            if (service.post_launch_services[item] === true) {
              servicesAllotter(item);
            }
          }
          return true;
        }
      );
    })();
  }, [navigate, partnerID]);

  useEffect(() => {
    const GetRequestsForPartner = async () => {
      if (servicesProvided) {
        const res = await GetReqForPartner(
          servicesProvided,
          partnerID,
          listPage,
          searchParam
        );
        console.log(res);
        setDataCount(res?.data?.count);
        setData([]);
        setData(res?.data?.rows);
      }
    };
    GetRequestsForPartner();
  }, [servicesProvided, listPage, searchParam, flag, partnerID]);

  useEffect(() => {
    if(isDetailsModalOpen) {
      (async () => {
        try {
          const response = await GetPublicFiles("brand", selectedRequestBrandID);
          setPublicFiles(response.data);
        } catch (error) {
          
        }
      })()
    }
  },[isDetailsModalOpen, selectedRequestBrandID])

  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="discovery" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item className="font12-regular gray-500">
              <Link to="/discoverymanagement/partner">
                DISCOVERY MANAGEMENT
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <Link to="#">PARTNER MATCHING REQUESTS</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text
                id="inputGroup-sizing-sm"
                onChange={(e: any) => {
                  setSearchParam(e.target.value);
                }}
              >
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button size="sm" className="btn-primary12 addfilter">
              Add Filter
            </Button> */}
          </div>
          <div className="table-mui">
            <div className="TableMUIcountainer">
              <TableMUI
                columns={columns}
                rows={rows}
                dataCount={dataCount}
                setListPage={setListPage}
                listPage={listPage}
              />
            </div>

            <PartnerApproval
              onCancel={handleCloseApprovePartner}
              setFlag={setFlag}
              flag={flag}
              isModalOpen={showApprovePartnerModal}
              title="Express Interest"
              subtitle="Select services which you can offer to brands:"
              buttonText="Express Interest"
              userType="Partner"
              selectedRequestID={selectedRequestID}
              partnerID={partnerID}
              brandID={selectedRequestBrandID}
              reqDetails={selectedRow}
            />
            <DetailsDisplayModal
              isModalOpen={isDetailsModalOpen}
              onCancel={handleCloseDetailsModal}
              selectedRequestID={selectedRequestID}
              selectedRequest={selectedRequest}
              selectedRequestBrandID={selectedRequestBrandID}
              publicFiles={publicFiles}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PartnerDiscoveryManagement;
