import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../styles/modals/modal400.scss";
import "./SuccessModal.scss";
import { Link } from "react-router-dom";

export interface SuccessModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  title: string;
  email?: string;
  bodyText: string;
  btn: boolean;
  link?: string;
  linkText?: string;
  isAdmin?: boolean;
}

const SuccessModal: React.FunctionComponent<SuccessModalProps> = ({
  isModalOpen,
  handleCloseModal,
  title,
  email,
  bodyText,
  btn,
  link,
  linkText,
  isAdmin,
}) => {
  const handleClose = () => {
    handleCloseModal();
  };

  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={handleClose}
        className="modal400 successmodal"
        centered
      >
        <Modal.Header closeButton={false}>
          <div className="alert-icon">
            <img src="../svgs/check-circle.svg" alt="check-circle" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="title-text font20-medium gray-800">{title}</div>
          <div className="font14-regular gray-500">
            {bodyText}
            {link && (
              <span>
                <Link className="blue-700" to={link}>
                  {linkText}
                </Link>
              </span>
            )}
          </div>
          {link && <div className="mb24px"></div>}
          {btn && (
            <div className="footer-btn">
              {!isAdmin ? (
                <Link to="/">
                  <Button className="btn-primary12 w-100" onClick={handleClose}>
                    Done
                  </Button>
                </Link>
              ) : (
                <Button className="btn-primary12 w-100" onClick={handleClose}>
                  Done
                </Button>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessModal;
