import { GridActionsCellItem } from "@mui/x-data-grid";
import { Dispatch, SetStateAction } from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Button } from "react-bootstrap";
// import { Trash } from "react-bootstrap-icons";
import { NavigateFunction, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import "./TableMUI.scss";
import { MakeAdvisoryPayment } from "../../../API/endpoints/Advisory";
import { MarkTodoDone } from "../../../API/endpoints/Todos";
import { CreateActivity } from "../../../API/endpoints/Dashboard";
import { Auth } from "aws-amplify";
import { InviteUser } from "../../../API/endpoints/User";
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }: any) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const brandTableColumns = (
  setBrandID: React.Dispatch<React.SetStateAction<string>>,
  setShowDeleteBrand: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  setBrandName: Dispatch<SetStateAction<string>>,
  setPopupType: React.Dispatch<React.SetStateAction<string>>,
  setAgreementPopup: React.Dispatch<React.SetStateAction<boolean>>,
  isSuperAdmin: boolean,
  userProfile: any,
  permissions: any
) => {
  const brand_columns_list = [];
  brand_columns_list.push(
    {
      field: "brandname",
      headerName: "Brand Name",
      sortable: true,
      flex: 1,
      minWidth: 166,
      renderCell: (params: {
        hasFocus: any;
        row: {
          brandname: string | undefined;
          id: string;
          is_deleted: boolean;
        };
      }) => (
        <strong>
          <Button
            className="link-btn text-decoration-none font13-medium"
            variant="link"
            disabled={params.row.is_deleted}
            onClick={() => {
              console.log(params.row);
              navigate(`/brand/${params.row.id}`);
            }}
          >
            {params.row.brandname}
          </Button>
        </strong>
      ),
    },
    {
      field: "organization",
      headerName: "Organization",
      sortable: true,
      flex: 1,
      minWidth: 166,
    }
  );
  if (
    permissions[0]?.sub_modules[3]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    brand_columns_list.push({
      field: "agreement_status",
      headerName: "Platform Terms",
      sortable: true,
      flex: 1,
      minWidth: 166,
      renderCell: (params: {
        row: {
          agreement_status: string | undefined;
          id: string;
          brandname: string;
          is_deleted: boolean;
        };
      }) => {
        switch (params.row.agreement_status) {
          case "unsigned":
            return (
              <Button
                className="link-btn font13-regular"
                variant="link"
                disabled
                onClick={() => {
                  console.log(params.row.id);
                  setBrandID(params.row.id);
                }}
              >
                Pending Acknowledgement
              </Button>
            );
          case "ready_to_sign":
            return (
              <Button
                className="link-btn font13-regular"
                variant="link"
                disabled={
                  params.row.is_deleted ||
                  isSuperAdmin ||
                  permissions[0]?.sub_modules[3]?.permissions_actions[0]
                    ?.permission[0]?.permission === false
                }
                onClick={() => {
                  console.log(params.row.id);
                  setBrandID(params.row.id);
                  setBrandName(params.row.brandname);
                  setAgreementPopup(true);
                }}
              >
                Pending Acknowledgement
              </Button>
            );
          case "signed":
            return (
              <div className="success-status font12-medium">Acknowledged</div>
            );
          default:
            return (
              <Button disabled className="font13-regular">
                NA
              </Button>
            );
        }
      },
    });
  }
  brand_columns_list.push(
    {
      field: "status",
      headerName: "Account Status",
      renderCell: (params: {
        row: {
          status: string | undefined;
        };
      }) => {
        if (params.row.status === "Activated") {
          return <div className="success-status font12-medium">Activated</div>;
        } else {
          return <div className="blue-status font12-medium">New</div>;
        }
      },
      flex: 1,
      minWidth: 166,
    },
    {
      field: "files",
      headerName: "Files",
      sortable: true,
      flex: 1,
      minWidth: 166,
      renderCell: (params: {
        row: {
          id: string;
          is_deleted: boolean;
        };
      }) => {
        return !params.row.is_deleted &&
          permissions[0]?.sub_modules[5]?.permissions_actions[1]?.permission[0]
            ?.permission === true ? (
          <div>
            <Link to={`/brand-filesettings/${params.row.id}`}>
              Manage Files
            </Link>
          </div>
        ) : (
          <div>Manage Files</div>
        );
      },
    }
  );
  if (
    (permissions[0]?.sub_modules[4]?.permissions_actions[2]?.permission[0]
      ?.permission === true &&
      (permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission === true ||
        permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
          ?.permission === true ||
        permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
          ?.permission === true)) ||
    permissions[0]?.sub_modules[4]?.permissions_actions[3]?.permission[0]
      ?.permission === true ||
    permissions[0]?.sub_modules[6]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    brand_columns_list.push({
      field: "action",
      headerName: "Actions",
      minWidth: 100,
      type: "actions",
      flex: 1,
      getActions: (params: {
        row: {
          brandname: string;
          id: SetStateAction<string>;
          is_deleted: boolean;
        };
      }) => {
        const action_items = [];
        if (
          permissions[0]?.sub_modules[4]?.permissions_actions[2]?.permission[0]
            ?.permission === true &&
          !params.row.is_deleted
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                console.log(params.row);
                navigate(`/editbrand/${params.row.id}`);
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[4]?.permissions_actions[3]?.permission[0]
            ?.permission === true &&
          params.row.is_deleted
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Restore"
              onClick={() => {
                setBrandName(params.row.brandname);
                setBrandID(params.row.id);
                setPopupType("restore");
                setShowDeleteBrand(true);
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[4]?.permissions_actions[3]?.permission[0]
            ?.permission === true &&
          !params.row.is_deleted
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                setBrandName(params.row.brandname);
                setBrandID(params.row.id);
                setPopupType("delete");
                setShowDeleteBrand(true);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }

        if (
          permissions[0]?.sub_modules[4]?.permissions_actions[3]?.permission[0]
            ?.permission === true &&
          params.row.is_deleted
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Delete Permanently"
              onClick={() => {
                setBrandName(params.row.brandname);
                setBrandID(params.row.id);
                setPopupType("delete-permanently");
                setShowDeleteBrand(true);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }

        if (
          permissions[0]?.sub_modules[6]?.permissions_actions[1]?.permission[0]
            ?.permission === true &&
          !params.row.is_deleted
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Assign Team"
              onClick={() => {
                console.log(params.row);
                navigate(`/assignbrandteam/${params.row.id}`);
              }}
              showInMenu
            />
          );
        }

        return action_items;
      },
    });
  }
  return brand_columns_list;
};

export const BrandDiscoveryTableColumns = (
  navigate: NavigateFunction,
  setIsDetailsModalOpen: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setSelectedRequestID: Dispatch<SetStateAction<string>>,
  setSelectedRequest: Dispatch<SetStateAction<any>>,
  setSelectedRequestBrandID: Dispatch<SetStateAction<string>>,
  usertype: string | undefined,
  permissions: any
) => {
  const brandDiscoveryTableCols = [];
  brandDiscoveryTableCols.push(
    {
      field: "brandName",
      headerName: "Brand Name",
      sortable: true,
      flex: 1,
      minWidth: 166,
      renderCell: (params: {
        hasFocus: any;
        row: {
          brandName: string | undefined;
          id: string;
          brand_id: string;
        };
      }) => (
        <strong>
          <Button
            size="sm"
            variant="link"
            className="link-btn text-decoration-none font13-medium"
            onClick={() => {
              console.log(params.row);
              setIsDetailsModalOpen(true);
              setSelectedRequestBrandID(params.row.brand_id);
              setSelectedRequestID(params.row.id);
              setSelectedRequest(params.row);
            }}
          >
            {params.row.brandName}
          </Button>
        </strong>
      ),
    },
    {
      field: "country",
      headerName: "Market Name",
      sortable: true,
      flex: 1,
      minWidth: 166,
    },
    {
      field: "postedDate",
      headerName: "Posted Date",
      sortable: true,
      flex: 1,
      minWidth: 166,
    },
    {
      field: "match_completion_date",
      headerName: "Completion Date",
      sortable: true,
      flex: 1,
      minWidth: 166,
    },
    {
      field: "suitable_partners_count",
      headerName: "Suitable",
      flex: 1,
      minWidth: 166,
    },
    {
      field: "no_of_req",
      headerName: "Interests",
      flex: 1,
      minWidth: 166,
    },
    {
      field: "confirmedPartners",
      headerName: "Confirmed",
      flex: 1,
      minWidth: 166,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params: {
        hasFocus: any;
        row: {
          status: string | undefined;
        };
      }) => {
        if (params.row.status === "Open")
          return <div className="blue-status font12-medium">Open</div>;
        if (params.row.status === "Interest Expressed")
          return (
            <div className="warning-status font12-medium">
              Interest Received
            </div>
          );
        if (params.row.status === "Matching In Progress")
          return (
            <div className="warning-status font12-medium">
              Matching In Progress
            </div>
          );
        if (params.row.status === "Interest Accepted")
          return (
            <div className="success-status font12-medium">Partners Matched</div>
          );
      },
      flex: 1,
      minWidth: 166,
    }
  );
  if (
    permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
      ?.permission ||
    permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
      ?.permission ||
    permissions[0]?.sub_modules[3]?.permissions_actions[1]?.permission[0]
      ?.permission
  ) {
    brandDiscoveryTableCols.push({
      field: "action",
      headerName: "Action",
      minWidth: 100,
      type: "actions",
      flex: 1,
      renderCell: (params: {
        hasFocus: any;
        row: {
          brandName: string | undefined;
          id: string | undefined;
          brand_id: string | undefined;
        };
      }) => (
        <strong>
          <Button
            size="sm"
            variant="link"
            className="link-btn text-decoration-none font13-medium"
            onClick={() => {
              //  console.log(params.row);
              if (usertype === "brand") {
                navigate(
                  `/discoverymanagement-brands/${params.row.id}&&${params.row.brandName}`
                );
              }
              if (usertype === "admin") {
                // console.log(params.row);
                navigate(
                  `/discoverymanagement-recommend/${params.row.id}&&${params.row.brandName}`
                );
              }
            }}
          >
            View
          </Button>
        </strong>
      ),
    });
  }
  return brandDiscoveryTableCols;
};

export const PartnerDiscoveryTableColumns = (
  navigate: NavigateFunction,
  setIsDetailsModalOpen: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setSelectedRequestID: Dispatch<SetStateAction<string>>,
  setSelectedRequest: Dispatch<SetStateAction<any>>,
  setSelectedRequestBrandID: Dispatch<SetStateAction<string>>,
  setShowApprovePartnerModal: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedRow: {
    (value: SetStateAction<undefined>): void;
    (arg0: { id: string; brandId: string }): void;
  },
  permissions: any
) => {
  const partnerDiscoveryTableCols = [];
  partnerDiscoveryTableCols.push(
    {
      field: "brandname",
      headerName: "Brand Name",
      sortable: true,
      flex: 1,
      renderCell: (params: {
        hasFocus: any;
        row: {
          brandname: string | undefined;
          id: string;
          brandId: string;
        };
      }) => (
        <strong>
          <Button
            size="sm"
            variant="link"
            className="link-btn text-decoration-none font13-medium"
            onClick={() => {
              setIsDetailsModalOpen(true);
              setSelectedRequest(params.row);
              setSelectedRequestID(params.row.id);
              setSelectedRequestBrandID(params.row.brandId);
            }}
          >
            {params.row.brandname}
          </Button>
        </strong>
      ),
    },
    {
      field: "postedDate",
      headerName: "Posted Date",
      sortable: true,
      flex: 1,
    },
    {
      field: "preferredmarket",
      headerName: "Market",
      sortable: true,
      flex: 1,
    },
    {
      field: "preferredservices",
      headerName: "Required services",
      flex: 2,
      renderCell: (params: {
        hasFocus: any;
        row: {
          preferredservices: any;
        };
      }) => (
        <strong>
          <Button variant="contained" size="sm" style={{ border: "none" }}>
            {params.row.preferredservices[0]}
          </Button>
          <BootstrapTooltip
            title={
              <>
                <b>Required Services</b>
                <ul>
                  {params.row.preferredservices.map((serviceReq: any) => {
                    return <li>{serviceReq}</li>;
                  })}
                </ul>
              </>
            }
          >
            <Button variant="contained" size="sm" className="table-link">
              {params.row.preferredservices.length > 1 &&
                `+ ${params.row.preferredservices.length - 1} more `}
            </Button>
          </BootstrapTooltip>
        </strong>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params: {
        hasFocus: any;
        row: {
          status: string | undefined;
        };
      }) => {
        if (params.row.status === "Open")
          return <div className="blue-status font12-medium">Open</div>;
        if (params.row.status === "New")
          return (
            <div className="warning-status font12-medium">
              Interest Expressed
            </div>
          );
        if (
          params.row.status === "In Progress" ||
          params.row.status === "In progress"
        )
          return (
            <div className="warning-status font12-medium">
              Matching In Progress
            </div>
          );
        if (params.row.status === "Completed")
          return (
            <div className="warning-status font12-medium">
              Matching In Progress
            </div>
          );
        if (params.row.status === "Interest Accepted")
          return (
            <div className="success-status font12-medium">
              Matching Completed
            </div>
          );
      },
      flex: 1,
    }
  );
  if (
    permissions[0]?.sub_modules[0]?.permissions_actions[0]?.permission[0]
      ?.permission
  ) {
    partnerDiscoveryTableCols.push({
      field: "action",
      headerName: "Action",
      minWidth: 80,
      type: "actions",
      flex: 1,
      renderCell: (params: {
        hasFocus: any;
        row: {
          id: string;
          brandId: string;
          status: string | undefined;
        };
      }) => (
        <strong>
          <Button
            variant="link"
            className="link-btn text-decoration-none font13-medium"
            disabled={params.row.status === "Interest Accepted" ? true : false}
            size="sm"
            style={{
              cursor: "pointer",
              border: "none",
              color:
                params.row.status === "Interest Accepted" ? "black" : "#0152FB",
              padding: 0,
            }}
            onClick={() => {
              setShowApprovePartnerModal(true);
              setSelectedRequestID(params.row.id);
              setSelectedRequestBrandID(params.row.brandId);
              setSelectedRow(params.row);
              // console.log(params.row);
            }}
          >
            {params.row.status === "Interest Accepted"
              ? "Closed"
              : params.row.status === "In Progress" ||
                params.row.status === "In progress" ||
                params.row.status === "New" ||
                params.row.status === "Completed"
              ? "View"
              : "Express Interest"}
          </Button>
        </strong>
      ),
    });
  }
  return partnerDiscoveryTableCols;
};

export const OrgDiscoveryTableColumns = (navigate: NavigateFunction) => [
  {
    field: "brandname",
    headerName: "Brand Name",
    sortable: true,
    flex: 1,
    renderCell: (params: {
      hasFocus: any;
      row: {
        brandname: string | undefined;
        id: string | undefined;
      };
    }) => (
      <strong>
        <Button
          variant="contained"
          size="sm"
          style={{ cursor: "pointer", border: "none", color: "blue" }}
          onClick={() => {
            console.log(params.row);
            navigate(`/discoverymanagement/${params.row.id}&&brand`);
          }}
        >
          {params.row.brandname}
        </Button>
      </strong>
    ),
  },
  {
    field: "organization",
    headerName: "Organization Name",
    sortable: true,
    flex: 1,
  },
  {
    field: "currentrevenue",
    headerName: "Current Revenue",
    sortable: true,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
  },
];

export const AdminDiscoveryTableColumns = (navigate: NavigateFunction) => [
  {
    field: "brandname",
    headerName: "Brand Name",
    sortable: true,
    flex: 1,
    renderCell: (params: {
      hasFocus: any;
      row: {
        brandname: string | undefined;
        id: string | undefined;
      };
    }) => (
      <strong>
        <Button
          variant="link"
          size="sm"
          // style={{ cursor: "pointer", border: "none", color: "blue" }}
          className="link-btn text-decoration-none font13-medium"
          onClick={() => {
            console.log(params.row);
            navigate(`/discoverymanagement/${params.row.id}&&admin`);
          }}
        >
          {params.row.brandname}
        </Button>
      </strong>
    ),
  },
  {
    field: "organization",
    headerName: "Organization Name",
    sortable: true,
    flex: 1,
  },
  {
    field: "currentrevenue",
    headerName: "Current Revenue",
    sortable: true,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
  },
];

export const partnerRequestsTableColumns = (
  setIsDetailsModalOpen: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setShowApprovePartnerModal: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setSelectedRequest: Dispatch<SetStateAction<any>>,
  setUserType: Dispatch<SetStateAction<string>>,
  setSelectedPartnerID: Dispatch<SetStateAction<string>>,
  permissions: any
) => [
  {
    field: "partnername",
    headerName: "Partner Name",
    sortable: true,
    flex: 1,
    renderCell: (params: {
      hasFocus: any;
      row: {
        partnerName: string | undefined;
        partnerId: string;
      };
    }) => (
      <strong>
        <Button
          variant="link"
          size="sm"
          // style={{ cursor: "pointer", border: "none", color: "blue" }}
          className="link-btn text-decoration-none font13-medium"
          onClick={() => {
            console.log(params.row);
            setUserType("Partner");
            setSelectedPartnerID(params.row.partnerId);
            // setSelectedRequestID(params.row.discovery_brands_id);
            setSelectedRequest(params.row);
            setIsDetailsModalOpen(true);
          }}
        >
          {params.row.partnerName}
        </Button>
      </strong>
    ),
  },
  {
    field: "servicerequests",
    headerName: "Interested Services",
    sortable: true,
    flex: 2,
    renderCell: (params: {
      hasFocus: any;
      row: {
        servicerequests: any;
      };
    }) => (
      <strong>
        <Button
          className="font13-regular gray-900"
          variant="contained"
          size="sm"
          style={{ border: "none", padding: 0 }}
        >
          {params.row.servicerequests[0].name + "   "}
          {params.row.servicerequests[0].status === "Recommended" && (
            <img
              src="../svgs/check-circle-fill.svg"
              alt="svg"
              onClick={() => {}}
            />
          )}
        </Button>
        <BootstrapTooltip
          title={
            <>
              <b>Interested Services</b>
              <ul>
                {params.row.servicerequests.map((serviceReq: any) => {
                  return (
                    <>
                      <li>
                        {serviceReq.name + "       "}
                        <span className="servicesListItemGreen">
                          {serviceReq.status === "Recommended" && (
                            <img
                              src="../svgs/check-circle-fill.svg"
                              alt="svg"
                              onClick={() => {}}
                            />
                          )}
                        </span>
                      </li>
                    </>
                  );
                })}
              </ul>
            </>
          }
        >
          <Button variant="contained" size="sm" className="table-link">
            {params.row.servicerequests.length > 1 &&
              `+ ${params.row.servicerequests.length - 1} more `}
          </Button>
        </BootstrapTooltip>
      </strong>
    ),
  },
  {
    field: "focusmarket",
    headerName: "Focus Market",
    sortable: true,
    flex: 1,
  },
  {
    field: "requestdate",
    headerName: "Interest Submission Date",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params: {
      hasFocus: any;
      row: {
        status: string | undefined;
      };
    }) => {
      if (params.row.status === "New")
        return <div className="blue-status font12-medium">Open</div>;
      if (
        params.row.status === "In Progress" ||
        params.row.status === "In progress"
      )
        return (
          <div className="warning-status font12-medium">
            Matching In Progress
          </div>
        );
      if (params.row.status === "Completed")
        return (
          <div className="success-status font12-medium">Matching Completed</div>
        );
    },
    flex: 1,
  },
  {
    field: "action",
    headerName: "Action",
    minWidth: 80,
    type: "actions",
    flex: 1,
    renderCell: (params: {
      hasFocus: any;
      row: {
        filename: string | undefined;
        dataitemid: string | undefined;
        status: string | undefined;
      };
    }) => (
      <strong>
        <Button
          variant="contained"
          size="sm"
          style={{
            cursor: "pointer",
            border: "none",
            color:
              params.row.status === "New"
                ? permissions[0]?.sub_modules[1]?.permissions_actions[0]
                    ?.permission[0]?.permission
                  ? "#0152FB"
                  : "black"
                : "black",
            padding: 0,
          }}
          className="font13-medium"
          disabled={
            !permissions[0]?.sub_modules[1]?.permissions_actions[0]
              ?.permission[0]?.permission
          }
          onClick={() => {
            if (params.row.status === "New") {
              console.log(params.row);
              setShowApprovePartnerModal(true);
              setSelectedRequest(params.row);
            }
          }}
        >
          {params.row.status === "New" ? "Accept Request" : "Accepted"}
        </Button>
      </strong>
    ),
  },
];

export const acceptedPartnerRequestsTableColumns = (
  navigate: NavigateFunction,
  setIsDetailsModalOpen: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setSelectedRequest: Dispatch<SetStateAction<any>>,
  setSelectedRequestID: Dispatch<SetStateAction<string | undefined>>,
  setUserType: Dispatch<SetStateAction<string>>,
  setSelectedPartnerID: Dispatch<SetStateAction<string>>
) => [
  {
    field: "partnerName",
    headerName: "Partner Name",
    sortable: true,
    flex: 1,
    renderCell: (params: {
      hasFocus: any;
      row: {
        partnerName: string | undefined;
        partnerId: string;
      };
    }) => (
      <strong>
        <Button
          variant="contained"
          size="sm"
          className="link-btn text-decoration-none font13-medium"
          onClick={() => {
            console.log(params.row);
            setUserType("Partner");
            setSelectedPartnerID(params.row.partnerId);
            // setSelectedRequestID(params.row.discovery_brands_id);
            setSelectedRequest(params.row);
            setIsDetailsModalOpen(true);
          }}
        >
          {params.row.partnerName}
        </Button>
      </strong>
    ),
  },
  {
    field: "focusmarket",
    headerName: "Market",
    sortable: true,
    flex: 1,
  },
  {
    field: "completiondate",
    headerName: "Accepted Date",
    flex: 1,
  },
  {
    field: "acceptedServices",
    headerName: "Matched Services",
    flex: 2,
    renderCell: (params: {
      hasFocus: any;
      row: {
        acceptedServices: any;
      };
    }) => (
      <div>
        {params.row.acceptedServices.length !== 0 && (
          <span className="success-status font12-medium mr10px">
            {params.row.acceptedServices[0]?.name}
          </span>
        )}
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Popover className="field-popover">
              <Popover.Body>
                <div className="font13-medium gray-800 mb6px">
                  Matched Services
                </div>
                <ul className="m-0">
                  {params.row.acceptedServices.map((service: any) => {
                    return (
                      <li className="font13-regular gray-700">
                        {service.name}
                      </li>
                    );
                  })}
                </ul>
              </Popover.Body>
            </Popover>
          }
        >
          <span
            style={{ cursor: "pointer" }}
            className="primary-blue font13-medium cursor-pointer"
          >
            {params.row.acceptedServices.length > 1 &&
              params.row.acceptedServices.length > 1 &&
              `+ ${params.row.acceptedServices.length - 1} more `}
          </span>
        </OverlayTrigger>
      </div>
    ),
  },
];

export const orgTableColumns = (
  setOrgID: React.Dispatch<React.SetStateAction<string | undefined>>,
  setShowDeleteOrg: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  setOrgName: Dispatch<SetStateAction<string>>,
  setPopupType: React.Dispatch<React.SetStateAction<string>>,
  permissions: any
) => {
  const orgTableCols: any = [];
  orgTableCols.push(
    {
      field: "orgName",
      headerName: "Organization Name",
      sortable: true,
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country Of Origin",
      sortable: true,
      flex: 1,
    },
    { field: "address", headerName: "Address", sortable: true, flex: 1 },
    {
      field: "pincode",
      headerName: "Zip Code",
      sortable: true,
      flex: 1,
    },
    { field: "regNumber", headerName: "Reg. Number", flex: 1 },
    { field: "website", headerName: "Website", flex: 1 }
  );
  if (
    permissions[0]?.sub_modules[2]?.permissions_actions[3]?.permission[0]
      ?.permission === true ||
    (permissions[0]?.sub_modules[2]?.permissions_actions[2]?.permission[0]
      ?.permission === true &&
      (permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission === true ||
        permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
          ?.permission === true))
  ) {
    orgTableCols.push({
      field: "actions",
      headerName: "Action",
      minWidth: 80,
      type: "actions",
      flex: 1,
      getActions: (params: {
        row: {
          id: SetStateAction<string | undefined>;
          orgName: string;
          is_deleted: boolean;
          isSuperAdmin: boolean;
          isOrgUser: boolean;
          permissions: any;
        };
      }) => {
        const actions_list = [];
        if (
          permissions[0]?.sub_modules[2]?.permissions_actions[3]?.permission[0]
            ?.permission === true &&
          params.row.is_deleted
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Restore"
              onClick={() => {
                console.log(params.row);
                setPopupType("restore");
                setOrgID(params.row.id);
                setShowDeleteOrg(true);
                setOrgName(params.row.orgName);
              }}
              showInMenu
            />,
            <GridActionsCellItem
              label="Delete Permanently"
              onClick={() => {
                console.log(params.row);
                setPopupType("delete-permanently");
                setOrgID(params.row.id);
                setShowDeleteOrg(true);
                setOrgName(params.row.orgName);
              }}
              showInMenu
            />
          );
        } else if (
          permissions[0]?.sub_modules[2]?.permissions_actions[3]?.permission[0]
            ?.permission === true
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                console.log(params.row);
                setPopupType("delete");
                setOrgID(params.row.id);
                setShowDeleteOrg(true);
                setOrgName(params.row.orgName);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[2]?.permissions_actions[2]?.permission[0]
            ?.permission === true &&
          !params.row.is_deleted &&
          (permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
            ?.permission === true ||
            permissions[0]?.sub_modules[1]?.permissions_actions[1]
              ?.permission[0]?.permission === true)
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                navigate(`/editorganization/${params.row.id}`);
              }}
              showInMenu
            />
          );
        }
        return actions_list;
      },
    });
  }
  return orgTableCols;
};

export const partnerTableColumns = (
  setPartnerID: React.Dispatch<React.SetStateAction<string | undefined>>,
  setShowDeletePartner: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  data: any,
  // setSelectedPartnerID: React.Dispatch<React.SetStateAction<string>>,
  // setShowDetailsModal: React.Dispatch<React.SetStateAction<boolean>>,
  setPopupType: React.Dispatch<React.SetStateAction<string>>,
  permissions: any
) => {
  const partnerTableCols = [];
  partnerTableCols.push(
    {
      field: "partnername",
      headerName: "Partner Name",
      sortable: true,
      minWidth: 180,
      flex: 1,
      renderCell: (params: {
        hasFocus: any;
        row: {
          partnername: string | undefined;
          id: string;
          is_deleted: boolean;
        };
      }) => (
        <strong>
          <Button
            variant="contained"
            size="sm"
            disabled={
              params.row.is_deleted ||
              permissions[0]?.sub_modules[4]?.permissions_actions[1]
                ?.permission[0]?.permission === false
            }
            className="link-btn text-decoration-none font13-medium"
            style={{ cursor: "pointer", border: "none" }}
            onClick={() => {
              // console.log(params.row);
              // setSelectedPartnerID(params.row.id);
              // setShowDetailsModal(true);
              navigate(`/partner/${params.row.id}`);
            }}
          >
            {params.row.partnername}
          </Button>
        </strong>
      ),
    },
    {
      field: "countryoforigin",
      headerName: "Country of origin",
      sortable: true,
      minWidth: 180,
      flex: 1,
    },
    // {
    //   field: "serviceCategory",
    //   headerName: "Category Experience",
    //   sortable: true,
    //   minWidth: 180,
    //   flex: 1,
    // },
    {
      field: "partnertype",
      headerName: "Partner Type",
      sortable: true,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      minWidth: 180,
      flex: 1,
      renderCell: (params: {
        row: {
          status: string | undefined;
        };
      }) => {
        if (params.row.status === "Invitation Sent") {
          return (
            <div className="warning-status font12-medium">Invitation Sent</div>
          );
        } else if (params.row.status === "Activated") {
          return <div className="success-status font12-medium">Activated</div>;
        } else if (params.row.status === "New") {
          return <div className="blue-status font12-medium">New</div>;
        }
      },
    },
    {
      field: "files",
      headerName: "Files",
      sortable: true,
      flex: 1,
      minWidth: 166,
      renderCell: (params: {
        row: {
          id: string;
          is_deleted: boolean;
        };
      }) => {
        return !params.row.is_deleted &&
          permissions[0]?.sub_modules[5]?.permissions_actions[1]?.permission[0]
            ?.permission === true ? (
          <div>
            <Link to={`/partner-filesettings/${params.row.id}`}>
              Manage Files
            </Link>
          </div>
        ) : (
          <div>Manage Files</div>
        );
      },
    }
  );
  if (
    (permissions[0]?.sub_modules[4]?.permissions_actions[2]?.permission[0]
      ?.permission &&
      (permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission ||
        permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
          ?.permission ||
        permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
          ?.permission)) ||
    permissions[0]?.sub_modules[4]?.permissions_actions[3]?.permission[0]
      ?.permission ||
    permissions[0]?.sub_modules[6]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    partnerTableCols.push({
      field: "action",
      headerName: "Action",
      minWidth: 90,
      type: "actions",
      flex: 1,
      getActions: (params: {
        row: {
          id: SetStateAction<string | undefined>;
          status: any;
          is_deleted: boolean;
        };
      }) => {
        const action_items = [];
        if (
          !params.row.is_deleted &&
          permissions[0]?.sub_modules[4]?.permissions_actions[2]?.permission[0]
            ?.permission === true &&
          (permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
            ?.permission === true ||
            permissions[0]?.sub_modules[1]?.permissions_actions[1]
              ?.permission[0]?.permission ||
            permissions[0]?.sub_modules[2]?.permissions_actions[1]
              ?.permission[0]?.permission)
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                navigate(`/editpartner/${params.row.id}`);
              }}
              showInMenu
            />
          );
        }

        if (
          permissions[0]?.sub_modules[4]?.permissions_actions[3]?.permission[0]
            ?.permission === true &&
          params.row.is_deleted
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Restore"
              onClick={() => {
                console.log(params);
                setPopupType("restore");
                setPartnerID(params.row.id);
                setShowDeletePartner(true);
              }}
              showInMenu
            />,
            <GridActionsCellItem
              label="Delete Permanently"
              onClick={() => {
                setPopupType("delete-permanently");
                setPartnerID(params.row.id);
                setShowDeletePartner(true);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }

        if (
          permissions[0]?.sub_modules[4]?.permissions_actions[3]?.permission[0]
            ?.permission === true &&
          !params.row.is_deleted
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                setPopupType("delete");
                setPartnerID(params.row.id);
                setShowDeletePartner(true);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }
        if (
          !params.row.is_deleted &&
          permissions[0]?.sub_modules[6]?.permissions_actions[1]?.permission[0]
            ?.permission === true
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Assign Team"
              onClick={() => {
                navigate(`/assignpartnerteam/${params.row.id}`);
              }}
              showInMenu
            />
          );
        }
        return action_items;
      },
    });
  }
  return partnerTableCols;
};

export const orgUserTableColumns = (
  setUserID: { (value: SetStateAction<string>): void; (arg0: any): void },
  setShow: { (value: SetStateAction<boolean>): void; (arg0: boolean): void },
  setUser: { (value: SetStateAction<undefined>): void; (arg0: any): void },
  setIsEdit: { (value: SetStateAction<boolean>): void; (arg0: any): void },
  setShowDelete: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setEmail: {
    (value: SetStateAction<string | undefined>): void;
    (arg0: any): void;
  },
  setLinkSent: { (value: SetStateAction<boolean>): void; (arg0: any): void },
  setNotification: React.Dispatch<
    React.SetStateAction<{ type: string; msg: string }>
  >,
  permissions: any
) => {
  const orgUserTableCols: any = [];
  orgUserTableCols.push(
    { field: "name", headerName: "Member Name", sortable: true, flex: 1 },
    { field: "designation", headerName: "Designation", flex: 1 },
    { field: "role", headerName: "App Role", sortable: true, flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 }
  );

  if (
    permissions[0]?.sub_modules[1]?.permissions_actions[3]?.permission[0]
      ?.permission === true ||
    permissions[0]?.sub_modules[1]?.permissions_actions[2]?.permission[0]
      ?.permission === true
  ) {
    orgUserTableCols.push({
      field: "action",
      headerName: "Action",
      minWidth: 80,
      type: "actions",
      flex: 1,
      getActions: (params: { row: React.SetStateAction<any> }) => {
        const actions_list = [];
        if (
          permissions[0]?.sub_modules[1]?.permissions_actions[0]?.permission[0]
            ?.permission === true &&
          !!!params.row.is_active
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Send Invite"
              onClick={async () => {
                try {
                  const invite = await InviteUser(params.row.id);
                  console.log(invite);
                  const activity = await CreateActivity(
                    `Admin has sent an invite to user: ${params.row.name} with email: ${params.row.email}.`
                  );
                  console.log(activity);
                } catch (ex: any) {
                  console.log(ex);
                }
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[1]?.permissions_actions[2]?.permission[0]
            ?.permission === true &&
          params.row.is_active
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Reset Password"
              onClick={async () => {
                try {
                  setEmail(params.row.email);
                  await Auth.forgotPassword(params.row.email).then(
                    async (data) => {
                      console.log(data);
                      setLinkSent(true);
                      await CreateActivity(
                        `Admin has sent an code to reset account password for user: ${params.row.name} with email: ${params.row.email}.`
                      );
                    }
                  );
                } catch (ex: any) {
                  if (
                    ex.message ===
                    "User password cannot be reset in the current state."
                  ) {
                    setNotification({
                      type: "error",
                      msg: "Please verify your password first then try reset password.",
                    });
                  } else {
                    setNotification({ type: "error", msg: ex.message });
                    console.log(ex);
                  }
                }
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[1]?.permissions_actions[2]?.permission[0]
            ?.permission === true
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                setIsEdit(true);
                setShow(true);
                setUserID(params.row.id);
                setUser(params.row);
                setShowDelete(false);
                console.log(params.row);
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[1]?.permissions_actions[3]?.permission[0]
            ?.permission === true
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                console.log(params.row, "DELETE");
                setUserID(params.row.id);
                setShowDelete(true);
                setUser(params.row);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }

        return actions_list;
      },
    });
  }
  return orgUserTableCols;
};

export const adminUsersTableColumns = (
  setAdminID: React.Dispatch<React.SetStateAction<string>>,
  setShowDeleteAdmin: React.Dispatch<React.SetStateAction<boolean>>,
  setAdminName: Dispatch<SetStateAction<string>>,
  navigate: NavigateFunction,
  permissions: any
) => {
  const adminUsersTableCols = [];
  adminUsersTableCols.push(
    {
      field: "fullname",
      headerName: "Name",
      minWidth: 180,
      sortable: true,
      flex: 1,
    },
    {
      field: "emailid",
      headerName: "Email",
      minWidth: 180,
      sortable: true,
      flex: 1,
    },
    {
      field: "contactnumber",
      headerName: "Phone",
      minWidth: 180,
      sortable: true,
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 150,
      sortable: true,
      flex: 1,
    },
    {
      field: "designation",
      headerName: "Designation",
      minWidth: 120,
      sortable: true,
      flex: 1,
    },
    {
      field: "department",
      headerName: "Department",
      minWidth: 150,
      sortable: true,
      flex: 1,
    },
    {
      field: "createdat",
      headerName: "Create At",
      minWidth: 160,
      sortable: true,
      flex: 1,
    },
    // {
    //   field: "owner",
    //   headerName: "Owner",
    //   minWidth: 100,
    //   sortable: true,
    //   flex: 1,
    // },
    {
      field: "isactive",
      headerName: "Active",
      minWidth: 80,
      sortable: true,
      flex: 1,
      renderCell: (params: {
        row: {
          isactive: string | undefined;
        };
      }) => {
        if (params.row.isactive === "Yes") {
          return <div className="success-status font12-medium">Yes</div>;
        } else {
          return <div className="warning-status font12-medium">No</div>;
        }
      },
    }
  );
  if (
    permissions[0]?.sub_modules[0]?.permissions_actions[2]?.permission[0]
      ?.permission === true ||
    permissions[0]?.sub_modules[0]?.permissions_actions[3]?.permission[0]
      ?.permission === true
  ) {
    adminUsersTableCols.push({
      field: "actions",
      headerName: "Action",
      minWidth: 100,
      type: "actions",
      flex: 1,
      getActions: (params: {
        row: { id: SetStateAction<string>; fullname: string };
      }) => {
        const adminUserTableActions = [];
        if (
          permissions[0]?.sub_modules[0]?.permissions_actions[2]?.permission[0]
            ?.permission === true
        ) {
          adminUserTableActions.push(
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                navigate(`/EditAdminUser/${params.row.id}`);
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[0]?.permissions_actions[3]?.permission[0]
            ?.permission === true
        ) {
          adminUserTableActions.push(
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                setAdminID(params.row.id);
                setShowDeleteAdmin(true);
                setAdminName(params.row.fullname);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }
        return adminUserTableActions;
      },
    });
  }
  return adminUsersTableCols;
};

export const partnerUserTableColumns = (
  setID: {
    (value: SetStateAction<string>): void;
    (arg0: SetStateAction<string>): void;
  },
  setShowDelete: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  handlePartnerInvite: any,
  setShow: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setUser: any,
  setPartnerName: Dispatch<SetStateAction<string>>,
  setEmail: {
    (value: SetStateAction<string | undefined>): void;
    (arg0: any): void;
  },
  setLinkSent: { (value: SetStateAction<boolean>): void; (arg0: any): void },
  permissions: any
) => {
  const partnerUserTableCols = [];
  partnerUserTableCols.push(
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      flex: 1,
    },
    {
      field: "designation",
      headerName: "Designation",
      minWidth: 120,
      sortable: true,
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      sortable: true,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      flex: 1,
    },
    { field: "phone", headerName: "Phone", sortable: true, flex: 1 }
  );

  if (
    permissions[0]?.sub_modules[1]?.permissions_actions[2]?.permission[0]
      ?.permission ||
    permissions[0]?.sub_modules[1]?.permissions_actions[3]?.permission[0]
      ?.permission
  ) {
    partnerUserTableCols.push({
      field: "action",
      headerName: "Actions",
      minWidth: 80,
      type: "actions",
      flex: 1,
      getActions: (params: {
        row: {
          id: string;
          name: string;
          email: string;
          is_active: boolean;
        };
      }) => {
        const actions_list = [];
        if (
          permissions[0]?.sub_modules[1]?.permissions_actions[0]?.permission[0]
            ?.permission &&
          !!!params.row.is_active
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Invite"
              onClick={async () => {
                await InviteUser(params.row.id);
                handlePartnerInvite();
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[1]?.permissions_actions[2]?.permission[0]
            ?.permission &&
          params.row.is_active
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Reset Password"
              onClick={async () => {
                try {
                  setEmail(params.row.email);
                  Auth.forgotPassword(params.row.email).then(async (data) => {
                    console.log(data);
                    setLinkSent(true);
                    await CreateActivity(
                      `Admin has sent an code to reset account password for user: ${params.row.name} with email: ${params.row.email}.`
                    );
                  });
                } catch (ex: any) {
                  console.log(ex);
                }
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[1]?.permissions_actions[2]?.permission[0]
            ?.permission
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                setShow(true);
                console.log(params.row);
                setUser(params.row);
                setShowDelete(false);
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[1]?.permissions_actions[3]?.permission[0]
            ?.permission
        ) {
          actions_list.push(
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                setPartnerName(params.row.name);
                setID(params.row.id);
                setShowDelete(true);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }
        return actions_list;
      },
    });
  }
  return partnerUserTableCols;
};

export const productTableColumns = (
  setProductId: React.Dispatch<React.SetStateAction<string>>,
  setShowDeleteProduct: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  setProductName: Dispatch<SetStateAction<string>>,
  permissions: any
) => {
  const prodTableCols = [];
  prodTableCols.push(
    {
      field: "title",
      headerName: "Product Name",
      minWidth: 180,
      sortable: true,
      flex: 1,
    },
    {
      field: "sku",
      headerName: "SKU",
      minWidth: 180,
      sortable: false,
      flex: 1,
    },
    {
      field: "brand_name",
      headerName: "Brand Name",
      minWidth: 180,
      sortable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 180,
      sortable: false,
      flex: 1,
    },
    {
      field: "files",
      headerName: "Files",
      sortable: true,
      flex: 1,
      minWidth: 166,
      renderCell: (params: {
        row: {
          id: string;
          is_deleted: boolean;
        };
      }) => {
        return !params.row.is_deleted &&
          permissions[0]?.sub_modules[4]?.permissions_actions[1]?.permission[0]
            ?.permission === true ? (
          <div>
            <Link to={`/product-filesettings/${params.row.id}`}>
              Manage Files
            </Link>
          </div>
        ) : (
          <div>Manage Files</div>
        );
      },
    }
  );
  if (
    (permissions[0]?.sub_modules[3]?.permissions_actions[2]?.permission[0]
      ?.permission &&
      (permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission ||
        permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
          ?.permission ||
        permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
          ?.permission)) ||
    permissions[0]?.sub_modules[3]?.permissions_actions[3]?.permission[0]
      ?.permission
  ) {
    prodTableCols.push({
      field: "actions",
      headerName: "Actions",
      type: "actions",
      minWidth: 180,
      sortable: true,
      flex: 1,
      getActions: (params: {
        row: { id: SetStateAction<string>; title: string };
      }) => {
        const action_items = [];
        if (
          permissions[0]?.sub_modules[3]?.permissions_actions[2]?.permission[0]
            ?.permission &&
          (permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
            ?.permission ||
            permissions[0]?.sub_modules[1]?.permissions_actions[1]
              ?.permission[0]?.permission ||
            permissions[0]?.sub_modules[2]?.permissions_actions[1]
              ?.permission[0]?.permission)
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Edit"
              showInMenu
              onClick={() => {
                console.log(params.row);
                navigate(`/EditProduct/${params.row.id}`);
              }}
            />
          );
        }
        if (
          permissions[0]?.sub_modules[3]?.permissions_actions[3]?.permission[0]
            ?.permission
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Delete"
              style={{ color: "#FF0000" }}
              showInMenu
              onClick={() => {
                console.log(params.row);
                setProductName(params.row.title);
                setProductId(params.row.id);
                setShowDeleteProduct(true);
              }}
            />
          );
        }
        return action_items;
      },
    });
  }
  return prodTableCols;
};
export const documentSettingsTableColumns = (
  setSettingID: Dispatch<SetStateAction<undefined>>,
  setShowDeleteSetting: Dispatch<SetStateAction<boolean>>,
  setShow: Dispatch<SetStateAction<boolean>>
) => [
  {
    field: "documentname",
    headerName: "Document Name",
    sortable: true,
    flex: 1,
  },
  {
    field: "documentcategory",
    headerName: "Document Category",
    sortable: true,
    flex: 1,
  },
  {
    field: "documentsubcategory",
    headerName: "Document Sub Category",
    sortable: true,
    flex: 1,
  },
  {
    field: "action",
    headerName: "Actions",
    minWidth: 80,
    type: "actions",
    flex: 1,
    getActions: (params: { row: any }) => [
      <GridActionsCellItem
        label="Edit"
        onClick={() => {
          setSettingID(params.row.id);
          setShow(true);
        }}
        showInMenu
      />,
      <GridActionsCellItem
        label="Delete"
        onClick={() => {
          setSettingID(params.row.id);
          setShowDeleteSetting(true);
        }}
        style={{ color: "#FF0000" }}
        showInMenu
      />,
    ],
  },
];

export const documentsUploadTable = (
  setDocumentID: {
    (value: SetStateAction<string>): void;
    (arg0: any): void;
  },
  setShow: { (value: SetStateAction<boolean>): void; (arg0: boolean): void },
  setSelectedDocument: {
    (value: SetStateAction<undefined>): void;
    (arg0: { action: SetStateAction<string>; id: string }): void;
  },
  routetodocument: any
) => [
  {
    field: "documentname",
    headerName: "Document Name",
    sortable: true,
    flex: 1,
  },
  {
    field: "filename",
    headerName: "File Name",
    sortable: true,
    flex: 1,
    renderCell: (params: {
      hasFocus: any;
      row: {
        filename: string | undefined;
        dataitemid: string | undefined;
      };
    }) => (
      <strong>
        <Button
          variant="contained"
          size="sm"
          style={{ cursor: "pointer", border: "none", color: "blue" }}
          onClick={() => {
            console.log(params.row);
            routetodocument(params.row.dataitemid);
          }}
        >
          {params.row.filename}
        </Button>
      </strong>
    ),
  },
  {
    field: "action",
    headerName: "Actions",
    renderCell: (params: {
      hasFocus: any;
      row: { action: SetStateAction<string>; id: string };
    }) => (
      <strong>
        {params.row.action !== "Delete" && (
          <img
            className="upload-icon"
            style={{ cursor: "pointer" }}
            src="../svgs/cloud-arrow-up-fill.svg"
            alt=""
            onClick={() => {
              setDocumentID(params.row.id);
              setSelectedDocument(params.row);
              setShow(true);
            }}
          />
        )}
        {params.row.action === "Delete" && (
          <img
            className="upload-icon"
            style={{ cursor: "pointer" }}
            src="../svgs/trash.svg"
            alt=""
            onClick={() => {}}
          />
        )}
      </strong>
    ),
    minWidth: 80,
    flex: 1,
  },
];

export const productSkuTableColumns = (
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedProduct: React.Dispatch<React.SetStateAction<any>>,
  setSelectedProductVariant: React.Dispatch<React.SetStateAction<any>>,
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
  setShowDeleteProductSKU: React.Dispatch<React.SetStateAction<boolean>>,
  permissions: any
) => {
  const prodSKUcols = [];
  prodSKUcols.push(
    {
      field: "sku_title",
      headerName: "Product SKU Title",
      minWidth: 180,
      sortable: true,
      flex: 1,
    },
    {
      field: "sku_number",
      headerName: "SKU Number",
      minWidth: 180,
      sortable: true,
      flex: 1,
    }
  );
  if (
    permissions[0]?.sub_modules[2]?.permissions_actions[2]?.permission[0]
      ?.permission ||
    permissions[0]?.sub_modules[2]?.permissions_actions[3]?.permission[0]
      ?.permission
  ) {
    prodSKUcols.push({
      field: "action",
      headerName: "Actions",
      minWidth: 80,
      type: "actions",
      flex: 1,
      getActions: (params: { row: any }) => {
        const action_items = [];
        if (
          permissions[0]?.sub_modules[2]?.permissions_actions[2]?.permission[0]
            ?.permission
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Edit"
              showInMenu
              onClick={() => {
                console.log(params.row);
                setSelectedProduct(params.row.id);
                setIsEdit(true);
                setShow(true);
              }}
            />
          );
        }

        if (
          permissions[0]?.sub_modules[2]?.permissions_actions[3]?.permission[0]
            ?.permission
        ) {
          action_items.push(
            <GridActionsCellItem
              label="Delete"
              showInMenu
              onClick={() => {
                console.log(params.row);
                setSelectedProductVariant(params.row);
                setShowDeleteProductSKU(true);
              }}
              style={{ color: "#FF0000" }}
            />
          );
        }

        return action_items;
      },
    });
  }
  return prodSKUcols;
};

export const recommendPartnerTableColumns = (
  navigate: NavigateFunction,
  setIsDetailsModalOpen: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setShowApprovePartnerModal: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setSelectedRequest: Dispatch<SetStateAction<any>>,
  setUserType: Dispatch<SetStateAction<string>>,
  setSelectedPartnerID: Dispatch<SetStateAction<string>>,
  permissions: any
) => {
  const recommendPartnerCols = [];
  recommendPartnerCols.push(
    {
      field: "partnername",
      headerName: "Partner Name",
      sortable: true,
      flex: 1,
      renderCell: (params: {
        hasFocus: any;
        row: {
          partnerName: string | undefined;
          partnerId: string;
        };
      }) => (
        <strong>
          <Button
            variant="link"
            size="sm"
            className="link-btn text-decoration-none font13-medium"
            style={{ cursor: "pointer", border: "none" }}
            onClick={() => {
              //console.log(params.row);
              //   setSelectedRequestID(params.row.discovery_brands_id);
              setSelectedRequest(params.row);
              setUserType("Partner");
              setSelectedPartnerID(params.row.partnerId);
              setIsDetailsModalOpen(true);
            }}
          >
            {params.row.partnerName}
          </Button>
        </strong>
      ),
    },
    {
      field: "servicerequests",
      headerName: "Interested Services",
      sortable: true,
      flex: 2,
      renderCell: (params: {
        hasFocus: any;
        row: {
          servicerequests: string[];
        };
      }) => (
        <strong>
          <Button
            variant="contained"
            size="sm"
            className="font13-regular gray-900"
            style={{ border: "none", padding: 0 }}
          >
            {params.row.servicerequests[0]}
          </Button>
          <BootstrapTooltip
            title={
              <>
                <b>Interested Services</b>
                <ul>
                  {params.row.servicerequests.map((serviceReq) => {
                    return <li>{serviceReq}</li>;
                  })}
                </ul>
              </>
            }
          >
            <Button variant="contained" size="sm" className="table-link">
              {params.row.servicerequests.length > 1 &&
                `+ ${params.row.servicerequests.length - 1} more `}
            </Button>
          </BootstrapTooltip>
        </strong>
      ),
    },
    {
      field: "focusmarket",
      headerName: "Focus Market",
      sortable: true,
      flex: 1,
    },
    {
      field: "requestdate",
      headerName: "Interest Submission Date",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params: {
        hasFocus: any;
        row: {
          status: string | undefined;
        };
      }) => {
        if (params.row.status === "Recommended") {
          return (
            <div className="success-status font12-medium">Recommended</div>
          );
        } else {
          return <div className="blue-status font12-medium">Not Available</div>;
        }
      },
      flex: 1,
    }
  );

  if (
    permissions[0]?.sub_modules[2]?.permissions_actions[0]?.permission[0]
      ?.permission ||
    permissions[0]?.sub_modules[2]?.permissions_actions[2]?.permission[0]
      ?.permission
  ) {
    recommendPartnerCols.push({
      field: "action",
      headerName: "Action",
      minWidth: 80,
      type: "actions",
      flex: 1,
      renderCell: (params: {
        hasFocus: any;
        row: {
          filename: string | undefined;
          dataitemid: string | undefined;
          status: string | undefined;
        };
      }) => (
        <strong>
          <Button
            variant="link"
            size="sm"
            className="link-btn text-decoration-none font13-medium"
            disabled={
              params.row.status === "Recommended"
                ? !permissions[0]?.sub_modules[2]?.permissions_actions[2]
                    ?.permission[0]?.permission
                  ? true
                  : false
                : !permissions[0]?.sub_modules[2]?.permissions_actions[0]
                    ?.permission[0]?.permission
                ? true
                : false
            }
            onClick={() => {
              // console.log(params.row);
              setShowApprovePartnerModal(true);
              setSelectedRequest(params.row);
            }}
          >
            {params.row.status === "Recommended" ? "Update" : "Recommend"}
          </Button>
        </strong>
      ),
    });
  }
  return recommendPartnerCols;
};

export const AdvisoryManagementTableColumns = (
  setSelectedRow: {
    (value: SetStateAction<undefined>): void;
    (arg0: { id: string; brandId: string }): void;
  },
  usertype: string | undefined,
  setShowUploadModal: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  permissions: any
) => [
  {
    field: "brandname",
    headerName: "Brand Name",
    sortable: true,
    flex: 1,
    renderCell: (params: {
      hasFocus: any;
      row: {
        brandname: string | undefined;
        id: string;
        brandId: string;
      };
    }) => (
      <strong>
        <Button
          variant="contained"
          size="sm"
          className="font13-regular gray-900"
          style={{ cursor: "pointer", border: "none", padding: 0 }}
          onClick={() => {
            console.log(params.row);
          }}
        >
          {params.row.brandname}
        </Button>
      </strong>
    ),
  },
  {
    field: "reportcategoryname",
    headerName: "Report Category",
    sortable: true,
    flex: 1,
    minWidth: 166,
    renderCell: (params: {
      hasFocus: any;
      row: {
        reportcategoryname: string | undefined;
      };
    }) => (
      <strong>
        <Button
          variant="contained"
          size="sm"
          className="font13-regular gray-900"
          style={{ cursor: "pointer", border: "none", padding: 0 }}
          onClick={() => {
            console.log(params.row);
          }}
        >
          {params.row.reportcategoryname ? params.row.reportcategoryname : "NA"}
        </Button>
      </strong>
    ),
  },
  {
    field: "reportname",
    headerName: "Report Name",
    sortable: true,
    flex: 1,
    minWidth: 200,
    renderCell: (params: {
      hasFocus: any;
      row: {
        reportname: string | undefined;
      };
    }) => (
      <strong>
        <Button
          variant="contained"
          size="sm"
          className="font13-regular gray-900"
          style={{ cursor: "pointer", border: "none", padding: 0 }}
          onClick={() => {
            console.log(params.row);
          }}
        >
          {params.row.reportname ? params.row.reportname : "NA"}
        </Button>
      </strong>
    ),
  },
  {
    field: "listeddate",
    headerName: "Listed Date",
    sortable: true,
    flex: 1,
  },

  {
    field: "leadtime",
    headerName: "Lead Time",
    sortable: true,
    hide: usertype === "admin" ? false : true,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: true,
    flex: 1,
    renderCell: (params: {
      row: {
        status: string | undefined;
      };
    }) => {
      if (params.row.status === "Document Uploaded") {
        return (
          <div className="success-status font12-medium">Report Published</div>
        );
      } else if (params.row.status === "Paid") {
        return <div className="blue-status font12-medium">Paid</div>;
      } else if (params.row.status === "Payment pending") {
        return (
          <div className="warning-status font12-medium">Payment Pending</div>
        );
      }
    },
  },
  usertype === "admin"
    ? {
        field: "action",
        headerName: "Actions",
        minWidth: 80,
        type: "actions",
        flex: 1,
        renderCell: (params: {
          hasFocus: any;
          row: {
            id: string;
            brandId: string;
            status: string;
            document: any;
          };
        }) => (
          <strong>
            <Button
              variant="link"
              size="sm"
              color={
                params.row.status === "Paid" ||
                params.row.status === "Payment pending"
                  ? permissions[0]?.sub_modules[1]?.permissions_actions[0]
                      ?.permission[0]?.permission
                    ? "black"
                    : "#0152FB"
                  : !permissions[0]?.sub_modules[0]?.permissions_actions[1]
                      ?.permission[0]?.permission
                  ? "black"
                  : "#0152FB"
              }
              disabled={
                params.row.status === "Paid" ||
                params.row.status === "Payment pending"
                  ? permissions[0]?.sub_modules[1]?.permissions_actions[0]
                      ?.permission[0]?.permission
                    ? false
                    : true
                  : permissions[0]?.sub_modules[1]?.permissions_actions[1]
                      ?.permission[0]?.permission
                  ? false
                  : true
              }
              className="link-btn text-decoration-none font13-medium"
              onClick={async () => {
                console.log(params.row);
                if (params.row.status === "Document Uploaded") {
                  window.open(params.row.document.path);
                  try {
                    const todoDone = await MarkTodoDone(
                      `advisory-uploaded:${params.row.id}`
                    );
                    console.log(todoDone);
                  } catch (ex: any) {
                    console.log(ex);
                  }
                } else {
                  setSelectedRow(params.row);
                  console.log(params.row);
                  setShowUploadModal(true);
                }
              }}
            >
              {usertype === "admin" && params.row.status === "Document Uploaded"
                ? "View Report"
                : "Upload"}
            </Button>
          </strong>
        ),
      }
    : {
        field: "files",
        headerName: "Files",
        flex: 1,
        renderCell: (params: {
          hasFocus: any;
          row: {
            id: string;
            brandId: string;
            status: string;
            reportname: string;
            reportprice: string;
            document: any;
            report: any;
          };
        }) => (
          <strong>
            <Button
              variant="contained"
              size="sm"
              style={{
                cursor: "pointer",
                border: "none",
                color:
                  params.row.status === "Paid" ||
                  params.row.status === "Payment pending"
                    ? !permissions[0]?.sub_modules[0]?.permissions_actions[0]
                        ?.permission[0]?.permission
                      ? "black"
                      : "#0152FB"
                    : !permissions[0]?.sub_modules[1]?.permissions_actions[1]
                        ?.permission[0]?.permission
                    ? "black"
                    : "#0152FB",
                padding: 0,
              }}
              disabled={
                params.row.status === "Paid" ||
                params.row.status === "Payment pending"
                  ? permissions[0]?.sub_modules[0]?.permissions_actions[0]
                      ?.permission[0]?.permission
                    ? false
                    : true
                  : permissions[0]?.sub_modules[1]?.permissions_actions[1]
                      ?.permission[0]?.permission
                  ? false
                  : true
              }
              className="font13-medium"
              onClick={() => {
                setSelectedRow(params.row);
                console.log(params.row);
                if (
                  params.row.status === "Payment pending" &&
                  params.row.report
                ) {
                  (async () => {
                    const res = await MakeAdvisoryPayment({
                      items: [
                        {
                          name: params.row.reportname,
                          amount: Number(params.row.reportprice) * 100,
                        },
                      ],
                      advisory_id: Number(params.row.id),
                    });
                    console.log(res);
                    window.location = res.data.url;
                  })();
                }
                if (params.row.status === "Document Uploaded") {
                  (async () => {
                    console.log(params.row);
                    window.open(params.row.document.path);
                    try {
                      const todoDone = await MarkTodoDone(
                        `advisory-uploaded:${params.row.id}`
                      );
                      console.log(todoDone);
                    } catch (ex: any) {
                      console.log(ex);
                    }
                  })();
                }
              }}
            >
              {/* {params.row.status === "Document Uploaded" &&
                              params.row.reportname + ".pdf"} */}
              {params.row.status === "Document Uploaded" && "View Report"}
              {params.row.status === "Payment pending" &&
                params.row.report &&
                "Complete Payment"}
              {params.row.status === "Payment pending" &&
                !params.row.report &&
                "Report not available"}
              {params.row.status === "Paid" && "No files available"}
            </Button>
          </strong>
        ),
      },
];

export const SuitablePartnersTableColumns = (
  setIsDetailsModalOpen: Dispatch<SetStateAction<boolean>>,
  setUserType: Dispatch<SetStateAction<string>>,
  setSelectedPartnerID: Dispatch<SetStateAction<string>>,
  setSelectedRequest: Dispatch<SetStateAction<any>>,
  setSelectedRequestID: Dispatch<SetStateAction<string | undefined>>
) => [
  {
    field: "company_name",
    headerName: "Partner Name",
    minWidth: 180,
    sortable: true,
    flex: 1,
    renderCell: (params: {
      hasFocus: any;
      row: {
        company_name: string;
        id: string;
        partner_id: string;
      };
    }) => (
      <strong>
        <Button
          variant="link"
          size="sm"
          className="link-btn text-decoration-none font13-medium"
          onClick={() => {
            setSelectedRequest(params.row);
            setUserType("Partner");
            setSelectedRequestID(params.row.id);
            setSelectedPartnerID(params.row.partner_id);
            setIsDetailsModalOpen(true);
          }}
        >
          {params.row.company_name}
        </Button>
      </strong>
    ),
  },
  {
    field: "services",
    headerName: "Service Capability",
    sortable: true,
    flex: 2,
    renderCell: (params: {
      hasFocus: any;
      row: {
        services: string[];
      };
    }) => (
      <strong>
        <Button
          variant="contained"
          className="font13-regular gray-900"
          size="sm"
          style={{ border: "none", padding: 0 }}
        >
          {params.row.services[0]}
        </Button>
        <BootstrapTooltip
          title={
            <>
              <b>Service Capability</b>
              <ul>
                {params.row.services.map((service) => {
                  return <li>{service}</li>;
                })}
              </ul>
            </>
          }
        >
          <Button variant="contained" size="sm" className="table-link">
            {params.row.services.length > 1 &&
              `+ ${params.row.services.length - 1} more `}
          </Button>
        </BootstrapTooltip>
      </strong>
    ),
  },
  {
    field: "country",
    headerName: "Focus Market",
    minWidth: 180,
    sortable: true,
    flex: 1,
  },
];

export const AdvisoryReportTableColumns = (
  setReportId: React.Dispatch<React.SetStateAction<string>>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  setShowDeleteReport: React.Dispatch<React.SetStateAction<boolean>>,
  permissions: any
) => {
  const advisoryReportTableCols = [];
  advisoryReportTableCols.push(
    {
      field: "report_name",
      headerName: "Document Name",
      minWidth: 180,
      sortable: true,
      flex: 1,
    },
    {
      field: "category_name",
      headerName: "Document Category",
      minWidth: 180,
      sortable: true,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 180,
      sortable: true,
      flex: 1,
    },
    {
      field: "report_price",
      headerName: "Price",
      minWidth: 180,
      sortable: true,
      flex: 1,
    }
  );
  if (
    permissions[0]?.sub_modules[1]?.permissions_actions[2]?.permission[0]
      ?.permission === true ||
    permissions[0]?.sub_modules[1]?.permissions_actions[3]?.permission[0]
      ?.permission === true
  ) {
    advisoryReportTableCols.push({
      field: "actions",
      headerName: "Action",
      minWidth: 80,
      type: "actions",
      flex: 1,
      getActions: (params: { row: { id: SetStateAction<string> } }) => {
        const advisoryReportTableActions = [];
        if (
          permissions[0]?.sub_modules[1]?.permissions_actions[2]?.permission[0]
            ?.permission === true
        ) {
          advisoryReportTableActions.push(
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                setShow(true);
                setReportId(params.row.id);
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[1]?.permissions_actions[3]?.permission[0]
            ?.permission === true
        ) {
          advisoryReportTableActions.push(
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                setReportId(params.row.id);
                setShowDeleteReport(true);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }
        return advisoryReportTableActions;
      },
    });
  }
  return advisoryReportTableCols;
};

export const CountryTableColumns = (
  setCountryId: React.Dispatch<React.SetStateAction<string>>,
  setShowDeleteCountry: React.Dispatch<React.SetStateAction<boolean>>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  permissions: any
) => {
  const countryTableCols = [];
  countryTableCols.push(
    {
      field: "name",
      headerName: "Country Name",
      minWidth: 180,
      sortable: true,
      flex: 1,
      renderCell: (params: {
        row: {
          name: string | undefined;
        };
      }) => (
        <span style={{ textTransform: "capitalize" }}>{params.row.name}</span>
      ),
    },
    {
      field: "country_img",
      headerName: "Country Flag",
      minWidth: 180,
      sortable: true,
      flex: 1,
      renderCell: (params: {
        row: {
          country_img: string | undefined;
        };
      }) => (
        <span>
          {params.row.country_img ? (
            <img
              src={params.row.country_img}
              alt="country_img"
              width={28}
              height={20}
            />
          ) : (
            "NA"
          )}
        </span>
      ),
    },
    {
      field: "country__alpha_code",
      headerName: "Country Code",
      minWidth: 180,
      sortable: true,
      flex: 1,
    },
    {
      field: "country_code",
      headerName: "Telephone Code",
      minWidth: 180,
      sortable: true,
      flex: 1,
    }
  );
  if (
    permissions[0]?.sub_modules[2]?.permissions_actions[2]?.permission[0]
      ?.permission === true ||
    permissions[0]?.sub_modules[2]?.permissions_actions[3]?.permission[0]
      ?.permission === true
  ) {
    countryTableCols.push({
      field: "actions",
      headerName: "Action",
      minWidth: 80,
      type: "actions",
      flex: 1,
      getActions: (params: { row: { id: SetStateAction<string> } }) => {
        const countryTableActions = [];
        if (
          permissions[0]?.sub_modules[2]?.permissions_actions[2]?.permission[0]
            ?.permission === true
        ) {
          countryTableActions.push(
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                setShow(true);
                setCountryId(params.row.id);
              }}
              showInMenu
            />
          );
        }

        if (
          permissions[0]?.sub_modules[2]?.permissions_actions[3]?.permission[0]
            ?.permission === true
        ) {
          countryTableActions.push(
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                setCountryId(params.row.id);
                setShowDeleteCountry(true);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }
        return countryTableActions;
      },
    });
  }
  return countryTableCols;
};

export const BrandPaymentsTableColumns = () => [
  {
    field: "transaction_id",
    headerName: "Transaction ID",
    sortable: true,
    flex: 1,
  },
  {
    field: "date",
    headerName: "Payment Date",
    sortable: true,
    flex: 1,
  },
  {
    field: "plan",
    headerName: "Plan Name",
    sortable: true,
    flex: 1,
  },
  {
    field: "frequency",
    headerName: "Frequency",
    sortable: true,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: true,
    flex: 1,
    renderCell: (params: {
      hasFocus: any;
      row: {
        status: string | undefined;
      };
    }) => {
      switch (params.row.status) {
        case "Completed":
          return <div className="success-status">Paid</div>;
        default:
          return <div>Empty</div>;
      }
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    sortable: true,
    flex: 1,
  },
];

export const FilesTableColumns = (
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  setFileId: React.Dispatch<React.SetStateAction<string>>,
  setShowDeleteFile: React.Dispatch<React.SetStateAction<boolean>>,
  permissions: any
) => {
  const filesTableCols = [];
  filesTableCols.push(
    {
      field: "name",
      headerName: "File Name",
      sortable: true,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "category",
      headerName: "File Category",
      sortable: true,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "file_type",
      headerName: "File Type",
      sortable: true,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "file_formats",
      headerName: "File Format",
      sortable: true,
      minWidth: 300,
      flex: 1,
      renderCell: (params: {
        hasFocus: any;
        row: {
          file_formats: any[];
        };
      }) => (
        <div className="d-flex">
          {params.row.file_formats?.map((itm, index) => {
            if (itm !== undefined) {
              return (
                <div
                  key={index}
                  className="badgepill-blue50 
              font12-regular 
              text-uppercase 
              gray-900 
              mr10px"
                >
                  {itm?.format}
                </div>
              );
            } else {
              return <div key={index}>Empty</div>;
            }
          })}
        </div>
      ),
    }
  );
  if (
    permissions[0]?.sub_modules[4]?.permissions_actions[2]?.permission[0]
      ?.permission === true ||
    permissions[0]?.sub_modules[4]?.permissions_actions[3]?.permission[1]
      ?.permission === true
  ) {
    filesTableCols.push({
      field: "actions",
      headerName: "Action",
      minWidth: 90,
      type: "actions",
      flex: 1,
      getActions: (params: {
        row: { id: SetStateAction<string>; public_access: boolean };
      }) => {
        const filesTableActions = [];
        if (
          permissions[0]?.sub_modules[4]?.permissions_actions[2]?.permission[0]
            ?.permission === true
        ) {
          filesTableActions.push(
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                setShow(true);
                setFileId(params.row.id);
                console.log(params.row);
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[4]?.permissions_actions[3]?.permission[0]
            ?.permission === true &&
          params.row.id.toString() !== "1" &&
          params.row.public_access === false
        ) {
          filesTableActions.push(
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                setShowDeleteFile(true);
                setFileId(params.row.id);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }
        return filesTableActions;
      },
    });
  }
  return filesTableCols;
};

export const RolesTableColumns = (
  navigate: NavigateFunction,
  setRoleId: React.Dispatch<React.SetStateAction<string>>,
  setShowDeleteRole: React.Dispatch<React.SetStateAction<boolean>>,
  permissions: any
) => {
  const rolesTableCols = [];
  rolesTableCols.push(
    {
      field: "role_name",
      headerName: "Role Name",
      sortable: true,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Role Type",
      sortable: true,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "user_count",
      headerName: "Users Assigned",
      sortable: true,
      minWidth: 180,
      flex: 1,
    }
  );
  if (
    permissions[0]?.sub_modules[3]?.permissions_actions[2]?.permission[0]
      ?.permission === true ||
    permissions[0]?.sub_modules[3]?.permissions_actions[3]?.permission[0]
      ?.permission === true
  ) {
    rolesTableCols.push({
      field: "actions",
      headerName: "Action",
      minWidth: 80,
      type: "actions",
      flex: 1,
      getActions: (params: { row: { id: SetStateAction<string> } }) => {
        const rolesTableActions = [];
        if (
          permissions[0]?.sub_modules[3]?.permissions_actions[2]?.permission[0]
            ?.permission === true
        ) {
          rolesTableActions.push(
            <GridActionsCellItem
              label="Edit role"
              onClick={() => {
                // setShow(true);
                // setCountryId(params.row.id);
                navigate(`/EditRole/${params.row.id}`);
              }}
              showInMenu
            />
          );
        }
        if (
          permissions[0]?.sub_modules[3]?.permissions_actions[3]?.permission[0]
            ?.permission === true
        ) {
          rolesTableActions.push(
            <GridActionsCellItem
              label="Delete role"
              onClick={() => {
                setRoleId(params.row.id);
                setShowDeleteRole(true);
              }}
              style={{ color: "#FF0000" }}
              showInMenu
            />
          );
        }
        return rolesTableActions;
      },
    });
  }
  return rolesTableCols;
};
