import "./FileActions.scss";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import PopupTemplate from "../../../components/PopupTemplate/PopupTemplate";

export interface FileActionProps {
  files: any;
  urls: any;
  setFlagProp?: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete: (filesToDelete: any, urlsToDelete: any) => void;
  handleInfo: () => void;
  permissions?: any;
  folderType?: string;
}
const FileActions: React.FunctionComponent<FileActionProps> = ({
  files,
  urls,
  handleDelete,
  handleInfo,
  setFlagProp,
  permissions,
  folderType,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [selectedUrls, setSelectedUrls] = useState<any>([]);
  const [showDeleteFile, setShowDeleteFile] = useState(false);

  useEffect(() => {
    let filelist = files.filter((Item: any) => Item?.isChecked === true);
    setSelectedFiles(filelist);
    let urllist = urls.filter((Item: any) => Item?.isChecked === true);
    setSelectedUrls(urllist);
  }, [files, urls, permissions]);
  const getTotalSelection = () => {
    if (selectedFiles.length !== 0 || selectedUrls.length !== 0) {
      let total = selectedFiles.length + selectedUrls.length;
      if (total === 1) {
        return "1 item selected";
      } else {
        return `${total} items selected`;
      }
    } else {
      return files.length + urls.length + " items available";
    }
  };
  const onDelete = () => {
    setShowDeleteFile(true);
  };

  const deleteFunction = () => {
    handleDelete(selectedFiles, selectedUrls);
    if (setFlagProp) {
      setFlagProp((prev) => !!!prev);
    }
    setShowDeleteFile(false);
  };
  const handleCloseDeleteFile = () => {
    setShowDeleteFile(false);
  };
  const onInfo = () => {
    handleInfo();
  };
  return (
    <div className="file-actions">
      <span className="gray-800">
        {getTotalSelection()}
        {/* <span className="primary-blue select-all"> Select all</span> */}
      </span>
      <div className="right-btns">
        {selectedFiles.length === 1 && (
          <Button
            disabled={selectedFiles.length === 1 ? false : true}
            className="gray-600 info-btn"
            onClick={onInfo}
          >
            <img src="../svgs/Info-gray600.svg" alt="Info" />
            <span>Info</span>
          </Button>
        )}
        {/* <Button disabled={false} className="gray-600">
                    <img src="../svgs/rename.svg" alt="rename" />
                    <span>Rename</span>
                </Button> */}
        {folderType === "brand" &&
          (selectedFiles.length > 0 || selectedUrls.length > 0) &&
          permissions[0]?.sub_modules[5]?.permissions_actions[3]?.permission[0]
            ?.permission && (
            <Button
              disabled={
                selectedFiles.length === 0 && selectedUrls.length === 0
                  ? true
                  : false
              }
              className="error-600 del-btn"
              onClick={onDelete}
            >
              <img src="../svgs/trash600.svg" alt="trash" />
              <span>Delete</span>
            </Button>
          )}
        {folderType === "product" &&
          (selectedFiles?.length > 0 || selectedUrls?.length > 0) &&
          permissions[0]?.sub_modules[4]?.permissions_actions[3]?.permission[0]
            ?.permission === true && (
            <Button
              disabled={
                selectedFiles.length === 0 && selectedUrls.length === 0
                  ? true
                  : false
              }
              className="error-600 del-btn"
              onClick={onDelete}
            >
              <img src="../svgs/trash600.svg" alt="trash" />
              <span>Delete</span>
            </Button>
          )}
        {folderType === "partner" &&
          (selectedFiles?.length > 0 || selectedUrls?.length > 0) &&
          permissions[0]?.sub_modules[5]?.permissions_actions[3]?.permission[0]
            ?.permission && (
            <Button
              disabled={
                selectedFiles.length === 0 && selectedUrls.length === 0
                  ? true
                  : false
              }
              className="error-600 del-btn"
              onClick={onDelete}
            >
              <img src="../svgs/trash600.svg" alt="trash" />
              <span>Delete</span>
            </Button>
          )}
      </div>
      <PopupTemplate
        popupHeader="Confirm Deletion."
        popupBody={`Are you sure you want to delete?`}
        handleFunction={deleteFunction}
        isModalOpen={showDeleteFile}
        onCancel={handleCloseDeleteFile}
        id=""
        setFlag={setFlagProp}
      />
    </div>
  );
};

export default FileActions;
