import "./Folder.scss";
import { useNavigate } from "react-router-dom";

export interface Propsfolder {
    url: string;
    fileName: string;
}

const Folder: React.FunctionComponent<Propsfolder> = ({
    url,
    fileName
}) => {
    const navigate = useNavigate();
    const openFolder = () => {
        navigate(url)
    }
    return (

        <div
            className="folder-container"
            onClick={openFolder}
        >
            <div className="folder">
                <img src="../svgs/Folder.svg" alt="folder" />
            </div>
            <h6
                className="folder-name font14-regular gray-800"
            >
                {fileName}
            </h6>
        </div>

    )
}

export default Folder;