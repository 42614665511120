import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ReportCategoriesDTO, AdvisoryReportInputDTO } from "../../../dtos/advisory.dtos";

export interface AddReportProps {
    onCancel: () => void;
    categories: ReportCategoriesDTO[];
    onSubmit: (data: AdvisoryReportInputDTO) => void;
    submitting: boolean;
    defaultData?: AdvisoryReportInputDTO;
    reportId: string;
}

const AddReportForm: React.FunctionComponent<AddReportProps> = ({
    onCancel,
    categories,
    onSubmit,
    submitting,
    defaultData,
    reportId
}) => {
    const { register, formState, handleSubmit, reset } = useForm({
        defaultValues: defaultData
    });
    const [isExistingCategory, setIsExistingCategory] = useState(false);
    const { errors } = formState;
    const onHandleClose = () => {
        onCancel();
    };
    const onHandleSubmit = (data: any) => {
        // event.preventDefault();
        onSubmit(data);
      };
    //   useEffect(() => {
    //     reset()
    //   },[isExistingCategory])
      useEffect(() => {
        if (defaultData) {
          const defaults = {
            category_name: defaultData?.category_name,
            reports_category_id: defaultData?.reports_category_id,
            report_name: defaultData?.report_name,
            report_price: defaultData?.report_price,
            description: defaultData?.description,
            url: defaultData?.url
          };
          if(defaultData.reports_category_id) {
            setIsExistingCategory(true)
          }
          reset(defaults);
        }
      }, [reset, defaultData]);
    return (
        <Form onSubmit={handleSubmit((data) => onHandleSubmit(data))}>
            <Container fluid>
                {reportId === "" && <Row>
                    <Col className="p-0">
                        <div className="d-flex">
                            <div className="font14-medium gray-700 mr28px">Add report to existing category ?</div>
                            <Form.Check
                                id="yes"
                                label="Yes"
                                type="radio"
                                name="group1"
                                className="font14-medium gray-700 mr48px"
                                defaultChecked={isExistingCategory === true ? true : false}
                                onChange={() => {
                                    setIsExistingCategory(true)
                                }}
                            />
                            <Form.Check
                                id="no"
                                label="No"
                                type="radio"
                                name="group1"
                                className="font14-medium gray-700"
                                defaultChecked={isExistingCategory === false ? true : false}
                                onChange={() => {
                                    setIsExistingCategory(false)
                                }}
                            />
                        </div>
                    </Col>
                </Row>}
                <Row className="mt24px">
                    <Col className="p-0">
                        <Form.Group className="formgroup mr12px" controlId="reportcategory">
                            <Form.Label>Report Category</Form.Label>
                            {!isExistingCategory && <Form.Control
                                type="text"
                                placeholder="Enter Report Category"
                                {...register("category_name", {
                                    required: "* This field is required",
                                })}
                            />}
                            {!isExistingCategory &&
                                <Form.Text muted>
                                    <span>
                                        {errors?.category_name && (
                                            <p className="error-form">
                                                {String(errors.category_name?.message)}
                                            </p>
                                        )}
                                    </span>
                                </Form.Text>}
                            {isExistingCategory && <Form.Select
                                aria-label="Default select example"
                                {...register("reports_category_id", {
                                    required: "* This field is required",
                                })}
                            >
                                <option  value="">Select</option>
                                {categories?.length && categories?.map((item: any) => {
                                    return (
                                        <option key={item.id} value={item.id}>
                                            {item.category_name}
                                        </option>
                                    )
                                })}
                            </Form.Select>}
                            {isExistingCategory &&
                                <Form.Text muted>
                                    <span>
                                        {errors?.reports_category_id && (
                                            <p className="error-form">
                                                {String(errors.reports_category_id?.message)}
                                            </p>
                                        )}
                                    </span>
                                </Form.Text>}
                        </Form.Group>
                    </Col>
                    <Col className="p-0">
                        <Form.Group className="formgroup" controlId="reportname">
                            <Form.Label>Report Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Report Name"
                                {...register("report_name", {
                                    required: "* This field is required",
                                })}
                            />
                            <Form.Text muted>
                                <span>
                                    {errors?.report_name && (
                                        <p className="error-form">
                                            {String(errors.report_name?.message)}
                                        </p>
                                    )}
                                </span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt24px">
                    <Col className="p-0">
                        <Form.Group className="formgroup mr12px" controlId="reportcategory">
                            <Form.Label>Report Price</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter Report Price"
                                {...register("report_price", {
                                    required: "* This field is required",
                                })}
                            />
                            <Form.Text muted>
                                <span>
                                    {errors?.report_price && (
                                        <p className="error-form">
                                            {String(errors.report_price?.message)}
                                        </p>
                                    )}
                                </span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col className="p-0">
                        <Form.Group className="formgroup" controlId="formFile">
                            <Form.Label>Demo PDF Link</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter URL Link"
                                {...register("url", {
                                    required: "* This field is required",
                                })}
                            />
                            <Form.Text muted>
                                <span>
                                    {errors?.url && (
                                        <p className="error-form">
                                            {String(errors.url?.message)}
                                        </p>
                                    )}
                                </span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt24px">
                    <Col className="p-0 mb-3">
                        <Form.Group className="formgroup" controlId="reportname">
                            <Form.Label>Report Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter Description"
                                className="h-auto"
                                {...register("description")}
                            />
                            <Form.Text muted>
                                <span>
                                    {errors?.description && (
                                        <p className="error-form">
                                            {String(errors.description?.message)}
                                        </p>
                                    )}
                                </span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="justify-content-end bt-gray200">
                    <Col className="d-flex justify-content-end p-0 mt-3">
                        <Button
                        className="m-1 btn-secondary12"
                        onClick={onHandleClose}
                        >
                            Cancel
                        </Button>
                        <Button 
                        type="submit"
                        className="m-1 btn-primary12"
                        disabled={submitting ? true : false}
                        >
                            {reportId ? "Update" : "Add"}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}

export default AddReportForm;

