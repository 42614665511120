import Table from "react-bootstrap/Table";
import "./Resource.scss";
export interface resourceProps {}
const Resource: React.FunctionComponent<resourceProps> = () => {
  return (
    <div className="resource-wraper">
      <Table borderless responsive className="table-resource">
        <tbody>
          <tr>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/doc.svg" alt="doc" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/doc.svg" alt="doc" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
          </tr>

          <tr>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/video.svg" alt="video" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/video.svg" alt="video" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
          </tr>

          <tr>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/image.svg" alt="pic" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/image.svg" alt="pic" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
          </tr>

          <tr>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/doc.svg" alt="doc" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/doc.svg" alt="doc" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
          </tr>

          <tr>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/video.svg" alt="video" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/video.svg" alt="video" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
          </tr>

          <tr>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/image.svg" alt="pic" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
            <td className="td-img">
              <div className="img-d">
                <img src="../svgs/image.svg" alt="pic" />
              </div>
            </td>
            <td>
              <div className="font12-regular gray-800">
                A Quick Guide for Brand Management
              </div>
              <div>
                <span>
                  <img src="../svgs/time.svg" alt="time" />
                </span>
                <span className="font10-regular gray-500">
                  {" "}
                  publish on 16 Feb
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
export default Resource;
