import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetBrand,
  GetBrandPreferences,
  GetEngagementModels,
  SaveBrandPreference,
  UpdateBrandAgreementStatus,
  UpdateBrandPreference,
} from "../../../../API/endpoints/Brands";
import "./../CreateBrand.scss";
// @ts-ignore
import BrandMarketPresenceForm from "../../../components/forms/brandMarketPresenceForm";
import {
  countriesDTO,
  EngagementModelDTO,
  MarketPreferenceDTO,
} from "../../../dtos/brand.dtos";
import { CreateTodo } from "../../../../API/endpoints/Todos";

interface BrandMarketPresenceDTO {
  countries: countriesDTO[];
  allCountries: countriesDTO[];
  brandID: string;
  setDefaultTab: (tab: string) => void;
  handleNotification: (type: string, msg: string) => void;
  permissions: any;
}

function BrandMarketPresence(props: BrandMarketPresenceDTO) {
  const params = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [brandID, setBrandID] = useState(props.brandID);
  const [engagementModelList, setEngagementModelList] = useState<
    EngagementModelDTO[]
  >([]);
  const [marketPreference, setMarketPreference] = useState<
    MarketPreferenceDTO[]
  >([]);
  const [aspiredMarket, setAspiredMarket] = useState<string>("");
  const [regulatoryCompliance, setRegulatoryCompliance] = useState(false);
  const [importClearance, setImportClearance] = useState(false);
  const [warehousingAndStorage, setWarehousingAndStorage] = useState(false);
  const [ecomSetupMgmt, setEcomSetupMgmt] = useState(false);
  const [retailDistribution, setRetailDistribution] = useState(false);
  const [orderFullfilment, setOrderFullfilment] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [brandName, setBrandName] = useState<string>("");
  const [fundRequired, setFundRequired] = useState(false);
  const [newMarketPreference, setNewMarketPreference] = useState<
    MarketPreferenceDTO[]
  >([]);
  // const [engagementModel, setEnagementModel] = useState<string>("1");
  const [preferenceErrorMsg, setPreferenceErrorMsg] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedModels, setSelectedModels] = useState([]);
  const [defaultModals, setDefaultModals] = useState([]);
  const [validateMessage, setValidateMessage] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    setBrandID(props.brandID);
    setAspiredMarket(props?.countries[0]?.id?.toString());

    const GetEngagementModelsList = async () => {
      const response = await GetEngagementModels();
      if (response) {
        // console.log(response.data);
        setEngagementModelList(response.data);
      }
    };

    GetEngagementModelsList();
  }, [props]);

  useEffect(() => {
    if (brandID) {
      const getBrandData = async () => {
        const response = await GetBrand(brandID);
        setBrandName(response.data.brand_name);
      };
      getBrandData();
    }
  }, [brandID]);

  // const getEngagementModel = (model: string) => {
  //   switch (model) {
  //     case "consignment":
  //       return "1";
  //     case "inventory_financed":
  //       return "2";
  //     case "pre-purchase":
  //       return "3";
  //     default:
  //       return "";
  //   }
  // };

  useEffect(() => {
    const GetPreferences = async (id: string) => {
      const res = await GetBrandPreferences(id);
      if (res) {
        console.log(res.data);
        setFundRequired(
          res.data.brand_preference?.fund_required
            ? res.data.brand_preference.fund_required
            : false
        );
        // setEnagementModel(
        //   `${
        //     res.data.brand_preference?.engagement_models?.id
        //       ? res.data.brand_preference?.engagement_models.id.toString()
        //       : "1"
        //   }`
        // );
        let modelList =
          res.data?.brand_preference?.engagement_models_list || [];
        setDefaultModals(modelList);
        setSelectedModels(modelList?.map((itm: any) => itm.id));

        if (res.data.market_preference) {
          let services = res.data.market_preference;
          setMarketPreference([]);
          services.map((item: any) => {
            let preferenceList = {
              region: item.regions.id
                ? item.regions.id.toString()
                : props?.countries[0]?.id?.toString(),
              regulatory_compliance:
                item.pre_launch_services.regulatory_compliance,
              importation_clearance:
                item.pre_launch_services.importation_clearance,
              warehousing_storage: item.pre_launch_services.warehousing_storage,
              ecom_setup_mgmt: item.post_launch_services.ecom_setup_mgmt,
              retail_distribution:
                item.post_launch_services.retail_distribution,
              order_fulfilment: item.post_launch_services.order_fulfilment,
              marketing: item.post_launch_services.marketing,
            };
            setMarketPreference((marketPreference) => [
              ...marketPreference,
              preferenceList,
            ]);
            return true;
          });
        }
      }
    };

    if (params?.brandid) {
      GetPreferences(params.brandid);
    }
  }, [params.brandid, props]);

  // console.log(marketPreference)
  const addPreference = () => {
    let flag = false;
    setPreferenceErrorMsg("");
    marketPreference.map((preference) => {
      if (preference.region === aspiredMarket) {
        flag = true;
        setPreferenceErrorMsg(
          "Market preference in the region already exists."
        );
        return true;
      }
      return true;
    });

    setTimeout(() => {
      if (!flag) {
        setMarketPreference([
          ...marketPreference,
          {
            region: aspiredMarket,
            regulatory_compliance: regulatoryCompliance,
            importation_clearance: importClearance,
            warehousing_storage: warehousingAndStorage,
            ecom_setup_mgmt: ecomSetupMgmt,
            retail_distribution: retailDistribution,
            order_fulfilment: orderFullfilment,
            marketing: marketing,
          },
        ]);
      }
      setDisabled(false);
    }, 100);
  };

  const handleEditButtonClick = (Region: string) => {
    setNewMarketPreference(
      marketPreference?.filter((market) => market.region !== Region)
    );
  };

  const handleConfirmEdit = (preference: MarketPreferenceDTO) => {
    let editedPreference = {
      region: preference.region,
      regulatory_compliance: preference.regulatory_compliance,
      importation_clearance: preference.importation_clearance,
      warehousing_storage: preference.warehousing_storage,
      ecom_setup_mgmt: preference.ecom_setup_mgmt,
      retail_distribution: preference.retail_distribution,
      order_fulfilment: preference.order_fulfilment,
      marketing: preference.marketing,
    };

    setMarketPreference([...newMarketPreference, editedPreference]);
  };

  const handleDelete = (Region: string) => {
    let newMarketPreference = marketPreference.filter(
      (market) => market.region !== Region
    );
    setMarketPreference(newMarketPreference);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedModels.length === 0) {
      setValidateMessage("* This field is required");
      return;
    }
    if (selectedModels.length === 0) {
      setValidateMessage("* This field is required");
      return;
    }
    setSubmitting(true);

    const BrandMarketPreference = {
      brand_id: brandID,
      fund_required: fundRequired,
      // engagement_model: engagementModel,
      engagement_models_list: selectedModels,
      market_prefernce: marketPreference,
    };

    const AddBrandPreference = async () => {
      console.log(BrandMarketPreference);
      const response = await SaveBrandPreference(BrandMarketPreference);
      if (response) {
        setSubmitting(false);
        setValidateMessage("");
        setValidateMessage("");
        const agreement_Status = await UpdateBrandAgreementStatus(
          brandID,
          "ready_to_sign"
        );
        console.log(agreement_Status);
        try {
          const todoData = {
            id: `brandKeyTerms:${brandID}`,
            brand_id: brandID,
            message: `You are ready to sign the agreement.`,
            url: "/BrandManagement",
            url_action: `Click here to activate the brand: ${brandName}`,
            admin: false,
            permission_submodule_id: 47,
            brand: true,
          };
          const res2 = await CreateTodo(todoData);
          console.log(res2);
          navigate("/BrandManagement");
        } catch (error: any) {
          console.log(error);
        }
      } else {
        setSubmitting(false);
        console.log("Cannot save Brand preferences");
      }
    };

    const EditBrandPreference = async () => {
      console.log(BrandMarketPreference);
      const response = await UpdateBrandPreference(
        BrandMarketPreference,
        params.brandid
      );
      if (response) {
        setSubmitting(false);
        setValidateMessage("");
        setValidateMessage("");
        props.handleNotification("success", "Updated successfully");
        const agreement_Status = await UpdateBrandAgreementStatus(
          params.brandid,
          "ready_to_sign"
        );
        console.log(agreement_Status);
        try {
          const todoData = {
            id: `brandKeyTerms:${brandID}`,
            brand_id: brandID,
            message: `You are ready to sign the agreement.`,
            url: `/BrandManagement`,
            url_action: `Click here to activate the brand: ${brandName}`,
            permission_submodule_id: 47,
            admin: false,
            brand: true,
          };
          const res2 = await CreateTodo(todoData);
          console.log(res2);
        } catch (error: any) {
          console.log(error);
        }
      } else {
        setSubmitting(false);
        console.log("Cannot update Brand preferences");
      }
    };

    if (marketPreference[0]) {
      if (params.brandid) {
        EditBrandPreference();
      } else {
        AddBrandPreference();
      }
    } else {
      console.log("Preferences list cannot be empty");
      setPreferenceErrorMsg("Preferences list cannot be empty");
    }
  };

  const handleEngementModal = (idList: any) => {
    setSelectedModels(idList);
  };

  return (
    <>
      <BrandMarketPresenceForm
        submitting={submitting}
        countries={props.countries}
        allCountries={props.allCountries}
        engagementModelList={engagementModelList}
        aspiredMarket={aspiredMarket}
        regulatoryCompliance={regulatoryCompliance}
        importClearance={importClearance}
        warehousingAndStorage={warehousingAndStorage}
        ecomSetupMgmt={ecomSetupMgmt}
        retailDistribution={retailDistribution}
        orderFullfilment={orderFullfilment}
        marketing={marketing}
        marketPreference={marketPreference}
        isDisabled={isDisabled}
        fundRequired={fundRequired}
        addPreference={addPreference}
        setAspiredMarket={setAspiredMarket}
        setRegulatoryCompliance={setRegulatoryCompliance}
        setImportClearance={setImportClearance}
        setWarehousingAndStorage={setWarehousingAndStorage}
        setEcomSetupMgmt={setEcomSetupMgmt}
        setRetailDistribution={setRetailDistribution}
        setOrderFullfilment={setOrderFullfilment}
        setMarketing={setMarketing}
        setIsDisabled={setIsDisabled}
        setFundRequired={setFundRequired}
        handleEditButtonClick={handleEditButtonClick}
        handleConfirmEdit={handleConfirmEdit}
        handleDelete={handleDelete}
        onSubmit={onSubmit}
        preferenceErrorMsg={preferenceErrorMsg}
        setPreferenceErrorMsg={setPreferenceErrorMsg}
        setDisabled={setDisabled}
        disabled={disabled}
        handleEngementModal={handleEngementModal}
        defaultModals={defaultModals}
        engagementMessage={validateMessage}
        permissions={props?.permissions}
      />

      <div></div>
    </>
  );
}

export default BrandMarketPresence;
