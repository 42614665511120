import "../../../styles/pagelayout.scss";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../components/sidebar/Sidebar";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";
import { useNavigate, useParams } from "react-router-dom";
import { GetUserProfile } from "../../../API/endpoints/User";
import { GetBrand } from "../../../API/endpoints/Brands";
import Breadcrumb from "../../components/header/BreadCrumb";
import Navbar from "../../components/header/NavBar";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { GetBrandTransactions } from "../../../API/endpoints/transactions";
import TableMUI from "../../components/TableMUI/TableMUI";
import { BrandPaymentsTableColumns } from "../../components/TableMUI/TableColumns";

const ViewBrandPayments = () => {
  type dataInput = {
    rows?: {
      id: string;
      transaction_id: string;
      frequency: string;
      date: string;
      plan: string;
      status: string;
      amount: string;
    }[];
  };
  const params = useParams();
  const navigate = useNavigate();
  const [userID, setUserID] = useState<string | undefined>("");
  const [userType, setUserType] = useState<string | undefined>("");
  const [userRole, setUserRole] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [brandName, setBrandName] = useState("");
  const [data, setData] = useState<dataInput>({});
  useEffect(() => {
    setUserType(Cookies.get("UserType"));
    setUserRole(Cookies.get("UserRole"));
    setUserID(Cookies.get("userID"));
  }, []);
  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();

    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });
    if (userType === "administrative" && userRole === "super_admin") {
      console.log(userType);
    } else if (userType === "partner") {
      navigate("/PartnerDashboard");
    }
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userType, userRole, navigate, userID]);
  useEffect(() => {
    (async () => {
      const response = await GetBrand(params.brandid);
      setBrandName(response.data.brand_name);
      const transactions = await GetBrandTransactions(params.brandid, 1);
      console.log(transactions);
      setData(transactions.data);
    })();
  }, [params]);
  const breadcrumbData = [
    {
      name: "Brand Management",
      url: "",
      active: false,
    },
    {
      name: "Brands",
      url: "",
      active: false,
    },
    {
      name: brandName || "",
      url: "",
      active: false,
    },
    {
      name: "View Payments",
      url: "",
      active: true,
    },
  ];
  const dateFormatter = (date: any) => {
    let payDate = new Date(date);
    return `${payDate.getDate()}/${
      payDate.getMonth() + 1
    }/${payDate.getFullYear()}`;
  };
  let rows: {
    id: string;
    transaction_id: string;
    frequency: string;
    date: string;
    plan: string;
    status: string;
    amount: string;
  }[] = [];
  if (data?.rows) {
    console.log(data.rows);
    rows = data?.rows
      .map((dataItem) => ({
        id: dataItem.id,
        transaction_id: dataItem.transaction_id,
        frequency: dataItem.frequency || "NA",
        date: dateFormatter(dataItem.date),
        plan: dataItem.plan,
        status: dataItem.status,
        amount: `$${dataItem.amount}`,
      }))
      .filter((dataItem) => dataItem.status === "Completed");
  }
  const columns = BrandPaymentsTableColumns();
  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="brand" />
        </Col>
        <Col className="content-section p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb breadcrumbData={breadcrumbData} />
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
              />
            </InputGroup>
          </div>
          <div className="font14-medium primary-blue table-title">
            TRANSACTION HISTORY
          </div>
          <div className="underline-gray200">
            <div></div>
          </div>
          <div className="table-mui">
            <TableMUI columns={columns} rows={rows} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewBrandPayments;
