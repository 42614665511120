import "./File.scss";
import Form from 'react-bootstrap/Form';
export interface FileProps {
    filename: string;
    file_size: number;
    fileId: any;
    handleSelection: (checked: boolean, fileId: any) => void;
    isChecked: boolean;
    handleFileDownload: (fileId: any) => void;
    fileTypeId: any;
}
const File: React.FunctionComponent<FileProps> = ({
    filename,
    file_size,
    fileId,
    handleSelection,
    isChecked,
    handleFileDownload,
    fileTypeId
}) => {
    const handleChange = (e: any, fileId: any) => {
        handleSelection(e, fileId);
    }
    const handleDownload = (fileId: any) => {
        handleFileDownload(fileId);
    }
    const formatBytes = (bytes: number, decimals = 2) => {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
    return (
        <div className="file-cntr">
            <div className="fhead">
                <div className="img-cntr">
                    {fileTypeId === 1 && 
                    <img
                    className="file-pic"
                    src="../svgs/file-pic.svg"
                    alt="file-pic"
                    />}
                    {fileTypeId === 2 && 
                    <img
                    className="file-pic"
                    src="../svgs/image-icon.svg"
                    alt="icon"
                    />}
                    <Form.Check
                        type="checkbox"
                        label=""
                        checked={isChecked}
                        onChange={(e) => {
                            handleChange(e.target.checked, fileId)
                        }}
                    />
                    <div className="download-wraper">
                        <button 
                        className="download-btn"
                        onClick={() => {
                            handleDownload(fileId)
                        }}
                        >
                            <img
                                src="../svgs/downloadbtn-blue.svg"
                                alt="downloadbtn-blue"
                            />
                        </button>
                        <p>Download</p>
                    </div>
                </div>
            </div>
            <div className="filename">{filename}</div>
            <div className="filesize">{formatBytes(file_size)}</div>
        </div>
    )
}

export default File;