import { Container, Row, Col } from "react-bootstrap";
import "./AegInfoCard.scss";
export interface aegInfoCardProps {
  dashboardCounts: any
}
const AegInfoCard: React.FunctionComponent<aegInfoCardProps> = ({
  dashboardCounts
}) => {
  return (
    <Container fluid className="aeginfocards">
      <Row className="row">
        <Col sm={6} md={4} lg={3} className="col-brands">
          <div className="card-brands">
            <div className="font30-semibold gray-800">{dashboardCounts.brandCount}</div>
            <div className="font14-regular gray-500">Brands</div>
          </div>
        </Col>
        <Col sm={6} md={4} lg={3} className="col-partners">
          <div className="card-partners">
            <div className="font30-semibold gray-800">{dashboardCounts.partnerCount}</div>
            <div className="font14-regular gray-500">Partners</div>
          </div>
        </Col>
        <Col sm={6} md={4} lg={3} className="col-discovery">
          <div className="card-discovery">
            <div className="font30-semibold gray-800">{dashboardCounts.discoveryCount}</div>
            <div className="font14-regular gray-500">Partner Discoveries</div>
          </div>
        </Col>
        <Col sm={6} md={4} lg={3} className="col-reports">
          <div className="card-reports">
            <div className="font30-semibold gray-800">{dashboardCounts.reportCount}</div>
            <div className="font14-regular gray-500">Advisory Reports</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default AegInfoCard;
