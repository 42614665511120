import "./NavBar.scss";
import Dropdown from "react-bootstrap/Dropdown";
import Auth from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import React, { useState } from "react";
import ConfirmModal from "../Modal/ConfirmModal";
export interface NavBarProps {
  userProfile: any;
}

const NavBar: React.FunctionComponent<NavBarProps> = ({ userProfile }) => {
  const navigate = useNavigate();

  const [logoutModal, setLogoutModal] = useState(false);

  const confirmLogOut = (): void => {
    setLogoutModal(true);
  };

  const logout = () => {
    Cookies.remove("userID");
    Auth.signOut().then(() => {
      navigate("/");
    });
    setLogoutModal(false);
  };

  const closeModal = () => {
    setLogoutModal(false);
  };

  return (
    <div className="header-1">
      <div className="icons">
        {/* <Link to="#">
          <img src="../svgs/blue-circle-plus.svg" alt="blue-circle-plus" />
        </Link>
        <Link className="notification-bell" to="#">
          <img
            src="../svgs/notification-bell-icon.svg"
            alt="notification-bell-icon"
          />
        </Link> */}
        {/* <Link to="#">
          <img src="../svgs/greenavatar.svg" alt="greenavatar" />
        </Link> */}
        <Dropdown className="account-dropdown">
          <Dropdown.Toggle id="dropdown-basic">
            <img src="../svgs/greenavatar.svg" alt="greenavatar" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item className="basic-info" eventKey="1">
              <div className="basic-info-wraper">
                <div className="short-name">
                  <span className="font12-regular gray-800 text-uppercase">
                    {userProfile.first_name
                      ? userProfile.first_name.charAt(0)
                      : ""}
                  </span>
                </div>
                <div>
                  <div className="font12-medium gray-800 text-capitalize">
                    {userProfile.first_name} {userProfile.last_name}
                  </div>
                  <div className="font10-regular gray-500">
                    {userProfile.email_id}
                  </div>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item className="usertype" eventKey="2">
              <div className="usertype-wraper">
                <div className="usertype-icon">
                  <img
                    className="font12-regular"
                    src="../svgs/building.svg"
                    alt="user-alternate"
                  />
                </div>
                <div>
                  <div className="font12-regular gray-600">
                    {userProfile?.organization?.company_name
                      ? userProfile.organization.company_name
                      : userProfile?.partner?.company_name
                      ? userProfile?.partner?.company_name
                      : "Asia Ecomm Group"}
                  </div>
                  {userProfile.user_roles && (
                    <div
                      key={userProfile.user_roles[0]?.roles?.id}
                      className="font12-regular gray-500"
                    >
                      {userProfile.user_roles[0]?.roles?.role_name}
                    </div>
                  )}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className="font12-regular error-600 logout"
              eventKey="2"
              onClick={confirmLogOut}
            >
              Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <ConfirmModal
          isModalOpen={logoutModal}
          submit={logout}
          closeModal={closeModal}
          submitBtnText="Yes, Log Out"
          cancelBtnText="No"
          title="Confirm Log Out"
          bodyText="Are you sure you want to Log Out ?"
        />
      </div>
    </div>
  );
};

export default NavBar;
