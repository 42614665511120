import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export interface ConfirmModalProps {
    isModalOpen: boolean;
    submit: () => void;
    closeModal: () => void;
    submitBtnText: string;
    cancelBtnText: string;
    title: string;
    bodyText: string;
}

const ErrorModal: React.FunctionComponent<ConfirmModalProps> = ({
    isModalOpen,
    submit,
    closeModal,
    submitBtnText,
    cancelBtnText,
    title,
    bodyText
}) => {
    const handleClose = () => {
        closeModal();
    };
    const handleSubmit = () => {
        submit();
    }

    return (
        <>
            <Modal
                show={isModalOpen}
                onHide={handleClose}
                className="confirm-modal"
                centered
            >
                <Modal.Header closeButton={false}>
                    <div className="alert-triangle">
                        <img src="../svgs/red-alert-triangle .svg" alt="alert-triangle" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="title-text font20-medium gray-800">{title}</div>
                    <div className="font14-regular gray-500">{bodyText}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="cancelbtn" onClick={handleClose}>
                        {cancelBtnText}
                    </Button>
                    <Button className="submitbtn" onClick={handleSubmit}>
                        {submitBtnText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ErrorModal;