import "./ProductList.scss";
import "../../styles/global.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TableMUI from "../components/TableMUI/TableMUI";
import { productTableColumns } from "../components/TableMUI/TableColumns";
import {
  GetProducts,
  DeleteProduct,
  GetOrgProducts,
  GetProductsFromAssignedBrands,
} from "../../API/endpoints/Products";
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";

const ProductList = () => {
  const navigate = useNavigate();

  const routeToForm = () => {
    navigate("/AddProduct");
  };

  type dataInput = {
    rows?: {
      id: string;
      title: string;
      sku: { sku_title: string }[];
      brand: { brand_name: string };
      status: string;
    }[];
  };

  const [dataCount, setDataCount] = useState(0);
  const [listPage, setListPage] = useState(0);
  const [searchParam, setSearchParam] = useState<string>("");
  const [data, setData] = useState<dataInput>({});
  const [productId, setProductId] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [showDeleteProduct, setShowDeleteProduct] = useState(false);
  const [flag, setFlag] = useState(false);
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [dataRestriction, setDataRestriction] = useState(false);
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [orgID, setOrgID] = useState<string | undefined>("");

  const columns = productTableColumns(
    setProductId,
    setShowDeleteProduct,
    navigate,
    setProductName,
    permissions
  );
  const handleCloseDeleteProduct = () => setShowDeleteProduct(false);
  const deleteFunction = async () => {
    await DeleteProduct(productId);
    setFlag(true);
  };

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setOrgID(response.data?.organization?.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID.toString());
      setDataRestriction(roleDetails?.data?.data_restriction);
      if (roleDetails?.data?.type === "organization") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "9"
        );
        console.log(response);
        setPermissions(response?.data);
      } else if (roleDetails?.data?.type === "administrative") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "3"
        );
        console.log(response);
        setPermissions(response?.data);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "1") {
      setIsSuperAdmin(true);
    }
  }, [permissions]);

  useEffect(() => {
    if (
      permissions[0]?.sub_modules[3]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      if (isSuperAdmin) {
        if (dataRestriction) {
          (async () => {
            const response = await GetProductsFromAssignedBrands(
              userID,
              listPage,
              searchParam
            );
            console.log(response);
            setData({ rows: response?.data });
            setDataCount(response?.data?.length);
          })();
        } else {
          GetProducts(listPage, searchParam)
            .then((response) => {
              console.log(response);
              setData(response?.data);
              setDataCount(response?.data?.count);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (orgID) {
          GetOrgProducts(listPage, searchParam, orgID)
            .then((response) => {
              setData(response?.data);
              setDataCount(response?.data?.count);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
    setFlag(false);
  }, [
    listPage,
    searchParam,
    flag,
    orgID,
    isSuperAdmin,
    permissions,
    userID,
    dataRestriction,
  ]);

  let rows: {
    id: string;
    title: string;
    sku: string;
    brand_name: string;
    status: string;
    files: string;
  }[] = [];

  if (data.rows) {
    rows = data.rows.map((dataItem) => {
      return {
        id: dataItem.id,
        title: dataItem.title,
        sku: dataItem.sku.length !== 0 ? dataItem.sku[0].sku_title : "No SKU",
        brand_name: dataItem?.brand?.brand_name,
        status: dataItem.status,
        files: "Manage Files",
      };
    });
  }

  return (
    <Container fluid className="productlist">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="brand-products" />
        </Col>
        <Col className="productlist-content p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item href="#">PRODUCT MANAGEMENT</Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button className="btn-primary12 addfilter">Add Filter</Button> */}
            {permissions[0]?.sub_modules[3]?.permissions_actions[0]
              ?.permission[0]?.permission &&
              permissions[0]?.sub_modules[0]?.permissions_actions[1]
                ?.permission[0]?.permission === true &&
              permissions[0]?.sub_modules[0]?.permissions_actions[0]
                ?.permission[0]?.permission === true && (
                <Button
                  className="btn-primary12 addproduct"
                  onClick={routeToForm}
                >
                  Add Product
                </Button>
              )}
          </div>
          <div className="producttable">
            <div className="TableMUIcountainer">
              <TableMUI
                columns={columns}
                rows={rows}
                dataCount={dataCount}
                setListPage={setListPage}
                listPage={listPage}
              />
            </div>
            <PopupTemplate
              popupHeader="Confirm Deletion."
              popupBody={`Are you sure you want to delete the product ${productName}?`}
              handleFunction={deleteFunction}
              isModalOpen={showDeleteProduct}
              onCancel={handleCloseDeleteProduct}
              id={productId}
              setFlag={setFlag}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductList;
