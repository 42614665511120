/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./OrgList.scss";
import "../../styles/global.scss";
// @ts-ignore
import Cookies from "js-cookie";

// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { orgTableColumns } from "../components/TableMUI/TableColumns";

// Components
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";

// TableMUI components
import TableMUI from "../components/TableMUI/TableMUI";
import Sidebar from "../components/sidebar/Sidebar";
import {
  GetAllOrg,
  GetOrg,
  DeleteOrganization,
  SoftDeleteOrganization,
  RestoreOrganization,
  GetAllOrgDataRestriction,
} from "../../API/endpoints/Organization";
import { CreateActivity } from "../../API/endpoints/Dashboard";
import Auth from "@aws-amplify/auth";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";

function OrgList() {
  const navigate = useNavigate();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isOrgUser, setIsOrgUser] = useState(false);
  // const [isAdminUser, setIsAdminUser] = useState(false);
  //  const [userRole, setUserRole] = useState<string | undefined>("");
  const routeToForm = (): void => {
    navigate("/addneworganization");
  };
  const [orgName, setOrgName] = useState("");
  const [data, setData] = useState<any>({});
  const [flag, setFlag] = useState(false);
  const [showDeleteOrg, setShowDeleteOrg] = useState(false);
  const [orgID, setOrgID] = useState<string | undefined>("");
  const [dataCount, setDataCount] = useState(0);
  const [listPage, setListPage] = useState(0);
  const [searchParam, setSearchParam] = useState<string>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [dataRestriction, setDataRestriction] = useState(false);
  const [popupType, setPopupType] = useState<string>("");
  const [popupHeader, setPopupHeader] = useState<string>("");
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);

  const handleClose = () => setShowDeleteOrg(false);

  let rows: {
    id: string;
    orgName: string;
    country: string;
    address: string;
    pincode: string;
    regNumber: string;
    website: string;
    is_deleted: boolean;
  }[] = [];

  if (data.rows) {
    rows = data.rows.map(
      (dataItem: {
        id: any;
        company_name: any;
        region: { name: any };
        company_address_line1: string;
        company_address_line2: string;
        company_address_postal_code: any;
        company_registration_number: any;
        website: any;
        is_deleted: boolean;
      }) => {
        return {
          id: dataItem.id,
          orgName: dataItem.company_name,
          country: dataItem.region.name,
          address:
            dataItem.company_address_line1 +
            " " +
            (dataItem.company_address_line2
              ? dataItem.company_address_line2
              : ""),
          pincode: dataItem.company_address_postal_code || "NA",
          regNumber: dataItem.company_registration_number || "NA",
          website: dataItem.website || "NA",
          is_deleted: dataItem.is_deleted,
        };
      }
    );
  } else if (data.id) {
    rows = [
      {
        id: data.id,
        orgName: data.company_name,
        country: data.region.name,
        address: data.company_address_line1 + " " + data.company_address_line2,
        pincode: data.company_address_postal_code,
        regNumber: data.company_registration_number,
        website: data.website,
        is_deleted: data.is_deleted,
      },
    ];
  }

  const columns = orgTableColumns(
    setOrgID,
    setShowDeleteOrg,
    navigate,
    setOrgName,
    setPopupType,
    permissions
  );

  useEffect(() => {
    //  setUserRole(Cookies.get("UserRole"));
    // setOrgID(Cookies.get("OrgId"));
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setOrgID(response.data?.organization?.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      if (roleID) {
        const roleDetails = await GetRoleById(roleID.toString());
        setDataRestriction(roleDetails?.data?.data_restriction);

        if (roleDetails?.data?.type === "organization") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID?.toString(),
            "0"
          );
          console.log(response?.data);
          setPermissions(response?.data);
        } else if (roleDetails?.data?.type === "administrative") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID?.toString(),
            "1"
          );
          console.log(response?.data);
          setPermissions(response?.data);
        }
      }
    })();
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "1") {
      setIsSuperAdmin(true);
    } else if (permissions[0]?.permission_type.toString() === "2") {
      setIsOrgUser(true);
    }
    // if (
    //   userRole === "super_admin" &&
    //   Cookies.get("UserType") === "administrative"
    // ) {
    //   setIsAdminUser(true);
    // }
  }, [permissions]);

  useEffect(() => {
    if (
      permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      if (isSuperAdmin) {
        if (dataRestriction) {
          (async () => {
            const res = await GetAllOrgDataRestriction(
              userID,
              listPage,
              searchParam
            );
            console.log(res);
            setData({ rows: res.data });
            setDataCount(res.data.length);
          })();
        } else {
          (async () => {
            const res = await GetAllOrg(listPage, searchParam);
            //  console.log(res.data);
            setData(res.data);
            setDataCount(res.data.count);
          })();
        }
      } else if (isOrgUser) {
        (async () => {
          const res = await GetOrg(orgID);
          setDataCount(1);
          setData(res.data);
        })();
      }
    }
    setFlag(false);
  }, [flag, isSuperAdmin, isOrgUser, orgID, listPage, searchParam]);

  const deleteFunction = async () => {
    await SoftDeleteOrganization(orgID);
    const activity = await CreateActivity(
      `Organization: ${orgName} deleted temporarily.`
    );
    console.log(activity);
    setFlag(true);
  };

  const restoreFunction = async () => {
    setFlag(false);
    await RestoreOrganization(orgID);
    const activity = await CreateActivity(`Organization: ${orgName} restored.`);
    console.log(activity);
    setFlag(true);
  };

  const premanentDeleteFunction = async () => {
    setFlag(false);
    DeleteOrganization(orgID).then(() => {
      setFlag(true);
    });
    const activity = await CreateActivity(
      `Organization: ${orgName} deleted permanently.`
    );
    console.log(activity);
    setFlag(true);
  };

  // useEffect(() => {
  //   (async () => {
  //     await Auth.currentAuthenticatedUser()
  //       .then((response) => {})
  //       .catch((error) => {
  //         navigate("/");
  //       });
  //   })();

  //   Auth.currentSession()
  //     .then((session) => {
  //       if (session.isValid()) {
  //       } else navigate("/");
  //     })
  //     .catch((err) => {
  //       navigate("/");
  //     });
  //   if (userID) {
  //     (async () => {
  //       const response = await GetUserProfile(userID);
  //       if (response?.data) {
  //         setUserProfile(response?.data);
  //       }
  //     })();
  //   }
  // }, [navigate, userID]);

  useEffect(() => {
    if (popupType === "restore") {
      setPopupHeader("Confirm Restore");
      setPopupMessage(
        "Are you sure you want to restore organization " + orgName + " ?"
      );
    } else if (popupType === "delete") {
      setPopupHeader("Confirm Deletion");
      setPopupMessage(
        "Are you sure you want to delete organization " + orgName + "?"
      );
    } else if (popupType === "delete-permanently") {
      setPopupHeader("Confirm Deletion");
      setPopupMessage(
        "Are you sure you want to permanently delete organization " +
          orgName +
          "?"
      );
    }
  }, [popupType, orgID, orgName]);

  return (
    <Container fluid className="adminusers">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="brand-org" />
        </Col>
        <Col className="adminusers-content p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item>BRAND MANAGEMENT </Breadcrumb.Item>
            <Breadcrumb.Item active>ORGANIZATIONS</Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button size="sm" className="btn-primary12 addfilter">
              Add Filter
            </Button> */}
            {permissions[0]?.sub_modules[2]?.permissions_actions[0]
              ?.permission[0]?.permission === true &&
              permissions[0]?.sub_modules[0]?.permissions_actions[0]
                ?.permission[0]?.permission === true &&
              permissions[0]?.sub_modules[0]?.permissions_actions[1]
                ?.permission[0]?.permission === true && (
                <div className="adduserBtn">
                  <Button
                    onClick={routeToForm}
                    className="btn-primary12 adduser"
                  >
                    Add New Organization
                  </Button>
                </div>
              )}
          </div>
          <div className="adminusertable">
            <div className="TableMUIcountainer">
              <TableMUI
                columns={columns}
                rows={rows}
                dataCount={dataCount}
                setListPage={setListPage}
                listPage={listPage}
              />
            </div>

            <PopupTemplate
              popupHeader={popupHeader}
              popupBody={popupMessage}
              handleFunction={
                popupType === "restore"
                  ? restoreFunction
                  : popupType === "delete"
                  ? deleteFunction
                  : premanentDeleteFunction
              }
              isModalOpen={showDeleteOrg}
              onCancel={handleClose}
              id={orgID}
              setFlag={setFlag}
              isRestore={popupType === "restore" ? true : false}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default OrgList;
