import "./BrandDashboard.scss";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/header/NavBar";
import TodoList from "../components/list/TodoList";
import CarouselDashboard from "../components/carousel/CarouselDashboard";
import Resource from "../components/resources/Resource";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { GetUserProfile } from "../../API/endpoints/User";

const BrandDashboard = () => {
  const navigate = useNavigate();
  const carouselData = [
    {
      text1: "Get Access to",
      text2: "Market Awareness",
      text3: "backed with margin analysis",
      link: "/advisorymanagement/brand",
    },
    {
      text1: "Get Access to",
      text2: "Market Readiness",
      text3: "backed with margin analysis",
      link: "/advisorymanagement/brand",
    },
    {
      text1: "Get Access to",
      text2: "Market Expansion",
      text3: "backed with margin analysis",
      link: "/advisorymanagement/brand",
    },
  ];

  const [userType, setUserType] = useState<string | undefined>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();

    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });

    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
          setUserType(response?.data?.user_type);
        }
      })();
    }
  }, [navigate, userID]);

  useEffect(() => {
    if (userType === "partner") {
      navigate("/PartnerDashboard");
    } else if (userType === "administrative") {
      navigate("/AdminDashboard");
    }
  }, [userType, navigate]);

  return (
    <Container fluid className="brand-dashboard">
      <Row>
        <Col className="sidebar">
          <Sidebar active="dashboard" />
        </Col>
        <Col className="p-0 col-right">
          <Navbar userProfile={userProfile} />
          <Container fluid className="container-content">
            <Row className="row-content">
              <Col md={6} className="col-todolist">
                <div className="todo-header">To-Do List</div>
                <div className="todo">
                  <TodoList userType="brand" userProfile={userProfile} />
                </div>
              </Col>
              <Col md={6} className="col-crslrsrc">
                <Container fluid className="crslrsrc-wraper">
                  <Row>
                    <Col xs={12} className="col-carousel">
                      <div className="carsl">
                        <CarouselDashboard carouselData={carouselData} />
                      </div>
                    </Col>
                    <Col xs={12} className="col-resource">
                      <div className="font16-medium gray-800 rsc-title">
                        Resources
                      </div>
                      <div className="resource">
                        <Resource />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default BrandDashboard;
