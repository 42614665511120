import "./style.scss";
import Alert from 'react-bootstrap/Alert';
export interface alertProps {
    headingText: string;
    bodyText: string;
    dismissible: boolean;
    isAlertOpen: boolean;
    onCancel: () => void;
}
const WarningAlert: React.FunctionComponent<alertProps> = ({
    headingText,
    bodyText,
    dismissible,
    isAlertOpen,
    onCancel
}) => {

    const handleClose = () => {
        onCancel();
    }
    return (
        <Alert
            dismissible={dismissible}
            className='AlertMessage warning'
            show={isAlertOpen}
            onClose={handleClose}
        >
            <Alert.Heading>
                <div>
                    <img
                        src="../svgs/alert-triangle.svg"
                        alt="alert-triangle"
                        width={20}
                        height={20}
                        className="icon-img"
                    />

                    <span
                        className="font14-medium gray-800"
                    >
                        {headingText}
                    </span>
                    {dismissible && <img
                        className="close-btn"
                        src="../svgs/close-btn.svg"
                        alt="close-btn"
                        onClick={handleClose}
                    />}
                </div>
            </Alert.Heading>
            <p
                className="font13-regular gray-600"
            >
                {bodyText}
            </p>
        </Alert>
    )
}

export default WarningAlert;