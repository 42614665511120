import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../components/sidebar/Sidebar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import "./AdvisoryReportList.scss";
import AddAdvisoryReport from "./AddAdvisoryReport";
import { GetAllReports, DeleteReport } from "../../API/endpoints/Advisory";
import TableMUI from "../components/TableMUI/TableMUI";
import { AdvisoryReportTableColumns } from "../components/TableMUI/TableColumns";
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import Navbar from "../components/header/NavBar";
import Auth from "@aws-amplify/auth";
import { GetUserProfile } from "../../API/endpoints/User";
import { GetPermissionsByRoleAndModuleID } from "../../API/endpoints/RolesAndPermissions/Roles";

const AdvisoryReportList = () => {
  const navigate = useNavigate();
  type dataInput = {
    data?: {
      id: Number;
      description: string;
      report_category: { category_name: string };
      report_name: string;
      report_price: Number;
      reports_category_id: Number;
      url: string;
    }[];
  };

  const [userID, setUserID] = useState<string | undefined>("");
  const [show, setShow] = useState(false);
  const [reportList, setReportList] = useState<dataInput>({});
  const [reportId, setReportId] = useState("");
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [flag, setFlag] = useState(false);
  const [userProfile, setUserProfile] = useState<any>({});
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setReportId("");
  };

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const response = await GetPermissionsByRoleAndModuleID(
        roleID.toString(),
        "7"
      );
      console.log(response);
      setPermissions(response?.data);
    })();
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "2") {
      navigate("/OrganizationManagement");
    } else if (permissions[0]?.permission_type.toString() === "3") {
      navigate("/PartnerManagement");
    }
  }, [permissions, navigate]);

  useEffect(() => {
    (async () => {
      const response = await GetAllReports();
      setReportList(response);
    })();
    setFlag(false);
  }, [flag]);
  const columns = AdvisoryReportTableColumns(
    setReportId,
    setShow,
    setShowDeleteReport,
    permissions
  );
  let rows: {
    id: Number;
    description: string;
    category_name: string;
    report_name: string;
    report_price: Number;
    reports_category_id: Number;
    url: string;
  }[] = [];
  if (
    reportList?.data &&
    permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    rows = reportList?.data?.map((dataItem) => {
      return {
        id: dataItem?.id,
        description: dataItem?.description ? dataItem?.description : "NA",
        category_name: dataItem?.report_category?.category_name,
        report_name: dataItem?.report_name,
        report_price: dataItem?.report_price,
        reports_category_id: dataItem?.reports_category_id,
        url: dataItem?.url,
      };
    });
  }

  const deleteFunction = async () => {
    await DeleteReport(reportId);
    setReportId("");
    setFlag(true);
  };
  const handleCloseDeleteReport = () => setShowDeleteReport(false);

  return (
    <Container fluid className="advisoryreportlist">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="report-content p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item href="/SettingsList">Settings</Breadcrumb.Item>
            <Breadcrumb.Item href="/ReportSettings" active>
              Reports
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
              />
            </InputGroup>
            {permissions[0]?.sub_modules[1]?.permissions_actions[0]
              ?.permission[0]?.permission === true && (
              <Button onClick={handleShow} className="btn-primary12 add-btn">
                Add Report
              </Button>
            )}
          </div>
          <AddAdvisoryReport
            isModalOpen={show}
            onCancel={handleClose}
            reportId={reportId}
            setFlag={setFlag}
          />
          <div className="advisoryreporttable">
            <TableMUI columns={columns} rows={rows} />
            <PopupTemplate
              popupHeader="Confirm Deletion."
              popupBody={`Are you sure you want to delete Report`}
              handleFunction={deleteFunction}
              isModalOpen={showDeleteReport}
              onCancel={handleCloseDeleteReport}
              id={reportId}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AdvisoryReportList;
