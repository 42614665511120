import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "../../../styles/modals/modal400.scss";
import "./PopupTemplate.scss";

interface DeletePopupProps {
  popupHeader: string;
  popupBody: string;
  isModalOpen: boolean;
  onCancel: Function;
  handleFunction: Function;
  id: string | string[] | undefined;
  setFlag?: Function;
  isRestore?: boolean;
}

const DeletePopup: React.FunctionComponent<DeletePopupProps> = (props) => {
  const handleClose = () => {
    props.onCancel();
  };

  const handleDelete = () => {
    console.log(props.id);
    props.handleFunction(props.id);
    props.onCancel();
  };

  return (
    <>
      <Modal
        show={props.isModalOpen}
        centered
        backdrop="static"
        onHide={handleClose}
        className={props.isRestore ? "modal400" : "modal400 delete-modal"}
      >
        <Modal.Header closeButton={false}>
          <div className="alert-icon delete-icon">
            <img src="../svgs/alert-circle-error500.svg" alt="delete-icon" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="title-text font20-medium gray-800">
              {props.popupHeader}
            </div>
            <div className="font14-regular gray-500">{props.popupBody}</div>
            <div className="footer-buttons">
              <Button
                variant="secondary"
                onClick={handleClose}
                className="cancelbtn"
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={handleDelete}
                className="submitbtn delete-btn"
              >
                {props.isRestore ? "Restore" : "Delete"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DeletePopup;
