/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-1",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:a2d2d25c-341e-4cc6-b2e0-e21201a6737c",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_86znClacl",
  aws_user_pools_web_client_id: "1r28lddrav350sefehrj9b3au7",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
