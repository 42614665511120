import { useState, useEffect } from "react";
import {
  ReportCategoriesDTO,
  AdvisoryReportInputDTO,
} from "../dtos/advisory.dtos";
import {
  GetReportCategories,
  AddReportWithCategory,
  AddReport,
  UpdateReport,
  GetReport,
} from "../../API/endpoints/Advisory";
import AddReportModal from "../components/modalForms/addReport/AddReportModal";

export interface addReportProps {
  isModalOpen: boolean;
  onCancel: () => void;
  reportId: string;
  setFlag: (val: boolean) => void;
}

const AddAdvisoryReport: React.FunctionComponent<addReportProps> = ({
  isModalOpen,
  onCancel,
  reportId,
  setFlag,
}) => {
  const [categories, setCategories] = useState<ReportCategoriesDTO[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [editData, setEditData] = useState<AdvisoryReportInputDTO>();
  useEffect(() => {
    (async () => {
      if (isModalOpen === true) {
        const response = await GetReportCategories();
        const categories_list = response.data.filter(
          (cat: { reports: any }) => cat.reports[0]
        );
        setCategories(categories_list);
      }
    })();
    if (reportId) {
      (async () => {
        const response = await GetReport(reportId);
        setEditData(response.data);
      })();
    }
  }, [isModalOpen, reportId]);
  const onSubmit = async (data: AdvisoryReportInputDTO) => {
    if (reportId) {
      setSubmitting(true);
      const response = await UpdateReport(reportId, data);
      if (response?.msg === "Report edited successfully") {
        onCancel();
        setEditData({
          id: 0,
          category_name: "",
          reports_category_id: 0,
          report_name: "",
          report_price: "",
          description: "",
          url: "",
        });
        setFlag(true);
      }
      setSubmitting(false);
    } else {
      if (data.reports_category_id) {
        setSubmitting(true);
        const response = await AddReport(data);
        if (response?.msg === "Report added successfully") {
          onCancel();
          setFlag(true);
        }
        setSubmitting(false);
      } else {
        setSubmitting(true);
        const response = await AddReportWithCategory(data);
        if (response?.msg === "Report added successfully") {
          onCancel();
          setFlag(true);
        }
        setSubmitting(false);
      }
    }
  };
  return (
    <div>
      <AddReportModal
        isModalOpen={isModalOpen}
        onCancel={onCancel}
        categories={categories}
        onSubmit={onSubmit}
        submitting={submitting}
        defaultData={editData}
        setEditData={setEditData}
        reportId={reportId}
      />
    </div>
  );
};

export default AddAdvisoryReport;
