import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Accordion from "react-bootstrap/Accordion";
import Sidebar from "../components/sidebar/Sidebar";
import "./SettingsList.scss";
import Auth from "@aws-amplify/auth";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import { GetPermissionsByRoleAndModuleID } from "../../API/endpoints/RolesAndPermissions/Roles";

const SettingsList = () => {
  const navigate = useNavigate();
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const response = await GetPermissionsByRoleAndModuleID(
        roleID.toString(),
        "7"
      );
      console.log(response);
      setPermissions(response?.data);
    })();
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "2") {
      navigate("/OrganizationManagement");
    } else if (permissions[0]?.permission_type.toString() === "3") {
      navigate("/PartnerManagement");
    }
  }, [permissions, navigate]);

  return (
    <Container fluid className="settingslist">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="settings-content p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item href="#" active>
              Settings
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#"></Breadcrumb.Item>
          </Breadcrumb>
          {(permissions[0]?.sub_modules[0]?.permissions_actions[1]
            ?.permission[0]?.permission === true ||
            permissions[0]?.sub_modules[3]?.permissions_actions[1]
              ?.permission[0]?.permission === true) && (
            <Accordion defaultActiveKey="0" className="usermanage-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>User Management</Accordion.Header>
                <Accordion.Body>
                  <Container fluid>
                    <Row>
                      {permissions[0]?.sub_modules[0]?.permissions_actions[1]
                        ?.permission[0]?.permission === true && (
                        <Col lg={2} className="d-flex">
                          <img
                            src="../svgs/usermanagement.svg"
                            alt="usermanagement"
                          />
                          <Link
                            className="font14-regular primary-blue"
                            to="/AdminUsersList"
                          >
                            Manage Users
                          </Link>
                        </Col>
                      )}
                      {permissions[0]?.sub_modules[3]?.permissions_actions[1]
                        ?.permission[0]?.permission === true && (
                        <Col lg={3} className="d-flex">
                          <img
                            src="../svgs/roles-permissions.svg"
                            alt="roles-permissions"
                          />
                          <Link
                            className="font14-regular primary-blue"
                            to="/RolesList"
                          >
                            Manage Roles & Permissions
                          </Link>
                        </Col>
                      )}
                    </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          {permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
            ?.permission === true && (
            <Accordion defaultActiveKey="0" className="advisory-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Advisory Settings</Accordion.Header>
                <Accordion.Body>
                  <Container fluid>
                    <Row>
                      <Col className="d-flex">
                        <img
                          src="../svgs/AdvisoryManagement.svg"
                          alt="Actions"
                        />
                        <Link
                          className="font14-regular primary-blue"
                          to="/AdvisoryReportList"
                        >
                          Manage Advisory Reports
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          {/* <Accordion defaultActiveKey="0" className="document-accordion">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Document Settings</Accordion.Header>
                            <Accordion.Body>
                                <Container fluid>
                                    <Row>
                                        <Col className="d-flex">
                                            <img src="../svgs/ManageDocuments.svg" alt="Actions" />
                                            <Link className="font14-regular primary-blue" to="/documentsettings">Manage Documents</Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion> */}

          {permissions[0]?.sub_modules[4]?.permissions_actions[1]?.permission[0]
            ?.permission === true && (
            <Accordion defaultActiveKey="0" className="document-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>File Settings</Accordion.Header>
                <Accordion.Body>
                  <Container fluid>
                    <Row>
                      <Col className="d-flex">
                        <img src="../svgs/ManageDocuments.svg" alt="Actions" />
                        <Link
                          className="font14-regular primary-blue"
                          to="/FileList"
                        >
                          Manage Files
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          {permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
            ?.permission === true && (
            <Accordion defaultActiveKey="0" className="platform-accordion">
              <Accordion.Item eventKey="0" className="mb-5">
                <Accordion.Header>Platform Settings</Accordion.Header>
                <Accordion.Body>
                  <Container fluid>
                    <Row>
                      <Col className="d-flex">
                        <img src="../svgs/Flag.svg" alt="Actions" />
                        <Link
                          className="font14-regular primary-blue"
                          to="/CountriesList"
                        >
                          Countries
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SettingsList;
