import { Container, Row, Col, Button } from "react-bootstrap";
import Auth from "@aws-amplify/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./PartnerAgreement.scss";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import {
  AcceptTermsAndConditions,
  GetPartner,
} from "../../API/endpoints/Partner";
import { CreateActivity } from "../../API/endpoints/Dashboard";

const PartnerKeyTerms = () => {
  const navigate = useNavigate();
  const [userID, setUserID] = useState<string | undefined>("");
  const [partnerID, setPartnerID] = useState<string | undefined>("");
  const [partnerName, setPartnerName] = useState<string>("");
  const [userProfile, setUserProfile] = useState<any>({});

  useEffect(() => {
    setUserID(Cookies.get("userID"));
    const isActivated = Cookies.get("IsActivated");
    if (isActivated === "true") {
      navigate("/PartnerDashboard");
    }
  }, [navigate]);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          console.log(response?.data);
          setUserProfile(response?.data);
          setPartnerID(response?.data?.partner_id);
          const partnerDetails = await GetPartner(response?.data?.partner_id);
          console.log(partnerDetails);
          setPartnerName(partnerDetails?.data?.company_name);
        }
      })();
    }
  }, [userID, navigate]);

  const onAgreementSubmit = async () => {
    try {
      await AcceptTermsAndConditions(partnerID);
      await CreateActivity(
        `Partner ${partnerName} accepted the agreement and is active.`
      );
      Cookies.set("IsActivated", "true");
      navigate("/PartnerDashboard");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navbar userProfile={userProfile} />
      <div className="OuterDiv">
        <p className="pageTitle"> Key Platform Terms</p>
        <p className="heading">Non-Circumvention</p>
        <p>
          The Partner is not permitted to enter into any business relationship
          with Brands listed on AEG's platform. The MoU prohibits the Partner
          from circumventing the MoU, directly or indirectly, such as by
          contacting any Brands without AEG's knowledge and approval, or
          entering into any agreements with Brands without AEG's approval, or
          undertaking any activities with any Brands without AEG's approval.
        </p>
        <p className="heading">Breach of Non-Circumvention</p>
        <p>
          In the event that the Partner circumvents the MoU, the Partner will be
          responsible for paying an amount equal to 10% of all revenue generated
          by the brand, 2 times the damages caused to AEG, and/or an amount
          equal to SGD 15,000 AEG may also terminate the MoU and all brand
          service agreements with the Partner
        </p>
        <p className="agreementFooter">
          By accepting this, I/We agree to the above terms of the platform.
        </p>
      </div>

      <Container fluid className="agreementAccept">
        <Row className="justify-content-end mb-3 mt-4">
          <Col className="d-flex justify-content-end">
            {/* <Link to="/PartnerManagement" className="m-1">
              <Button variant="secondary" className="btn-secondary12">
                Cancel
              </Button>
            </Link> */}

            <Button
              variant="primary"
              className="btn-primary12 m-1"
              onClick={onAgreementSubmit}
            >
              Accept
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PartnerKeyTerms;
