import { getWith, patchWith, postWith } from "../axios";

export async function GetOrgBrands(orgID: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/get-org-brands/${orgID}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetBrandMarketPreference(
  brandID: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/brand-preferences/${brandID}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreatePartnerRequest(data: {
  brand_id: any;
  engagement_model_id: any;
  country_id: number;
  status: string;
  services: any;
}) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/discovery/create-brand-request/`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetBrandRequests(
  brandID: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/discovery/fetch-brand-request/${brandID}`;

    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetBrandRequestsForOrg(brands: { brand_ids: any[] }) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/discovery/fetch-request-for-org`;
    return (await postWith<any, any>(url, brands)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

// export async function GetAllBrandRequests() {
//   try {
//     const url = `${process.env.REACT_APP_API_URL}/advisory/all-report-request/1`;
//     return (await getWith<any[]>(url)) as any;
//   } catch (ex: any) {
//     throw ex.response.data;
//   }
// }

export async function GetAllBrandRequests(
  listpage?: any,
  searchParam?: string
) {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/discovery/fetch-all-brand-requests/${
      listpage ? listpage + 1 : 1
    }/${searchParam}`;
    console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetBrandRequestsDataRestriction(
  user_id: string | undefined,
  listpage?: any,
  searchParam?: string
) {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/role/discovery-req-data-restriction/${user_id}/${
      listpage ? listpage + 1 : 1
    }/${searchParam ? searchParam : ""}`;
    console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetBrandDocuments(
  brandID: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/document/1/${brandID}`;
    // console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetReqForPartner(
  servicesProvided: { service_id: number; country_id: number }[],
  partnerID: string | undefined,
  listPage?: number,
  searchParam?: string
) {
  try {
    console.log(servicesProvided, listPage, searchParam);
    const url = `${
      process.env.REACT_APP_API_URL
    }/discovery/fetch-request-for-partner/${listPage ? listPage + 1 : 1}/${
      searchParam ? searchParam : ""
    }`;
    const param: any = { services: servicesProvided, partner_id: partnerID };
    console.log(url, param);
    return (await postWith<any, any>(url, param)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function ExpressPartnerInterest(data: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/discovery/create-partner-interest`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetInterestForBrand(id: string | undefined) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/discovery/fetch-interest-for-brand/${id}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllServices() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-services`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function AcceptPartnerInterest(data: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/discovery/accept-partner-interest`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function RecommendPartnerInterest(id: string, data: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/discovery/update-partner-recommendation/${id}`;
    return (await patchWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetSuitablePartners(data: any, listpage?: string) {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/discovery/fetch-suitable-partners/${listpage ? listpage : 1}`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
