import "../../../styles/pagelayout.scss";
import "../../../styles/global.scss";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Sidebar from "../../components/sidebar/Sidebar";
import { Container, Row, Col } from "react-bootstrap";
import ToastMessage from "../../components/toasts/ToastMessage";
import Auth from "@aws-amplify/auth";
import { GetUserProfile } from "../../../API/endpoints/User";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
  GetRolesWithDataRestriction,
  GetUsersByRole,
} from "../../../API/endpoints/RolesAndPermissions/Roles";
import {
  AssignTeam,
  GetAssignedUsers,
  DeleteAssignedTeam,
} from "../../../API/endpoints/RolesAndPermissions/AssignTeam";
import { GetMarketPresenceCountries } from "../../../API/endpoints/Common";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/header/NavBar";
import AssignUser from "../../components/assignUser/AssignUser";
import PopupTemplate from "../../components/PopupTemplate/PopupTemplate";

function AssignBrandTeam() {
  const navigate = useNavigate();
  const params = useParams();
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [roles, setRoles] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [marketPresence, setMarketPresence] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const [flag, setFlag] = useState(false);
  const [users, setUsers] = useState([]);
  const [marketValiate, setMarketValiate] = useState("");
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID.toString());

      if (roleDetails?.data?.type === "organization") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "8"
        );
        console.log(response);
        setPermissions(response?.data);
      } else if (roleDetails?.data?.type === "administrative") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "2"
        );
        console.log(response);
        setPermissions(response?.data);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    (async () => {
      try {
        const response = await GetRolesWithDataRestriction("administrative");
        setRoles(response.data);
      } catch (error) {}
      try {
        const response = await GetMarketPresenceCountries();
        setMarketPresence(response.data);
      } catch (error) {}
    })();
  }, [params]);

  useEffect(() => {
    if (marketPresence.length !== 0) {
      (async () => {
        try {
          const response = await GetAssignedUsers("brand", params.brandid);
          setUsers(response.data);
        } catch (error) {}
      })();
      setFlag(false);
    }
  }, [params, marketPresence, flag]);

  const handleRoleChange = async (roleid: any) => {
    try {
      const response = await GetUsersByRole(roleid);
      setTeamMembers(response.data);
    } catch (error) {}
  };

  const handleMarketChange = (markets: any) => {
    let idList = markets.map((market: any) => market.id.toString());
    setSelectedMarkets(idList);
  };

  const handleRemove = (markets: any) => {
    let idList = markets.map((market: any) => market.id.toString());
    setSelectedMarkets(idList);
  };

  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });

  const goBack = () => {
    navigate("/BrandManagement");
  };

  const onSubmit = async (data: any) => {
    let formData = {
      user_id: data.user_id,
      org_type: "brand",
      countries: selectedMarkets,
    };
    if (selectedMarkets.length === 0) {
      setMarketValiate("* This field is required");
      return;
    }
    try {
      const response = await AssignTeam(formData, params.brandid);
      if (response.msg === "User assigned to Brand successfully.") {
        setNotification({ type: "success", msg: response.msg });
        setMarketValiate("");
        setFlag(true);
        setSelectedMarkets([]);
      } else {
        setNotification({ type: "warning", msg: response.msg });
      }
    } catch (ex: any) {
      setNotification({ type: "error", msg: ex.msg });
    }
  };

  const deleteAssignedUser = (id: number) => {
    setDeleteId(id);
    setShowDeleteUser(true);
  };

  const handleCloseDeleteUser = () => {
    setShowDeleteUser(false);
  };

  const deleteFunction = async () => {
    try {
      const response = await DeleteAssignedTeam(deleteId);
      if (response.msg === "Assigned user removed successfully.") {
        setNotification({ type: "success", msg: response.msg });
        setFlag(true);
        setDeleteId(0);
      }
    } catch (error) {
      setNotification({ type: "error", msg: "Delete failed" });
    }
  };

  const breadcrumbData = [
    {
      name: "Brand management",
      url: "/BrandManagement",
      active: false,
    },
    {
      name: "brands",
      url: "/BrandManagement",
      active: false,
    },
    {
      name: "Assign Team",
      url: `/assignbrandteam/${params.brandid}`,
      active: true,
    },
  ];

  return (
    <Container fluid className="pge-layout">
      <Row>
        <Col className="sidebar">
          <Sidebar active="brand" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <AssignUser
            breadcrumbData={breadcrumbData}
            roles={roles}
            teamMembers={teamMembers}
            marketPresence={marketPresence}
            users={users}
            marketValiate={marketValiate}
            roleChange={handleRoleChange}
            MarketChange={handleMarketChange}
            handleMarketRemove={handleRemove}
            onSubmit={onSubmit}
            deleteAssignedUser={deleteAssignedUser}
            flag={flag}
            BackNavigation={goBack}
            permissions={permissions}
          />
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
          <PopupTemplate
            popupHeader="Confirm Deletion."
            popupBody={`Are you sure you want to delete?`}
            handleFunction={deleteFunction}
            isModalOpen={showDeleteUser}
            onCancel={handleCloseDeleteUser}
            id=""
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AssignBrandTeam;
