import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { SocialLinksDTO } from "../../dtos/brand.dtos";

export interface BrandSocialMediaFormProps {
  brandWebsite: string;
  socialName: string;
  socialFollowers: string;
  socialLink: string;
  socialLinks: SocialLinksDTO[];
  setSocialName: (name: string) => void;
  setSocialFollowers: (followers: string) => void;
  setSocialLink: (link: string) => void;
  setBrandWebsite: (link: string) => void;
  addSocialMedia: () => void;
  deleteSocialLink: (url: string) => void;
}

const BrandSocialMediaForm: React.FunctionComponent<
  BrandSocialMediaFormProps
> = ({
  brandWebsite,
  socialName,
  socialFollowers,
  socialLink,
  socialLinks,
  setSocialName,
  setSocialFollowers,
  setSocialLink,
  setBrandWebsite,
  addSocialMedia,
  deleteSocialLink,
}) => {
  return (
    <Form onSubmit={addSocialMedia}>
      <Row>
        <Col sm={4} className="p-0">
          <Form.Group className="formgroup" controlId="formBrandName">
            <Form.Label>Brand Website</Form.Label>
            <Form.Control
              type="text"
              placeholder="https://www.example.com"
              defaultValue={brandWebsite}
              onChange={(e) => {
                setBrandWebsite(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col xs={12} className="borderbottom mb-3 p-0">
          <div className="font14-medium primary-blue">
            {" "}
            SOCIAL MEDIA PROFILES{" "}
          </div>
        </Col>
        <Col md={4} lg={3} xl={2} className="p-0">
          <Form.Group
            className="formgroup mr12px"
            controlId="formSocialMediaName"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSocialName(e.target.value);
            }}
          >
            <Form.Label>Select Social Media</Form.Label>
            <Form.Select defaultValue={socialName}>
              <option value={""}>Select social media</option>
              <option>Facebook</option>
              <option>LinkedIn</option>
              <option>Twitter</option>
              <option>Instagram</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} lg={3} xl={2} className="p-0">
          <Form.Group
            className="formgroup mr12px"
            controlId="formFollowerCount"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSocialFollowers(e.target.value);
            }}
          >
            <Form.Label>Number Of Followers</Form.Label>
            <Form.Control
              type="text"
              disabled={!socialName}
              value={socialFollowers}
              placeholder="enter number of followers"
            />
          </Form.Group>
        </Col>
        <Col md={3} lg={5} xl={7} className="p-0">
          <Form.Group
            className="formgroup mr12px"
            controlId="formSocialLink"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSocialLink(e.target.value);
            }}
          >
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              value={socialLink}
              disabled={!socialName}
              placeholder="https://www.example.com"
            />
          </Form.Group>
        </Col>
        <Col md={1} lg={1} xl={1} className="align-self-end p-0">
          <Button
            variant="primary"
            className="btnblue40 w-100"
            disabled={!socialLink}
            onClick={addSocialMedia}
          >
            ADD
          </Button>
        </Col>
        {socialLinks[0] &&
          socialLinks.map((social) => {
            return (
              <>
                <Row className="display-result mr-top">
                  <Col md={3} lg={2} className="p-0">
                    <div className="input-display mr12px">
                      {social.platform}
                    </div>
                  </Col>
                  <Col md={3} lg={2} className="p-0">
                    <div className="input-display mr12px">
                      {social.number_of_followers}
                    </div>
                  </Col>
                  <Col md={3} lg={7} className="p-0">
                    <div className="input-display">
                      <a
                        href={`//${social.url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {social.url}
                      </a>
                    </div>
                  </Col>
                  <Col md={1} lg={1} className="pt-1 pl-2 pb-1">
                    <img
                      src="../svgs/trash.svg"
                      alt="deleteicon"
                      onClick={() => {
                        deleteSocialLink(social.url);
                      }}
                    />
                  </Col>
                </Row>
              </>
            );
          })}
      </Row>
    </Form>
  );
};

export default BrandSocialMediaForm;
