import "../login/login.scss";
import { Auth } from "aws-amplify";
import ForgotPasswordForm from "../components/forms/forgotpassword/forgotPasswordForm";
import SuccessModal from "../components/Modal/SuccessModal";
import ErrorModal from "../components/Modal/ErrorModal";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isLinkExpired, setLinkExpired] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const handleSetPassword = (data: any) => {
    console.log("code submitted");
    Auth.forgotPasswordSubmit(data.email, data.code, data.password)
      .then((data) => {
        console.log(data);
        setIsLinkSent(true);
        setTimeout(() => {
          navigate("/");
        }, 2500);
      })
      .catch((err) => {
        console.log(err);
        setInvalidCode(true);
      });
  };
  const handleCloseSuccessModal = () => setIsLinkSent(false);
  const handleLinkExpired = () => {
    navigate("/");
  };
  const handleCloseLinkExpired = () => setLinkExpired(false);

  const handleLinkAlreadyUsed = () => {
    navigate("/");
  };
  const handleCloseLinkAlreadyUsed = () => setInvalidCode(false);

  console.log(location);

  return (
    <Container fluid className="login-container">
      <Row>
        <Col className="left-content">
          <Container fluid>
            <Row>
              <Col xs={12} className="col-1">
                <img src="../svgs/aeglogo.svg" alt="aeglogo.svg" />
              </Col>
              <Col xs={12} className="col-2">
                <div className="unlockyour">
                  Unlock <span>your</span>
                </div>
                <div className="growth-journey">Asian Growth Journey</div>
                <div className="font16-medium gray-200 text-signup">
                  Sign up for access invite :
                </div>
                <Button
                  className="signup-btn btn-mr"
                  onClick={() => {
                    window.open(
                      `https://form.typeform.com/to/AIb1wwAG?typeform-source=www.google.com`,
                      "_blank"
                    );
                  }}
                >
                  Brands
                </Button>
                <Button
                  className="signup-btn"
                  onClick={() => {
                    window.open(
                      `https://form.typeform.com/to/nZhIEs2M?typeform-source=www.google.com`,
                      "_blank"
                    );
                  }}
                >
                  Partners
                </Button>
                <div className="learnmore">
                  <span className="font14-regular gray-200">
                    Learn more at :{" "}
                  </span>
                  <a
                    className="font14-regular gray-200"
                    href="https://www.asiaecommgroup.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.asiaecommgroup.com
                  </a>
                </div>
              </Col>
              <Col xs={12} className="col-3">
                <div>
                  <div className="font14-medium white">Connect with us :</div>
                  <img
                    src="../svgs/facebook.svg"
                    alt="facebook"
                    onClick={() => {
                      window.open(
                        `https://www.facebook.com/asiaecommgroup`,
                        "_blank"
                      );
                    }}
                  />
                  <img
                    src="../svgs/instagram.svg"
                    alt="instagram"
                    onClick={() => {
                      window.open(
                        `https://www.instagram.com/asiaecommgroup/?hl=en`,
                        "_blank"
                      );
                    }}
                  />
                  <img
                    src="../svgs/linkedin.svg"
                    alt="linkedin"
                    onClick={() => {
                      window.open(
                        `https://www.linkedin.com/company/asiaecommgroup/`,
                        "_blank"
                      );
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col className="right-content">
          <ForgotPasswordForm
            onSubmit={handleSetPassword}
            email={location?.state?.email ? location.state.email : ""}
          />
          <SuccessModal
            isModalOpen={isLinkSent}
            handleCloseModal={handleCloseSuccessModal}
            btn={false}
            link="/"
            linkText="click here."
            title="New Password has been set"
            bodyText="This page will automatically redirect to login page. 
                If not automatically redirected, "
          />
          {isLinkExpired && (
            <ErrorModal
              isModalOpen={isLinkExpired}
              submit={handleLinkExpired}
              closeModal={handleCloseLinkExpired}
              submitBtnText="Back to Login"
              cancelBtnText="Cancel"
              title="Oops !! Link Expired"
              bodyText="The link you are trying to access has expired.
                    To reset your password, return to the
                    login page and select Forgot Password
                    to receive a new password reset link."
            />
          )}
          {invalidCode && (
            <ErrorModal
              isModalOpen={invalidCode}
              submit={handleLinkAlreadyUsed}
              closeModal={handleCloseLinkAlreadyUsed}
              submitBtnText="Back to Login"
              cancelBtnText="Cancel"
              title="Oops !! Invalid Code Entered"
              bodyText="The code that you entered is wrong. To reset your password, 
                    return to the login page and select
                    Forgot Password to receive a new password 
                    reset code."
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
