import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {
  CreatePartner,
  GetPartner,
  UpdatePartner,
} from "../../../API/endpoints/Partner";
import { partnerDataDTO, partnerInfoSubmitDTO } from "../../dtos/partner.dtos";
import { CreateActivity } from "../../../API/endpoints/Dashboard";
import { Multiselect } from "multiselect-react-dropdown";

function PartnerInfoForm(props: {
  setPartnerID: (arg0: any) => void;
  setDefaultTab: (arg0: string) => void;
  countries: any[];
  years: any[];
  handleNotification: (type: string, msg: string) => void;
  categories: any[];
  permissions: any;
}) {
  const params = useParams();
  const [partnerDetails, setPartnerDetails] = useState<partnerDataDTO>();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);
  const [categoryMessage, setCategoryMessage] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      company_name: "",
      company_address_line1: "",
      company_address_line2: "",
      company_address_postal_code: "",
      company_registration_number: "",
      website: "",
      country_of_origin: 2,
      years_of_experience: undefined,
      no_of_cus: "",
      revenue_of_cus: "",
      purchase_inventory: true,
      focus_categories: undefined,
      partner_type: undefined,
    },
  });

  useEffect(() => {
    if (params?.partnerid) {
      (async () => {
        let data = await GetPartner(params?.partnerid);
        data = {
          ...data.data,
          purchase_inventory: data.data.purchase_inventory.toString(),
        };
        setPartnerDetails(data);
        if (data?.partner_categories?.length !== 0) {
          setDefaultCategories(
            data?.partner_categories.map((itm: any) => {
              return {
                id: itm.focus_category,
                name: itm.categories.name,
              };
            })
          );

          setSelectedCategories(
            data?.partner_categories.map((itm: any) => itm.focus_category)
          );
        }
        if (data?.partner_categories?.length !== 0) {
          setDefaultCategories(
            data?.partner_categories.map((itm: any) => {
              return {
                id: itm.focus_category,
                name: itm.categories.name,
              };
            })
          );

          setSelectedCategories(
            data?.partner_categories.map((itm: any) => itm.focus_category)
          );
        }
      })();
    }
  }, [params?.partnerid]);

  useEffect(() => {
    console.log(partnerDetails);
    let defaults = {
      company_name: partnerDetails ? partnerDetails.company_name : "",
      company_address_line1: partnerDetails
        ? partnerDetails.company_address_line1
        : "",
      company_address_line2: partnerDetails
        ? partnerDetails.company_address_line2
        : "",
      company_address_postal_code: partnerDetails
        ? partnerDetails.company_address_postal_code
        : "",
      company_registration_number: partnerDetails
        ? partnerDetails.company_registration_number
        : "",
      website: partnerDetails ? partnerDetails.website : "",
      country_of_origin: partnerDetails?.country_of_origin,
      years_of_experience: partnerDetails
        ? partnerDetails.years_of_experience
        : undefined,
      no_of_cus: partnerDetails ? partnerDetails.no_of_cus : "",
      revenue_of_cus: partnerDetails ? partnerDetails.revenue_of_cus : "",
      purchase_inventory: partnerDetails
        ? partnerDetails.purchase_inventory
        : true,
      focus_categories: partnerDetails
        ? partnerDetails.partner_categories[0]?.focus_category
        : undefined,
      partner_type: partnerDetails ? partnerDetails.partner_type : undefined,
    };
    reset(defaults);
  }, [partnerDetails, reset]);

  const onSubmit = (data: partnerInfoSubmitDTO) => {
    let newData: any = {
      company_name: data.company_name,
      company_address_line1: data.company_address_line1,
      company_registration_number: data.company_registration_number,
      website: data.website,
      country_of_origin: data.country_of_origin,
      purchase_inventory: data.purchase_inventory,
      // focus_categories: [+data?.focus_categories],
      focus_categories: selectedCategories,
      // focus_categories: [+data?.focus_categories],
      partner_type: +data?.partner_type,
    };
    if (data.company_address_line2)
      newData.company_address_line2 = data.company_address_line2;
    if (data.company_address_postal_code)
      newData.company_address_postal_code = data.company_address_postal_code;
    if (data.years_of_experience)
      newData.years_of_experience = data.years_of_experience;
    if (data.no_of_cus) newData.no_of_cus = data.no_of_cus;
    if (data.revenue_of_cus) newData.revenue_of_cus = data.revenue_of_cus;
    if (selectedCategories.length === 0) {
      setCategoryMessage("* This field is required");
      return;
    }
    if (selectedCategories.length === 0) {
      setCategoryMessage("* This field is required");
      return;
    }
    if (partnerDetails) {
      (async () => {
        const res = await UpdatePartner(newData, params.partnerid);
        console.log(res);
        const activity = await CreateActivity(
          `Partner ${data.company_name} updated.`
        );
        console.log(activity);
        props.handleNotification("success", "Updated successfully");
        setCategoryMessage("");
        setCategoryMessage("");
      })();
    } else {
      (async () => {
        console.log(newData);
        const res = await CreatePartner(newData);
        console.log(res);
        const activity = await CreateActivity(
          `Partner ${data.company_name} added.`
        );
        console.log(activity);
        props.setPartnerID(res.data.id);
        if (
          props?.permissions[0]?.sub_modules[1]?.permissions_actions[1]
            ?.permission[0]?.permission
        ) {
          props.setDefaultTab("partner-team");
        } else if (
          props?.permissions[0]?.sub_modules[2]?.permissions_actions[1]
            ?.permission[0]?.permission
        ) {
          props.setDefaultTab("partner-service-capabilities");
        } else {
          navigate("/PartnerManagement");
        }
        setCategoryMessage("");
        setCategoryMessage("");
      })();
    }
  };

  const handleCategoryChange = (categories: any) => {
    let idList = categories.map((item: any) => {
      return item.id;
    });
    setSelectedCategories(idList);
  };

  const handleRemove = (categories: any) => {
    let idList = categories.map((item: any) => {
      return item.id;
    });
    setSelectedCategories(idList);
  };

  return (
    <Form
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
      className="mt40px"
    >
      <Container fluid>
        <Row>
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group
              className="formgroup mr12px"
              controlId="formOrganizationName"
            >
              <Form.Label>Organization Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Organization Name"
                {...register("company_name", {
                  required: "* This field is required",
                })}
              />
              <Form.Text muted>
                <span>
                  {errors?.company_name && (
                    <p className="error-form">
                      {String(errors.company_name?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group className="formgroup" controlId="formBusinessRegNo">
              <Form.Label>Business Registration Number *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Registration Number"
                {...register("company_registration_number", {
                  required: "* This field is required",
                })}
              />
              <Form.Text muted>
                <span>
                  {errors?.company_registration_number && (
                    <p className="error-form">
                      {String(errors.company_registration_number?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt24px">
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group
              className="formgroup mr12px"
              controlId="formPartnerType"
            >
              <Form.Label>Partner Type *</Form.Label>
              <Form.Select
                {...register("partner_type", {
                  required: "* This field is required",
                })}
              >
                <option value="1">Service Providers</option>
                <option value="2">Influencers</option>
                <option value="3">Strategic Consultants</option>
                <option value="4">Manufacturers</option>
                <option value="5">R&D Partners</option>
                <option value="6">Investors</option>
              </Form.Select>
              <Form.Text muted>
                <span>
                  {errors?.partner_type && (
                    <p className="error-form">
                      {String(errors.partner_type?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group
              className="formgroup"
              controlId="formCategoryExperience"
            >
              <Form.Label>Category Experience *</Form.Label>
              <Multiselect
                id="css_custom"
                customCloseIcon={
                  <>
                    <img src="../svgs/close-btn.svg" alt="close-btn" />
                  </>
                }
                showArrow
                options={props.categories}
                displayValue="name"
                showCheckbox={true}
                placeholder="Select"
                closeIcon="cancel"
                selectedValues={defaultCategories}
                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleCategoryChange(e);
                }}
                onRemove={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleRemove(e);
                }}
              />
              <Form.Text muted>
                <span>
                  {categoryMessage && (
                    <p className="error-form">{String(categoryMessage)}</p>
                  )}
                </span>
              </Form.Text>
              {/* <Form.Select
                {...register("focus_categories", {
                  required: "* This field is required",
                })}
              >
                <option value="1">Health Supplements</option>
                <option value="2">Personal Care</option>
                <option value="3">Health Food</option>
                <option value="4">Pet Nutrition</option>
              </Form.Select>
              <Form.Text muted>
                <span>
                  {categoryMessage && (
                    <p className="error-form">{String(categoryMessage)}</p>
                  )}
                </span>
              </Form.Text> */}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt24px">
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group
              className="formgroup mr12px"
              controlId="formInventoryPurchase"
            >
              <Form.Label>Inventory Purchase *</Form.Label>
              <Form.Select
                {...register("purchase_inventory", {
                  required: "* This field is required",
                })}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Select>
              <Form.Text muted>
                <span>
                  {errors?.purchase_inventory && (
                    <p className="error-form">
                      {String(errors.purchase_inventory?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col sm={6} md={4} lg={3} className="p-0"></Col>
        </Row>
        <Row className="mt24px">
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group
              className="formgroup mr12px"
              controlId="formaddressline1"
            >
              <Form.Label>Address Line 1 *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address"
                {...register("company_address_line1", {
                  required: "* This field is required",
                })}
              />
              <Form.Text muted>
                <span>
                  {errors?.company_address_line1 && (
                    <p className="error-form">
                      {String(errors.company_address_line1?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group className="formgroup" controlId="formaddressline2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address line 2"
                {...register("company_address_line2")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt24px">
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group
              className="formgroup mr12px"
              controlId="formCountryOfOrigin"
              key={"country" + Math.floor(Math.random() * 100)}
            >
              <Form.Label>Country of origin *</Form.Label>
              <Form.Select
                {...register("country_of_origin", {
                  required: "* This field is required",
                })}
              >
                {props.countries?.map(
                  (country: { id: string; name: string }) => {
                    return (
                      <option value={country.id} key={country.id}>
                        {country.name}
                      </option>
                    );
                  }
                )}
              </Form.Select>
              <Form.Text muted>
                <span>
                  {errors?.country_of_origin && (
                    <p className="error-form">
                      {String(errors.country_of_origin?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group className="formgroup" controlId="formPostalCode">
              <Form.Label>Zip Code/Postal Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter postal code"
                {...register("company_address_postal_code")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt24px">
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group className="formgroup mr12px" controlId="formOrgWebsite">
              <Form.Label>Organization's Website</Form.Label>
              <InputGroup>
                <InputGroup.Text id="http-addon">https://</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Enter Organization Website"
                  {...register("website")}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group
              className="formgroup"
              controlId="formExpYears"
              key={"experience" + Math.floor(Math.random() * 100)}
            >
              <Form.Label>Years Of Experience</Form.Label>
              <Form.Select {...register("years_of_experience")}>
                {props.years &&
                  props.years.map((year) => {
                    return (
                      <option value={year.id} key={year.id}>
                        {year.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt24px">
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group
              className="formgroup mr12px"
              controlId="formNumberOfCustomers"
            >
              <Form.Label>Number of customers</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter number of customers"
                {...register("no_of_cus")}
              />
            </Form.Group>
          </Col>
          <Col sm={6} md={4} lg={3} className="p-0">
            <Form.Group className="formgroup" controlId="formAnnualRevenue">
              <Form.Label>Annual Revenue</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter annual revenue"
                {...register("revenue_of_cus")}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bt-gray200 mt40px">
        <Row className="justify-content-end mb-3 mt-4">
          <Col className="d-flex justify-content-end">
            <Link to="/PartnerManagement" className="m-1">
              <Button variant="secondary" className="btn-secondary12">
                Cancel
              </Button>
            </Link>

            {props?.permissions[0]?.sub_modules[0]?.permissions_actions[2]
              ?.permission[0]?.permission &&
              params.partnerid && (
                <Button
                  variant="primary"
                  className="btn-primary12 m-1"
                  type="submit"
                >
                  Update
                </Button>
              )}
            {props?.permissions[0]?.sub_modules[0]?.permissions_actions[0]
              ?.permission[0]?.permission &&
              !params.partnerid && (
                <Button
                  variant="primary"
                  className="btn-primary12 m-1"
                  type="submit"
                >
                  Save and continue
                </Button>
              )}
          </Col>
        </Row>
      </Container>
    </Form>
  );
}

export default PartnerInfoForm;
