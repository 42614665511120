import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Sidebar from "../../components/sidebar/Sidebar";
import { useParams } from "react-router-dom";
import "./AddProduct.scss";
import React from "react";
import ProductBasicInfo from "../addProduct/components/ProductBasicInfo";
import { useNavigate } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import {
  GetAllBrands,
  GetOrgBrands,
  GetCategories,
} from "../../../API/endpoints/Brands";
import {
  GetProductInfo,
  SaveProductInfo,
  UpdateProductInfo,
} from "../../../API/endpoints/Products";
import { BrandsDTO, categoriesDTO } from "../../dtos/brand.dtos";
import { ProductDataInputDTO } from "../../dtos/product.dtos";
import ProductSku from "../addProduct/components/ProductSku";
import ImageUpload from "../addProduct/components/ImageUpload";
import { CreateActivity } from "../../../API/endpoints/Dashboard";
import { GetOrg } from "../../../API/endpoints/Organization";
import { GetUserProfile } from "../../../API/endpoints/User";
import Navbar from "../../components/header/NavBar";
import ToastMessage from "../../components/toasts/ToastMessage";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../../API/endpoints/RolesAndPermissions/Roles";

const AddProduct = () => {
  const navigate = useNavigate();

  const [orgID, setOrgID] = useState<string | undefined>("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [defaultTab, setDefaultTab] = useState<any>("");
  const [brands, setBrands] = useState<BrandsDTO[]>([]);
  const [categories, setCategories] = useState<categoriesDTO[]>([]);
  const [productId, setProductId] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [orgName, setOrgName] = useState<string>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [userProfile, setUserProfile] = useState<any>({});
  const params = useParams();
  const [notification, setNotification] = useState({ type: "", msg: "" });

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setOrgID(response.data?.organization?.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID.toString());

      if (roleDetails?.data?.type === "organization") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "9"
        );
        console.log(response);
        setPermissions(response?.data);
      } else if (roleDetails?.data?.type === "administrative") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "3"
        );
        console.log(response);
        setPermissions(response?.data);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    if (
      permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("product-info");
    } else if (
      permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("image");
    } else if (
      permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("sku");
    }
  }, [permissions]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "1") {
      setIsSuperAdmin(true);
    }
  }, [permissions]);

  useEffect(() => {
    if (params.productid) {
      (async () => {
        const res = await GetProductInfo(params.productid);
        // console.log(res.data);
        setProductName(res.data.title);
      })();
    }
    if (orgID) {
      (async () => {
        const res = await GetOrg(orgID);
        setOrgName(res.data?.company_name);
      })();
    }
  }, [params, orgID]);

  useEffect(() => {
    if (isSuperAdmin) {
      GetAllBrands(0, "")
        .then((response) => {
          console.log(response);
          if (response.data.rows.length !== 0) {
            setBrands(response.data.rows);
          }
        })
        .catch((error) => {});
    } else if (orgID) {
      GetOrgBrands(orgID)
        .then((response) => {
          if (response.data.rows.length !== 0) {
            setBrands(response.data.rows);
          }
        })
        .catch((error) => {});
    }
    GetCategories()
      .then((response) => {
        if (response) {
          setCategories(response.data);
        }
      })
      .catch((error) => {});
  }, [isSuperAdmin, orgID]);

  const onSubmit = async (data: ProductDataInputDTO) => {
    if (params.productid) {
      const res = await UpdateProductInfo(data, params.productid);
      setNotification({
        type: "success",
        msg: "Updated successfully",
      });
      console.log(res);
    } else {
      SaveProductInfo(data)
        .then(async (response) => {
          // console.log("response:", response)
          setProductId(response?.data?.id);
          if (!!orgName) {
            const activity = await CreateActivity(
              `Org ${orgName} added product: ${productName}.`
            );
            console.log(activity);
          }
          setNotification({
            type: "success",
            msg: "Product added successfully",
          });
          if (
            permissions[0]?.sub_modules[1]?.permissions_actions[1]
              ?.permission[0]?.permission
          ) {
            setDefaultTab("image");
          } else if (
            permissions[0]?.sub_modules[2]?.permissions_actions[1]
              ?.permission[0]?.permission
          ) {
            setDefaultTab("sku");
          } else {
            navigate("/ProductList");
          }
        })
        .catch((error) => {});
    }
  };
  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });
  const handleNotification = (type: string, msg: string) => {
    setNotification({
      type: type,
      msg: msg,
    });
  };

  return (
    <Container fluid className="add-product">
      <Row>
        <Col className="sidebar">
          <Sidebar active="brand-products" />
        </Col>
        <Col className="content-main p-0">
          <Container fluid className="parent-container">
            <Row>
              <Col className="p-0">
                <Navbar userProfile={userProfile} />
              </Col>
            </Row>
            <Row>
              <Col className="breadcrumb-nav">
                <Breadcrumb>
                  <Breadcrumb.Item
                    className="font12-regular gray-500"
                    href="/ProductList"
                  >
                    PRODUCT MANAGEMENT
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="font12-medium gray-800">
                    {params?.productid
                      ? productName.toLocaleUpperCase()
                      : "ADD NEW PRODUCT"}
                  </Breadcrumb.Item>
                  {params?.productid && (
                    <Breadcrumb.Item
                      className="font12-medium gray-800"
                      href="#"
                      active
                    >
                      {params.productid ? "EDIT PRODUCT" : ""}
                    </Breadcrumb.Item>
                  )}
                </Breadcrumb>
              </Col>
            </Row>
            <Row className="tabs-row">
              <Col className="p-0">
                <Tabs
                  activeKey={defaultTab}
                  onSelect={(k) => {
                    if (params?.productid) {
                      setDefaultTab(k);
                    }
                  }}
                  id="fill-tab-example"
                  className="tabs-global"
                  fill
                >
                  {permissions[0]?.sub_modules[0]?.permissions_actions[1]
                    ?.permission[0]?.permission && (
                    <Tab
                      eventKey="product-info"
                      title={
                        <React.Fragment>
                          <div>Basic Product Info</div>
                        </React.Fragment>
                      }
                    >
                      <ProductBasicInfo
                        brands={brands}
                        categories={categories}
                        onSubmit={onSubmit}
                        permissions={permissions}
                      />
                    </Tab>
                  )}
                  {permissions[0]?.sub_modules[1]?.permissions_actions[1]
                    ?.permission[0]?.permission && (
                    <Tab
                      eventKey="image"
                      title={
                        <React.Fragment>
                          <div>Product Images</div>
                        </React.Fragment>
                      }
                    >
                      <ImageUpload
                        productId={
                          params.productid ? params.productid : productId
                        }
                        setDefaultTab={setDefaultTab}
                        userID={userID}
                        permissions={permissions}
                      />
                    </Tab>
                  )}
                  {permissions[0]?.sub_modules[2]?.permissions_actions[1]
                    ?.permission[0]?.permission && (
                    <Tab
                      eventKey="sku"
                      title={
                        <React.Fragment>
                          <div>Product SKU’s</div>
                        </React.Fragment>
                      }
                    >
                      <ProductSku
                        productId={productId}
                        handleNotification={handleNotification}
                        permissions={permissions}
                      />
                    </Tab>
                  )}
                </Tabs>
                <ToastMessage
                  notification={notification}
                  onCancel={handleCloseNotification}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default AddProduct;
