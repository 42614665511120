import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { BrandsDTO, categoriesDTO } from "../../dtos/brand.dtos";
import { ProductDataInputDTO } from "../../dtos/product.dtos";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GetProductInfo } from "../../../API/endpoints/Products";
import AsyncSelect from "react-select/async";
import Cookies from "js-cookie";
import {
  GetAllBrands,
  GetBrand,
  GetOrgBrands,
} from "../../../API/endpoints/Brands";
import { GetUserProfile } from "../../../API/endpoints/User";
import { GetRoleById } from "../../../API/endpoints/RolesAndPermissions/Roles";

export interface ProductBasicInfoProps {
  brands: BrandsDTO[];
  categories: categoriesDTO[];
  onSubmit: (data: ProductDataInputDTO) => void;
  permissions: any;
}

const ProductBasicInfoForm: React.FunctionComponent<ProductBasicInfoProps> = ({
  brands,
  categories,
  onSubmit,
  permissions,
}) => {
  const [defaultData, setDefaultData] = useState<any>({
    // brand_id: "",
    category: { id: "", name: "" },
    title: "",
    description: "",
    ingredients: "",
    nutritional_benefits: "",
    allergy_info: "",
    shelf_life: "",
    GTIN: "",
    storage_info: "",
  });
  const [brandID, setBrandID] = useState<string>(
    defaultData ? defaultData.brand_id : brands[0].id
  );
  const [selectedBrand, setSelectedBrand] = useState<any>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [brandsList, setBrandsList] = useState<any>();
  const [orgID, setOrgID] = useState<string | undefined>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [roleID, setRoleID] = useState<number>(0);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const { register, formState, handleSubmit, reset } = useForm({
    defaultValues: defaultData,
  });
  const { errors } = formState;
  const params = useParams();
  const onHandleSubmit = (data: ProductDataInputDTO) => {
    onSubmit({ brand_id: brandID, ...data });
  };

  const GetAllBrandsData = async (inputValue?: string) => {
    const response = await GetAllBrands(0, inputValue ? inputValue : "");
    console.log(response);
    console.log(inputValue);
    if (response) {
      setBrandsList(
        response?.data?.rows.map((dataItem: { id: any; brand_name: any }) => {
          return {
            value: dataItem.id,
            label: dataItem.brand_name,
          };
        })
      );
    } else console.log("Cannot get Org data");
  };

  const GetOrgBrandsData = async (inputValue?: string) => {
    const response = await GetOrgBrands(orgID, inputValue ? inputValue : "");
    if (response) {
      setBrandsList(
        response?.data?.rows.map((dataItem: { id: any; brand_name: any }) => {
          return {
            value: dataItem.id,
            label: dataItem.brand_name,
          };
        })
      );
    } else console.log("Cannot get Org data");
  };

  useEffect(() => {
    if (params.productid) {
      (async () => {
        const res = await GetProductInfo(params.productid);
        setBrandID(res.data?.brand?.id);
        setDefaultData(res.data);
      })();
    }
  }, [params]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setOrgID(response.data?.organization?.id);
        }
      })();
    }
  }, [userID]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID.toString());
      if (roleDetails?.data?.type === "administrative") {
        setIsSuperAdmin(true);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    (async () => {
      const response = await GetBrand(brandID);
      console.log(response);
      if (response) {
        setSelectedBrand({
          id: response.data?.id,
          brand_name: response.data?.brand_name,
        });
      }
    })();
  }, [brandID]);

  useEffect(() => {
    const GetAllBrandsData = async (inputValue?: string) => {
      const response = await GetAllBrands(0, inputValue ? inputValue : "");
      console.log(response);
      if (response) {
        setBrandsList(
          response?.data?.rows.map((dataItem: { id: any; brand_name: any }) => {
            return {
              value: dataItem.id,
              label: dataItem.brand_name,
            };
          })
        );
      } else console.log("Cannot get Org data");
    };

    const GetOrgBrandsData = async (inputValue?: string) => {
      const response = await GetOrgBrands(orgID, inputValue ? inputValue : "");
      if (response) {
        setBrandsList(
          response?.data?.rows.map((dataItem: { id: any; brand_name: any }) => {
            return {
              value: dataItem.id,
              label: dataItem.brand_name,
            };
          })
        );
      } else console.log("Cannot get Org data");
    };

    if (isSuperAdmin) {
      GetAllBrandsData();
    } else {
      GetOrgBrandsData();
    }
  }, [isSuperAdmin, orgID]);

  useEffect(() => {
    if (defaultData) {
      console.log(defaultData);
      const defaults = {
        // brand_id: defaultData ? defaultData.brand_id : brands[0].id,
        // brand_id: brands[0]?.id,
        category_id: defaultData?.category?.id,
        title: defaultData?.title,
        description: defaultData?.description,
        ingredients: defaultData?.ingredients,
        nutritional_benefits: defaultData?.nutritional_benefits,
        allergy_info: defaultData?.allergy_info,
        shelf_life: defaultData?.shelf_life,
        GTIN: defaultData?.GTIN,
        storage_info: defaultData?.storage_info,
      };
      console.log(defaults);
      reset(defaults);
    }
  }, [brands, defaultData, reset]);

  const filterOrgs = (inputValue: string) => {
    console.log(inputValue);
    if (isSuperAdmin) {
      GetAllBrandsData(inputValue);
      return brandsList?.filter((i: { label: string }) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else {
      GetOrgBrandsData(inputValue);
      return brandsList?.filter((i: { label: string }) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  };

  const promiseOptions = () =>
    new Promise<any>((resolve) => {
      setTimeout(() => {
        resolve(filterOrgs(searchTerm));
      }, 10);
    });

  return (
    <Form onSubmit={handleSubmit((data) => onHandleSubmit(data))}>
      <Container fluid>
        <Row>
          <Col xs={3}>
            <Form.Group controlId="">
              <Form.Label>Brand</Form.Label>

              <AsyncSelect
                className="asynselect"
                cacheOptions
                onChange={(e: any) => {
                  setBrandID(e.value);
                }}
                onInputChange={(e: any) => {
                  setSearchTerm(e);
                }}
                value={{
                  value: selectedBrand?.id,
                  label: selectedBrand?.brand_name,
                }}
                defaultOptions={brandsList}
                loadOptions={promiseOptions}
              />

              {/* <Form.Select
                {...register("brand_id", {
                  required: "* This field is required",
                })}
                name="brand_id"
              >
                <option value="">Select</option>
                {brands?.length &&
                  brands?.map((item: any) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.brand_name}
                      </option>
                    );
                  })}
              </Form.Select> */}
              <Form.Text muted>
                <span>
                  {errors?.brand_id && (
                    <p className="error-form">
                      {String(errors.brand_id?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId="">
              <Form.Label>Product Category</Form.Label>
              <Form.Select
                {...register("category_id", {
                  required: "* This field is required",
                })}
                name="category_id"
              >
                <option value="">Select</option>
                {categories?.length &&
                  categories?.map((item: any) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
              <Form.Text muted>
                <span>
                  {errors?.category_id && (
                    <p className="error-form">
                      {String(errors.category_id?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="">
              <Form.Label>Product Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Title"
                {...register("title", {
                  required: "* This field is required",
                })}
                name="title"
              />
              <Form.Text muted>
                <span>
                  {errors?.title && (
                    <p className="error-form">
                      {String(errors.title?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="">
              <Form.Label>Product Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Product Descrption"
                {...register("description", {
                  required: "* This field is required",
                })}
                name="description"
              />
              <Form.Text muted>
                <span>
                  {errors?.description && (
                    <p className="error-form">
                      {String(errors.description?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="">
              <Form.Label>Ingredients</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter The Ingredients"
                {...register("ingredients", {
                  required: "* This field is required",
                })}
                name="ingredients"
              />
              <Form.Text muted>
                <span>
                  {errors?.ingredients && (
                    <p className="error-form">
                      {String(errors.ingredients?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="">
              <Form.Label>Nutritional Benefits</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Nutritional Benefits"
                {...register("nutritional_benefits", {
                  required: "* This field is required",
                })}
                name="nutritional_benefits"
              />
              <Form.Text muted>
                <span>
                  {errors?.nutritional_benefits && (
                    <p className="error-form">
                      {String(errors.nutritional_benefits?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="">
              <Form.Label>Allergy Information</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Allergy Information"
                {...register("allergy_info", {
                  required: "* This field is required",
                })}
                name="allergy_info"
              />
              <Form.Text muted>
                <span>
                  {errors?.allergy_info && (
                    <p className="error-form">
                      {String(errors.allergy_info?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <Form.Group controlId="">
              <Form.Label>Shelf Life</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Shelf Life"
                {...register("shelf_life", {
                  required: "* This field is required",
                })}
                name="shelf_life"
              />
              <Form.Text muted>
                <span>
                  {errors?.shelf_life && (
                    <p className="error-form">
                      {String(errors.shelf_life?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId="">
              <Form.Label>GTIN</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter GTIN"
                {...register("GTIN", {
                  required: "* This field is required",
                })}
                name="GTIN"
              />
              <Form.Text muted>
                <span>
                  {errors?.GTIN && (
                    <p className="error-form">{String(errors.GTIN?.message)}</p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="">
              <Form.Label>Storage Info</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Storage Info"
                {...register("storage_info", {
                  required: "* This field is required",
                })}
                name="storage_info"
              />
              <Form.Text muted>
                <span>
                  {errors?.storage_info && (
                    <p className="error-form">
                      {String(errors.storage_info?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-end mb-3 mt-4">
          <Col className="d-flex justify-content-end">
            <Link to="/ProductList" className="m-1">
              <Button className="m-1">Cancel</Button>
            </Link>
            {params.productid
              ? permissions[0]?.sub_modules[0]?.permissions_actions[2]
                  ?.permission[0]?.permission && (
                  <Button type="submit" className="m-1">
                    Update
                  </Button>
                )
              : permissions[0]?.sub_modules[0]?.permissions_actions[0]
                  ?.permission[0]?.permission && (
                  <Button type="submit" className="m-1">
                    Save and continue
                  </Button>
                )}
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default ProductBasicInfoForm;
