import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./DiscoveryManagement.scss";
import "../../styles/global.scss";
import "../../styles/pagelayout.scss";

// React Bootstrap components
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Auth from "@aws-amplify/auth";

// Components
import TableMUI from "../components/TableMUI/TableMUI";
import {
  acceptedPartnerRequestsTableColumns,
  partnerRequestsTableColumns,
} from "../components/TableMUI/TableColumns";
import Sidebar from "../components/sidebar/Sidebar";

import AddPartnerRequest from "../components/modalForms/DiscoveryManagement/PartnerRequest";
import DetailsDisplayModal from "../components/modalForms/DiscoveryManagement/DetailsDisplayModal";
import React from "react";
import BrandApprovalPartner from "../components/modalForms/DiscoveryManagement/BrandApprovePartner";
import { GetInterestForBrand } from "../../API/endpoints/Discovery";
import { GetCountries } from "../../API/endpoints/Common";
import { countriesDTO } from "../dtos/brand.dtos";
import Cookies from "js-cookie";
import Navbar from "../components/header/NavBar";
import { GetUserProfile } from "../../API/endpoints/User";
import { GetPublicFiles } from "../../API/endpoints/fileupload/Files";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";

function AcceptRequest() {
  const navigate = useNavigate();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [flag, setFlag] = useState(false);
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [showApprovePartnerModal, setShowApprovePartnerModal] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [data, setData] = useState<{ rows: any[] }>();
  const [listPage, setListPage] = useState(0);
  const [countries, setCountries] = useState<countriesDTO[]>([]);
  const [searchParam, setSearchParam] = useState<string>("");
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [selectedPartnerID, setSelectedPartnerID] = useState<string>("");
  const [userType, setUserType] = useState<string>("");
  const [selectedRequestID, setSelectedRequestID] = useState<
    string | undefined
  >("");
  const [rows, setRows] = useState<
    {
      id: any;
      requestdate: any;
      partnerName: any;
      partnerId: any;
      brandid: any;
      discovery_brands_id: any;
      focusmarket: any;
      servicerequests: string[];
      servicerequestIds: any;
      status: string;
    }[]
  >([]);
  const [rows2, setRows2] = useState<
    {
      id: any;
      completiondate: any;
      partnerName: any;
      partnerId: any;
      brandid: any;
      discovery_brands_id: any;
      focusmarket: any;
      acceptedServices: string[];
      status: string;
    }[]
  >([]);
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [roleID, setRoleID] = useState<string | undefined>("");
  const [permissions, setPermissions] = useState<any>([]);
  const [publicFiles, setPublicFiles] = useState<any>([]);
  useEffect(() => {
    setUserID(Cookies.get("userID"));
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
          setRoleID(response.data?.user_roles[0]?.roles.id);
        }
      })();
    }
  }, [userID]);

  useEffect(() => {
    if (roleID) {
      (async () => {
        const roleDetails = await GetRoleById(roleID.toString());

        if (roleDetails?.data?.type === "organization") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "10"
          );
          console.log(response);
          setPermissions(response?.data);
        } else if (roleDetails?.data?.type === "administrative") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "5"
          );
          console.log(response);
          setPermissions(response?.data);
        }
      })();
    }
  }, [roleID]);

  const handleClose = () => setShowPartnerModal(false);
  const handleCloseDetailsModal = () => setIsDetailsModalOpen(false);
  const handleCloseApprovePartner = () => setShowApprovePartnerModal(false);
  const params = useParams();

  const columns = partnerRequestsTableColumns(
    setIsDetailsModalOpen,
    setShowApprovePartnerModal,
    setSelectedRequest,
    setUserType,
    setSelectedPartnerID,
    permissions
  );

  const columns2 = acceptedPartnerRequestsTableColumns(
    navigate,
    setIsDetailsModalOpen,
    setSelectedRequest,
    setSelectedRequestID,
    setUserType,
    setSelectedPartnerID
  );

  const dateFormatter = (date: any) => {
    return `${date?.toString().substring(8, 10)}/${date
      ?.toString()
      .substring(5, 7)}/${date?.toString().substring(0, 4)}`;
  };

  useEffect(() => {
    console.log(data?.rows);
    if (data?.rows) {
      const res1 = data.rows
        .map((dataItem) => ({
          id: dataItem.id,
          requestdate: dataItem.created_at
            ? dateFormatter(dataItem.created_at)
            : "Not Available",
          brandid: dataItem.discovery_brand.brand_id,
          partnerName: dataItem.partner.company_name,
          partnerId: dataItem.partner_id,
          discovery_brands_id: dataItem.discovery_brand.id,
          discovery_brand: dataItem.discovery_brand,
          focusmarket: countries.filter(
            (country) => country.id === dataItem.discovery_brand.country_id
          )[0]?.name,
          servicerequests: dataItem.partner_services.map(
            (service: {
              service_id: number;
              recommendation_status: string;
            }) => {
              if (service.service_id === 1)
                return {
                  name: "Requlatory Compliance",
                  status: service.recommendation_status,
                };
              if (service.service_id === 2)
                return {
                  name: "Importation and Clearance",
                  status: service.recommendation_status,
                };
              if (service.service_id === 3)
                return {
                  name: "Warehousing And Storage",
                  status: service.recommendation_status,
                };
              if (service.service_id === 4)
                return {
                  name: "Ecommerce Setup & Management",
                  status: service.recommendation_status,
                };
              if (service.service_id === 5)
                return {
                  name: "Retail Distribution",
                  status: service.recommendation_status,
                };
              if (service.service_id === 6)
                return {
                  name: "Order Fulfillment",
                  status: service.recommendation_status,
                };
              if (service.service_id === 7)
                return {
                  name: "Marketing",
                  status: service.recommendation_status,
                };
              return { name: "", status: "" };
            }
          ),
          servicerequestIds: dataItem.partner_services.map(
            (service: { service_id: any }) => {
              return service.service_id;
            }
          ),
          status: dataItem.status,
        }))
        .filter(
          (dataItem) =>
            dataItem.status === "New" ||
            dataItem.status === "Completed" ||
            dataItem.status === "In progress" ||
            dataItem.status === "In Progress" ||
            dataItem.status === "Interest Received"
        );
      console.log(res1);
      setRows(res1);

      const res2 = data.rows
        .map((dataItem) => ({
          id: dataItem.id,
          completiondate: dataItem.discovery_brand.match_completion_date
            ? dateFormatter(dataItem.discovery_brand.match_completion_date)
            : dataItem.discovery_brand.created_at
            ? dateFormatter(dataItem.discovery_brand.created_at)
            : "Not Available",
          brandid: dataItem.discovery_brand.brand_id,
          partnerName: dataItem.partner.company_name,
          partnerId: dataItem.partner_id,
          discovery_brands_id: dataItem.discovery_brand.id,
          focusmarket: countries.filter(
            (country) => country.id === dataItem.discovery_brand.country_id
          )[0]?.name,
          acceptedServices: dataItem.partner_services
            .filter((item: { status: string }) => item.status === "Accepted")
            .map(
              (service: {
                service_id: number;
                recommendation_status: string;
                status: string;
              }) => {
                if (service.service_id === 1 && service.status === "Accepted")
                  return {
                    name: "Requlatory Compliance",
                    status: service.recommendation_status,
                  };
                if (service.service_id === 2 && service.status === "Accepted")
                  return {
                    name: "Importation and Clearance",
                    status: service.recommendation_status,
                  };
                if (service.service_id === 3 && service.status === "Accepted")
                  return {
                    name: "Warehousing And Storage",
                    status: service.recommendation_status,
                  };
                if (service.service_id === 4 && service.status === "Accepted")
                  return {
                    name: "Ecommerce Setup & Management",
                    status: service.recommendation_status,
                  };
                if (service.service_id === 5 && service.status === "Accepted")
                  return {
                    name: "Retail Distribution",
                    status: service.recommendation_status,
                  };
                if (service.service_id === 6 && service.status === "Accepted")
                  return {
                    name: "Order Fulfillment",
                    status: service.recommendation_status,
                  };
                if (service.service_id === 7 && service.status === "Accepted")
                  return {
                    name: "Marketing",
                    status: service.recommendation_status,
                  };
                return { name: "", status: "" };
              }
            ),
          status: dataItem.status,
        }))
        .filter(
          (dataItem) =>
            dataItem.status === "Completed" ||
            dataItem.status === "In progress" ||
            dataItem.status === "In Progress"
        );
      console.log(res2);
      setRows2(res2);
    }
  }, [countries, data, flag]);

  useEffect(() => {
    const GetBrandRequestProposals = async () => {
      const res = await GetInterestForBrand(params?.id);
      setDataCount(res.data.rows.length);
      setData(res.data);
      console.log(res);
    };

    GetBrandRequestProposals();
  }, [params?.id, flag]);

  useEffect(() => {
    const GetAllCountries = async () => {
      const res = await GetCountries();
      setCountries(res.data);
    };

    GetAllCountries();
  }, []);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });
    console.log(searchParam);
  }, [navigate, searchParam]);

  useEffect(() => {
    if (isDetailsModalOpen) {
      (async () => {
        try {
          const response = await GetPublicFiles("partner", selectedPartnerID);
          setPublicFiles(response.data);
        } catch (error) {}
      })();
    }
  }, [isDetailsModalOpen, selectedPartnerID]);

  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="discovery" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item
              onClick={() => {
                navigate(-1);
              }}
            >
              DISCOVERY MANAGEMENT
              {/* <Link to="/discoverymanagement/org">DISCOVERY MANAGEMENT</Link> */}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {params?.brandName?.toLocaleUpperCase()}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>REQUEST </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button size="sm" className="btn-primary12 addfilter">
              Add Filter
            </Button> */}
          </div>
          <div className="tabs-wraper">
            <Tabs id="fill-tab-example" className="tabs-global" fill>
              {permissions[0]?.sub_modules[1]?.permissions_actions[1]
                ?.permission[0]?.permission && (
                <Tab
                  eventKey="interested-partners"
                  title={
                    <React.Fragment>
                      <div>Interested Partners</div>
                    </React.Fragment>
                  }
                >
                  <div className="mt16px mb16px">
                    <div className="TableMUIcountainer">
                      <TableMUI
                        columns={columns}
                        rows={rows}
                        dataCount={dataCount}
                        setListPage={setListPage}
                        listPage={listPage}
                      />
                    </div>
                  </div>
                </Tab>
              )}

              {permissions[0]?.sub_modules[2]?.permissions_actions[1]
                ?.permission[0]?.permission && (
                <Tab
                  eventKey="confirmed-partners"
                  title={
                    <React.Fragment>
                      <div>Confirmed Partners</div>
                    </React.Fragment>
                  }
                >
                  <div className="mt16px mb16px">
                    <div className="TableMUIcountainer">
                      <TableMUI
                        columns={columns2}
                        rows={rows2}
                        dataCount={dataCount}
                        setListPage={setListPage}
                        listPage={listPage}
                      />
                    </div>
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>
        </Col>

        <AddPartnerRequest
          onCancel={handleClose}
          setFlag={setFlag}
          flag={flag}
          isModalOpen={showPartnerModal}
        />
        <DetailsDisplayModal
          isModalOpen={isDetailsModalOpen}
          onCancel={handleCloseDetailsModal}
          selectedRequestID={selectedRequestID}
          selectedRequest={selectedRequest}
          userType={userType}
          selectedPartnerID={selectedPartnerID}
          publicFiles={publicFiles}
        />
        <BrandApprovalPartner
          onCancel={handleCloseApprovePartner}
          setFlag={setFlag}
          flag={flag}
          isModalOpen={showApprovePartnerModal}
          title={`Partner Approval - ${selectedRequest?.partnerName}`}
          subtitle="Select services which you would like to approve:"
          buttonText="Confirm Approval"
          userType="Brand"
          reqDetails={selectedRequest}
        />
      </Row>
    </Container>
  );
}

export default AcceptRequest;
