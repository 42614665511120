import { getWith, deleteWith, postWith, patchWith } from "../axios";

export async function GetPartner(partnerid: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/partner/` + partnerid;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllPartners(
  listPage: number,
  searchParam: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/partner/fetch-partners/${
      listPage ? listPage + 1 : 1
    }/${searchParam ? searchParam : ""}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllPartnersFilterDeleted(
  listPage: number,
  searchParam: string
): Promise<any> {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/partner/fetch-partners-filtered/${listPage ? listPage + 1 : 1}/${
      searchParam ? searchParam : ""
    }`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeletePartner(id: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/partner/` + id;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function SoftDeletePartner(
  partnerid: string | undefined
): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_PARTNER_URL}soft-delete/partner/` +
      partnerid;
    return (await patchWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function RestorePartner(
  partnerid: string | undefined
): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_PARTNER_URL}restore/partner/` + partnerid;
    return (await patchWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreatePartner(partner: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_PARTNER_URL}`;
    return (await postWith<any, any>(url, partner)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdatePartner(
  partner: any,
  partnerid: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_PARTNER_URL}` + partnerid;
    return (await patchWith<any, any>(url, partner)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function InvitePartner(
  partnerID: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_PARTNER_URL}invite-partner`;
    return (await postWith<any, any>(url, { partner_id: partnerID })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function AcceptTermsAndConditions(partnerID: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_PARTNER_URL}accept-tandc/${partnerID}`;
    return (await postWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeletePartnerUser(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/` + id;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPartnerUsers(
  partnerid: string | undefined
): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_PARTNER_URL}fetch-partner-users/` +
      partnerid +
      "/1";
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreatePartnerUser(teamMember: {
  partner_id: any;
  first_name: string;
  last_name: string;
  email_id: string;
  contact_number: string;
  contact_number_code: string;
  designation: string;
  user_type: string;
  role: string[];
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/org-user`;
    return (await postWith<any, any>(url, teamMember)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdatePartnerUser(
  userID: string,
  teamMember: {
    partner_id: any;
    first_name: string;
    last_name: string;
    email_id: string;
    contact_number: string;
    contact_number_code: string;
    designation: string;
    user_type: string;
    role: string[];
  }
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/` + userID;
    return (await patchWith<any, any>(url, teamMember)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreatePartnerServices(
  partnerID: string | undefined,
  services: {
    region: number;
    regulatory_compliance: boolean;
    importation_clearance: boolean;
    warehousing_storage: boolean;
    ecom_setup_mgmt: boolean;
    retail_distribution: boolean;
    order_fulfilment: boolean;
    marketing: boolean;
  }[]
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_PARTNER_URL}partner-services`;
    return (await postWith<any, any>(url, {
      partner_id: partnerID,
      services: services,
    })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdatePartnerServices(
  partnerID: string | undefined,
  services: {
    region: number;
    regulatory_compliance: boolean;
    importation_clearance: boolean;
    warehousing_storage: boolean;
    ecom_setup_mgmt: boolean;
    retail_distribution: boolean;
    order_fulfilment: boolean;
    marketing: boolean;
  }[]
): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_PARTNER_URL}partner-services/` + partnerID;
    return (await patchWith<any, any>(url, {
      services: services,
    })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPartnerServicesList(
  partnerid: string | undefined
): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_PARTNER_URL}partner-services/` + partnerid;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
