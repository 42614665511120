import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import "./AddTeamMember.scss";
import { AddTeamMemberProps } from "../../../dtos/organization.dtos";
import {
  CreateOrgUser,
  GetUserProfile,
  UpdateOrganizationUser,
} from "../../../../API/endpoints/Organization";
import { GetCountryCodes } from "../../../../API/endpoints/Common";
import { GetRolesByType } from "../../../../API/endpoints/RolesAndPermissions/Roles";

function AddTeamMember(props: AddTeamMemberProps) {
  const params = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      code: "",
      phone: "",
      designation: "",
      role: "",
    },
  });

  const navigate = useNavigate();
  const { user, onCancel, setUserID, setIsEdit, setFlag } = props;
  const [countryCodes, setCountryCodes] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);

  useEffect(() => {
    let userid = user?.id;
    if (userid) {
      (async () => {
        let userProfile = await GetUserProfile(userid);
        console.log(userProfile);
        let defaults: any = {
          firstName: userProfile.data?.first_name,
          lastName: userProfile.data?.last_name,
          email: userProfile.data?.email_id,
          code: userProfile.data?.contact_number_code,
          phone: userProfile.data?.contact_number,
          designation: userProfile.data?.designation,
          role: userProfile.data?.user_roles[0]?.roles?.id,
        };
        reset(defaults);
      })();
    } else {
      let defaults: any = {
        firstName: "",
        lastName: "",
        email: "",
        code: "",
        phone: "",
        designation: "",
        role: "",
      };
      reset(defaults);
    }
  }, [user, reset]);

  useEffect(() => {
    (async () => {
      setCountryCodes([]);
      const res = await GetCountryCodes();
      // console.log(res);
      setCountryCodes(res.data);
    })();
    (async () => {
      setRoles([]);
      const res = await GetRolesByType("organization");
      //  console.log(res);
      setRoles(res.data);
    })();
  }, []);

  //   const getCountryCodeNumber = (countryCodeName: string) => {
  //     switch (countryCodeName) {
  //       case "+65":
  //         return 1;
  //       case "+91":
  //         return 2;
  //       default:
  //         return 1;
  //     }
  //   };

  const onHandleSubmit = (data: FieldValues) => {
    handleSubmitForm(data);
  };

  const handleClose = () => {
    props.setFlag((prev) => !prev);
    setUserID("");
    onCancel();
  };
  const validateEmail = (email: string) => {
    const regx =
      /^([a-zA-Z0-9.-]+)@([a-zA-Z0-9-]+).([a-z]{2,8})(.[a-z]{2,8})?$/;

    /* 
    (yourname)@(domain).(extension).(optional-extension)
    eg: yourname11@gmail.com or yourname11@aeg.co.in
    yourname- Any letters, numbers, dot and/or hyphens
    domain- Any letter, number and/or hyphen(-)
    extension- Any letter(a-z)
    optional-extension - a dot(.) then any letter
    {2,8}- minimum length 2 and maximum length 8 
    */
    if (regx.test(email)) {
      return true;
    } else {
      setError("email", {
        type: "custom",
        message: "Please Enter Valid Email Address",
      });
      return false;
    }
  };

  const handleSubmitForm = (data: FieldValues) => {
    let teamMember: any = {
      org_id: props.orgID,
      first_name: data.firstName,
      email_id: data.email,
      contact_number_code: data.code,
      contact_number: data.phone,
      user_type: "organization",
      role: [data.role],
    };
    console.log(teamMember);
    const validEmail = validateEmail(teamMember.email_id);
    if (!validEmail) return;
    if (data.lastName) teamMember.last_name = data.lastName;
    if (data.designation) teamMember.designation = data.designation;

    let teamMember2 = {
      ...teamMember,
      brands: props.data.rows ? props.data.rows[0]?.user_brands : [],
    };

    let editTeamMember = {
      ...teamMember,
      region: [2],
      department: 1,
    };

    const handleExceptionMessage = function (ex: any) {
      if (ex.param === "first_name") {
        setError("firstName", {
          type: "custom",
          message: `${ex.msg} ${ex.value}`,
        });
      } else if (ex.param === "last_name") {
        setError("lastName", {
          type: "custom",
          message: `${ex.msg} ${ex.value}`,
        });
      } else if (ex === "Email already exists") {
        setError("email", { type: "custom", message: ex });
      } else if (ex === "Contact number already exists") {
        setError("phone", { type: "custom", message: ex });
      } else if (ex.param === "designation") {
        setError("designation", {
          type: "custom",
          message: `${ex.msg} ${ex.value}`,
        });
      } else {
        console.log(ex);
      }
    };

    if (params.orgid && !props.isEdit && props.data.rows[0]) {
      (async () => {
        try {
          const res = await CreateOrgUser(teamMember2);
          setFlag((prevFlag: boolean) => !prevFlag);
          console.log(res);
          setIsEdit(false);
          onCancel();
        } catch (ex: any) {
          handleExceptionMessage(ex);
        }
      })();
    } else if (params.orgid && props.isEdit) {
      console.log(editTeamMember, "editTeamMember");
      (async () => {
        try {
          await UpdateOrganizationUser(props.user.id, editTeamMember);
          setFlag((prevFlag: boolean) => !prevFlag);
          props.handleNotification("success", "Updated successfully");
          onCancel();
          return;
        } catch (ex: any) {
          handleExceptionMessage(ex);
        }
      })();
    } else {
      console.log("Brand Admin user");
      (async () => {
        try {
          const res = await CreateOrgUser(teamMember);
          console.log(res);
          setIsEdit(false);
          navigate("/OrganizationManagement");
        } catch (ex: any) {
          handleExceptionMessage(ex);
        }
      })();
    }
  };

  return (
    <>
      <Modal
        className="addteam-member"
        show={props.isModalOpen}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="font18-medium">
            Add New Team Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="addTeamMemberForm"
            onSubmit={handleSubmit((data: any) => {
              onHandleSubmit(data);
            })}
          >
            <Row>
              <Col>
                <Form.Group className="formgroup mr12px" controlId="firstname">
                  <Form.Label>First Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    {...register("firstName", {
                      required: "* This field is required",
                    })}
                  />
                  <Form.Text muted>
                    <span>
                      {errors?.firstName && (
                        <p className="error-form">
                          {String(errors.firstName?.message)}
                        </p>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="formgroup" controlId="lastname">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    {...register("lastName")}
                  />
                </Form.Group>
                <Form.Text muted>
                  <span>
                    {errors?.lastName && (
                      <p className="error-form">
                        {String(errors.lastName?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Col>
            </Row>
            <Row className="mt20px">
              <Col>
                <Form.Group className="formgroup mr12px" controlId="email">
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    {...register("email", {
                      required: "* This field is required",
                    })}
                  />
                  <Form.Text muted>
                    <span>
                      {errors?.email && (
                        <p className="error-form">
                          {String(errors.email?.message)}
                        </p>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="formgroup" controlId="phone">
                  <Form.Label>Phone *</Form.Label>
                  <InputGroup>
                    <Form.Select
                      {...register("code")}
                      aria-label="20"
                      className="countrycode"
                      key={"country" + Math.floor(Math.random() * 100)}
                    >
                      {countryCodes[0] &&
                        countryCodes?.map((code: { country_code: string }) => {
                          // console.log(country);
                          return (
                            <option
                              key={Math.random()}
                              value={code.country_code}
                            >
                              {code.country_code}
                            </option>
                          );
                        })}
                    </Form.Select>

                    <Form.Control
                      type="number"
                      placeholder="Enter Phone number"
                      {...register("phone", {
                        required: "* This field is required",
                      })}
                    />
                  </InputGroup>
                  <Form.Text muted>
                    <span>
                      {errors?.phone && (
                        <p className="error-form">
                          {String(errors.phone?.message)}
                        </p>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt20px">
              <Col>
                <Form.Group
                  className="formgroup mr12px"
                  controlId="designation"
                >
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter designation"
                    {...register("designation")}
                  />
                  <Form.Text muted>
                    <span>
                      {errors?.designation && (
                        <p className="error-form">
                          {String(errors.designation?.message)}
                        </p>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="formgroup" controlId="appRole">
                  <Form.Label>App Role *</Form.Label>
                  <Form.Select
                    aria-label="20"
                    {...register("role")}
                    className="app-role"
                    key={Math.floor(Math.random() * 100)}
                  >
                    {roles[0] &&
                      roles?.map((role: { id: number; role_name: string }) => {
                        return (
                          <option value={role.id}>{role.role_name}</option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-secondary12"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="btn-primary12"
            variant="primary"
            type="submit"
            form="addTeamMemberForm"
          >
            {props.isEdit ? "Update User" : "Add User"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddTeamMember;
