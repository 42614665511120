import { UrlObject } from "url";
import axios, { AxiosRequestConfig, Method, ResponseType } from "axios";
// import { getCookie } from '../utils/browser';

export interface RequestError {
  errorMessage?: string;
  error_description?: string;
}

/* Common Header */
axios.interceptors.request
  .use
  // (request : any) => {
  //     const token = getCookie('key');
  //     if (token) {
  //         request.headers.Authorization = `Bearer ${token}`;
  //         request.headers['Access-Control-Allow-Origin'] = '*';
  //     }
  //     return request;
  // }
  ();

export const MethodConst = {
  DELETE: "DELETE",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
} as const;

/**
 * Isomorphic fetch function
 * @param method restricted to GET', 'POST', 'PUT' and 'DELETE'
 * @param url Punycode only (not all Unicode characters)
 * @param data type `T` value
 * - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
 * - Browser only: FormData, File, Blob
 * - Node only: Stream, Buffer
 * @param responseType defaults to JSON
 * 'arraybuffer', 'document', 'json', 'text', 'stream'
 * - Browser only: 'blob'
 * @param withCredentials
 * @param headers e.g. {'authorization': 'Bearer TOKEN'}
 * @param params e.g. { ID: 12345 }
 * @returns desired response type `R` or `null` (204 No Content), otherwise `RequestError` object
 */
export const fetchWrapper = async <T, R>(
  Methods: Method,
  Url: UrlObject | string,
  Data: T | undefined,
  ResponseTypes: ResponseType,
  WithCredentials: boolean,
  Params?: Object | URLSearchParams,
): Promise<R | RequestError> => {
  const config: AxiosRequestConfig = {
    method: Methods,
    url: Url.toString(), // ! if Unicode support needed, then url.format() is required
    data: Methods === MethodConst.GET ? undefined : Data,
    responseType: ResponseTypes,
    withCredentials: WithCredentials,
    params: Params
  };
  // console.log("inside fetch wrapper", config);
  const response = await axios.request(config);
  // console.log("inside fetch wrapper result", response);
  switch (response.status) {
    case 200:
      try {
        const result = response.data;
        return result as R; // isAuthError/RequestError = false
      } catch (e: any) {
        return {
          errorMessage: e.message,
          error_description:
            "Something went wrong when parsing Response object",
        } as unknown as R;
      }
    case 201:
      try {
        const result = await response.data;
        return result as R; // isAuthError/RequestError = false
      } catch (e: any) {
        return {
          errorMessage: e.message,
          error_description:
            "Something went wrong when parsing Response object",
        } as unknown as R;
      }
    case 204:
      return new Promise((resolve, reject) => {
        resolve({
          errorMessage: undefined,
          error_description: undefined,
        } as unknown as R); // isAuthError/RequestError = false
      });
    case 400:
      try {
        const result = await response.data;
        return {
          errorMessage: !!response && response.statusText,
          error_description: "Error 400 from API server",
          ...result, // OBS.: overwrite fields
        } as unknown as R; // isAuthError/RequestError = true
      } catch (e: any) {
        return {
          errorMessage: e.message,
          error_description:
            "Error 400 from API server, without Response object",
        } as unknown as R; // isAuthError/RequestError = true
      }
    default:
      return new Promise((resolve, reject) => {
        resolve({
          errorMessage: "Some error ocurred",
          error_description: `Error ${response.status} from API server`,
        } as unknown as R); // isAuthError/RequestError = true
      });
  }
};
