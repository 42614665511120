import { getWith, postWith, deleteWith } from "../../axios";

export async function SaveUrl(data: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/url`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function DeleteUrl(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/url/${id}`;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetUrlsById(fileId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/url/${fileId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPartnerUrlsBySettingsId(
  settingsId?: string,
  partnerid?: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/urls/partner/${settingsId}&&${partnerid}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetBrandUrlsBySettingsId(
  settingsId: string,
  brandId: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/urls/brand/${settingsId}&&${brandId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetProductUrlsBySettingsId(
  settingsId: string,
  productId: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/urls/product/${settingsId}&&${productId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetUrlTypes(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/urltypes`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
