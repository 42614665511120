import ProductBasicInfoForm from "../../../components/forms/productBasicInfoForm";
import React from "react";
import { BrandsDTO, categoriesDTO } from "../../../dtos/brand.dtos";
import { ProductDataInputDTO } from "../../../dtos/product.dtos";
export interface AddProductProps {
  brands: BrandsDTO[];
  categories: categoriesDTO[];
  onSubmit: (data: ProductDataInputDTO) => void;
  permissions: any;
}
const ProductBasicInfo: React.FunctionComponent<AddProductProps> = ({
  brands,
  categories,
  onSubmit,
  permissions,
}) => {
  return (
    <ProductBasicInfoForm
      brands={brands}
      categories={categories}
      onSubmit={onSubmit}
      permissions={permissions}
    />
  );
};

export default ProductBasicInfo;
