import { getWith, deleteWith, postWith } from "../../axios";

export async function GetBrandFilesById(
  filetypeId: string,
  brandId: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/brand/${filetypeId}&&${brandId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
export async function GetProductFilesById(
  filetypeId: string,
  productId: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/product/${filetypeId}&&${productId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPartnerFilesById(
  filetypeId?: string,
  partnerId?: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/partner/${filetypeId}&&${partnerId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPublicFiles(
  filetype?: string,
  Id?: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/public/${filetype}&&${Id}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UploadFile(data: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/upload`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function DeleteFile(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/files/${id}`;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
