import "../../styles/pagelayout.scss";
import "./AddPermission.scss";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Auth from "@aws-amplify/auth";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/header/NavBar";
import ToastMessage from "../components/toasts/ToastMessage";
import { GetUserProfile } from "../../API/endpoints/User";
import {
  GetPermissionsByType,
  GetAssignedPermissionsByRoleId,
  AssignPermission,
  UpdatePermission,
} from "../../API/endpoints/RolesAndPermissions/Permissions";

const AddPermission = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [userType, setUserType] = useState<string | undefined>("");
  const [userRole, setUserRole] = useState<string | undefined>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [permissionList, setPermissionList] = useState<any>([]);
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const [submitting, setSubmitting] = useState(false);
  const [permissionsLength, SetPermissionsLength] = useState(0);

  useEffect(() => {
    setUserType(Cookies.get("UserType"));
    setUserRole(Cookies.get("UserRole"));
    setUserID(Cookies.get("userID"));
  }, []);
  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
    })();

    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });
    if (userType === "administrative" && userRole === "super_admin") {
      console.log(userType);
    } else if (userType === "partner") {
      navigate("/PartnerManagement");
    } else if (userType === "organization") {
      navigate("/OrganizationManagement");
    }
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userType, userRole, navigate, userID]);

  useEffect(() => {
    if (params.edit === "true") {
      (async () => {
        const response = await GetAssignedPermissionsByRoleId(
          params.roleid || ""
        );
        const res = {
          data: response?.data?.map((item: any) => {
            return item.permissions_list;
          }),
        };
        console.log(res);
        SetPermissionsLength(res.data.length);
        if (res.data.length !== 0) {
          const formatedList = res.data
            .sort((a: any, b: any) => {
              return a.id - b.id;
            })
            .map((module: any) => {
              return {
                ...module,
                permission: module.sub_modules.find((sub: any) =>
                  sub.permissions_actions.find(
                    (action: any) => action.permission[0].permission === true
                  )
                )
                  ? true
                  : false,
                sub_modules: module.sub_modules.map((sub: any) => {
                  return {
                    ...sub,
                    permission: sub.permissions_actions.find(
                      (action: any) => action.permission[0].permission === true
                    )
                      ? true
                      : false,
                    permissions_actions: sub.permissions_actions.map(
                      (action: any) => {
                        return {
                          ...action,
                          permission:
                            action.permission[0].permission === true
                              ? true
                              : false,
                        };
                      }
                    ),
                  };
                }),
              };
            });
          setPermissionList(formatedList);
        } else {
          const response = await GetPermissionsByType(params.typeid || "");
          console.log(response.data);
          const formatedList = response?.data
            ?.sort((a: any, b: any) => {
              return a.id - b.id;
            })
            .map((module: any) => {
              return {
                ...module,
                permission: false,
                sub_modules: module.sub_modules.map((sub: any) => {
                  return {
                    ...sub,
                    permission: false,
                    permissions_actions: sub.permissions_actions.map(
                      (action: any) => {
                        return {
                          ...action,
                          permission: false,
                        };
                      }
                    ),
                  };
                }),
              };
            });
          setPermissionList(formatedList);
        }
      })();
    } else {
      (async () => {
        const response = await GetPermissionsByType(params.typeid || "");
        const formatedList = response?.data
          ?.sort((a: any, b: any) => {
            return a.id - b.id;
          })
          .map((module: any) => {
            return {
              ...module,
              permission: false,
              sub_modules: module.sub_modules.map((sub: any) => {
                return {
                  ...sub,
                  permission: false,
                  permissions_actions: sub.permissions_actions.map(
                    (action: any) => {
                      return {
                        ...action,
                        permission: false,
                      };
                    }
                  ),
                };
              }),
            };
          });
        setPermissionList(formatedList);
      })();
    }
  }, [params]);

  const handleChangeModule = (checked: boolean, id: any) => {
    const result = permissionList.map((itm: any) =>
      itm.id === id
        ? {
            ...itm,
            permission: checked,
            sub_modules: itm.sub_modules.map((sub: any) => {
              return {
                ...sub,
                permission: false,
                permissions_actions: sub.permissions_actions.map(
                  (action: any) => {
                    return {
                      ...action,
                      permission: false,
                    };
                  }
                ),
              };
            }),
          }
        : itm
    );
    setPermissionList(result);
  };

  const handleChangeSubModule = (
    checked: boolean,
    subIndex: any,
    moduleId: any
  ) => {
    const result = permissionList.map((module: any) =>
      module.id === moduleId
        ? {
            ...module,
            sub_modules: module.sub_modules.map((sub: any, index: any) =>
              subIndex === index
                ? {
                    ...sub,
                    permission: checked,
                    permissions_actions: sub.permissions_actions.map(
                      (action: any) => {
                        return {
                          ...action,
                          permission: false,
                        };
                      }
                    ),
                  }
                : sub
            ),
          }
        : module
    );
    setPermissionList(result);
  };
  const handleChangeAction = (
    checked: boolean,
    actionItem: any,
    subIndex: any,
    moduleId: any
  ) => {
    console.log(actionItem);
    const result = permissionList.map((module: any) =>
      module.id === moduleId
        ? {
            ...module,
            sub_modules: module.sub_modules.map((sub: any, index: any) =>
              index === subIndex
                ? {
                    ...sub,
                    permissions_actions: sub.permissions_actions.map(
                      (action: any) => {
                        if (actionItem.name === "read") {
                          if (checked === true) {
                            return action.id === actionItem.id
                              ? {
                                  ...action,
                                  permission: checked,
                                }
                              : action;
                          } else {
                            return action.id === actionItem.id
                              ? {
                                  ...action,
                                  permission: checked,
                                }
                              : { ...action, permission: false };
                          }
                        } else {
                          return action.id === actionItem.id
                            ? {
                                ...action,
                                permission: checked,
                              }
                            : action;
                        }
                      }
                    ),
                  }
                : sub
            ),
          }
        : module
    );
    setPermissionList(result);
  };

  const onHandleSubmit = async () => {
    const filterList: {
      permissions_id: any;
      permissions_submodules_id: any;
      permissions_actions_id: any;
      permission: boolean;
    }[] = [];
    permissionList.map((mod: any) =>
      mod.sub_modules.map((sub: any) =>
        sub.permissions_actions.map((action: any) =>
          filterList.push({
            permissions_id: mod.id,
            permissions_submodules_id: action.sub_module_id,
            permissions_actions_id: action.id,
            permission: action.permission,
          })
        )
      )
    );
    const inputData = {
      permissions_list: filterList,
    };
    if (params.edit === "true") {
      if (permissionsLength !== 0) {
        try {
          setSubmitting(true);
          const res = await UpdatePermission(inputData, params.roleid);
          if (res.msg === "Permissions updated successfully for role.") {
            setNotification({ type: "success", msg: res.msg });
            setSubmitting(false);
            navigate(
              `/EditFileAccess/${params.typeid}&&${params.roleid}&&true`
            );
          }
        } catch (error) {
          setNotification({ type: "error", msg: "Updation failed" });
          setSubmitting(false);
        }
      } else {
        try {
          setSubmitting(true);
          const response = await AssignPermission(inputData, params.roleid);
          if (response.msg === "Permissions assigned to role successfully.") {
            setNotification({ type: "success", msg: response.msg });
            setSubmitting(false);
            navigate(
              `/EditFileAccess/${params.typeid}&&${params.roleid}&&true`
            );
          }
        } catch (error) {
          setNotification({
            type: "error",
            msg: "Failed to assign permissions",
          });
          setSubmitting(false);
        }
      }
    } else {
      try {
        setSubmitting(true);
        const response = await AssignPermission(inputData, params.roleid);
        if (response.msg === "Permissions assigned to role successfully.") {
          setNotification({ type: "success", msg: response.msg });
          setSubmitting(false);
          navigate(`/FileAccess/${params.typeid}&&${params.roleid}`);
        }
      } catch (error) {
        setNotification({ type: "error", msg: "Failed to assign permissions" });
        setSubmitting(false);
      }
    }
  };
  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });

  const goBack = () => {
    navigate("/RolesList");
  };

  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <div className="permission">
            <div className="permission-cntr">
              <Breadcrumb className="font12-medium breadcrumbnav">
                <Breadcrumb.Item href="/SettingsList">Settings</Breadcrumb.Item>
                <Breadcrumb.Item href="/RolesList">
                  Roles & permissions
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  href={
                    params.edit === "true"
                      ? `/EditPermission/${params.typeid}&&${params.roleid}&&${params.edit}`
                      : `/AddPermission/${params.typeid}&&${params.roleid}`
                  }
                  active
                >
                  {params.edit === "true" ? "Edit Permissions" : "Add New"}
                </Breadcrumb.Item>
              </Breadcrumb>
              <Form className="form">
                <div className="header-wraper">
                  <div className="form-header">
                    <Button onClick={goBack} className="backarrow-btn">
                      <img src="../svgs/arrows-left.svg" alt="arrow" />
                    </Button>
                    <span className="font18-medium gray-800">
                      Set Up Permissions
                    </span>
                  </div>
                </div>
                <div className="mt24px table-wraper">
                  <table>
                    <tbody>
                      <tr>
                        <th colSpan={4}>Module</th>
                        <th colSpan={4}>Permissions</th>
                      </tr>
                      <tr>
                        <td colSpan={4}></td>
                        <td className="perm-td">Create</td>
                        <td className="perm-td">Read</td>
                        <td className="perm-td">Update</td>
                        <td className="perm-td">Delete</td>
                      </tr>
                      {permissionList.map((module: any) => {
                        return [
                          <tr key={module.id}>
                            <td className="module-td" colSpan={4}>
                              <Form.Check
                                className="checkbox16"
                                checked={module.permission}
                                type="checkbox"
                                label={module.module}
                                onChange={(e) => {
                                  handleChangeModule(
                                    e.target.checked,
                                    module.id
                                  );
                                }}
                              />
                            </td>
                            <td className="p-td"></td>
                            <td className="p-td"></td>
                            <td className="p-td"></td>
                            <td className="p-td"></td>
                          </tr>,
                          module.sub_modules.map(
                            (subModule: any, subIndex: any) => {
                              return [
                                <tr
                                  className="sub-module-tr"
                                  key={module.id + 1}
                                >
                                  <td
                                    key={subModule.id}
                                    className="submodule-name-td"
                                    colSpan={4}
                                  >
                                    <Form.Check
                                      className="checkbox16"
                                      checked={
                                        module.permission === true
                                          ? subModule.permission
                                          : false
                                      }
                                      disabled={
                                        module.permission === true
                                          ? false
                                          : true
                                      }
                                      type="checkbox"
                                      label={subModule.sub_module_name}
                                      onChange={(e) => {
                                        handleChangeSubModule(
                                          e.target.checked,
                                          subIndex,
                                          module.id
                                        );
                                      }}
                                    />
                                  </td>
                                  {subModule.permissions_actions.map(
                                    (action: any) => {
                                      return (
                                        <td
                                          key={action.id}
                                          className="perm-check"
                                        >
                                          <Form.Check
                                            className="checkbox16"
                                            checked={
                                              module.permission === true &&
                                              subModule.permission === true
                                                ? action.permission
                                                : false
                                            }
                                            disabled={
                                              module.permission === true &&
                                              subModule.permission === true &&
                                              (subModule?.permissions_actions[1]
                                                ?.permission ||
                                                action.name === "read")
                                                ? false
                                                : true
                                            }
                                            type="checkbox"
                                            label=""
                                            onChange={(e) => {
                                              handleChangeAction(
                                                e.target.checked,
                                                action,
                                                subIndex,
                                                module.id
                                              );
                                            }}
                                          />
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>,
                              ];
                            }
                          ),
                          /*dummy row for spacing only*/
                          <tr key={module.id + 2}>
                            <td
                              className="p-td"
                              style={{ height: "24px" }}
                              colSpan={4}
                            ></td>
                            <td className="p-td"></td>
                            <td className="p-td"></td>
                            <td className="p-td"></td>
                            <td className="p-td"></td>
                          </tr>,
                          /*end dummy row*/
                        ];
                      })}
                    </tbody>
                  </table>
                  <div className="save-btn-wraper">
                    <Button
                      className="btn-primary12 save-btn"
                      onClick={onHandleSubmit}
                      disabled={submitting === true ? true : false}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AddPermission;
