import "../../../styles/pagelayout.scss";
import "./FileList.scss";
import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/header/NavBar";
import BreadCrumb from "../../components/header/BreadCrumb";
import { useState, useEffect } from "react";
import { GetUserProfile } from "../../../API/endpoints/User";
import { GetProductInfo } from "../../../API/endpoints/Products";
import FileActions from "../components/actions/FileActions";
import File from "../components/listfile/File";
import Url from "../components/listfile/Url";
import EmptyContent from "../../components/empty/EmptyContent";
import UploadModal from "../components/upload/UploadModal";
import InfoSideBar from "../components/fileinfo/InfoSideBar";
import ToastMessage from "../../components/toasts/ToastMessage";
import {
  GetProductFilesById,
  UploadFile,
  DeleteFile,
} from "../../../API/endpoints/fileupload/Files";
import {
  GetProductUrlsBySettingsId,
  GetUrlTypes,
  SaveUrl,
  DeleteUrl,
} from "../../../API/endpoints/fileupload/Urls";
import { GetFile } from "../../../API/endpoints/fileupload/FileSettings";
import { urlInputsDTO } from "../../dtos/file.dtos";
import { CreateActivity } from "../../../API/endpoints/Dashboard";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../../API/endpoints/RolesAndPermissions/Roles";

const FileList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [productName, setProductName] = useState("");
  const [fileSettingsName, setFileSettingsName] = useState("");
  const [show, setShow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showFileInfo, setShowFileInfo] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [fileTypeId, setFileTypeId] = useState("");
  const [fileFormat, setFileFormat] = useState("");
  const [fileFormatList, setFileFormatList] = useState([]);
  const [adminNotes, setAdminNotes] = useState("");
  const [urls, setUrls] = useState<any>([]);
  const [urlTypes, setUrlTypes] = useState([]);
  const [isUrlRequired, setIsUrlRequired] = useState(false);
  const [documents, setDocuments] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [urlSelectors, setUrlSelectors] = useState<urlInputsDTO[]>([
    {
      url_type_id: "",
      url: "",
      url_notes: "",
    },
  ]);
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const [flag, setFlag] = useState<boolean>(false);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID.toString());

      if (roleDetails?.data?.type === "organization") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "9"
        );
        console.log(response);
        setPermissions(response?.data);
      } else if (roleDetails?.data?.type === "administrative") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "3"
        );
        console.log(response);
        setPermissions(response?.data);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    if (params?.fileSettingsId && params?.productid) {
      (async () => {
        try {
          const res = await GetProductInfo(params.productid);
          setProductName(res?.data?.title);
        } catch (error) {}

        try {
          const response = await GetUrlTypes();
          setUrlTypes(response.data);
        } catch (error) {}
        try {
          const response = await GetFile(params?.fileSettingsId || "");
          setFileTypeId(response?.data?.file_type.id);
          setAdminNotes(response?.data?.admin_notes);
          setFileSettingsName(response?.data?.name);
          setFileFormatList(response?.data?.file_formats);
          const formats = response.data.file_formats;
          let formatedArray: string[] = [];
          if (response?.data?.file_type.id === 1) {
            formats.map((itm: any) => {
              switch (itm) {
                case 1:
                  formatedArray.push(".pdf");
                  break;
                case 2:
                  formatedArray.push("application/vnd.ms-powerpoint");
                  break;
                case 3:
                  formatedArray.push(".doc");
                  break;
                default:
                  return "";
              }
              return "";
            });
          } else if (response?.data?.file_type.id === 2) {
            formats.map((itm: any) => {
              switch (itm) {
                case 4:
                  formatedArray.push(".png");
                  break;
                case 5:
                  formatedArray.push(".jpeg");
                  formatedArray.push(".jpg");
                  break;
                case 6:
                  formatedArray.push("image/svg+xml");
                  break;
                default:
                  return "";
              }
              return "";
            });
          } else {
          }
          setFileFormat(formatedArray.toString());
        } catch (error) {}
      })();
    }
  }, [params, flag]);

  useEffect(() => {
    setFiles([]);
    setUrls([]);
    if (
      params?.fileSettingsId &&
      params?.productid &&
      permissions[0]?.sub_modules[4]?.permissions_actions[1]?.permission[0]
        ?.permission
    ) {
      (async () => {
        try {
          const response = await GetProductFilesById(
            params?.fileSettingsId || "",
            params?.productid || ""
          );
          setFiles(response?.data);
        } catch (error) {}
        try {
          const response = await GetProductUrlsBySettingsId(
            params?.fileSettingsId || "",
            params?.productid || ""
          );
          setUrls(response?.data);
        } catch (error) {}
      })();
    }
  }, [params, flag, permissions]);

  const breadcrumbData = [
    {
      name: "brand management",
      url: "/BrandManagement",
      active: false,
    },
    {
      name: "products",
      url: "/ProductList",
      active: false,
    },
    {
      name: productName,
      url: `/ProductList`,
      active: false,
    },
    {
      name: "Files",
      url: `/product-filesettings/${params.productid}`,
      active: false,
    },
    {
      name: `${fileSettingsName}`,
      url: `/product-files/${params.productid}&&${params.fileSettingsId}`,
      active: true,
    },
  ];
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setTimeout(() => {
      if (flag) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    }, 1000);
    setShow(false);
    setDocuments([]);
    setImages([]);
    setUrlSelectors([
      {
        url_type_id: "",
        url: "",
        url_notes: "",
      },
    ]);
    setIsUrlRequired(false);
  };
  const handleUrlRequired = (e: boolean) => setIsUrlRequired(e);
  const handleFileSelection = (checked: boolean, fileId: any) => {
    let tempFiles = files.map((itm: any) =>
      itm.id === fileId ? { ...itm, isChecked: checked } : itm
    );
    setFiles(tempFiles);
  };
  const handleUrlSelection = (checked: boolean, urlId: any) => {
    let tempFiles = urls.map((itm: any) =>
      itm.id === urlId ? { ...itm, isChecked: checked } : itm
    );
    setUrls(tempFiles);
  };
  const handleDelete = async (selectedFiles: any, selectedUrls: any) => {
    if (flag) {
      setFlag(false);
    } else {
      setFlag(true);
    }
    if (selectedFiles.length !== 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        try {
          await DeleteFile(selectedFiles[i].id);
        } catch (error) {}
      }

      setTimeout(() => {
        if (flag) {
          setFlag(false);
        } else {
          setFlag(true);
        }
      }, 2000);
    }
    if (selectedUrls.length !== 0) {
      for (let u = 0; u < selectedUrls.length; u++) {
        try {
          await DeleteUrl(selectedUrls[u].id);
        } catch (error) {
          console.log(error);
        }
      }
      setTimeout(() => {
        if (flag) {
          setFlag(false);
        } else {
          setFlag(true);
        }
      }, 2000);
    }
  };
  const handleInfo = () => {
    setShowFileInfo(true);
  };
  const handleInfoClose = () => {
    setShowFileInfo(false);
  };
  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });
  const handleFileDownload = (fileId: any) => {
    let filePath = files?.find((itm: any) => itm.id === fileId).path;
    if (filePath) {
      window.open(filePath);
    }
  };

  const handleDocuments = (docs: any) => {
    setDocuments(docs);
  };
  const handleImages = (imgs: any) => {
    setImages(imgs);
  };
  const handleUrlSelectorls = (urlList: any) => {
    setUrlSelectors(urlList);
  };

  const onDocumentSubmit = async (documents: any, urls: any) => {
    if (documents.length !== 0) {
      const data = new FormData();
      for (var doc = 0; doc < documents.length; doc++) {
        data.append("file", documents[doc]);
      }
      data.append("filename", documents[0].name);
      data.append("notes", "");
      data.append("file_settings_id", params?.fileSettingsId || "");
      data.append("product_id", params?.productid || "");
      data.append("uploaded_by", userID || "");
      try {
        setSubmitting(true);
        const response = await UploadFile(data);
        if (response.msg === "File uploaded successfully") {
          setTimeout(() => {
            if (flag) {
              setFlag(false);
            } else {
              setFlag(true);
            }
          }, 1000);
          setSubmitting(false);
          if (documents.length > 1) {
            const activity = await CreateActivity(
              `File: ${documents[0].name} and others uploaded for product ${productName} .`
            );
            console.log(activity);
          } else {
            const activity = await CreateActivity(
              `File: ${documents[0].name} uploaded for product ${productName} .`
            );
            console.log(activity);
          }
          setNotification({ type: "success", msg: response.msg });
          handleClose();
        }
      } catch (error) {
        setSubmitting(false);
        setNotification({ type: "error", msg: "Documents upload failed" });
      }
    }
    if (isUrlRequired && urls.length !== 0) {
      let urlData = {
        url: "",
        url_type_id: 0,
        url_notes: "",
        file_settings_id: 0,
        file_type_id: 0,
        product_id: "",
        uploaded_by: "",
      };
      for (var docurl = 0; docurl < urls.length; docurl++) {
        urlData.url = urls[docurl].url;
        urlData.url_type_id = Number(urls[docurl].url_type_id);
        urlData.url_notes = urls[docurl].url_notes;
        urlData.file_settings_id = Number(params?.fileSettingsId);
        urlData.file_type_id = Number(fileTypeId);
        urlData.product_id = params?.productid || "";
        urlData.uploaded_by = userID || "";
        try {
          const response = await SaveUrl(urlData);
          if (response.msg === "Url saved successfully.") {
            setNotification({ type: "success", msg: response.msg });
          }
        } catch (error) {
          setNotification({ type: "error", msg: "Failed to save URL" });
        }
      }
      setTimeout(() => {
        if (flag) {
          setFlag(false);
        } else {
          setFlag(true);
        }
      }, 1000);
      handleClose();
    }
  };

  const onImageSubmit = async (images: any, urls: any) => {
    if (images.length !== 0) {
      const data = new FormData();
      for (var img = 0; img < images.length; img++) {
        data.append("file", images[img]);
      }
      data.append("filename", images[0].name);
      data.append("notes", "");
      data.append("file_settings_id", params?.fileSettingsId || "");
      data.append("product_id", params?.productid || "");
      data.append("uploaded_by", userID || "");
      try {
        setSubmitting(true);
        const response = await UploadFile(data);
        if (response.msg === "File uploaded successfully") {
          setTimeout(() => {
            if (flag) {
              setFlag(false);
            } else {
              setFlag(true);
            }
          }, 1000);
          setSubmitting(false);
          if (images.length > 1) {
            const activity = await CreateActivity(
              `Image: ${images[0].name} and others uploaded for product ${productName} .`
            );
            console.log(activity);
          } else {
            const activity = await CreateActivity(
              `Image: ${images[0].name} uploaded for product ${productName} .`
            );
            console.log(activity);
          }
          setNotification({
            type: "success",
            msg: "Images saved successfully",
          });
          handleClose();
        }
      } catch (error) {
        setSubmitting(false);
        setNotification({ type: "error", msg: "Images upload failed" });
      }
    }
    if (isUrlRequired && urls.length !== 0) {
      let urlData = {
        url: "",
        url_type_id: 0,
        url_notes: "",
        file_settings_id: 0,
        file_type_id: 0,
        product_id: "",
        uploaded_by: "",
      };
      for (var imgurl = 0; imgurl < urls.length; imgurl++) {
        urlData.url = urls[imgurl].url;
        urlData.url_type_id = Number(urls[imgurl].url_type_id);
        urlData.url_notes = urls[imgurl].url_notes;
        urlData.file_settings_id = Number(params?.fileSettingsId);
        urlData.file_type_id = Number(fileTypeId);
        urlData.product_id = params?.productid || "";
        urlData.uploaded_by = userID || "";
        try {
          const response = await SaveUrl(urlData);
          if (response.msg === "Url saved successfully.") {
            setNotification({ type: "success", msg: response.msg });
          }
        } catch (error) {
          setSubmitting(false);
          setNotification({ type: "error", msg: "Url upload failed" });
        }
      }
      setTimeout(() => {
        if (flag) {
          setFlag(false);
        } else {
          setFlag(true);
        }
      }, 1000);
      handleClose();
    }
  };
  const onVideoUrlSubmit = async (urls: any) => {
    if (isUrlRequired && urls.length !== 0) {
      setSubmitting(true);
      let videoUrlData = {
        url: "",
        url_type_id: 0,
        url_notes: "",
        file_settings_id: 0,
        file_type_id: 0,
        product_id: "",
        uploaded_by: "",
      };
      for (var i = 0; i < urls.length; i++) {
        videoUrlData.url = urls[i].url;
        videoUrlData.url_type_id = Number(urls[i].url_type_id);
        videoUrlData.url_notes = urls[i].url_notes;
        videoUrlData.file_settings_id = Number(params?.fileSettingsId);
        videoUrlData.file_type_id = Number(fileTypeId);
        videoUrlData.product_id = params?.productid || "";
        videoUrlData.uploaded_by = userID || "";
        try {
          const response = await SaveUrl(videoUrlData);
          if (response.msg === "Url saved successfully.") {
            const activity = await CreateActivity(
              `Video urls saved for product ${productName} .`
            );
            console.log(activity);
            setNotification({ type: "success", msg: response.msg });
          }
        } catch (error) {
          setNotification({ type: "error", msg: "Failed to save URL" });
        }
      }
      setSubmitting(false);
      setTimeout(() => {
        if (flag) {
          setFlag(false);
        } else {
          setFlag(true);
        }
      }, 1000);
      handleClose();
    }
  };

  return (
    <Container fluid className="pge-layout filelist-cntr">
      <Row>
        <Col className="sidebar">
          <Sidebar active="brand-products" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <BreadCrumb breadcrumbData={breadcrumbData} />
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
              />
            </InputGroup>

            {permissions[0]?.sub_modules[4]?.permissions_actions[0]
              ?.permission[0]?.permission && (
              <Button className="btn-primary12 adduser" onClick={handleShow}>
                Add File/URL
              </Button>
            )}
          </div>
          <FileActions
            files={files}
            urls={urls}
            setFlagProp={setFlag}
            handleDelete={handleDelete}
            handleInfo={handleInfo}
            permissions={permissions}
            folderType={"product"}
          />
          {files && files.length === 0 && urls.length === 0 && (
            <EmptyContent message="Add a file or attach a URL" />
          )}
          {files && files.length !== 0 && (
            <div className="list-cntr">
              {files.map((itm: any) => {
                return (
                  <File
                    key={itm.id}
                    filename={itm.original_name}
                    fileId={itm.id}
                    file_size={itm.file_size}
                    handleSelection={handleFileSelection}
                    isChecked={itm?.isChecked || false}
                    handleFileDownload={handleFileDownload}
                    fileTypeId={fileTypeId}
                  />
                );
              })}
            </div>
          )}
          {urls.length !== 0 && (
            <div className="url-table-cntr">
              {urls.map((itm: any) => {
                return (
                  <div key={itm.id}>
                    <Url
                      url_type_id={itm.url_type_id}
                      url={itm.url}
                      url_notes={itm.url_notes}
                      urlTypes={urlTypes}
                      urlId={itm.id}
                      handleSelection={handleUrlSelection}
                      isChecked={itm?.isChecked || false}
                    />
                  </div>
                );
              })}
            </div>
          )}

          <UploadModal
            isModalOpen={show}
            onCancel={handleClose}
            isUrlRequired={isUrlRequired}
            handleUrlRequired={handleUrlRequired}
            fileTypeId={fileTypeId}
            adminNotes={adminNotes}
            urlTypes={urlTypes}
            fileFormat={fileFormat}
            fileFormatList={fileFormatList}
            documents={documents}
            handleDocuments={handleDocuments}
            images={images}
            handleImages={handleImages}
            urlSelectors={urlSelectors}
            handleUrlSelectorls={handleUrlSelectorls}
            onDocumentSubmit={onDocumentSubmit}
            onImageSubmit={onImageSubmit}
            onVideoUrlSubmit={onVideoUrlSubmit}
            submitting={submitting}
          />
          <InfoSideBar
            showFileInfo={showFileInfo}
            onClose={handleInfoClose}
            files={files}
            fileTypeId={fileTypeId}
          />
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FileList;
