import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";

export interface LoginFormprops {
  submitting: boolean;
  onSubmit: (username: string, password: string) => void;
  errorMessage: string;
  isError: boolean;
  handleShowResetLinkModal: () => void;
}

const LoginForm: React.FunctionComponent<LoginFormprops> = ({
  submitting,
  onSubmit,
  errorMessage,
  isError,
  handleShowResetLinkModal
}) => {
  const { register, formState, handleSubmit } = useForm();
  const { errors } = formState;
  const onHandleSubmit = (email: string, password: string) => {
    onSubmit(email, password);
  };

  return (
    <div className="loginform">
      <div>
        <div className="tradehublogo">
          <div className="logo">
            <img src="../svgs/itradehublogo_login.svg" alt="itradehublogo_login" />
          </div>
          <div className="iTradeHub-text">iTradeHub</div>
        </div>
        <div className="font18-medium gray-800 text-center">Sign in to your account</div>
        <Form
          onSubmit={handleSubmit((data) =>
            onHandleSubmit(data.email, data.password)
          )}
          data-testid={"login-form"}
        >
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <div className="font14-medium gray-700 label-email">Username</div>
            <Form.Control
              className="email-input"
              type="email"
              placeholder="Enter your registered email id"
              {...register("email", { required: "* This field is required" })}
              name="email"
              // onChange={(e) => handleEmailChange(e.target.value)}
            />
            <Form.Text muted>
              <span>
                {errors?.email && (
                  <p className="error-form">{String(errors.email?.message)}</p>
                )}
              </span>
            </Form.Text>
          </Form.Group>
          <Form.Group
            className="d-flex flex-column"
            controlId="formGroupPassword"
          >
            {/* <Form.Label>Password</Form.Label> */}
            <div className="font14-medium gray-700 label-password">
              Password
            </div>
            <Form.Control
              className="password-input"
              type="password"
              {...register("password", {
                required: "* This field is required",
                minLength: {
                  value: 8,
                  message: "* Password length too small",
                },
              })}
              // name="password"
              placeholder="Enter Password"
              // onChange={(e) => handlePasswordChange(e.target.value)}
            />
            <Form.Text muted>
              <span>
                {errors?.password && (
                  <p className="error-form">
                    {String(errors.password?.message)}
                  </p>
                )}
              </span>
            </Form.Text>
          </Form.Group>
          <div className="forgotpassword-wraper">
            <Button
              className="forgotpassword-btn font12-medium"
              onClick={()=>{
                handleShowResetLinkModal();
              }}
            >
              Forgot Password ?
            </Button>
          </div>
          <Button
            type="submit"
            variant="primary"
            className="login-btn font12-semibold"
            disabled={submitting ? true : false}
          >
            Sign In
          </Button>
          <Form.Text muted>
            <span>
              {isError && <p className="error-form">{errorMessage}</p>}
            </span>
          </Form.Text>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
