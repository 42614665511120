import { useState, useEffect, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import "./AdvisoryPopup.scss";
import {
  CreateReportRequest,
  GetReportCategories,
  GetReportNamesForCategory,
  MakeAdvisoryPayment,
} from "../../../API/endpoints/Advisory";
import { GetOrgBrands } from "../../../API/endpoints/Discovery";

function RequestNewReport(props: {
  onCancel: () => void;
  setFlag: any;
  flag: boolean | undefined;
  id: string | undefined;
  isModalOpen: boolean | undefined;
  orgID: string | undefined;
}) {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [reportNames, setReportNames] = useState<any>([]);
  const [reportPrice, setReportPrice] = useState<string>("");
  const [reportDescription, setReportDescription] = useState<string>("");
  const [defaultValues, setDefaultValues] = useState<any>([]);
  const [orgID, setOrgID] = useState<string | undefined>("");
  const [brandsList, setBrandsList] = useState<any[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      reportcategory: 1,
      reportname: "",
      reportprice: "",
      brand: "",
      reportdescription: "",
    },
  });

  useEffect(() => {
    let defaults = {
      reportcategory: defaultValues.reportcategory,
      reportname: defaultValues.reportname,
      reportprice: defaultValues.reportprice,
      reportdescription: defaultValues.reportdescription,
    };
    reset(defaults);
    console.log(defaults);
    setOrgID(props?.orgID);
  }, [defaultValues, reset, props]);

  useEffect(() => {
    (async () => {
      const res = await GetReportCategories();
      const categories_list = res.data.filter(
        (cat: { reports: any }) => cat.reports[0]
      );
      setCategories(categories_list);
    })();
    (async () => {
      const res = await GetReportNamesForCategory(selectedCategory);
      setReportNames(res.data);
    })();
    (async () => {
      const data = await GetOrgBrands(orgID);
      const response = data?.data?.rows?.filter(
        (item: any) => item.status === "Activated"
      );
      if (response) {
        setBrandsList([]);
        response.map((item: { brand_name: string; id: string }) =>
          setBrandsList((brand: any[]) => [
            ...brand,
            { name: item.brand_name, id: item.id },
          ])
        );
      } else console.log("Cannot get Brands Data");
    })();
  }, [orgID, selectedCategory]);

  useEffect(() => {
    setSelectedBrand(brandsList[0]?.id);
  }, [brandsList]);

  const handleClose = () => {
    setDefaultValues([]);
    setSelectedBrand("");
    let defaults = {
      reportcategory: 1,
      reportname: "",
      reportprice: "",
      reportdescription: "",
    };
    reset(defaults);
    props.onCancel();
    setUrl("");
  };

  const handleChangeBrand = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedBrand(e.target.value);
  };

  const onFormSubmit = async (data: {
    reportcategory: number;
    reportname: string;
    reportprice: string;
    brand: string;
    reportdescription: string;
  }) => {
    let formData = {
      brand_id: selectedBrand,
      reports_id: Number(data.reportname.split(",")[0]),
      lead_time: 7,
    };
    console.log(formData);
    const res = await CreateReportRequest(formData);
    console.log(res.data);
    const response = await MakeAdvisoryPayment({
      items: [
        {
          name: reportNames.filter(
            (report: {
              id: number;
              report_name: string;
              report_price: string;
              // eslint-disable-next-line array-callback-return
            }) => {
              if (Number(data.reportname.split(",")[0])) {
                return report.report_name;
              }
            }
          )[0].report_name,
          amount: Number(data.reportname.split(",")[1]) * 100,
        },
      ],
      advisory_id: Number(res?.data?.id),
    });

    window.location = response.data.url;

    props.setFlag((prevState: any) => !prevState);
    handleClose();
  };
  const getUrl = (e: any) => {
    let reportId = Number(
      e.target.value.substring(0, e.target.value.indexOf(","))
    );
    let result = reportNames.find((itm: any) => itm.id === reportId);
    setUrl(result.url);
  };

  return (
    <Modal show={props.isModalOpen} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Buy A Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit((data) => {
            onFormSubmit(data);
          })}
        >
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="reportcategory">
                <Form.Label>Report Category</Form.Label>
                <Form.Select
                  aria-label="20"
                  {...register("reportcategory", {
                    required: "* This field is required",
                  })}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                  }}
                >
                  <option value="">Select category</option>;
                  {categories &&
                    categories.map(
                      (category: { id: number; category_name: string }) => {
                        return (
                          <option value={category.id} key={category.id}>
                            {category.category_name}
                          </option>
                        );
                      }
                    )}
                </Form.Select>
                <Form.Text muted>
                  <span>
                    {errors?.reportcategory && (
                      <p className="error-form">
                        {String(errors.reportcategory?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="reportname">
                <Form.Label>Report Name</Form.Label>
                <Form.Select
                  aria-label="20"
                  {...register("reportname", {
                    required: "* This field is required",
                  })}
                  onChange={(e) => {
                    getUrl(e);
                    setReportPrice(e.target.value.split(",")[1]);
                    setReportDescription(e.target.value.split(",")[2]);
                    console.log("hello", e.target.value.split(",")[1]);
                  }}
                >
                  <option value="">Select Report</option>;
                  {reportNames &&
                    reportNames.map(
                      (report: {
                        id: number;
                        report_name: string;
                        report_price: string;
                        description: string;
                      }) => {
                        return (
                          <option
                            value={[
                              report.id.toString(),
                              report.report_price,
                              report.description,
                            ]}
                            key={report.id}
                          >
                            {report.report_name}
                          </option>
                        );
                      }
                    )}
                </Form.Select>
                <Form.Text muted>
                  <span>
                    {errors?.reportname && (
                      <p className="error-form">
                        {String(errors.reportname?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          {url && (
            <Row>
              <Col xs={12}>
                <a href={url} target="_blank" rel="noreferrer">
                  Click here to preview a sample report
                </a>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              {" "}
              <Form.Group className="mb-3" controlId="brand">
                <Form.Label>Select Your Brand</Form.Label>
                <Form.Select
                  aria-label="20"
                  {...register("brand", {
                    required: "* This field is required",
                  })}
                  onChange={(e) => handleChangeBrand(e)}
                >
                  {brandsList &&
                    brandsList.map((brand) => {
                      return (
                        <option value={brand.id} key={brand.id}>
                          {brand.name}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Text muted>
                  <span>
                    {errors?.brand && (
                      <p className="error-form">
                        {String(errors.brand?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="reportprice">
                <Form.Label>Report Price (USD)</Form.Label>
                <Form.Control
                  disabled
                  {...register("reportprice")}
                  value={reportPrice}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="reportdescription">
                <Form.Label>Report Description</Form.Label>
                <Form.Control
                  disabled
                  as="textarea"
                  {...register("reportdescription")}
                  value={reportDescription}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button
          className="primaryButton"
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          className="primaryButton"
          variant="primary"
          type="submit"
          onClick={handleSubmit((data) => {
            onFormSubmit(data);
          })}
        >
          {defaultValues.document_name ? "Update" : "Buy A Report"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RequestNewReport;
