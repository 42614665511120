import "../../../../styles/modals/modal700.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col } from "react-bootstrap";

export interface PartnerAgreementProps {
    isModalOpen: boolean;
    onCancel: () => void;
    partnerID: string;
    partnerName: string;
    onSubmit: (id: string, name: string) => void;
    submitting: boolean;
}
const PartnerAgreement: React.FunctionComponent<PartnerAgreementProps> = ({
    isModalOpen,
    onCancel,
    partnerID,
    partnerName,
    onSubmit,
    submitting
}) => {
    const handleClose = () => {
        onCancel();
    }
    const onHandleSubmit = () => {
        onSubmit(partnerID, partnerName);
    }
    return (
        <div>
            <Modal
                className="modal700"
                show={isModalOpen}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="font18-medium">
                        Key Platform Terms
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="form">
                        <Container fluid >
                            <Row className="row-body mt24px">
                                <Col className="p-0">
                                    <p className="gray-800 font14-medium">Non-Circumvention</p>
                                    <p className="gray-500 font14-medium">
                                        The Partner is not permitted to enter into any business relationship
                                        with Brands listed on AEG's platform. The MoU prohibits the Partner
                                        from circumventing the MoU, directly or indirectly, such as by
                                        contacting any Brands without AEG's knowledge and approval, or
                                        entering into any agreements with Brands without AEG's approval, or
                                        undertaking any activities with any Brands without AEG's approval.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="row-body">
                                <Col className="p-0">
                                    <p className="gray-800 font14-medium">Breach of Non-Circumvention</p>
                                    <p className="gray-500 font14-medium">
                                        In the event that the Partner circumvents the MoU, the Partner will be
                                        responsible for paying an amount equal to 10% of all revenue generated
                                        by the brand, 2 times the damages caused to AEG, and/or an amount
                                        equal to SGD 15,000 AEG may also terminate the MoU and all brand
                                        service agreements with the Partner
                                    </p>
                                </Col>
                            </Row>
                            <Row className="row-body">
                                <Col className="p-0">
                                    <p className="gray-800 font14-medium">
                                        By accepting this, I/We agree to the above terms of the platform.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="bt-gray200">
                                <Col className="p-0">
                                    <div className="btn-footer">
                                        <Button
                                            className="btn-secondary12"
                                            variant="secondary"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            className="btn-primary12"
                                            variant="primary"
                                            type="submit"
                                            form="addTeamMemberForm"
                                            onClick={onHandleSubmit}
                                            disabled={submitting === true ? true : false}
                                        >
                                            I Agree
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PartnerAgreement;