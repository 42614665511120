import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import "./DetailsDisplayModal.scss";
import "../../../../styles/global.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetBrandMarketPreference,
  GetBrandRequests,
} from "../../../../API/endpoints/Discovery";
import { GetBrand } from "../../../../API/endpoints/Brands";
import {
  GetPartner,
  GetPartnerServicesList,
} from "../../../../API/endpoints/Partner";
import { GetCountries } from "../../../../API/endpoints/Common";

function DetailsDisplayModal(props: {
  isModalOpen: boolean;
  onCancel: () => void;
  selectedRequestID?: string | undefined;
  selectedRequest?: any;
  selectedRequestBrandID?: string;
  userType?: string;
  selectedPartnerID?: string;
  publicFiles?: any;
}) {
  const params = useParams();
  const [data, setData] = useState<any>();
  const [socialLinks, setSocialLinks] = useState<
    { platform: string; url: string }[]
  >([]);
  const [marketplaceLinks, setMarketplaceLinks] = useState<
    { platform: string; url: string }[]
  >([]);
  const [country, setCountry] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");
  const [partnerName, setPartnerName] = useState<string>("");
  const [preferredmarket, setPreferredMarket] = useState<string>("");
  const [focusCategory, setFocusCategory] = useState<any>([]);
  const [preferredmodel, setPreferredModel] = useState<string>("");
  const [preferredservices, setPreferredServices] = useState<any>([]);
  // const [BrandDocuments, setBrandDocuments] = useState<any[]>([]);
  const [website, setWebsite] = useState<string>("NA");
  const [status, setStatus] = useState<string>("");
  const handleClose = () => {
    props.onCancel();
  };

  const handleSocialMediaClick = (social_media: string) => {
    socialLinks?.map((item) => {
      if (social_media === item.platform) {
        window.open(`//${item.url}`, "_blank");
      }
      return true;
    });
  };

  const handleMarketplaceClick = (marketplace: string) => {
    marketplaceLinks?.map((item) => {
      if (marketplace === item.platform) {
        window.open(`//${item.url}`, "_blank");
      }
      return true;
    });
  };

  useEffect(() => {
    if (props?.isModalOpen) {
      if (props.userType === "Partner") {
        (async () => {
          const res = await GetPartner(props.selectedPartnerID);
          setData(res.data);
        })();
      } else if (props.userType === "BrandList") {
        (async () => {
          const res = await GetBrand(props.selectedRequestBrandID);
          setData(res.data);
        })();
        (async () => {
          const res = await GetBrandMarketPreference(
            props.selectedRequestBrandID
          );
          setPreferredServices(res?.data);
        })();
      } else if (props.userType === "PartnerList") {
        (async () => {
          const res = await GetPartner(props.selectedRequestBrandID);
          setData(res.data);
        })();
        (async () => {
          const res = await GetPartnerServicesList(
            props.selectedRequestBrandID
          );
          setPreferredServices(res.data.services);
        })();
      } else {
        (async () => {
          const res = await GetBrandRequests(
            props.selectedRequestBrandID
              ? props.selectedRequestBrandID
              : props.selectedRequest?.brandid
              ? props.selectedRequest?.brandid
              : params.id
          );
          setData({ rows: res.data });
        })();
      }
    }
  }, [params.id, props]);

  useEffect(() => {
    if (props.userType === "Partner") {
      setPreferredMarket(
        props.selectedRequest?.country
          ? props.selectedRequest?.country
          : props.selectedRequest?.focusmarket
          ? props.selectedRequest?.focusmarket
          : "NA"
      );
      setPreferredServices([]);
      setPartnerName(data?.company_name);
      setStatus(data?.status);
      setWebsite(data?.website ? data?.website : "NA");
      setCountry(data?.region ? data.region.name : "NA");
      // setFocusCategory(
      //   data?.partner_categories[0]
      //     ? data?.partner_categories[0]?.categories?.name
      //     : "NA"
      // );
      setFocusCategory(data?.partner_categories || []);
      // (async () => {
      //   const res = await GetAllServices();
      //   setPreferredServices([]);
      //   res.data?.post_launch_service?.map((service: { display_name: any }) => {
      //     setPreferredServices((prevState: any) => [
      //       ...prevState,
      //       service.display_name,
      //     ]);
      //     return true;
      //   });
      //   res.data?.pre_launch_service?.map((service: { display_name: any }) => {
      //     setPreferredServices((prevState: any) => [
      //       ...prevState,
      //       service.display_name,
      //     ]);
      //     return true;
      //   });
      // })();

      (async () => {
        const res = await GetPartnerServicesList(props.selectedPartnerID);
        setPreferredServices([]);
        console.log(props.selectedRequest);
        const country = res?.data?.services?.filter((item: any) => {
          if (props.selectedRequest?.focusmarket) {
            return item.regions.name === props.selectedRequest?.focusmarket;
          } else {
            return item.regions.name === props.selectedRequest?.country;
          }
        });
        console.log(country);
        if (country[0].pre_launch_services.importation_clearance === true) {
          setPreferredServices((prevState: any) => [
            ...prevState,
            "Importation Clearance",
          ]);
        }
        if (country[0].pre_launch_services.warehousing_storage === true) {
          setPreferredServices((prevState: any) => [
            ...prevState,
            "Warehousing Storage",
          ]);
        }
        if (country[0].pre_launch_services.regulatory_compliance === true) {
          setPreferredServices((prevState: any) => [
            ...prevState,
            "Regulatory Compliance",
          ]);
        }
        if (country[0].post_launch_services.ecom_setup_mgmt === true) {
          setPreferredServices((prevState: any) => [
            ...prevState,
            "Ecommerce Setup & Management",
          ]);
        }
        if (country[0].post_launch_services.marketing === true) {
          setPreferredServices((prevState: any) => [...prevState, "Marketing"]);
        }
        if (country[0].post_launch_services.retail_distribution === true) {
          setPreferredServices((prevState: any) => [
            ...prevState,
            "Retail Distribution",
          ]);
        }
        if (country[0].post_launch_services.order_fulfilment === true) {
          setPreferredServices((prevState: any) => [
            ...prevState,
            "Order Fulfillment",
          ]);
        }
      })();

      //  (async () => {
      // const response = await GetBrandDocuments(data?.id);
      // setBrandDocuments([]);
      // response.data.rows.map(
      //   (dataItem: { document_settings: any; path: any }) => {
      //     setBrandDocuments((prevState) => [
      //       ...prevState,
      //       {
      //         name: dataItem.document_settings.document_name,
      //         path: dataItem.path,
      //       },
      //     ]);
      //     return true;
      //   }
      // );
      //})();
    } else if (props.userType === "PartnerList") {
      setBrandName(data?.company_name);
      setStatus(data?.status);
      setWebsite(data?.website ? data?.website : "NA");
      // setFocusCategory(
      //   data?.partner_categories[0]
      //     ? data?.partner_categories[0]?.categories?.name
      //     : "NA"
      // );
      setFocusCategory(data?.partner_categories || []);

      setCountry(data?.region.name);
      // const getBrandDocData = async () => {
      //   const response = await GetBrandDocuments(data?.id);
      //   setBrandDocuments([]);
      //   response.data.rows.map(
      //     (dataItem: { document_settings: any; path: any }) => {
      //       setBrandDocuments((prevState) => [
      //         ...prevState,
      //         {
      //           name: dataItem.document_settings.document_name,
      //           path: dataItem.path,
      //         },
      //       ]);
      //       return true;
      //     }
      //   );
      // };
      // getBrandDocData();
    } else if (props.userType === "BrandList") {
      setBrandName(data?.brand_name);
      setStatus(data?.status);
      setWebsite(data?.website ? data?.website : "NA");
      // setFocusCategory(data?.brand_categories[0]?.categories?.name);
      setFocusCategory(data?.brand_categories || []);

      (async () => {
        const response = await GetBrand(data?.id);
        setSocialLinks([]);
        setMarketplaceLinks([]);
        response?.data?.social_media?.map((item: any) => {
          const socialdata = {
            platform: item.platform,
            url: item.url,
          };
          setSocialLinks((prevState) => [...prevState, socialdata]);
          return true;
        });
        response?.data?.market_place?.map((item: any) => {
          const marketplaceData = {
            platform: item.platform,
            url: item.url,
          };
          setMarketplaceLinks((prevState) => [...prevState, marketplaceData]);
          return true;
        });
      })();

      // const getBrandDocData = async () => {
      //   const response = await GetBrandDocuments(data?.id);
      //   setBrandDocuments([]);
      //   // response.data.rows.map((dataItem: { filename: any; path: any }) => {
      //   //   setBrandDocuments((prevState) => [
      //   //     ...prevState,
      //   //     { name: dataItem.filename, path: dataItem.path },
      //   //   ]);
      //   //   return true;
      //   // });
      //   response.data.rows.map(
      //     (dataItem: { document_settings: any; path: any }) => {
      //       setBrandDocuments((prevState) => [
      //         ...prevState,
      //         {
      //           name: dataItem.document_settings.document_name,
      //           path: dataItem.path,
      //         },
      //       ]);
      //       return true;
      //     }
      //   );
      // };
      // getBrandDocData();
    } else {
      data?.rows?.map(async (row: any) => {
        // Set social media links
        // Set country, category, preferred market and model
        if (
          row.id === props.selectedRequest?.brandid ||
          row.id === props.selectedRequestID
        ) {
          if (row.brand) {
            const countries = await GetCountries();
            console.log(countries);
            const selectedCountry = countries?.data?.filter(
              (country: any) =>
                country.id === row?.brand?.brand_organization?.country_of_origin
            );
            setCountry(
              selectedCountry[0]?.name ? selectedCountry[0]?.name : "NA"
            );
          }
          setBrandName(row?.brand?.brand_name);

          if (row.status === "Interest Expressed") {
            setStatus("Interest Received");
          } else if (row.status === "Interest Accepted") {
            setStatus("All Partners Matched");
          } else {
            setStatus(row.status);
            console.log(row);
          }
          console.log(props.selectedRequest);
          setPreferredMarket(
            props.selectedRequest?.country
              ? props.selectedRequest?.country
              : props.selectedRequest?.preferredmarket
              ? props.selectedRequest?.preferredmarket
              : "NA"
          );
          setWebsite(row.brand.website);
          setFocusCategory(row?.brand?.brand_categories || []);
          setPreferredModel(row.engagement_model.display_name);
          setPreferredServices([]);
          row?.brand_services?.map(
            (data: { service: { display_name: string } }) => {
              setPreferredServices((prevState: any) => [
                ...prevState,
                data.service.display_name,
              ]);
              return true;
            }
          );
          (async () => {
            const response = await GetBrand(row.brand_id);

            setSocialLinks([]);
            setMarketplaceLinks([]);
            response?.data?.social_media?.map((item: any) => {
              const socialdata = {
                platform: item.platform,
                url: item.url,
              };
              setSocialLinks((prevState) => [...prevState, socialdata]);
              return true;
            });
            response?.data?.market_place?.map((item: any) => {
              const marketplaceData = {
                platform: item.platform,
                url: item.url,
              };
              setMarketplaceLinks((prevState) => [
                ...prevState,
                marketplaceData,
              ]);
              return true;
            });
          })();

          // const getBrandDocData = async () => {
          //   const response = await GetBrandDocuments(row.brand_id);
          //   console.log(response);
          //   setBrandDocuments([]);
          //   // response.data.rows.map((dataItem: { filename: any; path: any }) => {
          //   //   setBrandDocuments((prevState) => [
          //   //     ...prevState,
          //   //     { name: dataItem.filename, path: dataItem.path },
          //   //   ]);
          //   //   return true;
          //   // });
          //   response.data.rows.map(
          //     (dataItem: { document_settings: any; path: any }) => {
          //       setBrandDocuments((prevState) => [
          //         ...prevState,
          //         {
          //           name: dataItem.document_settings.document_name,
          //           path: dataItem.path,
          //         },
          //       ]);
          //       return true;
          //     }
          //   );
          // };
          // getBrandDocData();
          return true;
        }

        return true;
      });
    }
  }, [data, props, params]);

  const downloadFile = (path: string) => {
    window.open(path);
  };

  return (
    <div>
      <Modal
        className="detailsdisplay-modal"
        show={props.isModalOpen}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="font18-medium gray-800">
            {props.userType === "Partner" ? partnerName : brandName}
          </Modal.Title>

          <div className="status-badge">
            <span className="font10-medium primary-blue"> {status}</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          {props.userType !== "Partner" && props.userType !== "PartnerList" && (
            <Row className="socialmedia-row spacing-row">
              <Col xl={4} lg={4} sm={4}>
                <div className="font12-medium gray-800">Social Profiles </div>
              </Col>
              <Col xl={1} lg={1} sm={1}>
                <div className="font12-regular gray-500">:</div>
              </Col>
              {socialLinks.length !== 0 &&
                socialLinks.map((item) => {
                  if (item.platform === "Facebook") {
                    return (
                      <Col className="value-space">
                        <img
                          src="../svgs/facebook.svg"
                          alt="svg"
                          onClick={() => {
                            handleSocialMediaClick("Facebook");
                          }}
                        />
                      </Col>
                    );
                  } else if (item.platform === "LinkedIn") {
                    return (
                      <Col className="value-space">
                        <img
                          src="../svgs/linkedin.svg"
                          alt="svg"
                          onClick={() => {
                            handleSocialMediaClick("LinkedIn");
                          }}
                        />
                      </Col>
                    );
                  } else if (item.platform === "Instagram") {
                    return (
                      <Col className="value-space">
                        <img
                          src="../svgs/instagram.svg"
                          alt="svg"
                          onClick={() => {
                            handleSocialMediaClick("Instagram");
                          }}
                        />
                      </Col>
                    );
                  } else if (item.platform === "Twitter") {
                    return (
                      <Col className="value-space">
                        <img
                          src="../svgs/twitter.svg"
                          alt="svg"
                          onClick={() => {
                            handleSocialMediaClick("Twitter");
                          }}
                        />
                      </Col>
                    );
                  } else {
                    return (
                      <Col className="value-space">
                        <div className="font12-regular gray-500">NA</div>
                      </Col>
                    );
                  }
                })}
              {socialLinks.length === 0 && (
                <Col className="value-space">
                  <div className="font12-regular gray-500">NA</div>
                </Col>
              )}
            </Row>
          )}
          {props.userType !== "Partner" && props.userType !== "PartnerList" && (
            <Row className="socialmedia-row spacing-row">
              <Col xl={4} lg={4} sm={4}>
                <div className="font12-medium gray-800">Marketplace Links </div>
              </Col>
              <Col xl={1} lg={1} sm={1}>
                <div className="font12-regular gray-500">:</div>
              </Col>
              {marketplaceLinks.length !== 0 &&
                marketplaceLinks.map((item) => {
                  if (item.platform === "Amazon") {
                    return (
                      <Col className="value-space">
                        <img
                          src="../svgs/amazon.svg"
                          alt="svg"
                          onClick={() => {
                            handleMarketplaceClick("Amazon");
                          }}
                        />
                      </Col>
                    );
                  } else if (item.platform === "Alibaba") {
                    return (
                      <Col className="value-space">
                        <img
                          src="../svgs/alibaba.svg"
                          alt="svg"
                          onClick={() => {
                            handleMarketplaceClick("Alibaba");
                          }}
                        />
                      </Col>
                    );
                  } else if (item.platform === "Flipkart") {
                    return (
                      <Col className="value-space">
                        <img
                          src="../svgs/flipkart.svg"
                          alt="svg"
                          onClick={() => {
                            handleMarketplaceClick("Flipkart");
                          }}
                        />
                      </Col>
                    );
                  } else if (item.platform === "Shoppee") {
                    return (
                      <Col className="value-space">
                        <img
                          src="../svgs/shopee.svg"
                          alt="svg"
                          onClick={() => {
                            handleMarketplaceClick("Shoppee");
                          }}
                        />
                      </Col>
                    );
                  } else if (item.platform === "Nykaa") {
                    return (
                      <Col className="value-space">
                        <img
                          src="../svgs/nykaa-logo.svg"
                          alt="svg"
                          onClick={() => {
                            handleMarketplaceClick("Nykaa");
                          }}
                        />
                      </Col>
                    );
                  } else if (item.platform === "1mg") {
                    return (
                      <Col className="value-space">
                        <img
                          src="../svgs/tata-1mg-logo.svg"
                          alt="svg"
                          onClick={() => {
                            handleMarketplaceClick("1mg");
                          }}
                        />
                      </Col>
                    );
                  } else {
                    return (
                      <Col className="value-space">
                        <div className="font12-regular gray-500">NA</div>
                      </Col>
                    );
                  }
                })}

              {marketplaceLinks.length === 0 && (
                <Col className="value-space">
                  <div className="font12-regular gray-500">NA</div>
                </Col>
              )}
            </Row>
          )}

          <Row className="spacing-row">
            <Col xl={4} lg={4} sm={4}>
              <div className="font12-medium gray-800">Website</div>
            </Col>
            <Col xl={1} lg={1} sm={1}>
              <div className="font12-regular gray-500">:</div>
            </Col>
            <Col className="value-space">
              <div
                className="font12-regular gray-500"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (website !== "NA") {
                    window.open(`//${website}`, "_blank");
                  }
                }}
              >
                {website}
              </div>
            </Col>
          </Row>
          {props.userType !== "BrandList" && (
            <Row className="spacing-row">
              <Col xl={4} lg={4} sm={4}>
                <div className="font12-medium gray-800">Country Of Origin</div>
              </Col>
              <Col xl={1} lg={1} sm={1}>
                <div className="font12-regular gray-500">:</div>
              </Col>
              <Col className="value-space">
                <div className="font12-regular gray-500">{country}</div>
              </Col>
            </Row>
          )}
          {/* <Row className="spacing-row category-row">
            <Col xl={4} lg={4} sm={4}>
              <div className="font12-medium gray-800">Category</div>
            </Col>
            <Col xl={1} lg={1} sm={1}>
              <div className="font12-regular gray-500">:</div>
            </Col>
            <Col className="value-space">
              <div className="font12-regular gray-500">{focusCategory}</div>
            </Col>
          </Row> */}

          {props.userType !== "BrandList" && props.userType !== "PartnerList" && (
            <Row className="spacing-row">
              <Col xl={4} lg={4} sm={4}>
                <div className="font12-medium gray-800">Market</div>
              </Col>
              <Col xl={1} lg={1} sm={1}>
                <div className="font12-regular gray-500">:</div>
              </Col>
              <Col className="value-space">
                <div className="font12-regular gray-500">{preferredmarket}</div>
              </Col>
            </Row>
          )}
          {(props.userType === "BrandList" ||
            props.userType === "PartnerList") && (
            <>
              <Row className="spacing-row">
                <Col xl={12}>
                  <div className="font12-medium gray-800">Markets</div>
                </Col>
              </Row>
              <Row className="spacing-row prefservice-row">
                <Col className="prefservice-col" xs={12}>
                  {preferredservices.market_preference
                    ? preferredservices.market_preference.map(
                        (service: any) => {
                          return (
                            <div className="font12-regular gray-500">
                              {service.regions ? service.regions.name : service}
                            </div>
                          );
                        }
                      )
                    : preferredservices.map((service: any) => {
                        return (
                          <div className="font12-regular gray-500">
                            {service.regions ? service.regions.name : service}
                          </div>
                        );
                      })}
                </Col>
              </Row>
            </>
          )}
          {props.userType !== "Partner" &&
            props.userType !== "BrandList" &&
            props.userType !== "PartnerList" && (
              <Row className="spacing-row prefmodel-row">
                <Col xl={4} lg={4} sm={4}>
                  <div className="font12-medium gray-800">Preferred Model</div>
                </Col>
                <Col xl={1} lg={1} sm={1}>
                  <div className="font12-regular gray-500">:</div>
                </Col>
                <Col className="value-space">
                  <div className="font12-regular gray-500">
                    {preferredmodel}
                  </div>
                </Col>
              </Row>
            )}
          {focusCategory.length !== 0 && (
            <Row className="spacing-row">
              <Col>
                <div className="font12-medium gray-800">Categories</div>
              </Col>
            </Row>
          )}
          {focusCategory.length !== 0 && (
            <Row className="spacing-row cat-row">
              <Col className="cat-col">
                {focusCategory.map((itm: any) => {
                  return (
                    <div key={itm.id} className="font12-regular gray-500">
                      {itm.categories.name}
                    </div>
                  );
                })}
              </Col>
            </Row>
          )}
          {props.userType !== "BrandList" && props.userType !== "PartnerList" && (
            <Row className="spacing-row">
              <Col xl={12}>
                {props.userType === "Partner" ? (
                  <div className="font12-medium gray-800">
                    Services Provided
                  </div>
                ) : (
                  <div className="font12-medium gray-800">
                    Required Services
                  </div>
                )}
              </Col>
            </Row>
          )}
          {props.userType !== "BrandList" && props.userType !== "PartnerList" && (
            <Row className="spacing-row prefservice-row">
              <Col className="prefservice-col" xs={12}>
                {preferredservices &&
                  preferredservices?.map((service: any) => {
                    return (
                      <div className="font12-regular gray-500">
                        {service.regions ? service.regions.name : service}
                      </div>
                    );
                  })}
              </Col>
            </Row>
          )}
          {props.publicFiles.length !== 0 && (
            <Row className="spacing-row">
              <Col>
                <div className="font12-medium gray-800">Files</div>
              </Col>
            </Row>
          )}
          {props.publicFiles.length !== 0 && (
            <Row className="spacing-row files-row">
              <Col className="cat-col">
                {props.publicFiles.map((itm: any) => {
                  return (
                    <div
                      key={itm.id}
                      className="font12-regular gray-500 file"
                      onClick={() => downloadFile(itm.path)}
                    >
                      {itm.original_name}
                    </div>
                  );
                })}
              </Col>
            </Row>
          )}
          {/* <Row className="spacing-row">
            <Col xl={4} lg={4} sm={4}>
              <div className="font12-medium gray-800">Documents</div>
            </Col>
          </Row>
          {BrandDocuments &&
            BrandDocuments.map((document) => {
              return (
                <Row className="documents-row">
                  <Col xl={6} lg={6} sm={6}>
                    <img src="../svgs/file-earmark-text.svg" alt="svg" />
                    <a className="font12-regular gray-500" href={document.path}>
                      {document.name}
                    </a>
                  </Col>
                </Row>
              );
            })} */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DetailsDisplayModal;
