import "../../../../styles/modals/modal700.scss";
import "./UploadModal.scss";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ImageUpload from "../../../components/fileInput/ImageUpload";
import DocumentUpload from "../../../components/fileInput/DocumentUpload";
import PrimaryMessage from "../../../components/Alerts/PrimaryMessage";
import WarningAlert from "../../../components/Alerts/WarningAlert";
import { useState } from "react";
import Switch from "../../../components/inputs/Switch";
import UploadedFile from "./UploadedFile";
import UrlSelector from "./UrlSelector";

export interface PropsUploadModal {
  isModalOpen: boolean;
  onCancel: () => void;
  isUrlRequired: boolean;
  handleUrlRequired: (e: boolean) => void;
  urlTypes: any;
  fileTypeId: any;
  adminNotes: string;
  fileFormat: string;
  fileFormatList: any;
  documents: any;
  handleDocuments: (docs: any) => void;
  images: any;
  handleImages: (imgs: any) => void;
  urlSelectors: any;
  handleUrlSelectorls: (urlList: any) => void;
  onDocumentSubmit: (documents: any, urls: any) => void;
  onImageSubmit: (images: any, urls: any) => void;
  onVideoUrlSubmit: (urls: any) => void;
  submitting: boolean;
}
const UploadModal: React.FunctionComponent<PropsUploadModal> = ({
  isModalOpen,
  onCancel,
  isUrlRequired,
  handleUrlRequired,
  urlTypes,
  fileTypeId,
  adminNotes,
  fileFormat,
  fileFormatList,
  documents,
  handleDocuments,
  images,
  handleImages,
  urlSelectors,
  handleUrlSelectorls,
  onDocumentSubmit,
  onImageSubmit,
  onVideoUrlSubmit,
  submitting,
}) => {
  const [showAdminAlert, setShowAdminAlert] = useState(true);
  const [urlInfo, setUrlInfo] = useState(true);
  const handleClose = () => {
    onCancel();
  };
  const handleImageUpload = (event: any) => {
    console.log(fileFormat);
    if (images.length + event.length > 5) {
      return;
    }
    const formatedList = fileFormatList.map((itm: any) => {
      if (itm === 4) {
        return {
          format: "png",
        };
      } else if (itm === 5) {
        return {
          format: ["jpeg", "jpg"],
        };
      } else if (itm === 6) {
        return {
          format: "svg",
        };
      } else {
        return [];
      }
    });
    console.log(formatedList);
    for (let i = 0; i < event.length; i++) {
      let ext = event[i].name.split(".").pop();
      let result =
        formatedList.find((itm: any) => itm.format === ext) ||
        formatedList.find((itm: any) => itm.format.includes(ext));
      if (result) {
      } else {
        return;
      }
    }
    handleImages([...images, ...event]);
  };
  const handleDocumentUpload = (event: any) => {
    if (documents.length + event.length > 5) {
      return;
    }
    const formatedList = fileFormatList.map((itm: any) => {
      if (itm === 1) {
        return {
          format: "pdf",
        };
      } else if (itm === 2) {
        return {
          format: "ppt",
        };
      } else if (itm === 3) {
        return {
          format: "doc",
        };
      } else {
        return [];
      }
    });
    for (let i = 0; i < event.length; i++) {
      let ext = event[i].name.split(".").pop();
      let result = formatedList.find((itm: any) => itm.format === ext);
      if (result) {
      } else {
        return;
      }
    }
    handleDocuments([...documents, ...event]);
  };
  const closeAdminNoteAlert = () => setShowAdminAlert(false);
  const closeUrlInfo = () => setUrlInfo(false);
  const handleUrlChecked = (e: boolean) => handleUrlRequired(e);

  const urlTypeChanged = (e: any, index: any) => {
    const urlData = [...urlSelectors];
    urlData[index] = {
      url_type_id: e,
      url: urlData[index].url,
      url_notes: urlData[index].url_notes,
    };
    handleUrlSelectorls(urlData);
  };
  const urlChanged = (e: any, index: any) => {
    const urlData = [...urlSelectors];
    urlData[index] = {
      url: e,
      url_type_id: urlData[index].url_type_id,
      url_notes: urlData[index].url_notes,
    };
    handleUrlSelectorls(urlData);
  };
  const urlNotesChanged = (e: any, index: any) => {
    const urlData = [...urlSelectors];
    urlData[index] = {
      url: urlData[index].url,
      url_type_id: urlData[index].url_type_id,
      url_notes: e,
    };
    handleUrlSelectorls(urlData);
  };
  const addNewUrl = () => {
    const urlDefaultData = {
      url_type_id: "",
      url: "",
      url_notes: "",
    };
    const newVal = [...urlSelectors, urlDefaultData];
    handleUrlSelectorls(newVal);
  };
  const deleteDocument = (e: React.Key | null | undefined) => {
    const deletedList = documents.filter(
      (item: any, index: React.Key | null | undefined) => index !== e
    );
    handleDocuments(deletedList);
  };

  const deleteImage = (e: React.Key | null | undefined) => {
    const deletedList = images.filter(
      (item: any, index: React.Key | null | undefined) => index !== e
    );
    handleImages(deletedList);
  };

  const handleSubmit = () => {
    if (fileTypeId === 1) {
      onDocumentSubmit(documents, urlSelectors);
    } else if (fileTypeId === 2) {
      onImageSubmit(images, urlSelectors);
    } else if (fileTypeId === 3) {
      onVideoUrlSubmit(urlSelectors);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Modal
            show={isModalOpen}
            onHide={handleClose}
            size="lg"
            className="modal700"
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title className="font18-medium gray-800">
                Add File/URL
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form">
                <Container fluid>
                  <Row className="row-body mt24px">
                    <Col className="p-0">
                      {fileTypeId === 2 && (
                        <ImageUpload
                          handleImageUpload={handleImageUpload}
                          requirement="Upload images maximum 5 at a time"
                          fileAccept={fileFormat}
                        />
                      )}
                      {fileTypeId === 1 && (
                        <DocumentUpload
                          handleDocumentUpload={handleDocumentUpload}
                          requirement="Upload documents maximum 5 at a time"
                          fileAccept={fileFormat}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="row-body mt20px">
                    <Col className="p-0">
                      <PrimaryMessage
                        isAlertOpen={showAdminAlert}
                        headingText="Admin Notes"
                        bodyText={adminNotes}
                        dismissible={false}
                        onCancel={closeAdminNoteAlert}
                      />
                    </Col>
                  </Row>
                  {documents.length !== 0 && (
                    <Row className="row-body mt34px">
                      <Col className="p-0 overflow-auto">
                        <table style={{ height: "130px" }}>
                          <tbody>
                            <tr>
                              {documents.map((itm: any, index: any) => {
                                return (
                                  <td key={index}>
                                    <UploadedFile
                                      index={index}
                                      handleDelete={deleteDocument}
                                      fileName={itm.name}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  )}
                  {images.length !== 0 && (
                    <Row className="row-body mt34px">
                      <Col className="p-0 overflow-auto">
                        <table style={{ height: "130px" }}>
                          <tbody>
                            <tr>
                              {images.map((itm: any, index: any) => {
                                return (
                                  <td key={index}>
                                    <UploadedFile
                                      index={index}
                                      handleDelete={deleteImage}
                                      fileName={itm.name}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  )}
                  <Row className="row-body mt40px">
                    <Col className="p-0">
                      <Switch
                        label="I would like to attach a url of the file"
                        change={handleUrlChecked}
                      />
                    </Col>
                  </Row>
                  {isUrlRequired && (
                    <Row className="row-body mt20px">
                      <Col className="p-0">
                        <WarningAlert
                          isAlertOpen={urlInfo}
                          headingText="Info"
                          bodyText="Our system accepts only URL’s from Google Drive, 
                                                OneDrive, and Dropbox. Please make sure to provide a direct 
                                                URL from one of these sources. URL’s from any other source 
                                                will not be accepted."
                          dismissible={true}
                          onCancel={closeUrlInfo}
                        />
                      </Col>
                    </Row>
                  )}
                  {isUrlRequired && (
                    <Row className="row-body mt24px">
                      <Col className="p-0">
                        {urlSelectors?.map((itm: any, index: any) => {
                          return (
                            <UrlSelector
                              key={index}
                              item={itm}
                              urlTypes={urlTypes}
                              index={index}
                              urlTypeChanged={urlTypeChanged}
                              urlChanged={urlChanged}
                              urlNotesChanged={urlNotesChanged}
                            />
                          );
                        })}
                        {urlSelectors.length < 5 && (
                          <Button className="plus-btn-blue" onClick={addNewUrl}>
                            <img src="../svgs/plus-blue.svg" alt="plus-blue" />
                            <span>Add New</span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col className="p-0">
                      <div className="btn-footer">
                        <Button
                          className="btn-secondary12"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-primary12"
                          onClick={handleSubmit}
                          disabled={submitting === true ? true : false}
                        >
                          Add
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default UploadModal;
