import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./documentSettings.scss";
// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
// Components
import TableMUI from "../components/TableMUI/TableMUI";
import Sidebar from "../components/sidebar/Sidebar";
import { documentsUploadTable } from "../components/TableMUI/TableColumns";
import AddDocumentPopup from "./AddDocumentPopup";
import {
  FetchDocumentFromID,
  GetDocuments,
  GetDocumentSettings,
} from "../../API/endpoints/document";
import { GetBrand } from "../../API/endpoints/Brands";
import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import Breadcrumb from "../components/header/BreadCrumb";

function BrandDocuments() {
  const navigate = useNavigate();
  const params = useParams();
  const ID = params.id;
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [documentID, setDocumentID] = useState("");
  const [documentSettings, setDocumentSettings] = useState<{ data?: any }>({});
  const [selectedDocument, setSelectedDocument] = useState<any>();
  const [documents, setDocuments] = useState<{ data?: any }>({});
  const [dataCount, setDataCount] = useState(0);
  const [listPage, setListPage] = useState(0);
  const [brandName, setBrandName] = useState<string>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  let rows: any = [];
  useEffect(() => {
    setUserID(Cookies.get("userID"));
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response => {
        }))
        .catch((error) => {
          navigate("/");
        })
    })()

    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });

    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID)
        if (response?.data) {
          setUserProfile(response?.data)
        }
      })();
    }
  }, [ navigate, userID]);

  useEffect(() => {
    (async () => {
      const documentSettingsList = await GetDocumentSettings("1", "");
      setDocumentSettings(documentSettingsList);
      console.log(documentSettingsList);
      documentSettingsList.data?.count &&
        setDataCount(documentSettingsList.data?.count);
    })();
    (async () => {
      const documentsList = await GetDocuments(ID);
      console.log(documentsList);
      setDocuments(documentsList);
    })();
    (async () => {
      const res = await GetBrand(ID);
      console.log(res.data);
      setBrandName(res.data.brand_name);
    })();
  }, [ID, flag]);

  documentSettings.data?.rows &&
    documentSettings.data?.rows.map((dataItem: any) => {
      const newItem = {
        documentname: dataItem.document_name,
        documenttype: dataItem.document_type,
        id: dataItem.id,
      };
      rows = [...rows, newItem];
      return true;
    });

  let newRow: any[] = [];

  rows[0] &&
    rows.map((data: any) => {
      documents.data?.rows &&
        documents.data?.rows.map((dataItem: any) => {
          console.log(dataItem.document_settings.id, data.id);
          if (dataItem.document_settings.id === data.id) {
            const updatedRowItem = {
              ...data,
              filename: dataItem.filename,
              action: "Delete",
              dataitemid: dataItem.id,
            };
            data = updatedRowItem;
          }
          return true;
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      newRow = [...newRow, data];
      return data;
    });

  const routetodocument = (id: any) => {
    console.log(id);
    (async () => {
      const fetchedDoc = await FetchDocumentFromID(id);
      //   console.log(fetchedDoc.data.path);
      window.open(fetchedDoc.data.path);
    })();
  };

  const columns = documentsUploadTable(
    setDocumentID,
    setShow,
    setSelectedDocument,
    routetodocument
  );
  const handleClose = () => setShow(false);
  const breadcrumbData = [
    {
      name: " BRAND MANAGEMENT",
      url: "/BrandManagement",
      active: false,
    },
    {
      name: brandName || "",
      url: "",
      active: false,
    },
    {
      name: "MANAGE DOCUMENTS",
      url: "",
      active: true,
    }
  ];

  return (
    <Container fluid className="pge-layout">
      <Row>
        <Col className="sidebar">
          <Sidebar active="brand" />
        </Col>

        <Col className="content-section p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb breadcrumbData={breadcrumbData} />
          <AddDocumentPopup
            isModalOpen={show}
            onCancel={handleClose}
            setFlag={setFlag}
            flag={flag}
            documentID={documentID}
            ID={ID}
            selectedDocument={selectedDocument}
          />

          <div className="table-mui mt16px">
            <TableMUI
              columns={columns}
              rows={newRow}
              dataCount={dataCount}
              setListPage={setListPage}
              listPage={listPage}
              height={"85vh"}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default BrandDocuments;
