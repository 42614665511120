import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetBrand,
  SaveBrandDigitalInfo,
  UpdateBrandDigitalInfo,
} from "../../../../API/endpoints/Brands";
import BrandDigitalPresenceForm from "../../../components/forms/brandDigitalPresenceForm";
import { MarketPlaceLinksDTO, SocialLinksDTO } from "../../../dtos/brand.dtos";
import "./../CreateBrand.scss";

interface BrandDigitalPresenceDTO {
  brandID: string;
  setDefaultTab: (tab: string) => void;
  brand?: string;
  handleNotification: (type: string, msg: string) => void;
  permissions: any;
}

function BrandDigitalPresence(props: BrandDigitalPresenceDTO) {
  const params = useParams();
  const [isError, setisError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [socialName, setSocialName] = useState<string>("");
  const [socialFollowers, setSocialFollowers] = useState<string>("");
  const [socialLink, setSocialLink] = useState<string>("");
  const [socialLinks, setSocialLinks] = useState<SocialLinksDTO[]>([]);
  const [brandWebsite, setBrandWebsite] = useState<string>("");
  const [marketplaceName, setMarketplaceName] = useState<string>("");
  const [marketplaceRating, setMarketplaceRating] = useState<string>("");
  const [marketplaceURL, setMarketplaceURL] = useState<string>("");
  const [errors, setErrors] = useState({
    rating: "",
  });
  const [marketplaceLinks, setMarketplaceLinks] = useState<
    MarketPlaceLinksDTO[]
  >([]);
  const brandID = props.brandID ? props.brandID : "";
  const navigate = useNavigate();

  useEffect(() => {
    if (params.brandid) {
      let brand = params.brandid;
      const getBrandData = async () => {
        const response = await GetBrand(brand);
        setBrandWebsite(response.data.website);
        setSocialLinks([]);
        response?.data?.social_media?.map((item: any) => {
          const socialdata = {
            platform: item.platform,
            number_of_followers: item.number_of_followers,
            url: item.url,
          };
          setSocialLinks((prevState) => [...prevState, socialdata]);
          return true;
        });
        setMarketplaceLinks([]);
        response?.data.market_place?.map((item: any) => {
          const marketplacedata = {
            platform: item.platform,
            store_rating: item.store_rating,
            url: item.url,
          };

          setMarketplaceLinks((prevState) => [...prevState, marketplacedata]);

          return true;
        });
      };
      getBrandData();
    }
  }, [params.brandid]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);
    const brandDigitalInfo: any = {
      brand_id: brandID,
    };

    if (brandWebsite) brandDigitalInfo.website = brandWebsite;
    if (socialLinks.length > 0) brandDigitalInfo.social_media = socialLinks;
    if (marketplaceLinks.length > 0)
      brandDigitalInfo.market_place = marketplaceLinks;

    const AddDigitalInfo = async () => {
      const response = await SaveBrandDigitalInfo(brandDigitalInfo);
      if (
        props?.permissions[0]?.sub_modules[2]?.permissions_actions[1]
          ?.permission[0]?.permission === true
      ) {
        props?.setDefaultTab("social-info");
      } else {
        navigate("/BrandManagement");
      }
      if (
        response &&
        props?.permissions[0]?.sub_modules[2]?.permissions_actions[1]
          ?.permission[0]?.permission === true
      ) {
        props.setDefaultTab("market-info");
      } else if (response) {
        props.handleNotification("success", "Added successfully");
      } else {
        setisError(true);
      }
    };

    const UpdateDigitalInfo = async () => {
      const response = await UpdateBrandDigitalInfo(brandDigitalInfo);
      if (response) {
        console.log(response);
        props.handleNotification("success", "Updated successfully");
      } else {
        setisError(true);
      }
    };

    if (params.brandid) {
      UpdateDigitalInfo();
    } else {
      AddDigitalInfo();
    }
    setSubmitting(false);
  };

  const addSocialMedia = () => {
    let social = {
      platform: socialName,
      number_of_followers: socialFollowers,
      url: socialLink,
    };
    setSocialLinks([...socialLinks, social]);
    setSocialLink("");
    setSocialName("");
    setSocialFollowers("");
  };

  const deleteSocialLink = (Link: string) => {
    let newSocialLinks = socialLinks.filter(
      (socialLink) => socialLink.url !== Link
    );
    setSocialLinks(newSocialLinks);
  };

  const addMarketplace = () => {
    let marketplace = {
      platform: marketplaceName,
      store_rating: marketplaceRating,
      url: marketplaceURL,
    };

    if (Number(marketplaceRating) >= 0 && Number(marketplaceRating) <= 5) {
      setErrors({ rating: "" });
      setMarketplaceLinks([...marketplaceLinks, marketplace]);
      setMarketplaceName("");
      setMarketplaceRating("");
      setMarketplaceURL("");
    } else {
      setErrors({ rating: "Store rating must be between 0 and 5" });
    }
  };

  const deleteMarketplaceLink = (Link: string) => {
    let newMarketplaceLinks = marketplaceLinks.filter(
      (marketplaceLink) => marketplaceLink.url !== Link
    );
    setMarketplaceLinks(newMarketplaceLinks);
  };

  return (
    <BrandDigitalPresenceForm
      submitting={submitting}
      socialName={socialName}
      socialFollowers={socialFollowers}
      socialLink={socialLink}
      socialLinks={socialLinks}
      brandWebsite={brandWebsite}
      marketplaceName={marketplaceName}
      marketplaceRating={marketplaceRating}
      marketplaceURL={marketplaceURL}
      marketplaceLinks={marketplaceLinks}
      errors={errors}
      setErrors={setErrors}
      isError={isError}
      setSocialName={setSocialName}
      setSocialFollowers={setSocialFollowers}
      setSocialLink={setSocialLink}
      setSocialLinks={setSocialLinks}
      setBrandWebsite={setBrandWebsite}
      addSocialMedia={addSocialMedia}
      deleteSocialLink={deleteSocialLink}
      setMarketplaceName={setMarketplaceName}
      setMarketplaceRating={setMarketplaceRating}
      setMarketplaceURL={setMarketplaceURL}
      addMarketplace={addMarketplace}
      deleteMarketplaceLink={deleteMarketplaceLink}
      onSubmit={onSubmit}
      permissions={props.permissions}
    />
  );
}

export default BrandDigitalPresence;
