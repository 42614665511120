import Auth from "@aws-amplify/auth";
import { getWith, postWith } from "../axios";

export interface GetCurrentUserResponse {}

// export const checkCurrentUser = async (
//   setUserType: React.Dispatch<React.SetStateAction<string>>,
//   setUserRole: React.Dispatch<React.SetStateAction<string>>
// ): Promise<void> => {
//   const { attributes } = await Auth.currentAuthenticatedUser();
//   axios
//     .get(
//       "https://devapi.itradehub.io/api/v1/user/getUserByCognitoId/" +
//         attributes["custom:cognito_id"]
//     )
//     .then((userData) => {
//       const userRole = userData.data.data.user_roles[0].roles.role_name;
//       const UserType = userData.data.data.user_type;
//       setUserType(UserType);
//       setUserRole(userRole);
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

export async function GetCurrentUser(): Promise<any> {
  try {
    const { attributes } = await Auth.currentAuthenticatedUser();
    const url =
      `${process.env.REACT_APP_API_URL}user/getUserByCognitoId/` +
      attributes["custom:cognito_id"];

    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetUserDetails(attributes: any): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/user/getUserByCognitoId/` +
      attributes["custom:cognito_id"];

    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function activateOrganization(orgID: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/organization/activate-org`;
    const data = { org_id: orgID };
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function activateUser(ID: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/activate-user`;
    const data = { id: ID };
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function InviteUser(ID: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/organization/invite-user`;
    return (await postWith<any, any>(url, { id: ID })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetUserProfile(id: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/` + id;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function getUserByCognitoId(id: string | undefined): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/user/getUserByCognitoId/` + id;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function getUserByEmailId(
  email_id: string | undefined
): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/user/getUserByEmailId/` + email_id;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
