import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";

export interface PasswordFormProps {
  forgotSubmitting: boolean;
  onSubmit: (password: string) => void;
  ForgotErrorMessage: string;
  showPasswordPage?: boolean;
  isForgotError?: boolean;
  email: string;
}

const PasswordForm: React.FunctionComponent<PasswordFormProps> = ({
  forgotSubmitting,
  onSubmit,
  ForgotErrorMessage,
  showPasswordPage,
  email,
}) => {
  const { register, formState, handleSubmit, watch } = useForm();
  const [submitted, setIsSubmitted] = useState<boolean>(false);
  const { errors } = formState;
  const password = useRef({});
  password.current = watch("password", "");
  const onHandleSubmit = (password: string) => {
    onSubmit(password);
  };
  return (
    <div className="passwordset">
      <div className="otp-verified d-flex align-items-center">
        <img src="../svgs/Featured icon.svg" alt="Featured icon" />
        <div className="font18-medium gray-800">Email Verified</div>
      </div>
      <div className="font18-medium gray-800 mt-3">Set a Password</div>
      <Form
        onSubmit={handleSubmit((data) => {
          setIsSubmitted(true);
          onHandleSubmit(data.password);
        })}
        data-testid={"password-form"}
      >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control disabled type="email" value={email} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            {...register("password", {
              required: "* This field is required",
              minLength: {
                value: 8,
                message: "* Password length too small",
              },
            })}
            // onChange={(e) => handlePassword(e.target.value)}
          />
          <Form.Text muted>
            <span>
              {errors?.password && (
                <p className="error-form">{String(errors.password?.message)}</p>
              )}
            </span>
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            {...register("password_repeat", {
              required: "* This field is required",
              minLength: {
                value: 8,
                message: "* Password length too small",
              },
              validate: (value) =>
                value === password.current || "The passwords do not match",
            })}
            // onChange={(e) => handleConfirmPassword(e.target.value)}
          />
          <Form.Text muted>
            <span>
              {errors?.password_repeat && (
                <p className="error-form">
                  {String(errors.password_repeat?.message)}
                </p>
              )}
            </span>
          </Form.Text>
        </Form.Group>
        <Button
          className="setpassword-btn"
          variant="primary"
          type="submit"
          disabled={submitted}
          //   onClick={handleSetNewPassword}
        >
          Set Password
        </Button>
      </Form>
    </div>
  );
};

export default PasswordForm;
