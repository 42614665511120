import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./CreateBrand.scss";
import "../../../styles/global.scss";
import Cookies from "js-cookie";
import Sidebar from "../../components/sidebar/Sidebar";

// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import {
  GetBrand,
  GetCategories,
  GetYearsOfOperation,
} from "../../../API/endpoints/Brands";

//DTOs
import {
  categoriesDTO,
  organizationsDTO,
  yearsOfOperationDTO,
} from "../../dtos/brand.dtos";

//Components
import BrandInfo from "./components/BrandInfo";
import { GetAllOrgFilterDeleted } from "../../../API/endpoints/Organization";
import BrandDigitalPresence from "./components/BrandDigitalPresence";
import BrandMarketPresence from "./components/BrandMarketPresence";
import Auth from "@aws-amplify/auth";
import { GetUserProfile } from "../../../API/endpoints/User";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/header/NavBar";
import {
  GetCountries,
  GetMarketPresenceCountries,
} from "../../../API/endpoints/Common";
import ToastMessage from "../../components/toasts/ToastMessage";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../../API/endpoints/RolesAndPermissions/Roles";

function CreateBrand() {
  const navigate = useNavigate();
  const params = useParams();
  const [defaultTab, setDefaultTab] = useState<any>("");
  const [brandID, setBrandID] = useState<string>("");
  const [org, setOrg] = useState<organizationsDTO[]>([]);
  const [years, setYears] = useState<yearsOfOperationDTO[]>([]);
  const [categories, setCategories] = useState<categoriesDTO[]>([]);
  const [newID, setNewID] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [allCountries, setAllCountries] = useState([]);
  const [marketPresenceCountries, setMarketPresenceCountries] = useState([]);
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const [permissions, setPermissions] = useState<any[]>([]);
  const [roleID, setRoleID] = useState<number>(0);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID.toString());
      if (roleDetails?.data?.type === "administrative") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "2"
        );
        setPermissions(response?.data);
      } else if (roleDetails?.data?.type === "organization") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "8"
        );
        setPermissions(response?.data);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    if (
      permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("organization-info");
    } else if (
      permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("social-info");
    } else if (
      permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("market-info");
    }
  }, [permissions]);

  useEffect(() => {
    setDefaultTab(params.defaultTab);
    if (params.brandid) {
      setBrandID(params.brandid);
    }
  }, [params]);

  useEffect(() => {
    if (brandID) {
      const getBrandData = async () => {
        const response = await GetBrand(brandID);
        if (response) {
          setBrandName(response.data.brand_name);
        }
        console.log("hi", response.data);
      };

      getBrandData();
    }
  }, [brandID]);

  useEffect(() => {
    const GetAllOrganizationData = async () => {
      const response = await GetAllOrgFilterDeleted();
      if (response) {
        console.log("org data ", response.data);
        setOrg(response?.data?.rows);
      } else console.log("Cannot get Org data");
    };

    const GetAllCategoriesData = async () => {
      const response = await GetCategories();
      if (response) {
        setCategories(response.data);
      } else console.log("Cannot get Categories Data");
    };

    const GetYearsOfOperationData = async () => {
      const response = await GetYearsOfOperation();
      if (response) {
        setYears(response.data);
      } else console.log("Cannot get Years of Operation Data");
    };

    (async () => {
      const countries = await GetMarketPresenceCountries();
      setMarketPresenceCountries(countries.data);
    })();

    (async () => {
      const countries = await GetCountries();
      setAllCountries(countries.data);
    })();

    GetAllOrganizationData();
    GetAllCategoriesData();
    GetYearsOfOperationData();
  }, []);

  useEffect(() => {
    setNewID(brandID);
  }, [brandID]);

  const handleNotification = (type: string, msg: string) => {
    setNotification({
      type: type,
      msg: msg,
    });
  };
  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });
  return (
    <Container fluid className="createbrand">
      <Row>
        <Col className="sidebar">
          <Sidebar active="brand" />
        </Col>
        <Col className="content-main p-0">
          <Navbar userProfile={userProfile} />
          <Container fluid className="parent-container">
            <Row>
              <Col className="breadcrumb-nav">
                <Breadcrumb>
                  <Breadcrumb.Item className="font12-regular gray-500">
                    <Link to="/BrandManagement">BRAND MANAGEMENT</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="font12-regular gray-500">
                    <Link to="#">
                      {params?.brandid
                        ? brandName.toLocaleUpperCase()
                        : "BRANDS"}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active className="font12-medium gray-800">
                    {params?.brandid ? "EDIT BRAND" : "ADD NEW BRAND"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <Row className="tabs-row">
              <Col className="p-0">
                <Tabs
                  //     activeKey={params?.brandid ? undefined : defaultTab}
                  id="controlled-tab"
                  activeKey={defaultTab}
                  onSelect={(k) => {
                    console.log("selected tab");
                    if (params?.brandid) {
                      setDefaultTab(k);
                    }
                  }}
                  className="tabs-global"
                  fill
                >
                  {permissions[0]?.sub_modules[0]?.permissions_actions[1]
                    ?.permission[0]?.permission === true && (
                    <Tab
                      eventKey="organization-info"
                      disabled={params?.brandid ? false : true}
                      className="adminusersTabSelectorClass"
                      title={
                        <React.Fragment>
                          <div>Basic Brand Info</div>
                        </React.Fragment>
                      }
                    >
                      <BrandInfo
                        categories={categories}
                        years={years}
                        org={org}
                        brand={params?.brandid}
                        setBrandID={setBrandID}
                        setDefaultTab={setDefaultTab}
                        handleNotification={handleNotification}
                        permissions={permissions}
                      />
                    </Tab>
                  )}
                  {permissions[0]?.sub_modules[1]?.permissions_actions[1]
                    ?.permission[0]?.permission === true && (
                    <Tab
                      eventKey="social-info"
                      disabled={params?.brandid ? false : true}
                      className="adminusersTabSelectorClass"
                      title={
                        <React.Fragment>
                          <div>Digital Presence</div>
                        </React.Fragment>
                      }
                    >
                      <BrandDigitalPresence
                        brandID={params?.brandid ? params.brandid : newID}
                        setDefaultTab={setDefaultTab}
                        handleNotification={handleNotification}
                        permissions={permissions}
                      />
                    </Tab>
                  )}
                  {permissions[0]?.sub_modules[2]?.permissions_actions[1]
                    ?.permission[0]?.permission === true && (
                    <Tab
                      eventKey="market-info"
                      disabled={params?.brandid ? false : true}
                      className="adminusersTabSelectorClass"
                      title={
                        <React.Fragment>
                          <div>Market Preferences</div>
                        </React.Fragment>
                      }
                    >
                      <BrandMarketPresence
                        countries={marketPresenceCountries}
                        allCountries={allCountries}
                        brandID={params?.brandid ? params.brandid : newID}
                        setDefaultTab={setDefaultTab}
                        handleNotification={handleNotification}
                        permissions={permissions}
                      />
                    </Tab>
                  )}
                </Tabs>
                <ToastMessage
                  notification={notification}
                  onCancel={handleCloseNotification}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateBrand;
