import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./DiscoveryManagement.scss";
import "../../styles/global.scss";

// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Auth from "@aws-amplify/auth";

// Components
import TableMUI from "../components/TableMUI/TableMUI";
import { AdminDiscoveryTableColumns } from "../components/TableMUI/TableColumns";
import Sidebar from "../components/sidebar/Sidebar";
import { OrgBrandsListDTO } from "../dtos/discovery";
import { GetAllBrands } from "../../API/endpoints/Brands";
import Cookies from "js-cookie";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";

function AdminDiscoveryManagement() {
  const navigate = useNavigate();
  const [data, setData] = useState<OrgBrandsListDTO>();
  const [dataCount, setDataCount] = useState(0);
  const [listPage, setListPage] = useState(0);
  const [searchParam, setSearchParam] = useState<string>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});

  const columns = AdminDiscoveryTableColumns(navigate);

  let rows: {
    id: string;
    brandname: string;
    organization: string;
    currentrevenue: string;
    status: string;
  }[] = [];

  if (data?.rows) {
    //  console.log(data.rows);
    rows = data.rows.map((dataItem) => ({
      id: dataItem.id,
      brandname: dataItem.brand_name,
      organization: dataItem.brand_organization?.company_name,
      currentrevenue: dataItem.current_revenue + " USD",
      status: dataItem.status,
    }));
  }

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });

    (async () => {
      const res = await GetAllBrands(listPage, searchParam);
      setDataCount(res.data.count);
      setData(res.data);
      console.log(res.data);
    })();
  }, [navigate, listPage, searchParam]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID)
        if (response?.data) {
          setUserProfile(response?.data)
        }
      })();
    }
  },[userID])

  return (
    <Container fluid className="adminusers">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="discovery" />
        </Col>
        <Col className="adminusers-content p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Link to="/discoverymanagement/org">DISCOVERY MANAGEMENT /</Link>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button size="sm" className="btn-primary12 addfilter">
              Add Filter
            </Button> */}
          </div>
          <div className="adminusertable">
            <div className="TableMUIcountainer">
              <TableMUI
                columns={columns}
                rows={rows}
                dataCount={dataCount}
                setListPage={setListPage}
                listPage={listPage}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminDiscoveryManagement;
