import React, { useRef, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import "./ImageUpload.scss";
import "../../../../styles/global.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToastMessage from "../../../components/toasts/ToastMessage";
import {
  GetProductFilesById,
  UploadFile,
} from "../../../../API/endpoints/fileupload/Files";
export interface AddProductProps {
  productId: string;
  userID?: string;
  setDefaultTab: (tab: string) => void;
  permissions?: any;
}
const ImageUpload: React.FunctionComponent<AddProductProps> = ({
  productId,
  userID,
  setDefaultTab,
  permissions,
}) => {
  const params = useParams();
  const [files, setFiles] = useState<any>([]);
  const [flag, setFlag] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);
  const inputRef = useRef<any>([]);
  const navigate = useNavigate();
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const fileListAsArray = Array.from(event.dataTransfer.files);
    console.log(fileListAsArray);
    setFiles([...files, ...fileListAsArray]);
  };
  const onChangeHandler = (event: any) => {
    const fileListAsArray = Array.from(event.target.files);
    console.log(fileListAsArray);
    let errorFlag = false;
    fileListAsArray.forEach((file: any) => {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpg"
      ) {
        setNotification({
          type: "error",
          msg: "Only jpg and png files are allowed",
        });
        errorFlag = true;
        return;
      }
    });
    if (errorFlag) {
      return;
    } else {
      setFiles([...files, ...fileListAsArray]);
    }
  };
  const deleteImage = async (e: React.Key | null | undefined) => {
    const deletedList = files.filter(
      (item: any, index: React.Key | null | undefined) => index !== e
    );
    setFiles(deletedList);
  };
  const upload = async () => {
    if (files.length !== 0) {
      console.log(files);
      const data = new FormData();
      for (var i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }
      data.append("filename", "test_image");
      data.append("product_id", productId);
      data.append("notes", "");
      data.append("file_settings_id", "1");
      data.append("uploaded_by", userID || "");
      setSubmitting(true);
      const response = await UploadFile(data);
      console.log(response);
      // const response = await UploadProductImage(data);
      if (response.msg === "File uploaded successfully") {
        setFiles([]);
        setNotification({
          type: "success",
          msg: "Image uploaded successfully",
        });
        setFlag((prev) => !prev);
      }
      setSubmitting(false);
    }
  };
  useEffect(() => {
    const getImageList = async () => {
      const response = await GetProductFilesById("1", productId);
      console.log(response);
      setUploadedImages(response.data);
    };
    if (productId) {
      getImageList();
    }
  }, [flag, productId]);
  const setTab = () => {
    if (
      permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
        ?.permission
    ) {
      setDefaultTab("sku");
    } else {
      navigate("/ProductList");
    }
  };
  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });
  return (
    <Container fluid className="upload-container">
      {(permissions[0]?.sub_modules[1]?.permissions_actions[0]?.permission[0]
        ?.permission ||
        permissions[0]?.sub_modules[1]?.permissions_actions[2]?.permission[0]
          ?.permission) && (
        <Row className="drag-drop-row">
          <Col
            className="drag-drop-col"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => inputRef.current.click()}
          >
            <img src="../svgs/upload_icon.svg" alt="upload_icon" />
            <div>
              <span className="font14-regular primary-blue">
                Click to upload{" "}
              </span>
              <span className="font14-regular gray-500">or drag & drop</span>
            </div>
            <div className="font14-regular gray-500">
              Add a minimum of 4 images in PNG or JPG format with a size lower
              than 10 KB
            </div>
            <input
              type="file"
              id="imageFile"
              accept="image/*"
              multiple
              onChange={onChangeHandler}
              hidden
              ref={inputRef}
            />
          </Col>
        </Row>
      )}
      {files.length !== 0 && (
        <Row className="list-images-row">
          {files?.map((img: any, index: React.Key | null | undefined) => {
            return (
              <Col
                className="list-images-col"
                key={index}
                xs={4}
                sm={3}
                md={3}
                lg={2}
              >
                <img
                  height={100}
                  width={100}
                  src={URL.createObjectURL(img)}
                  alt="product_image"
                />
                <Button
                  className="close-btn"
                  onClick={() => deleteImage(index)}
                >
                  <img src="../svgs/close.svg" alt="close" />
                </Button>
              </Col>
            );
          })}
        </Row>
      )}
      {files.length !== 0 && (
        <Row className="upload-btn-row">
          <Col className="upload-btn-col">
            <Button
              className="btn-secondary12"
              onClick={upload}
              disabled={submitting ? true : false}
            >
              Upload
            </Button>
          </Col>
        </Row>
      )}
      {uploadedImages.length !== 0 && (
        <Row className="uploads-heading-row">
          <Col className="p-0">
            <div className="primary-blue font14-medium">UPLOADS</div>
          </Col>
        </Row>
      )}
      {uploadedImages.length !== 0 && (
        <Row className="uploadedimages-row">
          {uploadedImages?.map(
            (img: any, index: React.Key | null | undefined) => {
              return (
                <Col
                  className="uploadedimages-col"
                  key={index}
                  xs={4}
                  sm={3}
                  md={2}
                >
                  <img
                    height={80}
                    width={100}
                    src={img.path}
                    alt="product_image"
                  />
                  {/* <Button className='close-btn' onClick={() => deleteImage(index)}>
                <img src='../svgs/close.svg' alt='close' />
              </Button> */}
                </Col>
              );
            }
          )}
        </Row>
      )}
      {!params.productid ? (
        <Row className="mb-3 mt-4 savecancelbtn-row">
          <Col className="d-flex justify-content-end">
            <Link to="/ProductList">
              <Button className="m-1 btn-secondary12">Cancel</Button>
            </Link>

            <Button
              className="m-1 btn-primary12"
              disabled={uploadedImages.length === 0}
              onClick={setTab}
            >
              Save & Continue
            </Button>
          </Col>
        </Row>
      ) : (
        <Row className="mb-3 mt-4 savecancelbtn-row">
          <Col className="d-flex justify-content-end">
            <Link to="/ProductList">
              <Button className="m-1 btn-secondary12">Cancel</Button>
            </Link>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default ImageUpload;
