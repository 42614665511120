import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import "./discoverymanagement.scss";
import "../../../../styles/global.scss";
import {
  ExpressPartnerInterest,
  GetInterestForBrand,
} from "../../../../API/endpoints/Discovery";
import {
  GetPartner,
  GetPartnerServicesList,
} from "../../../../API/endpoints/Partner";
import { sendPartnerInterestExpressedEmail } from "../../../../API/endpoints/Email";
import { CreateTodo } from "../../../../API/endpoints/Todos";
import { CreateActivity } from "../../../../API/endpoints/Dashboard";
import { GetBrand } from "../../../../API/endpoints/Brands";

function PartnerApproval(props: {
  onCancel: () => void;
  setFlag: (arg0: boolean) => void;
  flag: boolean | undefined;
  isModalOpen: boolean | undefined;
  title: string;
  buttonText: string;
  userType: string;
  selectedRequestID: string;
  partnerID?: string;
  brandID?: string;
  subtitle: string;
  reqDetails: any;
}) {
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [regulatoryCompliance, setRegulatoryCompliance] = useState(false);
  const [importClearance, setImportClearance] = useState(false);
  const [warehousingAndStorage, setWarehousingAndStorage] = useState(false);
  const [ecomSetupMgmt, setEcomSetupMgmt] = useState(false);
  const [retailDistribution, setRetailDistribution] = useState(false);
  const [orderFullfilment, setOrderFullfilment] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [interestedServices, setInterestedServices] = useState<any>([]);
  const [partnerName, setPartnerName] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");
  const [partnerServicesInMarket, setPartnerServicesInMarket] = useState({
    regulatory_compliance: false,
    importation_clearance: false,
    warehousing_storage: false,
    ecom_setup_mgmt: false,
    retail_distribution: false,
    order_fulfilment: false,
    marketing: false,
  });

  const handleClose = () => {
    setInterestedServices([]);
    setSelectedServices([]);
    setWarehousingAndStorage(false);
    setOrderFullfilment(false);
    setEcomSetupMgmt(false);
    setMarketing(false);
    setRetailDistribution(false);
    setRegulatoryCompliance(false);
    setImportClearance(false);
    props.onCancel();
  };

  useEffect(() => {
    (async () => {
      setInterestedServices([]);
      setWarehousingAndStorage(false);
      setOrderFullfilment(false);
      setEcomSetupMgmt(false);
      setMarketing(false);
      setRetailDistribution(false);
      setRegulatoryCompliance(false);
      setImportClearance(false);
      const res = await GetInterestForBrand(props.selectedRequestID);
      // console.log(
      //   res.data.rows.filter(
      //     (row: any) => row.partner_id === props.partnerID
      //   )[0].partner_services
      // );
      const partnerServices = res?.data?.rows?.filter(
        (row: any) => row.partner_id === props.partnerID
      )[0]?.partner_services;
      setInterestedServices(partnerServices);
      partnerServices?.map((service: any) => {
        if (service.service_id === 1) {
          setRegulatoryCompliance(true);
        } else if (service.service_id === 2) {
          setImportClearance(true);
        } else if (service.service_id === 3) {
          setWarehousingAndStorage(true);
        } else if (service.service_id === 4) {
          setEcomSetupMgmt(true);
        } else if (service.service_id === 5) {
          setRetailDistribution(true);
        } else if (service.service_id === 6) {
          setOrderFullfilment(true);
        } else if (service.service_id === 7) {
          setMarketing(true);
        }
        return true;
      });
    })();
  }, [props]);

  console.log(props.reqDetails);

  useEffect(() => {
    setSelectedServices([]);
    if (regulatoryCompliance === true) {
      setSelectedServices((prevState) => [
        ...prevState,
        { service_id: 1, status: "Awaiting response" },
      ]);
    }
    if (importClearance === true) {
      setSelectedServices((prevState) => [
        ...prevState,
        { service_id: 2, status: "Awaiting response" },
      ]);
    }
    if (warehousingAndStorage === true) {
      setSelectedServices((prevState) => [
        ...prevState,
        { service_id: 3, status: "Awaiting response" },
      ]);
    }
    if (ecomSetupMgmt === true) {
      setSelectedServices((prevState) => [
        ...prevState,
        { service_id: 4, status: "Awaiting response" },
      ]);
    }
    if (retailDistribution === true) {
      setSelectedServices((prevState) => [
        ...prevState,
        { service_id: 5, status: "Awaiting response" },
      ]);
    }
    if (orderFullfilment === true) {
      setSelectedServices((prevState) => [
        ...prevState,
        { service_id: 6, status: "Awaiting response" },
      ]);
    }
    if (marketing === true) {
      setSelectedServices((prevState) => [
        ...prevState,
        { service_id: 7, status: "Awaiting response" },
      ]);
    }
  }, [
    ecomSetupMgmt,
    importClearance,
    marketing,
    orderFullfilment,
    regulatoryCompliance,
    retailDistribution,
    warehousingAndStorage,
  ]);

  useEffect(() => {
    const GetPartnerServices = async () => {
      const res = await GetPartnerServicesList(props?.partnerID);
      res.data.services.map((service: any) => {
        if (service.regions.name === props.reqDetails?.preferredmarket)
          setPartnerServicesInMarket({
            regulatory_compliance:
              service.pre_launch_services.regulatory_compliance,
            importation_clearance:
              service.pre_launch_services.importation_clearance,
            warehousing_storage:
              service.pre_launch_services.warehousing_storage,
            ecom_setup_mgmt: service.post_launch_services.ecom_setup_mgmt,
            retail_distribution:
              service.post_launch_services.retail_distribution,
            order_fulfilment: service.post_launch_services.order_fulfilment,
            marketing: service.post_launch_services.marketing,
          });
        return true;
      });
    };
    GetPartnerServices();
  }, [props?.partnerID, props.reqDetails?.preferredmarket]);

  useEffect(() => {
    (async () => {
      const partner = await GetPartner(props.partnerID);
      if (partner) {
        console.log(partner);
        setPartnerName(partner?.data?.company_name);
      } else console.log("Cannot get Partner Data");
    })();
    (async () => {
      const brand = await GetBrand(props.brandID);
      if (brand) {
        console.log(brand);
        setBrandName(brand?.data?.brand_name);
      } else console.log("Cannot get Brands Data");
    })();
  }, [props.partnerID, props.brandID]);

  const onFormSubmit = async () => {
    const formData = {
      discovery_brands_id: props.reqDetails.id,
      partner_id: props.partnerID,
      brand_id: props.brandID,
      status: "New",
      services: selectedServices,
    };
    if (props.userType === "Partner") {
      try {
        props.setFlag(false);
        //  console.log(formData);
        const res = await ExpressPartnerInterest(formData);
        console.log(res);
        await sendPartnerInterestExpressedEmail({
          discovery_brands_id: res.data.discovery_brands_id,
          partner_id: res.data.partner_id,
        });
        try {
          const todoData = {
            id: `partner-interest:${res?.data?.id}`,
            brand_id: props.brandID,
            partner_id: res.data.partner_id,
            message: `<b>${partnerName}</b> has expressed interest to your request for <b>${props.reqDetails?.preferredmarket}</b>.`,
            url: `/discoverymanagement-brands/${res.data.discovery_brands_id}&&${brandName}`,
            url_action: "Please review and accept if suitable.",
            visibility: "active users",
            permission_submodule_id: 31,
            admin: false,
            brand: true,
          };
          const todoData2 = {
            id: `partner-interest-admin:${res?.data?.id}`,
            brand_id: props.brandID,
            partner_id: res.data.partner_id,
            message: `<b>${partnerName}</b> has expressed an interest for <b>${props.reqDetails?.brandname}</b> request in <b>${props.reqDetails?.preferredmarket}</b>.`,
            url: `/discoverymanagement-recommend/${res.data.discovery_brands_id}&&${brandName}&&recommendation`,
            url_action: "Click to view the details.",
            permission_submodule_id: 15,
            admin: true,
          };
          const todoData3 = {
            id: `partner-interest-partner:${res?.data?.id}`,
            brand_id: props.brandID,
            partner_id: props.partnerID ? props.partnerID : "",
            message: `You have expressed interest for brand <b>${brandName}</b> request in <b>${props.reqDetails?.preferredmarket}</b>.`,
            url: `/discoverymanagement/partner`,
            url_action: "Click to view the details.",
            visibility: "active users",
            permission_submodule_id: 39,
            admin: false,
            partner: true,
          };
          const res2 = await CreateTodo(todoData);
          const res3 = await CreateTodo(todoData2);
          const res4 = await CreateTodo(todoData3);
          console.log(res2);
          console.log(res3);
          console.log(res4);
          const activity = await CreateActivity(
            `Partner ${partnerName} has expressed an interest for brand: ${props.reqDetails?.brandname} request in ${props.reqDetails?.preferredmarket}.`
          );
          console.log(activity);
        } catch (e) {
          console.log(e);
        }
        props.setFlag(true);
        handleClose();
      } catch (ex) {
        props.setFlag(!props.flag);
        console.log(ex);
        handleClose();
      }
    }
  };

  return (
    //@ts-ignore
    <Modal
      show={props.isModalOpen}
      onHide={handleClose}
      className="brandpartnerapproval"
    >
      <Modal.Header closeButton>
        <Modal.Title className="font18-medium gray-800">
          {interestedServices && interestedServices[0]
            ? "Interested Services"
            : props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={() => {
            onFormSubmit();
          }}
        >
          <Row className="subtitle-row">
            <Col>
              <div className="font14-regular gray-500">
                {interestedServices && interestedServices[0]
                  ? "Services which you have offered to brand:"
                  : props.subtitle}
              </div>
            </Col>
          </Row>
          {props.reqDetails?.preferredserviceIDs?.includes(1) &&
            partnerServicesInMarket.regulatory_compliance && (
              <Row className="checkbox-row">
                <Col>
                  <input
                    type="checkbox"
                    disabled={
                      (interestedServices && interestedServices[0]
                        ? true
                        : false) ||
                      props.reqDetails.brand_services.filter((service: any) => {
                        return service.service_id === 1;
                      })[0]?.status === "Accepted"
                    }
                    onChange={() => {
                      setRegulatoryCompliance((prevState) => !prevState);
                    }}
                    checked={regulatoryCompliance}
                  />
                  <label className="gray-800 font12-regular">
                    Regulatory Compliance
                  </label>
                </Col>
              </Row>
            )}
          {props.reqDetails?.preferredserviceIDs?.includes(2) &&
            partnerServicesInMarket.importation_clearance && (
              <Row className="checkbox-row">
                <Col>
                  <input
                    type="checkbox"
                    disabled={
                      (interestedServices && interestedServices[0]
                        ? true
                        : false) ||
                      props.reqDetails.brand_services.filter((service: any) => {
                        return service.service_id === 2;
                      })[0]?.status === "Accepted"
                    }
                    onChange={() => {
                      setImportClearance((prevState) => !prevState);
                    }}
                    checked={importClearance}
                  />
                  <label className="gray-800 font12-regular">
                    Importation And Clearance
                  </label>
                </Col>
              </Row>
            )}
          {props.reqDetails?.preferredserviceIDs?.includes(3) &&
            partnerServicesInMarket.warehousing_storage && (
              <Row className="checkbox-row">
                <Col>
                  <input
                    type="checkbox"
                    disabled={
                      (interestedServices && interestedServices[0]
                        ? true
                        : false) ||
                      props.reqDetails.brand_services.filter((service: any) => {
                        return service.service_id === 3;
                      })[0]?.status === "Accepted"
                    }
                    onChange={() => {
                      setWarehousingAndStorage((prevState) => !prevState);
                    }}
                    checked={warehousingAndStorage}
                  />
                  <label className="gray-800 font12-regular">
                    Warehousing And Storage
                  </label>
                </Col>
              </Row>
            )}
          {props.reqDetails?.preferredserviceIDs?.includes(4) &&
            partnerServicesInMarket.ecom_setup_mgmt && (
              <Row className="checkbox-row">
                <Col>
                  <input
                    type="checkbox"
                    disabled={
                      (interestedServices && interestedServices[0]
                        ? true
                        : false) ||
                      props.reqDetails.brand_services.filter((service: any) => {
                        return service.service_id === 4;
                      })[0]?.status === "Accepted"
                    }
                    onChange={() => {
                      setEcomSetupMgmt((prevState) => !prevState);
                    }}
                    checked={ecomSetupMgmt}
                  />
                  <label className="gray-800 font12-regular">
                    Ecommerce Setup And Management
                  </label>
                </Col>
              </Row>
            )}
          {props.reqDetails?.preferredserviceIDs?.includes(5) &&
            partnerServicesInMarket.retail_distribution && (
              <Row className="checkbox-row">
                <Col>
                  <input
                    type="checkbox"
                    disabled={
                      (interestedServices && interestedServices[0]
                        ? true
                        : false) ||
                      props.reqDetails.brand_services.filter((service: any) => {
                        return service.service_id === 5;
                      })[0]?.status === "Accepted"
                    }
                    onChange={() => {
                      setRetailDistribution((prevState) => !prevState);
                    }}
                    checked={retailDistribution}
                  />
                  <label className="gray-800 font12-regular">
                    Retail Distribution
                  </label>
                </Col>
              </Row>
            )}
          {props.reqDetails?.preferredserviceIDs?.includes(6) &&
            partnerServicesInMarket.order_fulfilment && (
              <Row className="checkbox-row">
                <Col>
                  <input
                    type="checkbox"
                    disabled={
                      (interestedServices && interestedServices[0]
                        ? true
                        : false) ||
                      props.reqDetails.brand_services.filter((service: any) => {
                        return service.service_id === 6;
                      })[0]?.status === "Accepted"
                    }
                    onChange={() => {
                      setOrderFullfilment((prevState) => !prevState);
                    }}
                    checked={orderFullfilment}
                  />
                  <label className="gray-800 font12-regular">
                    Order Fulfillment
                  </label>
                </Col>
              </Row>
            )}
          {props.reqDetails?.preferredserviceIDs?.includes(7) &&
            partnerServicesInMarket.marketing && (
              <Row className="checkbox-row">
                <Col>
                  <input
                    type="checkbox"
                    disabled={
                      (interestedServices && interestedServices[0]
                        ? true
                        : false) ||
                      props.reqDetails.brand_services.filter((service: any) => {
                        return service.service_id === 7;
                      })[0]?.status === "Accepted"
                    }
                    onChange={() => {
                      setMarketing((prevState) => !prevState);
                    }}
                    checked={marketing}
                  />
                  <label className="gray-800 font12-regular">Marketing</label>
                </Col>
              </Row>
            )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-secondary12"
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        {!interestedServices && (
          <Button
            className="btn-primary12"
            variant="primary"
            type="submit"
            onClick={() => {
              onFormSubmit();
            }}
          >
            {props.buttonText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default PartnerApproval;
