import "../../styles/pagelayout.scss";
import "./AddRole.scss";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Multiselect } from "multiselect-react-dropdown";
import Auth from "@aws-amplify/auth";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Sidebar from "../components/sidebar/Sidebar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Navbar from "../components/header/NavBar";
import ToastMessage from "../components/toasts/ToastMessage";
import { GetUserProfile } from "../../API/endpoints/User";
import { GetFilesByCategory } from "../../API/endpoints/fileupload/FileSettings";
import { GetPermissionsByRoleIdAndModuleId } from "../../API/endpoints/RolesAndPermissions/Permissions";
import {
  AssignFoldersToRole,
  UpdateFoldersOfRole,
  GetFolderList,
} from "../../API/endpoints/RolesAndPermissions/Folders";

const FileAccess = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [userType, setUserType] = useState<string | undefined>("");
  const [userRole, setUserRole] = useState<string | undefined>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [multiselectData, setMultiselectData] = useState<any>([]);
  const [entityList, setEntityList] = useState<any>([]);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const [filesPresent, setFilesPresent] = useState(true);
  const multiselectRef = useRef<any>([]);

  useEffect(() => {
    setUserType(Cookies.get("UserType"));
    setUserRole(Cookies.get("UserRole"));
    setUserID(Cookies.get("userID"));
  }, []);
  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
    })();

    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });
    if (userType === "administrative" && userRole === "super_admin") {
      console.log(userType);
    } else if (userType === "partner") {
      navigate("/PartnerManagement");
    } else if (userType === "organization") {
      navigate("/OrganizationManagement");
    }
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userType, userRole, navigate, userID]);

  useEffect(() => {
    (async () => {
      if (params.typeid === "2") {
        try {
          const response = await GetPermissionsByRoleIdAndModuleId(
            `${params.roleid}`,
            "10"
          );
          const discoveryStatus = response.data[0].sub_modules.find(
            (sub: any) =>
              sub.permissions_actions.find(
                (action: any) => action.permission[0].permission === true
              )
          )
            ? true
            : false;
          if (discoveryStatus === true) {
            setEntityList([
              { id: 1, name: "Brand" },
              { id: 2, name: "Partner" },
              { id: 3, name: "Product" },
            ]);
          } else {
            setEntityList([
              { id: 1, name: "Brand" },
              { id: 3, name: "Product" },
            ]);
          }
        } catch (error) {}
      } else if (params.typeid === "3") {
        try {
          const response = await GetPermissionsByRoleIdAndModuleId(
            `${params.roleid}`,
            "13"
          );
          const discoveryStatus = response.data[0].sub_modules.find(
            (sub: any) =>
              sub.permissions_actions.find(
                (action: any) => action.permission[0].permission === true
              )
          )
            ? true
            : false;
          if (discoveryStatus === true) {
            setEntityList([
              { id: 1, name: "Brand" },
              { id: 2, name: "Partner" },
            ]);
          } else {
            setEntityList([{ id: 2, name: "Partner" }]);
          }
        } catch (error) {}
      } else {
        setEntityList([
          { id: 1, name: "Brand" },
          { id: 2, name: "Partner" },
          { id: 3, name: "Product" },
        ]);
      }
      if (params?.edit === "true") {
        const res = await GetFolderList(params.roleid);
        setFiles(
          res?.data?.map((itm: any) => {
            return itm.file_settings;
          })
        );
        if (res?.data.length === 0) {
          setFilesPresent(false);
        }
      }
    })();
  }, [params]);

  const goBack = () => {
    navigate("/RolesList");
  };

  const handleEntityChange = async (entity: string) => {
    multiselectRef.current.resetSelectedValues();
    if (entity === "1") {
      const response = await GetFilesByCategory("brand");
      setMultiselectData(response?.data);
    } else if (entity === "2") {
      const response = await GetFilesByCategory("partner");
      setMultiselectData(response?.data);
    } else if (entity === "3") {
      const response = await GetFilesByCategory("product");
      setMultiselectData(response?.data);
    } else {
    }
  };

  const handleFileChange = (event: any) => {
    event.map((data: any) =>
      selectedFiles.find((file: any) => file.id === data.id)
        ? setSelectedFiles(selectedFiles)
        : setSelectedFiles([...selectedFiles, data])
    );
  };
  const handleRemove = (event: any) => {
    if (event.length !== 0) {
      let result = selectedFiles.filter((itm: any) => {
        return multiselectData[0].category.id !== itm.category.id;
      });
      setSelectedFiles([...result, ...event]);
    } else {
      let result = selectedFiles.filter((itm: any) => {
        return multiselectData[0].category.id !== itm.category.id;
      });
      setSelectedFiles(result);
    }
  };
  const addFiles = () => {
    let arr: any[] = [];
    selectedFiles.map((data: any) =>
      files.find((file: any) => file.id === data.id) ? "" : arr.push(data)
    );
    setFiles([...files, ...arr]);
    multiselectRef.current.resetSelectedValues();
  };

  const deleteBrand = () => {
    setFiles(files?.filter((itm: any) => itm.category.id !== 1));
  };
  const deletePartner = () => {
    setFiles(files?.filter((itm: any) => itm.category.id !== 2));
  };
  const deleteProduct = () => {
    setFiles(files?.filter((itm: any) => itm.category.id !== 3));
  };

  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });

  const onSubmit = async () => {
    let InputData = files.map((itm: any) => {
      return itm.id;
    });
    if (params?.edit === "true" && filesPresent) {
      try {
        setSubmitting(true);
        const res = await UpdateFoldersOfRole(
          { file_settings_id_list: InputData },
          params.roleid
        );
        if (res.msg === "Folders list updated successfuly.") {
          setSubmitting(false);
          navigate("/RolesList");
        }
      } catch (error: any) {
        setNotification({ type: "error", msg: error.msg });
        setSubmitting(false);
      }
    } else {
      try {
        setSubmitting(true);
        const response = await AssignFoldersToRole(
          { file_settings_id_list: InputData },
          params.roleid
        );
        if (response.msg === "Folders assigned to role successfully.") {
          setSubmitting(false);
          navigate("/RolesList");
        }
      } catch (error: any) {
        setNotification({ type: "error", msg: error.msg });
        setSubmitting(false);
      }
    }
  };

  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <div className="file-access">
            <div className="fileaccess-cntr">
              <Breadcrumb className="font12-medium breadcrumbnav">
                <Breadcrumb.Item href="/SettingsList">Settings</Breadcrumb.Item>
                <Breadcrumb.Item href="/RolesList">
                  Roles & permissions
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  href={
                    params?.edit === "true"
                      ? `/EditFileAccess/${params.typeid}&&${params.roleid}&&${params.edit}`
                      : `/FileAccess/${params.typeid}&&${params.roleid}`
                  }
                  active
                >
                  {params?.edit === "true" ? "Edit File Access" : "Add new"}
                </Breadcrumb.Item>
              </Breadcrumb>
              <Form className="form">
                <Container fluid>
                  <Row className="header-row">
                    <Col className="header-col">
                      <div className="form-header">
                        <Button className="backarrow-btn" onClick={goBack}>
                          <img src="../svgs/arrows-left.svg" alt="arrow" />
                        </Button>
                        <span className="font18-medium gray-800">
                          Define File Access
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="input-row">
                    <Col>
                      <Form.Group
                        className="formgroup entity"
                        controlId="entity"
                      >
                        <Form.Label>Select Entity</Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            handleEntityChange(e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {entityList?.map((item: any) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                        className="formgroup folders"
                        controlId="folders"
                      >
                        <Form.Label>Select Folders</Form.Label>
                        <Multiselect
                          id="css_custom"
                          customCloseIcon={
                            <>
                              <img
                                src="../svgs/close-btn.svg"
                                alt="close-btn"
                              />
                            </>
                          }
                          showArrow
                          options={multiselectData}
                          displayValue="name"
                          showCheckbox={true}
                          placeholder="Select"
                          closeIcon="cancel"
                          disable={multiselectData.length === 0}
                          selectedValues={[]}
                          onSelect={(
                            e: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            handleFileChange(e);
                          }}
                          onRemove={(
                            e: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            handleRemove(e);
                          }}
                          ref={multiselectRef}
                        />
                      </Form.Group>
                      <Button className="plus-btn btn41" onClick={addFiles}>
                        <img src="../svgs/plus-blue.svg" alt="plus-blue" />
                      </Button>
                    </Col>
                  </Row>
                  {files.length !== 0 && (
                    <Row className="br-row">
                      <Col className="sep-line">
                        <div></div>
                      </Col>
                    </Row>
                  )}
                  {files?.find((file: any) => file?.category?.id === 1) ? (
                    <Row className="display-row">
                      <Col className="display-col">
                        <div className="display-wraper">
                          <div className="display-header">
                            <div className="font14-medium gray-800">Brand</div>
                            <Button className="trash-btn" onClick={deleteBrand}>
                              <img src="../svgs/trash600.svg" alt="trash600" />
                            </Button>
                          </div>
                          <div className="folders">
                            {files.map((itm: any) => {
                              return itm.category.id === 1 ? (
                                <div
                                  key={itm.id}
                                  className="badgepill-gray50 font10-regular gray-800"
                                >
                                  {itm.name}
                                </div>
                              ) : (
                                ""
                              );
                            })}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {files?.find((file: any) => file.category.id === 2) ? (
                    <Row className="display-row mt16px">
                      <Col className="display-col">
                        <div className="display-wraper">
                          <div className="display-header">
                            <div className="font14-medium gray-800">
                              Partner
                            </div>
                            <Button
                              className="trash-btn"
                              onClick={deletePartner}
                            >
                              <img src="../svgs/trash600.svg" alt="trash600" />
                            </Button>
                          </div>
                          <div className="folders">
                            {files.map((itm: any) => {
                              return itm.category.id === 2 ? (
                                <div
                                  key={itm.id}
                                  className="badgepill-gray50 font10-regular gray-800"
                                >
                                  {itm.name}
                                </div>
                              ) : (
                                ""
                              );
                            })}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {files?.find((file: any) => file?.category?.id === 3) ? (
                    <Row className="display-row mt16px">
                      <Col className="display-col">
                        <div className="display-wraper">
                          <div className="display-header">
                            <div className="font14-medium gray-800">
                              Product
                            </div>
                            <Button
                              className="trash-btn"
                              onClick={deleteProduct}
                            >
                              <img src="../svgs/trash600.svg" alt="trash600" />
                            </Button>
                          </div>
                          <div className="folders">
                            {files.map((itm: any) => {
                              return itm.category.id === 3 ? (
                                <div
                                  key={itm.id}
                                  className="badgepill-gray50 font10-regular gray-800"
                                >
                                  {itm.name}
                                </div>
                              ) : (
                                ""
                              );
                            })}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row className="nextbtn-row">
                    <Col className="nextbtn-col">
                      <Button
                        className="btn-primary12"
                        onClick={onSubmit}
                        disabled={submitting ? true : false}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>
          </div>
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FileAccess;
