import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
// @ts-ignore
import Cookies from "js-cookie";
import {
  CreatePartnerRequest,
  GetBrandMarketPreference,
  GetOrgBrands,
} from "../../../../API/endpoints/Discovery";
import { GetCountries } from "../../../../API/endpoints/Common";
import { SendMatchingPartnerEmail } from "../../../../API/endpoints/Email";
import { CreateTodo } from "../../../../API/endpoints/Todos";
import { CreateActivity } from "../../../../API/endpoints/Dashboard";
import { GetAllBrands, GetBrand } from "../../../../API/endpoints/Brands";
import AsyncSelect from "react-select/async";
import { GetUserProfile } from "../../../../API/endpoints/User";
import { GetRoleById } from "../../../../API/endpoints/RolesAndPermissions/Roles";
import { GetAssignedOrgsForUser } from "../../../../API/endpoints/RolesAndPermissions/AssignTeam";

function AddPartnerRequest(props: {
  onCancel: () => void;
  setFlag: any;
  flag: any;
  isModalOpen: boolean | undefined;
  dataRestriction?: boolean;
}) {
  const [selectedBrand, setSelectedBrand] = useState<any>();
  const [defaultValues, setDefaultValues] = useState<any>([]);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [orgID, setOrgID] = useState<string | undefined>("");
  const [countries, setCountries] = useState<any[]>([]);
  // const [engagementmodels, setEngagementmodels] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [preferredmodels, setPreferredModels] = useState<any>();
  const [brandServices, setBrandServices] = useState<any[]>([]);
  const [servicesInMarket, setServicesInMarket] = useState<any>([]);
  // const [selectedModel, setSelectedModel] = useState<any>();
  const [servicesError, setServicesError] = useState<string | undefined>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [roleID, setRoleID] = useState<number>(0);
  const [brandID, setBrandID] = useState<string>(
    brands[0]?.id ? brands[0]?.id : ""
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      brand: undefined,
      market: undefined,
      model: undefined,
    },
  });

  const GetAllBrandsData = async (inputValue?: string) => {
    const data = await GetAllBrands(0, inputValue ? inputValue : "");
    const response = data?.data?.rows?.filter(
      (item: any) => item.status === "Activated"
    );
    if (response) {
      setBrands(
        response?.data?.rows.map((dataItem: { id: any; brand_name: any }) => {
          return {
            value: dataItem.id,
            label: dataItem.brand_name,
          };
        })
      );
    } else console.log("Cannot get Org data");
  };

  const GetOrgBrandsData = async (inputValue?: string) => {
    const data = await GetOrgBrands(orgID);
    const response = data?.data?.rows?.filter(
      (item: any) => item.status === "Activated"
    );
    if (response) {
      setBrands(
        response?.data?.rows.map((dataItem: { id: any; brand_name: any }) => {
          return {
            value: dataItem.id,
            label: dataItem.brand_name,
          };
        })
      );
    } else console.log("Cannot get Org data");
  };

  useEffect(() => {
    let defaults = {
      brand: defaultValues.brand ? defaultValues.brand : undefined,
      market: defaultValues.market ? defaultValues.market : undefined,
      model: defaultValues.model ? defaultValues.model : undefined,
    };
    reset(defaults);
  }, [defaultValues, reset]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setOrgID(response.data?.organization?.id);
          setRoleID(response.data?.user_roles[0]?.roles.id);
        }
      })();
    }
  }, [userID]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID.toString());
      if (roleDetails?.data?.type === "administrative") {
        setIsSuperAdmin(true);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    const GetAllBrandsData = async (inputValue?: string) => {
      const data = await GetAllBrands(0, inputValue ? inputValue : "");
      const response = data?.data?.rows?.filter(
        (item: any) => item.status === "Activated"
      );
      if (response) {
        setBrands(
          response?.map((dataItem: { id: any; brand_name: any }) => {
            return {
              value: dataItem.id,
              label: dataItem.brand_name,
            };
          })
        );
      } else console.log("Cannot get Org data");
    };

    const GetOrgBrandsData = async (inputValue?: string) => {
      const data = await GetOrgBrands(orgID);
      const response = data?.data?.rows?.filter(
        (item: any) => item.status === "Activated"
      );

      if (response) {
        setBrands(
          response?.map((dataItem: { id: any; brand_name: any }) => {
            return {
              value: dataItem.id,
              label: dataItem.brand_name,
            };
          })
        );
      } else console.log("Cannot get Org data");
    };

    if (isSuperAdmin) {
      GetAllBrandsData();
    } else {
      GetOrgBrandsData();
    }
  }, [isSuperAdmin, orgID]);

  useEffect(() => {
    (async () => {
      const response = await GetBrand(brandID);
      console.log(response);
      if (response) {
        setSelectedBrand({
          id: response.data?.id,
          brand_name: response.data?.brand_name,
        });
      }
    })();
  }, [brandID]);

  const handleClose = () => {
    props.setFlag((prev: any) => !prev);
    setSelectedServices([]);
    setCountries([]);
    setPreferredModels([]);
    setServicesInMarket([]);
    setDefaultValues({
      brand: undefined,
      market: undefined,
      model: undefined,
    });
    props.onCancel();
  };

  const handleServicesChange = (selectedList: any) => {
    setSelectedServices([]);
    selectedList.map((item: { id: string; name: string }) => {
      setSelectedServices((services: any) => [
        ...services,
        {
          id: item.id,
          name: item.name,
          service_id: item.id,
          status: "Awaiting response",
        },
      ]);
      return true;
    });
  };

  const handleRemoveService = (selectedList: any) => {
    setSelectedServices(selectedList);
  };

  useEffect(() => {
    setSelectedServices([]);
    setServicesInMarket([]);
    setPreferredModels([]);
    (async () => {
      const response = await GetBrandMarketPreference(selectedBrand?.id);
      if (response) {
        if (props?.dataRestriction) {
          const assignedBrands = await GetAssignedOrgsForUser("brand", userID);
          let countriesListAssigned: string[] = [];
          assignedBrands?.data?.map((item: any) => {
            if (item.brand_id === brandID) {
              countriesListAssigned = item.countries;
            }
            return true;
          });
          console.log(response.data);
          setBrandServices(response.data.market_preference);
          setPreferredModels(
            response.data.brand_preference.engagement_models_list
          );
          setCountries([]);

          response.data.market_preference.map(
            (item: { regions: { name: string; id: string } }) => {
              if (countriesListAssigned.includes(item.regions.id)) {
                setCountries((country: any[]) => [
                  ...country,
                  { name: item.regions.name, id: item.regions.id },
                ]);
              }
              return true;
            }
          );
        } else {
          console.log(response.data);
          setBrandServices(response.data.market_preference);
          setPreferredModels(
            response.data.brand_preference.engagement_models_list
          );
          setCountries([]);

          response.data.market_preference.map(
            (item: { regions: { name: string; id: string } }) => {
              setCountries((country: any[]) => [
                ...country,
                { name: item.regions.name, id: item.regions.id },
              ]);
              return true;
            }
          );
        }
      } else console.log("Cannot get Countries Data");
    })();
  }, [selectedBrand, brandID, props.dataRestriction, userID]);

  // const handleChangeBrand = async (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   console.log(e.target.value);
  //   setPreferredModels([]);
  //   setSelectedServices([]);
  //   setServicesInMarket([]);
  //   const response = await GetBrandMarketPreference(e.target.value);
  //   if (response) {
  //     console.log(response.data);
  //     setBrandServices(response.data.market_preference);
  //     setCountries([]);
  //     response.data.market_preference.map(
  //       (item: { regions: { name: string; id: string } }) =>
  //         setCountries((country: any[]) => [
  //           ...country,
  //           { name: item.regions.name, id: item.regions.id },
  //         ])
  //     );
  //     console.log(response.data.brand_preference.engagement_models_list);
  //     setPreferredModels(response.data.brand_preference.engagement_models_list);
  //   } else console.log("Cannot get Countries Data");
  // };

  const handleChangeCountry = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    console.log(e.target.value);
    setSelectedServices([]);
    setServicesInMarket([]);
    const servicesSelectedInMarket = brandServices.filter(
      (item) => item.regions.id === Number(e.target.value)
    );

    const options = servicesSelectedInMarket && [
      servicesSelectedInMarket[0]?.pre_launch_services
        ?.regulatory_compliance && {
        id: 1,
        name: "Regulatory Compliance",
      },
      servicesSelectedInMarket[0]?.pre_launch_services
        ?.importation_clearance && {
        id: 2,
        name: "Importation & Clearance",
      },
      servicesSelectedInMarket[0]?.pre_launch_services?.warehousing_storage && {
        id: 3,
        name: "Warehousing and Storage",
      },
      servicesSelectedInMarket[0]?.post_launch_services?.ecom_setup_mgmt && {
        id: 4,
        name: "Ecommerce Setup & Management",
      },
      servicesSelectedInMarket[0]?.post_launch_services
        ?.retail_distribution && {
        id: 5,
        name: "Retail Distribution",
      },
      servicesSelectedInMarket[0]?.post_launch_services?.order_fulfilment && {
        id: 6,
        name: "Order Fulfilment",
      },
      servicesSelectedInMarket[0]?.post_launch_services?.marketing && {
        id: 7,
        name: "Marketing",
      },
    ];
    setServicesInMarket(options.filter((item) => item !== false));
  };

  const onFormSubmit = async (data: {
    brand: undefined;
    market: undefined;
    model: undefined;
  }) => {
    if (selectedServices.length === 0) {
      setServicesError("*This field is required");
      return;
    }
    props.setFlag(true);
    const formSubmitData = {
      brand_id: brandID,
      engagement_model_id: data.model,
      country_id: Number(data.market),
      status: "Open",
      services: selectedServices,
    };
    console.log(formSubmitData);
    const countries = await GetCountries();
    const selectedcountry = countries.data.filter((country: { id: number }) => {
      return country.id === formSubmitData.country_id;
    })[0]?.name;
    let emailServiceList: string[] = selectedServices.map((item) => {
      return item.name;
    });

    const brandDetails = await GetBrand(brandID);
    // let brand = "";
    // brands.map((item) => {
    //   if (item.id === formSubmitData.brand_id) {
    //     brand = item.name;
    //   }
    //   return false;
    // });

    const res = await CreatePartnerRequest(formSubmitData);
    setServicesError("");

    if (res) {
      console.log("Success 1");
      props.setFlag(false);
      console.log(res);
      const res2 = await SendMatchingPartnerEmail({
        serviceList: emailServiceList,
        brandName: brandDetails.data?.brand_name,
        market: formSubmitData.country_id,
        brand_id: formSubmitData.brand_id,
        discovery_brands_id: res.data.id,
      });
      console.log("Success2", res2);

      try {
        const todoData = {
          id: `partner-request-created:${res?.data?.id}`,
          brand_id: formSubmitData?.brand_id,
          message: `<b>${brandDetails.data?.brand_name}</b> has requested for partner in
            <b>${selectedcountry}</b>.`,
          url: `/discoverymanagement/org&&admin`,
          url_action: "Click to view the details.",
          visibility: "active users",
          permission_submodule_id: 14,
          admin: true,
        };
        const response = await CreateTodo(todoData);
        console.log("Success 3", response);
      } catch (ex: any) {
        console.log(ex);
      }

      try {
        const activity = await CreateActivity(
          `Brand ${brandDetails.data?.brand_name} has requested for a partner in ${selectedcountry}.`
        );
        console.log(activity);
      } catch (ex: any) {
        console.log(ex);
      }
      console.log("Success 4");
    }
    props.setFlag((prev: boolean) => !prev);
    handleClose();
  };

  const filterOrgs = (inputValue: string) => {
    if (isSuperAdmin) {
      GetAllBrandsData(inputValue);
      return brands?.filter((i: { label: string }) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else {
      GetOrgBrandsData(inputValue);
      return brands?.filter((i: { label: string }) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<any>((resolve) => {
      setTimeout(() => {
        resolve(filterOrgs(inputValue));
      }, 10);
    });

  return (
    <Modal show={props.isModalOpen} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Find A Partner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit((data) => {
            onFormSubmit(data);
          })}
        >
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="brand">
                <Form.Label>Select Brand</Form.Label>
                <AsyncSelect
                  className="asynselect"
                  cacheOptions
                  onChange={(e: any) => {
                    setBrandID(e.value);
                  }}
                  value={{
                    value: selectedBrand?.id,
                    label: selectedBrand?.brand_name,
                  }}
                  defaultOptions={brands}
                  loadOptions={promiseOptions}
                />
                <Form.Text muted>
                  <span>
                    {errors?.brand && (
                      <p className="error-form">
                        {String(errors.brand?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="market">
                <Form.Label>Select Market</Form.Label>
                <Form.Select
                  aria-label="20"
                  disabled={countries.length === 0}
                  {...register("market", {
                    required: "* This field is required",
                  })}
                  onChange={(e) => handleChangeCountry(e)}
                >
                  <option hidden value="">
                    Select a market
                  </option>
                  ;
                  {countries &&
                    countries.map((country) => {
                      return (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Text muted>
                  <span>
                    {errors?.market && (
                      <p className="error-form">
                        {String(errors.market?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="model">
                <Form.Label>Preferred Model</Form.Label>
                <Form.Select
                  aria-label="20"
                  disabled={!preferredmodels || preferredmodels?.length === 0}
                  {...register("model", {
                    required: "* This field is required",
                  })}
                >
                  {preferredmodels &&
                    preferredmodels?.map((model: any) => {
                      return (
                        <option value={model.id} key={model.id}>
                          {model?.name ? model.name : ""}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Text muted>
                  <span>
                    {errors?.model && (
                      <p className="error-form">
                        {String(errors.model?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="mandatory">
                <Form.Label>Select All Required Services</Form.Label>

                <Multiselect
                  showArrow
                  options={servicesInMarket}
                  displayValue="name"
                  showCheckbox={true}
                  placeholder="Select Services"
                  closeIcon="cancel"
                  selectedValues={selectedServices}
                  onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    handleServicesChange(e);
                  }}
                  onRemove={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    handleRemoveService(e);
                  }}
                />
                <Form.Text muted>
                  {servicesError && (
                    <span>
                      <p className="error-form">{servicesError}</p>
                    </span>
                  )}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button
          className="primaryButton"
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          className="primaryButton"
          variant="primary"
          type="submit"
          onClick={handleSubmit((data) => {
            onFormSubmit(data);
          })}
        >
          {defaultValues.document_name ? "Update" : "Submit Request"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPartnerRequest;
