import "./styles/adminuserslist.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Sidebar from "../components/sidebar/Sidebar";
import TableMUI from "../components/TableMUI/TableMUI";
import { adminUsersTableColumns } from "../components/TableMUI/TableColumns";
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";
import Navbar from "../components/header/NavBar";
import BreadCrumb from "../components/header/BreadCrumb";

import { GetAdminUsers, DeleteAdmin } from "../../API/endpoints/Admin";
import { GetUserProfile } from "../../API/endpoints/User";
import { GetPermissionsByRoleAndModuleID } from "../../API/endpoints/RolesAndPermissions/Roles";

const AdminUsersList = () => {
  const navigate = useNavigate();
  type dataInput = {
    rows?: {
      id: string;
      first_name: string;
      last_name: string;
      email_id: string;
      contact_number_code: string;
      contact_number: string;
      departments: { department_display_name: string };
      designation: string;
      user_roles: { roles: { role_name: string } }[];
      // owner: boolean;
      is_active: boolean;
      created_at: string;
    }[];
  };

  const [data, setData] = useState<dataInput>({});
  const [adminID, setAdminID] = useState("");
  const [adminName, setAdminName] = useState("");
  const [showDeleteAdmin, setShowDeleteAdmin] = useState(false);
  const [flag, setFlag] = useState(false);
  const [roleID, setRoleID] = useState<number>(0);
  const [dataCount, setDataCount] = useState(0);
  const [listPage, setListPage] = useState(0);
  const [searchParam, setSearchParam] = useState<string>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [permissions, setPermissions] = useState<any[]>([]);

  const addNewUser = () => navigate("/AddAdminUser");
  const handleCloseDeleteAdmin = () => setShowDeleteAdmin(false);
  const deleteFunction = async () => {
    await DeleteAdmin(adminID);
    setFlag(true);
  };

  const columns = adminUsersTableColumns(
    setAdminID,
    setShowDeleteAdmin,
    setAdminName,
    navigate,
    permissions
  );

  const dateFormatter = (date: any) => {
    let createdDate = new Date(date);
    return `${createdDate.getDate()}/${
      createdDate.getMonth() + 1
    }/${createdDate.getFullYear()}`;
  };

  let rows: {
    id: string;
    fullname: string;
    emailid: string;
    contactnumber: string;
    department: string;
    designation: string;
    role: string;
    // owner: string;
    isactive: string;
    createdat: string;
  }[] = [];

  if (
    data.rows &&
    permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    rows = data.rows.map((dataItem) => {
      return {
        id: dataItem?.id,
        fullname: dataItem?.first_name + " " + dataItem?.last_name,
        emailid: dataItem?.email_id,
        contactnumber:
          dataItem?.contact_number_code + " " + dataItem?.contact_number,
        department: dataItem?.departments?.department_display_name,
        designation: dataItem?.designation,
        role: dataItem?.user_roles[0]?.roles?.role_name || "NA",
        // owner: dataItem?.owner ? "Yes" : "No",
        isactive: dataItem?.is_active ? "Yes" : "No",
        // createdat: new Intl.DateTimeFormat("en-US", {
        //   year: "numeric",
        //   month: "2-digit",
        //   day: "2-digit",
        //   timeZone: "Asia/Kolkata",
        // }).format(new Date(dataItem?.created_at)),
        createdat: dateFormatter(dataItem?.created_at),
      };
    });
  }

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const response = await GetPermissionsByRoleAndModuleID(
        roleID.toString(),
        "7"
      );
      console.log(response);
      setPermissions(response?.data);
    })();
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "2") {
      navigate("/OrganizationManagement");
    } else if (permissions[0]?.permission_type.toString() === "3") {
      navigate("/PartnerManagement");
    }
  }, [permissions, navigate]);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });

    GetAdminUsers(listPage, searchParam)
      .then((response) => {
        setData(response.data);
        setDataCount(response.data.count);
      })
      .catch((error) => {});
    setFlag(false);
  }, [flag, navigate, listPage, searchParam]);

  const breadcrumbData = [
    {
      name: "settings",
      url: "/SettingsList",
      active: false,
    },
    {
      name: "Manage users",
      url: "/AdminUsersList",
      active: true,
    },
  ];

  return (
    <Container fluid className="adminusers">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="adminusers-content p-0">
          <Navbar userProfile={userProfile} />
          <BreadCrumb breadcrumbData={breadcrumbData} />
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Form.Select className="filter-select usertype" aria-label="20">
              <option value="1">User Type</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="20">20</option>
            </Form.Select>
            <Button className="addfilter">Add Filter</Button> */}
            {permissions[0]?.sub_modules[0]?.permissions_actions[0]
              ?.permission[0]?.permission === true && (
              <Button className="btn-primary12 adduser" onClick={addNewUser}>
                Add New User
              </Button>
            )}
          </div>
          <div className="adminusertable">
            <div className="TableMUIcountainer">
              <TableMUI
                columns={columns}
                rows={rows}
                dataCount={dataCount}
                setListPage={setListPage}
                listPage={listPage}
              />
            </div>
            <PopupTemplate
              popupHeader="Confirm Deletion."
              popupBody={`Are you sure you want to delete user ${adminName}?`}
              handleFunction={deleteFunction}
              isModalOpen={showDeleteAdmin}
              onCancel={handleCloseDeleteAdmin}
              id={adminID}
              setFlag={setFlag}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminUsersList;
