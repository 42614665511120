import "../../../styles/pagelayout.scss";
import "./SettingFileLIst.scss";
import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { GetUserProfile } from "../../../API/endpoints/User";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/header/NavBar";
import BreadCrumb from "../../components/header/BreadCrumb";
import PrimaryMessage from "../../components/Alerts/PrimaryMessage";
import Folder from "../components/folder/Folder";
import { GetFilesSettingsForRole } from "../../../API/endpoints/fileupload/FileSettings";
import { GetBrand } from "../../../API/endpoints/Brands";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../../API/endpoints/RolesAndPermissions/Roles";

const SettingFileLIst = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [showAlert, setAlert] = useState(true);
  const [files, setFiles] = useState<any[]>([]);
  const [brandName, setBrandName] = useState("");
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    if (roleID) {
      (async () => {
        const roleDetails = await GetRoleById(roleID.toString());

        if (roleDetails?.data?.type === "organization") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "8"
          );
          console.log(response);
          setPermissions(response?.data);
        } else if (roleDetails?.data?.type === "administrative") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "2"
          );
          console.log(response);
          setPermissions(response?.data);
        }
      })();
    }
  }, [roleID]);

  useEffect(() => {
    (async () => {
      // try {
      //   const response = await GetFilesByCategory("brand");
      //   setFiles(response?.data);
      // } catch (error) {}
      try {
        const res = await GetBrand(params.brandid);
        setBrandName(res?.data?.brand_name);
      } catch (error) {}
    })();
  }, [params.brandid]);

  useEffect(() => {
    // if (
    //   permissions[0]?.sub_modules[5]?.permissions_actions[1]?.permission[0]
    //     ?.permission === true
    // ) {
    //   try {
    //     const response = await GetFilesByCategory("brand");
    //     setFiles(response?.data);
    //   } catch (error) {}
    // }
    (async () => {
      if (
        permissions[0]?.sub_modules[5]?.permissions_actions[1]?.permission[0]
          ?.permission === true
      ) {
        try {
          const response = await GetFilesSettingsForRole(roleID.toString());
          const fileSettingsList = response?.data
            .map((item: any) => {
              return item.file_settings;
            })
            .filter((item: any) => {
              return item.category_id === 1;
            });
          console.log(fileSettingsList);
          setFiles(fileSettingsList);
          // const res = await GetFilesByCategory("brand");
          // console.log(res);
          // const publicFiles = res?.data.filter((item: any) => {
          //   return item.public_access === true;
          // });
          // setFiles((prev: any) => [...prev, ...publicFiles]);
        } catch (error) {}
      }
    })();
  }, [roleID, permissions]);

  const breadcrumbData = [
    {
      name: "brand management",
      url: "/BrandManagement",
      active: false,
    },
    {
      name: "brands",
      url: "/BrandManagement",
      active: false,
    },
    {
      name: brandName,
      url: `/brand/${params.brandid}`,
      active: false,
    },
    {
      name: "Files",
      url: `/brand-filesettings/${params.brandid}`,
      active: true,
    },
  ];
  const handleCloseAlert = () => {
    setAlert(false);
  };

  return (
    <Container fluid className="pge-layout SettingFileLIst">
      <Row>
        <Col className="sidebar">
          <Sidebar active="brand" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <BreadCrumb breadcrumbData={breadcrumbData} />
          {showAlert && (
            <div className="alert-wraper">
              <PrimaryMessage
                isAlertOpen={showAlert}
                // type="primary"
                headingText="Info"
                bodyText="This section works like a File manager.
                                Upload/drag and drop your product files
                                to each of the specified folders to access
                                and manage all your product files in one
                                place"
                dismissible={true}
                onCancel={handleCloseAlert}
              />
            </div>
          )}
          <div className="folder-wraper">
            {files.map((item: any) => {
              return (
                <div key={item.id}>
                  <Folder
                    url={`/brand-files/${params.brandid}&&${item.id}`}
                    fileName={item.name}
                  />
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingFileLIst;
