import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetReportRequestByID,
  UpdateReportRequestStatus,
} from "../../API/endpoints/Advisory";
import { CreateActivity } from "../../API/endpoints/Dashboard";
import { sendNewReportNotificationEmail } from "../../API/endpoints/Email";
import { CreateTodo } from "../../API/endpoints/Todos";

function SuccessAdvisory() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-restricted-globals
  const id = new URLSearchParams(location.search).get("advisory_id");

  useEffect(() => {
    if (id) {
      (async () => {
        await UpdateReportRequestStatus(id);
        await sendNewReportNotificationEmail({ advisory_id: id });
        const advisoryReportRequest = await GetReportRequestByID(id);
        console.log(advisoryReportRequest);
        try {
          const todoData = {
            id: `advisory-request:${id}`,
            brand_id: advisoryReportRequest?.data?.brand_id,
            message: `<b>${advisoryReportRequest?.data?.brand?.brand_name}</b> has purchased an Advisory report : <b>${advisoryReportRequest?.data?.report?.report_name}</b>`,
            url: "/advisorymanagement/admin",
            url_action: "Please review and publish the report.",
            permission_submodule_id: 17,
            admin: true,
          };
          const res = await CreateTodo(todoData);
          console.log(res);
          const activity = await CreateActivity(
            `Brand ${advisoryReportRequest?.data?.brand?.brand_name} has purchased an Advisory Report : ${advisoryReportRequest?.data?.report?.report_name}.`
          );
          console.log(activity);
        } catch (e) {
          console.log(e);
        }
        Cookies.set("IsActivated", "true");
        navigate("/advisorymanagement/brand");
      })();
    }
  }, [navigate, id]);
  return <div></div>;
}

export default SuccessAdvisory;
