import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import "./documentSettings.scss";
import {
  CreateDocumentSetting,
  GetCategories,
  GetDocumentSetting,
  GetSubCategories,
  UpdateDocumentSetting,
} from "../../API/endpoints/document";

function AddDocumentSettingsPopup(props: {
  onCancel: () => void;
  setFlag: (arg0: boolean) => void;
  flag: boolean | undefined;
  id: string | undefined;
  isModalOpen: boolean | undefined;
}) {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [defaultValues, setDefaultValues] = useState<any>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      category_id: undefined,
      sub_category_id: undefined,
      document_name: "",
      document_type: "Pdf",
      is_mandatory: undefined,
    },
  });

  useEffect(() => {
    let defaults = {
      category_id: defaultValues.category_id
        ? defaultValues.category_id
        : undefined,
      sub_category_id: defaultValues.document_sub_category?.id
        ? defaultValues.document_sub_category.id
        : undefined,
      document_name: defaultValues.document_name
        ? defaultValues.document_name
        : "",
      document_type: "Pdf",
      is_mandatory: undefined,
    };
    reset(defaults);
    console.log(defaults);
  }, [defaultValues, reset]);

  useEffect(() => {
    (async () => {
      const categories = await GetCategories();
      setCategories(categories.data);
    })();
    (async () => {
      const subcategories = await GetSubCategories();
      setSubcategories(subcategories.data);
    })();
    (async () => {
      const defaultData = await GetDocumentSetting(props.id);
      setDefaultValues(defaultData.data);
    })();
  }, [props.id]);

  const handleClose = () => {
    setDefaultValues([]);
    props.onCancel();
  };

  const onFormSubmit = (data: {
    category_id: number | undefined;
    sub_category_id: number | undefined;
    document_name: string;
    document_type: string;
    is_mandatory: boolean | undefined;
  }) => {
    let formData = {
      category_id: data.category_id,
      document_name: data.document_name,
      document_type: data.document_type,
      is_mandatory: data.is_mandatory,
      sub_category_id: data.sub_category_id,
    };
    if (defaultValues.category_id === undefined) {
      (async () => {
        await CreateDocumentSetting(formData);
        props.setFlag(!props.flag);
        handleClose();
      })();
    } else {
      (async () => {
        await UpdateDocumentSetting(formData, props.id);
        props.setFlag(!props.flag);
        handleClose();
      })();
    }
  };

  return (
    <Modal show={props.isModalOpen} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Document Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit((data) => {
            onFormSubmit(data);
          })}
        >
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="category">
                <Form.Label>Document Category</Form.Label>
                <Form.Select
                  aria-label="20"
                  {...register("category_id", {
                    required: "* This field is required",
                  })}
                >
                  <option value="">Select a category</option>;
                  {categories &&
                    categories.map((category: { id: number; name: string }) => {
                      return (
                        <option value={category.id} key={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Text muted>
                  <span>
                    {errors?.category_id && (
                      <p className="error-form">
                        {String(errors.category_id?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="subcategory">
                <Form.Label>Document Sub Category</Form.Label>
                <Form.Select
                  aria-label="20"
                  {...register("sub_category_id", {
                    required: "* This field is required",
                  })}
                >
                  <option value="">Select a sub category</option>;
                  {subcategories &&
                    subcategories.map(
                      (subcategory: { id: number; name: string }) => {
                        return (
                          <option value={subcategory.id} key={subcategory.id}>
                            {subcategory.name}
                          </option>
                        );
                      }
                    )}
                </Form.Select>
                <Form.Text muted>
                  <span>
                    {errors?.sub_category_id && (
                      <p className="error-form">
                        {String(errors.sub_category_id?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="documentname">
                <Form.Label>Document Name</Form.Label>
                <Form.Control
                  type="text"
                  {...register("document_name", {
                    required: "* This field is required",
                  })}
                />
                <Form.Text muted>
                  <span>
                    {errors?.document_name && (
                      <p className="error-form">
                        {String(errors.document_name?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="mandatory">
                <Form.Label>Is this document mandatory?</Form.Label>
                <Form.Select
                  aria-label="20"
                  {...register("is_mandatory", {
                    required: "* This field is required",
                  })}
                >
                  <option value="">Select Option</option>;
                  <option value="true">Yes</option>;
                  <option value="false">No</option>;
                </Form.Select>
                <Form.Text muted>
                  <span>
                    {errors?.is_mandatory && (
                      <p className="error-form">
                        {String(errors.is_mandatory?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="documenttype">
                <Form.Label>Document Type</Form.Label>
                <Form.Select
                  disabled
                  aria-label="20"
                  {...register("document_type", {
                    required: "* This field is required",
                  })}
                >
                  <option value="Pdf">PDF</option>;
                </Form.Select>
                <Form.Text muted>
                  <span>
                    {errors?.document_type && (
                      <p className="error-form">
                        {String(errors.document_type?.message)}
                      </p>
                    )}
                  </span>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button
          className="primaryButton"
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          className="primaryButton"
          variant="primary"
          type="submit"
          onClick={handleSubmit((data) => {
            onFormSubmit(data);
          })}
        >
          {defaultValues.document_name ? "Update" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddDocumentSettingsPopup;
