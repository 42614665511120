import Breadcrumb from "react-bootstrap/Breadcrumb";
import "./BreadCrumb.scss"
export interface breadcrumbProps {
    breadcrumbData: any
}

const BreadCrumb: React.FunctionComponent<breadcrumbProps> = ({
    breadcrumbData
}) => {
    return (
        <Breadcrumb className="font12-medium breadcrumb-nav">
            {breadcrumbData?.map((data: any, index: any) => {
                return (
                    <Breadcrumb.Item
                        key={index}
                        href={data.url ? data.url : ""}
                        active={data.active}
                    >
                        {data.name}
                    </Breadcrumb.Item>
                )
            })}
        </Breadcrumb>
    )
}

export default BreadCrumb;