import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import AddReportForm from "../../forms/reports/addReportForm";
import { ReportCategoriesDTO, AdvisoryReportInputDTO } from "../../../dtos/advisory.dtos";

export interface RetportSettingsProps {
  isModalOpen: boolean;
  onCancel: () => void;
  categories: ReportCategoriesDTO[];
  onSubmit: (data: AdvisoryReportInputDTO) => void;
  submitting: boolean;
  defaultData?: AdvisoryReportInputDTO;
  setEditData: (arg0: AdvisoryReportInputDTO) => void;
  reportId: string;
}
const AddReportModal: React.FunctionComponent<RetportSettingsProps> = ({
  isModalOpen,
  onCancel,
  categories,
  onSubmit,
  submitting,
  defaultData,
  setEditData,
  reportId
}) => {
  const handleClose = () => {
    onCancel();
    setEditData({
      id: 0,
      category_name: "",
      reports_category_id: 0,
      report_name: "",
      report_price: "",
      description: "",
      url: ""
    })
  }

  return (
    <Container fluid>
      <Row>
        <Col className="p-0">
          <Modal
            show={isModalOpen}
            onHide={handleClose}
            size="lg"
            className="modal-layout"
          >
            <Modal.Header closeButton>
              <Modal.Title
                className="font16-medium gray-800"
              >{reportId ? "Update Advisory Report" : "Add New Advisory Report"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddReportForm
                onCancel={handleClose}
                categories={categories}
                onSubmit={onSubmit}
                submitting={submitting}
                defaultData={defaultData}
                reportId={reportId}
              />
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  )
}

export default AddReportModal;