import { getWith, postWith, patchWith } from "../../axios";

export async function GetPermissionsByType(typeId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/permissions-list/${typeId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAssignedPermissionsByRoleId(roleId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/permissions/${roleId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPermissionsByRoleIdAndModuleId(roleId: string, moduleId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/permissions/module/${roleId}&&${moduleId}`;
    const countries = await getWith<any[]>(url);
    return countries;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function AssignPermission(data: any, roleId: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/assign-permissions/${roleId}`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}

export async function UpdatePermission(data: any, roleId: any) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/role/assign-permissions/${roleId}`;
    return (await patchWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    console.log(ex);
    throw ex.response.data;
  }
}