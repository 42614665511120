import { useEffect, useState } from "react";
import "./passwordStrengthMeter.scss";
export interface PasswordStrengthMeterProps {
    password: string;
}

const PasswordStrengthMeter: React.FunctionComponent<PasswordStrengthMeterProps> = ({
    password
}) => {
    const [status, setStaus] = useState("weak");
    useEffect(() => {
        const strengthMeter = document.getElementById('strength-meter');
        const status = document.getElementById('status')
        const updateStrengthMeter = (pass: any) => {
            const weaknesses = calculatePasswordStrength(pass)
            console.log(weaknesses)
            let strength = 100
            let progresscolor = "#F97066"
            weaknesses.forEach(weakness => {
                if (weakness == null) return
                strength -= weakness?.deduction
            })
            // if(pass === "") {
            //     strength = 10
            //     progresscolor = "#DC6803"
            // }
            if (strength > 40) {
                progresscolor = "#F97066"
                status?.style.setProperty("--statusColor", "#F97066")
                setStaus("weak")
            }
            if (strength > 60) {
                progresscolor = "#FDB022"
                status?.style.setProperty("--statusColor", "#FDB022")
                setStaus("medium")
            }
            if (strength === 100) {
                progresscolor = "#32D583"
                status?.style.setProperty("--statusColor", "#32D583")
                setStaus("strong")
            }
            strengthMeter?.style.setProperty("--strength", `${strength}`)
            strengthMeter?.style.setProperty("--progresscolor", `${progresscolor}`)
        }
        const calculatePasswordStrength = (pass: any) => {
            const weaknesses: any[] = []
            weaknesses.push(lengthWeakness(pass))
            weaknesses.push(lowercaseWeakness(pass))
            weaknesses.push(uppercaseWeakness(pass))
            weaknesses.push(numberWeakness(pass))
            weaknesses.push(specialCharactersWeakness(pass))
            weaknesses.push(repeatCharactersWeakness(pass))
            return weaknesses
        }
        const lengthWeakness = (pass: any) => {
            const length = pass.length
            if (length <= 5) {
                return {
                    message: "Your password is too short",
                    deduction: 40
                }
            }
            if (length <= 10) {
                return {
                    message: "Your password could be longer",
                    deduction: 15
                }
            }
        }
        const lowercaseWeakness = (pass: any) => {
            return characterTypeWeakness(pass, /[a-z]/g, "lowercase characters")
        }
        const uppercaseWeakness = (pass: any) => {
            return characterTypeWeakness(pass, /[A-Z]/g, "Uppercase characters")
        }
        const numberWeakness = (pass: any) => {
            return characterTypeWeakness(pass, /[0-9]/g, "numbers")
        }
        const specialCharactersWeakness = (pass: any) => {
            return characterTypeWeakness(pass, /[^0-9a-zA-Z\s]/g, "special characters")
        }
        function characterTypeWeakness(password: any, regex: any, type: any) {
            const matches = password.match(regex) || []
            if (matches.length === 0) {
                return {
                    message: `Your password has no ${type}`,
                    deduction: 20
                }
            }
            if (matches.length <= 2) {
                return {
                    message: `Your password could use more ${type}`,
                    deduction: 5
                }
            }
        }
        const repeatCharactersWeakness = (password: any) => {
            const matches = password.match(/(.)\1/g) || []
            if (matches.length > 0) {
                return {
                    message: `Your password has repeat characters`,
                    deduction: matches.length * 10
                }
            }
        }
        updateStrengthMeter(password)
    }, [password])

    return (
        <div>
            {password && <div className="meter-wraper">
                <div className="strength-meter" id="strength-meter"></div>
                <div className="status font12-regular" id="status">{status}</div>
            </div>}
        </div>
    )
}

export default PasswordStrengthMeter;