import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import "./documentSettings.scss";
import Cookies from "js-cookie";
import { UploadDocument } from "../../API/endpoints/document";

function AddDocumentPopup(props: {
  onCancel: () => void;
  setFlag: (arg0: boolean) => void;
  flag: boolean | undefined;
  isModalOpen: boolean | undefined;
  documentID: string | undefined;
  ID: string | undefined;
  selectedDocument: any;
}) {
  const params = useParams();
  const [data, setData] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [disableUpload, setDisableUpload] = useState(false);
  const userID = Cookies.get("userID");
  const formSubmitData: any = new FormData();
  const myRefname: React.MutableRefObject<any> = useRef(null);
  const handleClick = () => {
    !disableUpload && myRefname.current.click();
  };

  const { handleSubmit } = useForm({
    defaultValues: {
      document_name: "",
      document_type: "",
      file: undefined,
    },
  });

  const handleClose = () => {
    props.onCancel();
    setData([]);
    setDisableUpload(false);
  };

  const onFormSubmit = async (formData: {
    category_id?: undefined;
    sub_category_id?: undefined;
    document_name: any;
    document_type?: string;
    is_mandatory?: undefined;
    file?: any;
  }) => {
    console.log(data);
    await formSubmitData.append("file", data[0]);
    await formSubmitData.append("filename", data[0].name);
    await formSubmitData.append("document_settings_id", props.documentID);

    if (params.type === "brand") {
      await formSubmitData.append("brand_id", params.id);
      await formSubmitData.append("user_type", "Brand");
    }

    if (params.type === "partner") {
      await formSubmitData.append("partner_id", params.id);
      await formSubmitData.append("user_type", "Partner");
    }
    if (params.type === "product") {
      await formSubmitData.append("product_id", params.id);
      await formSubmitData.append("user_type", "Product");
    }
    await formSubmitData.append("uploaded_by", userID);

    (async () => {
      await UploadDocument(formSubmitData);
      props.setFlag(!props.flag);
      handleClose();
      setData([]);
      setDisableUpload(false);
    })();
  };

  const uploadHandler = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    file.isUploading = true;
    setData([...data, file]);
    setDisableUpload(true);
    console.log(file);
    if (file.type !== "application/pdf") {
      setErrorMessage("The file is not a PDF");
    }
  };

  const deleteFile = (filename: string) => {
    setData(data.filter((file: { name: any }) => file.name !== filename));
    setDisableUpload(false);
    setErrorMessage("");
  };

  return (
    <Modal show={props.isModalOpen} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Upload Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit((data) => {
            onFormSubmit(data);
          })}
        >
          <Row>
            <Col>
              <div
                className={disableUpload ? "file-card2" : "file-card"}
                onClick={handleClick}
              >
                <div className="file-inputs">
                  {!disableUpload && (
                    <input
                      type="file"
                      ref={myRefname}
                      onChange={uploadHandler}
                      accept="image/png,.pdf"
                    />
                  )}
                  <div className="file-upload-img">
                    <img
                      className="headerPlusIcon"
                      src="../svgs/cloud-arrow-up-fill.svg"
                      alt=""
                    />
                  </div>
                </div>

                <>
                  <p className="main">Click to upload</p>
                  <p className="info">or drag and drop</p>
                  <p className="info">Supported file type: PDF</p>
                </>
              </div>
            </Col>
          </Row>
          <Row>
            <ul className="file-list">
              {data &&
                data.map((file: { name: string }) => (
                  <>
                    <li className="file-item" key={file.name}>
                      <p>{file.name}</p>
                      <div className="actions">
                        <img
                          src="../svgs/trash.svg"
                          alt=""
                          onClick={() => {
                            deleteFile(file.name);
                          }}
                        />
                      </div>
                    </li>
                    <Form.Text muted>
                      <span>
                        {errorMessage && (
                          <p className="error-form">{errorMessage}</p>
                        )}
                      </span>
                    </Form.Text>
                  </>
                ))}
            </ul>
          </Row>
        </Form>
      </Modal.Body>
      {data[0] && !errorMessage && (
        <Modal.Footer className="footer">
          <Button
            className="primaryButton"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="primaryButton"
            variant="primary"
            type="submit"
            onClick={handleSubmit((data) => {
              onFormSubmit(data);
            })}
          >
            Add
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default AddDocumentPopup;
