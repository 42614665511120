import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./AddPartner.scss";
import "../../styles/global.scss";
// Components
import Sidebar from "../components/sidebar/Sidebar";

// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// @ts-ignore
import PartnerInfoForm from "../components/forms/partnerInfoForm";
import PartnerTeam from "./PartnerTeam";
import PartnerServiceCapabilities from "./PartnerServiceCapabilities";
// import PartnerMOU from "./PartnerMOU";
import {
  GetAllCountries,
  GetMarketPresenceCountries,
  GetYearsOfOperation,
  GetCategories,
} from "../../API/endpoints/Common";
// import PartnerAgreement from "./PartnerAgreement";
import { GetPartner } from "../../API/endpoints/Partner";
// @ts-ignore
import Cookies from "js-cookie";
import Navbar from "../components/header/NavBar";
import { GetUserProfile } from "../../API/endpoints/User";
import ToastMessage from "../components/toasts/ToastMessage";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";

function AddPartner() {
  const params = useParams();
  const [defaultTab, setDefaultTab] = useState<any>("partner-info");
  const [partnerID, setPartnerID] = useState(
    params?.partnerid ? params.partnerid : undefined
  );
  const [years, setYears] = useState([]);
  const [countries, setCountries] = useState([]);
  const [marketPresenceCountries, setMarketPresenceCountries] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isActivePartner, setIsActivePartner] = useState(false);
  const [partnerName, setPartnerName] = useState<string>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const [categories, setCategories] = useState([]);
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setPartnerID(response.data?.partner_id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID]);

  useEffect(() => {
    if (roleID) {
      (async () => {
        const roleDetails = await GetRoleById(roleID.toString());

        if (roleDetails?.data?.type === "partner") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "12"
          );
          console.log(response);
          setPermissions(response?.data);
        } else if (roleDetails?.data?.type === "administrative") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "4"
          );
          console.log(response);
          setPermissions(response?.data);
        }
      })();
    }
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "1") {
      setIsSuperAdmin(true);
    }
  }, [permissions]);

  useEffect(() => {
    (async () => {
      const countries = await GetAllCountries(-1);
      setCountries(countries.data);
    })();
    (async () => {
      const countries = await GetMarketPresenceCountries();
      setMarketPresenceCountries(countries.data);
    })();
    (async () => {
      const countries = await GetYearsOfOperation();
      setYears(countries.data);
    })();
    (async () => {
      try {
        const response = await GetCategories();
        setCategories(response.data);
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    if (params.defaultTab) {
      setDefaultTab(params.defaultTab);
    }
  }, [params]);

  useEffect(() => {
    if (
      permissions[0]?.sub_modules[0]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("partner-info");
    } else if (
      permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("partner-team");
    } else if (
      permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      setDefaultTab("partner-service-capabilities");
    }
  }, [permissions]);

  useEffect(() => {
    if (partnerID) {
      (async () => {
        let res = await GetPartner(partnerID);
        console.log(res.data);
        setPartnerName(res.data.company_name);
        if (res.data.status === "Invitation Sent") {
          setIsActivePartner(true);
        }
      })();
    }
  }, [partnerID]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID]);

  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });
  const handleNotification = (type: string, msg: string) => {
    setNotification({
      type: type,
      msg: msg,
    });
  };

  return (
    <Container fluid className="addpartner">
      <Row>
        <Col className="sidebar">
          <Sidebar active={"partner"} />
        </Col>
        <Col className="content-main p-0">
          <Container fluid className="parent-container">
            <Row>
              <Col className="p-0">
                <Navbar userProfile={userProfile} />
              </Col>
            </Row>
            <Row>
              <Col className="breadcrumb-nav">
                <Breadcrumb>
                  <Breadcrumb.Item className="font12-regular gray-500">
                    <Link to="/PartnerManagement">PARTNER MANAGEMENT</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="font12-medium gray-800">
                    {params?.partnerid
                      ? partnerName.toLocaleUpperCase()
                      : "ADD NEW PARTNER"}
                  </Breadcrumb.Item>
                  {params?.partnerid && (
                    <Breadcrumb.Item active className="font12-medium gray-800">
                      {params?.partnerid ? "EDIT PARTNER" : ""}
                    </Breadcrumb.Item>
                  )}
                </Breadcrumb>
              </Col>
            </Row>
            <Row className="tabs-row">
              <Col className="p-0">
                <Tabs
                  activeKey={defaultTab}
                  onSelect={(k) => {
                    if (params?.partnerid) {
                      setDefaultTab(k);
                    }
                  }}
                  id="fill-tab-example"
                  className="tabs-global"
                  fill
                >
                  {permissions[0]?.sub_modules[0]?.permissions_actions[1]
                    ?.permission[0]?.permission && (
                    <Tab
                      eventKey="partner-info"
                      title={
                        <React.Fragment>
                          <div>Basic Info</div>
                        </React.Fragment>
                      }
                    >
                      <PartnerInfoForm
                        countries={countries}
                        years={years}
                        setPartnerID={setPartnerID}
                        setDefaultTab={setDefaultTab}
                        handleNotification={handleNotification}
                        categories={categories}
                        permissions={permissions}
                      />
                    </Tab>
                  )}
                  {permissions[0]?.sub_modules[1]?.permissions_actions[1]
                    ?.permission[0]?.permission && (
                    <Tab
                      eventKey="partner-team"
                      title={
                        <React.Fragment>
                          <div>Team</div>
                        </React.Fragment>
                      }
                    >
                      <PartnerTeam
                        partnerID={partnerID}
                        setDefaultTab={setDefaultTab}
                        handleNotification={handleNotification}
                        permissions={permissions}
                        isSuperAdmin={isSuperAdmin}
                      />
                    </Tab>
                  )}
                  {permissions[0]?.sub_modules[2]?.permissions_actions[1]
                    ?.permission[0]?.permission && (
                    <Tab
                      eventKey="partner-service-capabilities"
                      title={
                        <React.Fragment>
                          <div>Service Capabilities</div>
                        </React.Fragment>
                      }
                    >
                      <PartnerServiceCapabilities
                        countries={marketPresenceCountries}
                        partnerID={partnerID}
                        isActivePartner={isActivePartner}
                        isSuperAdmin={isSuperAdmin}
                        permissions={permissions}
                      />
                    </Tab>
                  )}
                </Tabs>
              </Col>
            </Row>
          </Container>
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AddPartner;
