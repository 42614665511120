import "./AssignUser.scss";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Multiselect } from "multiselect-react-dropdown";
import React, { useEffect, useRef } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";

export interface assignUserProps {
  breadcrumbData: any;
  roles: any;
  teamMembers: any;
  marketPresence: any;
  users: any;
  marketValiate: string;
  roleChange: (roleId: any) => void;
  MarketChange: (markets: any) => void;
  handleMarketRemove: (markets: any) => void;
  onSubmit: (data: any) => void;
  deleteAssignedUser: (id: number) => void;
  flag: boolean;
  BackNavigation: () => void;
  permissions?: any;
}
const AssignUser: React.FunctionComponent<assignUserProps> = ({
  breadcrumbData,
  roles,
  teamMembers,
  marketPresence,
  users,
  marketValiate,
  roleChange,
  MarketChange,
  handleMarketRemove,
  onSubmit,
  deleteAssignedUser,
  flag,
  BackNavigation,
  permissions,
}) => {
  const { register, formState, handleSubmit, reset } = useForm();
  const { errors } = formState;
  const multiselectRef = useRef<any>([]);

  useEffect(() => {
    if (flag === true) {
      reset();
      multiselectRef.current.resetSelectedValues();
    }
  }, [flag, reset]);

  const handleRoleChange = async (roleid: any) => {
    roleChange(roleid);
  };
  const handleMarketChange = (markets: any) => {
    MarketChange(markets);
  };
  const handleRemove = (markets: any) => {
    handleMarketRemove(markets);
  };
  const deleteTeam = (id: number) => {
    deleteAssignedUser(id);
  };
  const handleBackNavigation = () => {
    BackNavigation();
  };

  const onHandleSubmit = async (data: any) => {
    onSubmit(data);
  };
  return (
    <div className="assign-user">
      <div className="assign-team-cntr">
        <Breadcrumb className="font12-medium breadcrumbnav">
          {breadcrumbData?.map((data: any, index: any) => {
            return (
              <Breadcrumb.Item
                key={index}
                href={data.url ? data.url : ""}
                active={data.active}
              >
                {data.name}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        <div className="content-wraper">
          <div className="header-wraper">
            <div>
              <Button className="backarrow-btn" onClick={handleBackNavigation}>
                <img src="../svgs/arrows-left.svg" alt="arrow" />
              </Button>
              <span className="font18-medium gray-800">Assign Team</span>
            </div>
          </div>
          <Form
            className="form"
            onSubmit={handleSubmit((data) => onHandleSubmit(data))}
          >
            <Form.Group className="formgroup role" controlId="role">
              <Form.Label>Select Role</Form.Label>
              <Form.Select
                {...register("role", {
                  required: "* This field is required",
                })}
                onChange={(e) => {
                  handleRoleChange(e.target.value);
                }}
                disabled={
                  permissions[0]?.sub_modules[6]?.permissions_actions[0]
                    ?.permission[0]?.permission === false
                }
              >
                <option value="">Select</option>
                {roles?.length &&
                  roles?.map((item: any) => {
                    return (
                      <option
                        key={item.roleDetails.id}
                        value={item.roleDetails.id}
                      >
                        {item.roleDetails.role_name}
                      </option>
                    );
                  })}
              </Form.Select>
              <Form.Text muted>
                <span>
                  {errors?.role && (
                    <p className="error-form">{String(errors.role?.message)}</p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
            <Form.Group className="formgroup team" controlId="team">
              <Form.Label>Select Team Member</Form.Label>
              <Form.Select
                {...register("user_id", {
                  required: "* This field is required",
                })}
                disabled={teamMembers.length === 0 ? true : false}
              >
                <option value="">Select</option>
                {teamMembers?.length &&
                  teamMembers?.map((item: any) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.first_name} {item?.last_name}
                      </option>
                    );
                  })}
              </Form.Select>
              <Form.Text muted>
                <span>
                  {errors?.user_id && (
                    <p className="error-form">
                      {String(errors.user_id?.message)}
                    </p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
            <Form.Group className="formgroup markets" controlId="markets">
              <Form.Label>Select Markets</Form.Label>
              <Multiselect
                id="css_custom"
                customCloseIcon={
                  <>
                    <img src="../svgs/close-btn.svg" alt="close-btn" />
                  </>
                }
                showArrow
                options={marketPresence}
                displayValue="name"
                showCheckbox={true}
                placeholder="Select"
                closeIcon="cancel"
                selectedValues={[]}
                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleMarketChange(e);
                }}
                onRemove={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleRemove(e);
                }}
                ref={multiselectRef}
              />
              <Form.Text muted>
                <span>
                  {marketValiate && (
                    <p className="error-form">{marketValiate}</p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
            {permissions[0]?.sub_modules[6]?.permissions_actions[0]
              ?.permission[0]?.permission && (
              <Button className="plus-btn btn41" type="submit">
                <img src="../svgs/plus-blue.svg" alt="plus-blue" />
              </Button>
            )}
          </Form>
          <Container fluid>
            <Row className="row-1">
              <Col xs={12} className="search-col">
                <InputGroup size="sm" className="input-search ssss">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    <img src="../svgs/lenseicon.svg" alt="searchicon" />
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Search"
                  />
                </InputGroup>
              </Col>
            </Row>
            {users.length !== 0 &&
              users.map((itm: any) => {
                return (
                  <Row key={itm.id} className="row-2">
                    <Col md={4} className="basicinfo-col">
                      <div className="basicinfo">
                        <div className="first-letter">
                          {itm.user?.first_name.charAt(0)}
                        </div>
                        <div>
                          <div className="font14-medium gray-800">
                            {itm.user.first_name} {itm.user?.last_name}
                          </div>
                          <div className="font12-regular gray-500">
                            {itm.user.user_roles[0]?.roles?.role_name}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={7} className="markets-col">
                      <div className="markets-wraper">
                        {itm.countries.length !== 0 &&
                          itm.countries.map((country: any) => {
                            return (
                              <div
                                key={country.id}
                                className="badgepill-gray50 
                                                                        font10-regular gray-800 market"
                              >
                                {country.name}
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                    <Col md={1} className="trash-col">
                      {permissions[0]?.sub_modules[6]?.permissions_actions[3]
                        ?.permission[0]?.permission && (
                        <Button
                          className="trash-btn"
                          onClick={() => deleteTeam(itm.id)}
                        >
                          <img src="../svgs/trash600.svg" alt="trash600" />
                        </Button>
                      )}
                    </Col>
                    <Col className="sep-line" xs={12}>
                      <div></div>
                    </Col>
                  </Row>
                );
              })}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default AssignUser;
