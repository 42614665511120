import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  CreatePartnerServices,
  GetPartnerServicesList,
  UpdatePartnerServices,
} from "../../API/endpoints/Partner";
import { partnerServiceCapabilities } from "../dtos/partner.dtos";
import "./PartnerServiceCapabilities.scss";
import ToastMessage from "../components/toasts/ToastMessage";
import { GetCountries } from "../../API/endpoints/Common";
import { countryDTO } from "../dtos/organization.dtos";

function PartnerServiceCapabilities(props: {
  // setDefaultTab: (arg0: string) => void;
  isActivePartner: boolean;
  isSuperAdmin: boolean;
  partnerID: any;
  countries: any[];
  permissions: any;
}) {
  const [allCountries, setAllCountries] = useState<countryDTO[]>();
  const [regulatoryCompliance, setRegulatoryCompliance] = useState(false);
  const [importClearance, setImportClearance] = useState(false);
  const [warehousingAndStorage, setWarehousingAndStorage] = useState(false);
  const [ecomSetupMgmt, setEcomSetupMgmt] = useState(false);
  const [retailDistribution, setRetailDistribution] = useState(false);
  const [orderFullfilment, setOrderFullfilment] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(0);
  const [servicesError, setServicesError] = useState<string>("");
  const [services, setServices] = useState<
    {
      region: number;
      regulatory_compliance: boolean;
      importation_clearance: boolean;
      warehousing_storage: boolean;
      ecom_setup_mgmt: boolean;
      retail_distribution: boolean;
      order_fulfilment: boolean;
      marketing: boolean;
    }[]
  >([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const params = useParams();
  const navigate = useNavigate();
  const onSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (services.length > 0) {
      if (params.partnerid) {
        (async () => {
          await UpdatePartnerServices(params.partnerid, services);
          // console.log(props.isActivePartner);
          // await CreatePartnerServices(props.partnerID, services);
          props.isActivePartner &&
            !props.isSuperAdmin &&
            // props.setDefaultTab("agreement");
            navigate("/PartnerManagement");
          setNotification({
            type: "success",
            msg: "Updated successfully",
          });
        })();
      } else {
        (async () => {
          await CreatePartnerServices(props.partnerID, services);
          // !props.isSuperAdmin && props.setDefaultTab("agreement");
          !props.isSuperAdmin && navigate("/PartnerManagement");
          props.isSuperAdmin && navigate("/PartnerManagement");
        })();
      }
    } else {
      setServicesError("Services list cannot be empty");
    }
  };

  const deleteFromList = (del: any) => {
    let newServicesList = services.filter((service) => service.region !== del);
    setServices(newServicesList);
  };

  const addToList = () => {
    let list = {
      region: selectedMarket,
      regulatory_compliance: regulatoryCompliance,
      importation_clearance: importClearance,
      warehousing_storage: warehousingAndStorage,
      ecom_setup_mgmt: ecomSetupMgmt,
      retail_distribution: retailDistribution,
      order_fulfilment: orderFullfilment,
      marketing: marketing,
    };
    let flag = false;
    setServicesError("");
    services.map((service) => {
      console.log(service.region, selectedMarket);
      if (service.region.toString() === selectedMarket.toString()) {
        flag = true;
        setServicesError("Service Capabilities in the region already exists.");
        return true;
      }
      return true;
    });

    if (!flag) {
      setServices([...services, list]);
      setServicesError("");
    }
  };

  useEffect(() => {
    setSelectedMarket(props?.countries[0]?.id);
    (async () => {
      const countries = await GetCountries();
      setAllCountries(countries.data);
    })();
  }, [props]);

  useEffect(() => {
    params.partnerid &&
      (async () => {
        const res = await GetPartnerServicesList(params.partnerid);
        const servicesAdded = res.data.services;
        setServices([]);
        servicesAdded.map((service: partnerServiceCapabilities) => {
          let servicesList = {
            region: service.regions.id,
            regulatory_compliance:
              service.pre_launch_services.regulatory_compliance,
            importation_clearance:
              service.pre_launch_services.importation_clearance,
            warehousing_storage:
              service.pre_launch_services.warehousing_storage,
            ecom_setup_mgmt: service.post_launch_services.ecom_setup_mgmt,
            retail_distribution:
              service.post_launch_services.retail_distribution,
            order_fulfilment: service.post_launch_services.order_fulfilment,
            marketing: service.post_launch_services.marketing,
          };
          setServices((prevState) => [...prevState, servicesList]);
          return true;
        });
      })();
  }, [params.partnerid]);

  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });

  return (
    <Form onSubmit={onSubmit} className="mt40px servicecpbts">
      <Container fluid className="container-1">
        <Form onSubmit={addToList} className="form">
          <Row className="b-gray200 row-input">
            <Col sm={3} md={3} lg={3} className="br-gray200 col-market">
              <Form.Group
                className="formgroup"
                controlId="formSocialMediaName"
                defaultValue={selectedMarket}
                onChange={(e: any) => {
                  setSelectedMarket(e.target.value);
                }}
              >
                <Form.Label>Select Market *</Form.Label>
                <Form.Select>
                  {props.countries &&
                    props.countries.map((country) => {
                      return (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={8} md={8} lg={8} className="br-gray200 col-services">
              <Row>
                <Col sm={6} lg={4} xl={3}>
                  <Form.Check
                    type="checkbox"
                    id="regulatorycompliance"
                    className="font14-regular gray-700"
                    label="Regulatory Compliance"
                    checked={regulatoryCompliance}
                    onChange={(e) => {
                      setRegulatoryCompliance(!regulatoryCompliance);
                    }}
                  />
                </Col>
                <Col sm={6} lg={4} xl={3}>
                  <Form.Check
                    type="checkbox"
                    id="importClearance"
                    className="font14-regular gray-700"
                    label="Importation And Clearance"
                    checked={importClearance}
                    onChange={(e) => {
                      setImportClearance(!importClearance);
                    }}
                  />
                </Col>
                <Col sm={6} lg={4} xl={3}>
                  <Form.Check
                    type="checkbox"
                    id="warehousingandstorage"
                    className="font14-regular gray-700"
                    label="Warehousing And Storage"
                    checked={warehousingAndStorage}
                    onChange={(e) => {
                      setWarehousingAndStorage(!warehousingAndStorage);
                    }}
                  />
                </Col>
                {/* <Col></Col> */}
                {/* </Row> */}
                {/* <Row> */}
                <Col sm={6} lg={4} xl={3}>
                  <Form.Check
                    type="checkbox"
                    id="ecommerceSetup"
                    className="font14-regular gray-700"
                    label="Ecommerce Setup And Management"
                    checked={ecomSetupMgmt}
                    onChange={(e) => {
                      setEcomSetupMgmt(!ecomSetupMgmt);
                    }}
                  />
                </Col>
                <Col sm={6} lg={4} xl={3}>
                  <Form.Check
                    type="checkbox"
                    id="retaildistribution"
                    className="font14-regular gray-700"
                    label="Retail Distribution"
                    checked={retailDistribution}
                    onChange={(e) => {
                      setRetailDistribution(!retailDistribution);
                    }}
                  />
                </Col>
                <Col sm={6} lg={4} xl={3}>
                  <Form.Check
                    type="checkbox"
                    id="orderFullfilment"
                    className="font14-regular gray-700"
                    label="Order Fulfilment"
                    checked={orderFullfilment}
                    onChange={(e) => {
                      setOrderFullfilment(!orderFullfilment);
                    }}
                  />
                </Col>
                <Col sm={6} lg={4} xl={3}>
                  <Form.Check
                    type="checkbox"
                    id="marketing"
                    className="font14-regular gray-700"
                    label="Marketing"
                    checked={marketing}
                    onChange={(e) => {
                      setMarketing(!marketing);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={1} md={1} lg={1} className="col-action">
              <Button
                variant="primary"
                className="btnblue40"
                onClick={() => {
                  addToList();
                }}
              >
                ADD
              </Button>
            </Col>
          </Row>
          <Form.Text muted>
            <span>
              {servicesError && <p className="error-form">{servicesError}</p>}
            </span>
          </Form.Text>

          {services[0] &&
            services.map((service) => {
              return (
                <>
                  <Row
                    className="b-gray200 mt24px row-output"
                    key={service.region}
                  >
                    <Col sm={3} md={3} lg={3} className="br-gray200 col-market">
                      <Form.Group
                        className="formgroup"
                        controlId="formSocialMediaName"
                      >
                        <Form.Control
                          type="text"
                          disabled
                          placeholder={
                            allCountries?.filter((country: any) => {
                              return (
                                country.id?.toString() ===
                                service.region?.toString()
                              );
                            })[0]?.name || "Singapore"
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      sm={8}
                      md={8}
                      lg={8}
                      className="br-gray200 col-services"
                    >
                      <Row>
                        <Col sm={6} lg={4} xl={3}>
                          <Form.Check
                            type="checkbox"
                            id="regulatorycompliance"
                            className="font14-regular gray-700"
                            label="Regulatory Compliance"
                            disabled={isDisabled}
                            defaultChecked={service.regulatory_compliance}
                            onChange={(e) => {
                              service.regulatory_compliance =
                                !service.regulatory_compliance;
                            }}
                          />
                        </Col>
                        <Col sm={6} lg={4} xl={3}>
                          <Form.Check
                            type="checkbox"
                            id="importClearance"
                            className="font14-regular gray-700"
                            label="Importation And Clearance"
                            disabled={isDisabled}
                            defaultChecked={service.importation_clearance}
                            onChange={(e) => {
                              service.importation_clearance =
                                !service.importation_clearance;
                            }}
                          />
                        </Col>
                        <Col sm={6} lg={4} xl={3}>
                          <Form.Check
                            type="checkbox"
                            id="warehousingandstorage"
                            className="font14-regular gray-700"
                            label="Warehousing And Storage"
                            disabled={isDisabled}
                            defaultChecked={service.warehousing_storage}
                            onChange={(e) => {
                              service.warehousing_storage =
                                !service.warehousing_storage;
                            }}
                          />
                        </Col>
                        {/* <Col></Col> */}
                        {/* </Row> */}
                        {/* <Row> */}
                        <Col sm={6} lg={4} xl={3}>
                          <Form.Check
                            type="checkbox"
                            id="ecommerceSetup"
                            className="font14-regular gray-700"
                            label="Ecommerce Setup And Management"
                            disabled={isDisabled}
                            defaultChecked={service.ecom_setup_mgmt}
                            onChange={(e) => {
                              service.ecom_setup_mgmt =
                                !service.ecom_setup_mgmt;
                            }}
                          />
                        </Col>
                        <Col sm={6} lg={4} xl={3}>
                          <Form.Check
                            type="checkbox"
                            id="retaildistribution"
                            className="font14-regular gray-700"
                            label="Retail Distribution"
                            disabled={isDisabled}
                            defaultChecked={service.retail_distribution}
                            onChange={(e) => {
                              service.retail_distribution =
                                !service.retail_distribution;
                            }}
                          />
                        </Col>
                        <Col sm={6} lg={4} xl={3}>
                          <Form.Check
                            type="checkbox"
                            id="orderFullfilment"
                            className="font14-regular gray-700"
                            label="Order Fulfilment"
                            disabled={isDisabled}
                            defaultChecked={service.order_fulfilment}
                            onChange={(e) => {
                              service.order_fulfilment =
                                !service.order_fulfilment;
                            }}
                          />
                        </Col>
                        <Col sm={6} lg={4} xl={3}>
                          <Form.Check
                            type="checkbox"
                            id="marketing"
                            className="font14-regular gray-700"
                            label="Marketing"
                            disabled={isDisabled}
                            defaultChecked={service.marketing}
                            onChange={(e) => {
                              service.marketing = !service.marketing;
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={1} md={1} lg={1} className="col-action">
                      {isDisabled &&
                        props?.permissions[0]?.sub_modules[2]
                          ?.permissions_actions[2]?.permission[0]
                          ?.permission && (
                          <OverlayTrigger
                            key="editbutton"
                            placement="left"
                            overlay={
                              <Tooltip id="tooltip-top" className="tooltip">
                                Edit Selection
                              </Tooltip>
                            }
                          >
                            <img
                              className="listicon"
                              src="../svgs/pencil.svg"
                              alt="editbutton"
                              onClick={() => {
                                setIsDisabled(false);
                              }}
                            />
                          </OverlayTrigger>
                        )}
                      {!isDisabled && (
                        <OverlayTrigger
                          key="confirmbutton"
                          placement="left"
                          overlay={
                            <Tooltip id="tooltip-top" className="tooltip">
                              Confirm Edit
                            </Tooltip>
                          }
                        >
                          <img
                            className="listicon"
                            src="../svgs/check.svg"
                            alt="confirmbutton"
                            onClick={() => {
                              const editedService = {
                                region: service.region,
                                regulatory_compliance:
                                  service.regulatory_compliance,
                                importation_clearance:
                                  service.importation_clearance,
                                warehousing_storage:
                                  service.warehousing_storage,
                                ecom_setup_mgmt: service.ecom_setup_mgmt,
                                retail_distribution:
                                  service.retail_distribution,
                                order_fulfilment: service.order_fulfilment,
                                marketing: service.marketing,
                              };
                              let newServicesList = services.filter(
                                (selection) =>
                                  selection.region !== service.region
                              );
                              setServices([...newServicesList, editedService]);
                              setIsDisabled(true);
                            }}
                          />
                        </OverlayTrigger>
                      )}
                      {props?.permissions[0]?.sub_modules[2]
                        ?.permissions_actions[3]?.permission[0]?.permission && (
                        <img
                          src="../svgs/trash.svg"
                          alt="trashbutton"
                          className="listicon"
                          onClick={() => {
                            deleteFromList(service.region);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              );
            })}
        </Form>
      </Container>
      <Container fluid className="bt-gray200 mt40px">
        <Row className="justify-content-end mb-3 mt-4">
          <Col className="d-flex justify-content-end">
            <Link to="/PartnerManagement" className="m-1">
              <Button variant="secondary" className="btn-secondary12">
                Cancel
              </Button>
            </Link>
            {props?.permissions[0]?.sub_modules[2]?.permissions_actions[2]
              ?.permission[0]?.permission &&
              params.partnerid && (
                <Button
                  variant="primary"
                  className="btn-primary12 m-1"
                  type="submit"
                >
                  Update
                </Button>
              )}
            {props?.permissions[0]?.sub_modules[2]?.permissions_actions[0]
              ?.permission[0]?.permission &&
              !params.partnerid && (
                <Button
                  variant="primary"
                  className="btn-primary12 m-1"
                  type="submit"
                >
                  Save and continue
                </Button>
              )}
          </Col>
        </Row>
      </Container>
      <ToastMessage
        notification={notification}
        onCancel={handleCloseNotification}
      />
    </Form>
  );
}

export default PartnerServiceCapabilities;
