import "../../styles/pagelayout.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../components/sidebar/Sidebar";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import TableMUI from "../components/TableMUI/TableMUI";
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import Navbar from "../components/header/NavBar";
import ToastMessage from "../components/toasts/ToastMessage";
import BreadCrumb from "../components/header/BreadCrumb";
import Auth from "@aws-amplify/auth";
import { GetUserProfile } from "../../API/endpoints/User";
import { RolesTableColumns } from "../components/TableMUI/TableColumns";
import {
  GetRoles,
  DeleteRole,
  GetPermissionsByRoleAndModuleID,
} from "../../API/endpoints/RolesAndPermissions/Roles";

const RolsList = () => {
  const navigate = useNavigate();
  type dataInput = {
    data?: {
      roleDetails: {
        id: number;
        role_name: string;
        type: string;
      };
      user_count: number;
    }[];
  };

  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [roles, setRoles] = useState<dataInput>({});
  const [showDeleteRole, setShowDeleteRole] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [flag, setFlag] = useState(false);
  const [notification, setNotification] = useState({ type: "", msg: "" });

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const response = await GetPermissionsByRoleAndModuleID(
        roleID.toString(),
        "7"
      );
      console.log(response);
      setPermissions(response?.data);
    })();
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "2") {
      navigate("/OrganizationManagement");
    } else if (permissions[0]?.permission_type.toString() === "3") {
      navigate("/PartnerManagement");
    }
  }, [permissions, navigate]);

  useEffect(() => {
    (async () => {
      try {
        const response = await GetRoles();
        setRoles(response);
      } catch (error) {}
    })();
    setFlag(false);
  }, [flag]);

  let rows: {
    id: number;
    role_name: string;
    type: string;
    user_count: string;
  }[] = [];

  if (
    roles?.data &&
    permissions[0]?.sub_modules[3]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    rows = roles?.data.map((dataItem) => {
      return {
        id: dataItem?.roleDetails?.id,
        role_name: dataItem?.roleDetails?.role_name,
        type: dataItem?.roleDetails?.type,
        user_count: `${dataItem?.user_count} users`,
      };
    });
  }

  const columns = RolesTableColumns(
    navigate,
    setRoleId,
    setShowDeleteRole,
    permissions
  );

  const deleteFunction = async () => {
    try {
      const response = await DeleteRole(roleId);
      setFlag(true);
      if (response.msg === "Role deleted successfully.") {
        setNotification({ type: "success", msg: response.msg });
        setRoleId("");
      }
    } catch (error) {
      setNotification({ type: "error", msg: "Delete Failed" });
    }
  };

  const handleCloseDeleteRole = () => setShowDeleteRole(false);

  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });

  const breadcrumbData = [
    {
      name: "settings",
      url: "/SettingsList",
      active: false,
    },
    {
      name: "Roles & permissions",
      url: "/RolesList",
      active: true,
    },
  ];
  const routeToForm = () => {
    navigate("/AddRole");
  };

  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <BreadCrumb breadcrumbData={breadcrumbData} />
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
              />
            </InputGroup>
            {permissions[0]?.sub_modules[3]?.permissions_actions[0]
              ?.permission[0]?.permission === true && (
              <Button className="btn-primary12 addbtn" onClick={routeToForm}>
                Add New Role
              </Button>
            )}
          </div>
          <div className="table-mui">
            <TableMUI columns={columns} rows={rows} />
            <PopupTemplate
              popupHeader="Confirm Deletion."
              popupBody={`Are you sure you want to delete Role`}
              handleFunction={deleteFunction}
              isModalOpen={showDeleteRole}
              onCancel={handleCloseDeleteRole}
              id={roleId}
            />
          </div>
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default RolsList;
