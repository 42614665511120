import "../../styles/pagelayout.scss";
import "./FileAccess.scss";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Auth from "@aws-amplify/auth";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Sidebar from "../components/sidebar/Sidebar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Navbar from "../components/header/NavBar";
import ToastMessage from "../components/toasts/ToastMessage";
import { GetUserProfile } from "../../API/endpoints/User";
import {
  CreateRole,
  GetRoleById,
  UpdateRole,
} from "../../API/endpoints/RolesAndPermissions/Roles";
import { roleDataInputDTO } from "../dtos/roles.dtos";

const AddRole = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [userType, setUserType] = useState<string | undefined>("");
  const [userRole, setUserRole] = useState<string | undefined>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [roleType, setRoleType] = useState("administrative");
  const [restriction, setRestriction] = useState(false);
  const [roleData, setRoleData] = useState<roleDataInputDTO>();
  const [notification, setNotification] = useState({ type: "", msg: "" });
  const [submitting, setSubmitting] = useState(false);

  const { register, formState, handleSubmit, reset } = useForm({
    defaultValues: roleData,
  });
  const { errors } = formState;

  useEffect(() => {
    setUserType(Cookies.get("UserType"));
    setUserRole(Cookies.get("UserRole"));
    setUserID(Cookies.get("userID"));
  }, []);
  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
    })();

    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });
    if (userType === "administrative" && userRole === "super_admin") {
      console.log(userType);
    } else if (userType === "partner") {
      navigate("/PartnerManagement");
    } else if (userType === "organization") {
      navigate("/OrganizationManagement");
    }
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userType, userRole, navigate, userID]);
  useEffect(() => {
    if (params?.roleid) {
      (async () => {
        try {
          const response = await GetRoleById(params?.roleid || "");
          setRoleData(response.data);
        } catch (error) {}
      })();
    }
  }, [params]);
  useEffect(() => {
    if (roleData) {
      const defaults = {
        role_name: roleData.role_name,
        role_description: roleData.role_description,
      };
      reset(defaults);
      setRoleType(roleData.type);
      setRestriction(roleData.data_restriction);
    }
  }, [reset, roleData]);
  const goBack = () => {
    navigate("/RolesList");
  };
  const onHandleSubmit = async (data: any) => {
    const formData = {
      role_name: data.role_name,
      type: roleType,
      role_description: data.role_description,
      data_restriction: restriction,
    };
    if (params.roleid) {
      try {
        setSubmitting(true);
        const res = await UpdateRole(formData, params.roleid);
        if (res?.msg === "Role updated successfully.") {
          setNotification({ type: "success", msg: res.msg });
          setSubmitting(false);
          if (formData.type === "administrative") {
            navigate(`/EditPermission/1&&${params.roleid}&&true`);
          } else if (formData.type === "organization") {
            navigate(`/EditPermission/2&&${params.roleid}&&true`);
          } else {
            navigate(`/EditPermission/3&&${params.roleid}&&true`);
          }
        }
      } catch (error) {
        setNotification({ type: "error", msg: "Update Failed" });
        setSubmitting(false);
      }
    } else {
      try {
        setSubmitting(true);
        const response = await CreateRole(formData);
        if (response.msg === "Role added successfully.") {
          setNotification({ type: "success", msg: response.msg });
          setSubmitting(false);
          if (response.data.type === "administrative") {
            navigate(`/AddPermission/1&&${response.data.id}`);
          } else if (response.data.type === "organization") {
            navigate(`/AddPermission/2&&${response.data.id}`);
          } else {
            navigate(`/AddPermission/3&&${response.data.id}`);
          }
        }
      } catch (error) {
        setNotification({ type: "error", msg: "Create Role Failed" });
        setSubmitting(false);
      }
    }
  };

  const handleCloseNotification = () =>
    setNotification({
      type: "",
      msg: "",
    });

  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="settings" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <div className="add-role">
            <div className="role-cntr">
              <Breadcrumb className="font12-medium breadcrumbnav">
                <Breadcrumb.Item href="/SettingsList">Settings</Breadcrumb.Item>
                <Breadcrumb.Item href="/RolesList">
                  Roles & permissions
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  href={
                    params.roleid ? `/EditRole/${params.roleid}` : "/AddRole"
                  }
                  active
                >
                  {params.roleid ? "Edit Role" : "Add New"}
                </Breadcrumb.Item>
              </Breadcrumb>
              <Form
                className="form"
                onSubmit={handleSubmit((data) => onHandleSubmit(data))}
              >
                <Container fluid>
                  <Row className="header-row">
                    <Col className="header-col">
                      <div className="form-header">
                        <Button className="backarrow-btn" onClick={goBack}>
                          <img src="../svgs/arrows-left.svg" alt="arrow" />
                        </Button>
                        <span className="font18-medium gray-800">
                          {params.roleid ? "Edit Role" : "Add New Role"}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="roltype-row">
                    <Col className="roltype-col">
                      <label className="main-label mb12px">
                        Select role type:
                      </label>
                      <div className="radio-group">
                        <Form.Check
                          type="radio"
                          id="aeg"
                          label="AEG"
                          disabled={params.roleid ? true : false}
                          checked={roleType === "administrative" ? true : false}
                          onChange={() => {
                            setRoleType("administrative");
                          }}
                        />
                        <Form.Check
                          type="radio"
                          id="brand"
                          label="Brand Organization"
                          disabled={params.roleid ? true : false}
                          checked={roleType === "organization" ? true : false}
                          onChange={() => {
                            setRoleType("organization");
                          }}
                        />
                        <Form.Check
                          type="radio"
                          id="partner"
                          label="Partner"
                          disabled={params.roleid ? true : false}
                          checked={roleType === "partner" ? true : false}
                          onChange={() => {
                            setRoleType("partner");
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="rolename-row">
                    <Col className="rolename-col">
                      <Form.Group className="formgroup" controlId="rolename">
                        <Form.Label>Role Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter role name"
                          {...register("role_name", {
                            required: "* This field is required",
                          })}
                        />
                        <Form.Text muted>
                          <span>
                            {errors?.role_name && (
                              <p className="error-form">
                                {String(errors.role_name?.message)}
                              </p>
                            )}
                          </span>
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="roledesc-row">
                    <Col className="roledesc-col">
                      <Form.Group className="formgroup" controlId="roledesc">
                        <Form.Label>Role Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter role description"
                          {...register("role_description", {
                            required: "",
                          })}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {roleType === "administrative" && (
                    <Row className="data-res-row">
                      <Col className="data-res-col">
                        <label className="main-label mb12px">
                          Enable data access restrictions for this role?
                        </label>
                        <div className="radio-group">
                          <Form.Check
                            type="radio"
                            id="yes"
                            label="Yes"
                            checked={restriction === true ? true : false}
                            onChange={() => {
                              setRestriction(true);
                            }}
                          />
                          <Form.Check
                            type="radio"
                            id="no"
                            label="No"
                            checked={restriction === false ? true : false}
                            onChange={() => {
                              setRestriction(false);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className="nextbtn-row">
                    <Col className="nextbtn-col">
                      <Button
                        className="btn-primary12"
                        type="submit"
                        disabled={submitting === true ? true : false}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>
          </div>
          <ToastMessage
            notification={notification}
            onCancel={handleCloseNotification}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AddRole;
