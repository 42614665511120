import "./BrandProfile.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";
import { useNavigate, useParams } from "react-router-dom";
import { GetUserProfile } from "../../../API/endpoints/User";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/header/NavBar";
import BreadCrumb from "../../components/header/BreadCrumb";
import { GetBrand, GetBrandPreferences } from "../../../API/endpoints/Brands";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../../API/endpoints/RolesAndPermissions/Roles";
// import { GetDocumentSettings } from "../../../API/endpoints/document";

const Brand = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [brandName, setBrandName] = useState<string | undefined>("");
  const [data, setData] = useState<any>();
  const [marketPreference, setMarketPreference] = useState<any>();
  const [engagementModels, setEngagementModels] = useState<any>([]);
  // const [docTotal, setDocTotal] = useState(0)
  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    (async () => {
      const roleDetails = await GetRoleById(roleID?.toString());

      if (roleDetails?.data?.type === "organization") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "8"
        );
        console.log(response);
        setPermissions(response?.data);
      } else if (roleDetails?.data?.type === "administrative") {
        const response = await GetPermissionsByRoleAndModuleID(
          roleID.toString(),
          "2"
        );
        console.log(response);
        setPermissions(response?.data);
      }
    })();
  }, [roleID]);

  useEffect(() => {
    const GetBrandData = async () => {
      const response = await GetBrand(params.id);
      if (response?.data) {
        setBrandName(response.data.brand_name);
        setData(response.data);
      }
    };
    const GetPreference = async () => {
      const response = await GetBrandPreferences(params.id || "");
      if (response?.data?.market_preference) {
        setMarketPreference(response.data.market_preference);
      }
      if (
        response?.data?.brand_preference?.engagement_models_list.length !== 0
      ) {
        setEngagementModels(
          response.data.brand_preference.engagement_models_list
        );
      }
    };
    // const GetDocumentsCount = async () => {
    //     const response = await GetDocumentSettings("1", "")
    //     if (response?.data?.count) {
    //         setDocTotal(response.data.count)
    //     }
    // }
    GetBrandData();
    GetPreference();
    // GetDocumentsCount();
  }, [params.id]);
  const breadcrumbData = [
    {
      name: "Brand Management",
      url: "/BrandManagement",
      active: false,
    },
    {
      name: "Brands",
      url: "/BrandManagement",
      active: false,
    },
    {
      name: brandName || "",
      url: `/brand/${params.id}`,
      active: true,
    },
  ];
  return (
    <Container fluid className="pge-layout">
      <Row>
        <Col className="sidebar">
          <Sidebar active="brand" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <BreadCrumb breadcrumbData={breadcrumbData} />
          <div className="cover-img-container">
            <div className="cover-img"></div>
          </div>
          <Container fluid className="profile-container">
            <Row className="row-1">
              <Col md={3} lg={3} className="col-profile">
                <img
                  className="img-fluid img-thumbnail profile-pic"
                  src="../images/noimage.jpg"
                  alt="profilepic"
                />
                <div className="name-status">
                  <span className="font16-medium gray-800 mr8px">
                    {data?.brand_name}
                  </span>
                  <span
                    className={
                      data?.status === "Activated"
                        ? "success-status font12-medium"
                        : "blue-status font12-medium"
                    }
                  >
                    {data?.status}
                  </span>
                </div>
                <div className="font12-regular gray-500 mt4px text-capitalize">
                  {data?.brand_organization?.company_name}
                </div>
                {data?.website && (
                  <div className="mt8px">
                    <img
                      className="mr6px"
                      width="16px"
                      height="16px"
                      src="../svgs/globe.svg"
                      alt="globe"
                    />
                    <a
                      className="font12-regular"
                      href={`https://${data.website}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {data.website}
                    </a>
                  </div>
                )}
                <div className="focus-cat mt16px">
                  {data?.brand_categories.map((item: any) => {
                    return (
                      <div key={item.id} className="cat">
                        <span className="font12-regular gray-600">
                          {item.categories.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </Col>
              <Col md={5} lg={5} className="col-digital">
                <div className="font12-regular gray-800 mb6px">
                  Brand Social Profiles
                </div>
                {data?.social_media.length === 0 && (
                  <div className="media-presence font12-regular gray-700">
                    Not Available
                  </div>
                )}
                {data?.social_media.length !== 0 && (
                  <div className="media-presence font12-regular gray-700">
                    {data?.social_media.map((item: any) => {
                      if (item.platform === "Facebook") {
                        return (
                          <div
                            className="item"
                            key={item.id}
                            onClick={() => {
                              window.open(`${item.url}`, "_blank");
                            }}
                          >
                            <img
                              className="mr6px"
                              src="../svgs/fb-color.svg"
                              alt="fb"
                            />
                            <span>{item.number_of_followers} followers</span>
                          </div>
                        );
                      } else if (item.platform === "LinkedIn") {
                        return (
                          <div
                            className="item"
                            key={item.id}
                            onClick={() => {
                              window.open(`${item.url}`, "_blank");
                            }}
                          >
                            <img
                              className="mr6px"
                              src="../svgs/linkedin-color.svg"
                              alt="linkedin"
                            />
                            <span>{item.number_of_followers} followers</span>
                          </div>
                        );
                      } else if (item.platform === "Twitter") {
                        return (
                          <div
                            className="item"
                            key={item.id}
                            onClick={() => {
                              window.open(`${item.url}`, "_blank");
                            }}
                          >
                            <img
                              className="mr6px"
                              src="../svgs/twitter-color.svg"
                              alt="twitter"
                            />
                            <span>{item.number_of_followers} followers</span>
                          </div>
                        );
                      } else if (item.platform === "Instagram") {
                        return (
                          <div
                            className="item"
                            key={item.id}
                            onClick={() => {
                              window.open(`${item.url}`, "_blank");
                            }}
                          >
                            <img
                              className="mr6px"
                              src="../svgs/insta-color.svg"
                              alt="instagram"
                            />
                            <span>{item.number_of_followers} followers</span>
                          </div>
                        );
                      } else {
                        return "";
                      }
                    })}
                  </div>
                )}
                <div className="font12-regular gray-800 mb6px">
                  Marketplace Ratings
                </div>
                {data?.market_place.length === 0 && (
                  <div className="media-presence font12-regular gray-700">
                    Not Available
                  </div>
                )}
                {data?.market_place.length !== 0 && (
                  <div className="media-presence font12-regular gray-700">
                    {data?.market_place.map((item: any, index: any) => {
                      if (item.platform === "Amazon") {
                        return (
                          <div
                            className="item"
                            key={index}
                            onClick={() => {
                              window.open(`${item.url}`, "_blank");
                            }}
                          >
                            <img
                              className="mr6px"
                              src="../svgs/amazon-color.svg"
                              alt="amazon"
                            />
                            <span>{item.store_rating}/5</span>
                          </div>
                        );
                      } else if (item.platform === "Flipkart") {
                        return (
                          <div
                            className="item"
                            key={index}
                            onClick={() => {
                              window.open(`${item.url}`, "_blank");
                            }}
                          >
                            <img
                              className="mr6px"
                              src="../svgs/flipkart-color.svg"
                              alt="flipkart"
                            />
                            <span>{item.store_rating}/5</span>
                          </div>
                        );
                      } else if (item.platform === "Alibaba") {
                        return (
                          <div
                            className="item"
                            key={index}
                            onClick={() => {
                              window.open(`${item.url}`, "_blank");
                            }}
                          >
                            <img
                              className="mr6px"
                              src="../svgs/alibaba-color.svg"
                              alt="alibaba"
                            />
                            <span>{item.store_rating}/5</span>
                          </div>
                        );
                      } else if (item.platform === "Nykaa") {
                        return (
                          <div
                            className="item"
                            key={index}
                            onClick={() => {
                              window.open(`${item.url}`, "_blank");
                            }}
                          >
                            <img
                              className="mr6px"
                              src="../svgs/Nyka.svg"
                              alt="nykaa"
                            />
                            <span>{item.store_rating}/5</span>
                          </div>
                        );
                      } else if (item.platform === "1mg") {
                        return (
                          <div
                            className="item"
                            key={index}
                            onClick={() => {
                              window.open(`${item.url}`, "_blank");
                            }}
                          >
                            <img
                              className="mr6px"
                              src="../svgs/Tata1mg.svg"
                              alt="alibaba"
                            />
                            <span>{item.store_rating}/5</span>
                          </div>
                        );
                      } else if (item.platform === "Shoppee") {
                        return (
                          <div
                            className="item"
                            key={index}
                            onClick={() => {
                              window.open(`${item.url}`, "_blank");
                            }}
                          >
                            <img
                              className="mr6px"
                              src="../svgs/shopee-color.svg"
                              alt="shopee"
                            />
                            <span>{item.store_rating}/5</span>
                          </div>
                        );
                      } else {
                        return "";
                      }
                    })}
                  </div>
                )}
              </Col>
              <Col md={4} lg={4} className="col-basicinfo">
                <table>
                  <tbody>
                    <tr>
                      <td
                        className="font12-regular gray-500"
                        style={{ verticalAlign: "top" }}
                      >
                        Preferred Inventory Model
                      </td>
                      <td
                        style={{ verticalAlign: "top" }}
                        className="font12-regular gray-500 cln"
                      >
                        :
                      </td>
                      <td className="font12-medium gray-800">
                        {engagementModels.length !== 0
                          ? engagementModels.map((itm: any, index: any) => {
                              return (
                                <div key={itm.id}>
                                  {itm.display_name}
                                  <span>
                                    {index !== engagementModels.length - 1
                                      ? ","
                                      : ""}
                                  </span>
                                </div>
                              );
                            })
                          : "Empty"}
                      </td>
                    </tr>
                    <tr>
                      <td className="font12-regular gray-500">
                        Fund Requirement
                      </td>
                      <td className="font12-regular gray-500 cln">:</td>
                      <td className="font12-medium gray-800">
                        {data?.brand_preference?.fund_required ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td className="font12-regular gray-500">
                        Current Revenue
                      </td>
                      <td className="font12-regular gray-500 cln">:</td>
                      <td className="font12-medium gray-800">
                        {data?.current_revenue
                          ? `$${data?.current_revenue}`
                          : "Empty"}
                      </td>
                    </tr>
                    <tr>
                      <td className="font12-regular gray-500">
                        Years of Operation
                      </td>
                      <td className="font12-regular gray-500 cln">:</td>
                      <td className="font12-medium gray-800">
                        {data?.years_of_operations
                          ? data?.years_of_operations.name
                          : "Empty"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {permissions[0]?.sub_modules[5]?.permissions_actions[1]
                  ?.permission[0]?.permission === true && (
                  <div>
                    <a
                      className="font12-regular"
                      href={`/brand-filesettings/${params.id}`}
                      rel="noreferrer"
                    >
                      <span>Files </span>
                      <img src="../svgs/redirect.svg" alt="redirect" />
                    </a>
                  </div>
                )}
              </Col>
              <Col className="col-market-prefer" xs={12}>
                <div className="font14-medium gray-800 mb12px">
                  Market Preferences
                </div>
                <div className="pref-container">
                  {marketPreference?.length !== 0 &&
                    marketPreference?.map((item: any) => {
                      return (
                        <div key={item.id} className="pref-card">
                          <div className="country">
                            {item.regions.country_img && (
                              <img
                                width={22}
                                height={16}
                                src={item.regions.country_img}
                                alt="flag"
                                className="mr12px"
                              />
                            )}
                            <span className="font14-regular gray-800">
                              {item.regions.name}
                            </span>
                          </div>
                          <div className="preference font12-regular gray-600">
                            {item.pre_launch_services.regulatory_compliance && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/regulatory.svg"
                                  alt="regulatory"
                                />
                                <span>Regulatory Compliance</span>
                              </div>
                            )}
                            {item.pre_launch_services.importation_clearance && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/importation.svg"
                                  alt="importation"
                                />
                                <span>Importation & Clearance</span>
                              </div>
                            )}
                            {item.post_launch_services.ecom_setup_mgmt && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/ecomm-management.svg"
                                  alt="ecomm"
                                />
                                <span>Ecommerce Setup & Management</span>
                              </div>
                            )}
                            {item.post_launch_services.retail_distribution && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/retail-distribution.svg"
                                  alt="retail"
                                />
                                <span>Retail Distribution</span>
                              </div>
                            )}
                            {item.pre_launch_services.warehousing_storage && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/warehouse.svg"
                                  alt="warehouse"
                                />
                                <span>Warehousing & Storage</span>
                              </div>
                            )}
                            {item.post_launch_services.order_fulfilment && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/order-fulfillment.svg"
                                  alt="order-ful"
                                />
                                <span>Order Fulfillment</span>
                              </div>
                            )}
                            {item.post_launch_services.marketing && (
                              <div className="item">
                                <img
                                  className="mr8px"
                                  src="../svgs/marketing.svg"
                                  alt="marketing"
                                />
                                <span>Marketing</span>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Brand;
