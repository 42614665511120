import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
// @ts-ignore
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { adminInputDTO } from "../../dtos/admin.dtos";

export interface region {
    id: string;
    country__alpha_code: string;
    country_code: string;
}
export interface department {
    id: number;
    department_display_name: string;
    department_name: string;
    is_active: boolean;
    type: string;
}

export interface role {
    id: number;
    is_active: boolean;
    role_display: string;
    role_name: string;
    type: string;
    data_restriction: boolean;
    is_deleted: boolean;
}
export interface addAdminFormprops {
    regionList: region[];
    departmentsList: department[];
    rolesList: role[];
    onSubmit: (data: adminInputDTO) => void;
    defaultData: adminInputDTO;
    submitting: boolean;
    isEdit: boolean;
    roleChange: (roleId: any) => void;
    setShowRolePermissions: (val: any) => void;
    roleData: any;
    error: any;
    resetErrors: (field: string, msg: string) => void;
}

const AddAdminForm: React.FunctionComponent<addAdminFormprops> = ({
    regionList,
    departmentsList,
    rolesList,
    onSubmit,
    defaultData,
    submitting,
    isEdit,
    roleChange,
    setShowRolePermissions,
    roleData,
    error,
    resetErrors
}) => {
    const { register, formState, handleSubmit, reset, setError } = useForm({
        defaultValues: defaultData
    });
    const { errors } = formState;
    useEffect(() => {
        if (defaultData) {
            const defaults = {
                first_name: defaultData?.first_name,
                last_name: defaultData?.last_name,
                email_id: defaultData?.email_id,
                contact_number: defaultData?.contact_number,
                contact_number_code: defaultData?.contact_number_code,
                department: defaultData?.department,
                designation: defaultData?.designation,
                role: defaultData?.role,
            };
            reset(defaults);
        }
    }, [reset, defaultData]);

    const handleRoleChange = (roleid: any) => {
        roleChange(roleid);
    }
    const showRolePermission = () => {
        setShowRolePermissions(true);
    }

    const onHandleSubmit = (data: any) => {
        resetErrors("","");
        onSubmit(
            {
                ...data,
                role: [parseInt(data.role)],
                region: [1],
                user_type: "administrative",
                owner: false
            });
    };

    useEffect(() => {
        if(error.field) {
            setError(error.field, { type: "custom", message: error.msg });
        }
    },[error, setError])

    return (
        <Form
            onSubmit={handleSubmit((data) => onHandleSubmit(data))}
        >
            <Container fluid>
                <Row className="pt24px">
                    <Col className="pl20px pr8px">
                        <Form.Group className="formgroup" controlId="first_name">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter first name"
                                {...register("first_name", {
                                    required: "* This field is required",
                                })}
                            />
                            <Form.Text muted>
                                <span>
                                    {errors?.first_name && (
                                        <p className="error-form">
                                            {String(errors.first_name?.message)}
                                        </p>
                                    )}
                                </span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col className="pl8px pr20px">
                        <Form.Group className="formgroup" controlId="lastname">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter last name"
                                {...register("last_name", {
                                    required: "* This field is required",
                                })}
                            />
                            <Form.Text muted>
                                <span>
                                    {errors?.last_name && (
                                        <p className="error-form">
                                            {String(errors.last_name?.message)}
                                        </p>
                                    )}
                                </span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt24px">
                    <Col className="pl20px pr8px">
                        <Form.Group className="formgroup" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                {...register("email_id", { required: "* This field is required" })}
                            />
                            <Form.Text muted>
                                <span>
                                    {errors?.email_id && (
                                        <p className="error-form">
                                            {String(errors.email_id?.message)}
                                        </p>
                                    )}
                                </span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col className="pl8px pr20px">
                        <Form.Group className="formgroup" controlId="contact_number">
                            <Form.Label>Phone</Form.Label>
                            <InputGroup>
                                <Form.Select
                                    style={{ maxWidth: "100px" }}
                                    aria-label="20"
                                    // onChange={(e) => countrycode(e.target.value)}
                                    {...register("contact_number_code", {
                                        required: "* This field is required",
                                    })}
                                >
                                    <option value="">Select</option>
                                    {regionList?.length &&
                                        regionList?.map((item: any) => {
                                            return (
                                                <option key={item.id} value={item.country_code}>
                                                    {item.country_code}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Phone number"
                                    {...register("contact_number", {
                                        required: "* This field is required",
                                    })}
                                />
                            </InputGroup>
                            <Form.Text muted>
                                <span>
                                    {errors?.contact_number && (
                                        <p className="error-form">
                                            {String(errors.contact_number?.message)}
                                        </p>
                                    )}
                                </span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt24px">
                    <Col className="pl20px pr8px">
                        <Form.Group className="formgroup" controlId="department">
                            <Form.Label>Department</Form.Label>
                            <Form.Select
                                aria-label="20"
                                // onChange={(e) => handleDepartment(e.target.value)}
                                {...register("department", {
                                    required: "* This field is required",
                                })}
                                name="department"
                            >
                                <option value="">Select Department</option>
                                {departmentsList?.length &&
                                    departmentsList?.map((item: any) => {
                                        return (
                                            <option key={item.id} value={item.id}>
                                                {item.department_display_name}
                                            </option>
                                        );
                                    })}
                            </Form.Select>
                            <Form.Text muted>
                                <span>
                                    {errors?.department && (
                                        <p className="error-form">
                                            {String(errors.department?.message)}
                                        </p>
                                    )}
                                </span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col className="pl8px pr20px">
                        <Form.Group className="formgroup" controlId="designation">
                            <Form.Label>Designation</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Designation"
                                {...register("designation", {
                                    required: "* This field is required",
                                })}
                            />
                            <Form.Text muted>
                                <span>
                                    {errors?.designation && (
                                        <p className="error-form">
                                            {String(errors.designation?.message)}
                                        </p>
                                    )}
                                </span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt24px">
                    <Col className="plr20px">
                        <Form.Group className="formgroup" controlId="role">
                            <Form.Label>Role</Form.Label>
                            <Form.Select
                                aria-label="20"
                                {...register("role", {
                                    required: "* This field is required",
                                })}
                                onChange={(e) => {
                                    handleRoleChange(e.target.value)
                                }}
                            >
                                <option value="">Select Role</option>
                                {rolesList?.length &&
                                    rolesList?.map((item: any) => {
                                        return (
                                            <option key={item.id} value={item.id}>
                                                {item.role_name}
                                            </option>
                                        );
                                    })}
                            </Form.Select>
                            <Form.Text muted>
                                <span>
                                    {errors?.role && (
                                        <p className="error-form">{String(errors.role?.message)}</p>
                                    )}
                                </span>
                            </Form.Text>
                            {Object.keys(roleData).length !== 0 &&
                                <Button
                                    className="link-btn 
                                    font13-regular d-flex 
                                    align-items-center
                                    mt8px"
                                    onClick={showRolePermission}
                                >
                                    <img
                                        src="../svgs/Info-gray600.svg"
                                        alt="Info-gray600"
                                        width={16}
                                        height={16}
                                        className="mr6px"
                                    />
                                    <span>Click to see permissions for this role</span>
                                </Button>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt40px">
                    <Col className="d-flex justify-content-end plr20px">
                        <Button
                            disabled={submitting ? true : false}
                            variant="primary"
                            type="submit"
                            className="btn-primary12 mb40px"
                        >
                            {isEdit ? "Update User" : "Add User"}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
};

export default AddAdminForm;
