import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// Stylesheets
import "./PartnerList.scss";
import "./PartnerTeam.scss";

// React Bootstrap components
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";

// Components
import AddPartnerTeam from "../components/modalForms/addPartnerTeam/addPartnerTeam";
import TableMUI from "../components/TableMUI/TableMUI";
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import { partnerUserTableColumns } from "../components/TableMUI/TableColumns";
import {
  DeletePartnerUser,
  GetPartner,
  GetPartnerUsers,
} from "../../API/endpoints/Partner";
import { GetCountryCodes } from "../../API/endpoints/Common";
import { CreateActivity } from "../../API/endpoints/Dashboard";
import SuccessModal from "../components/Modal/SuccessModal";

function PartnerTeam(props: {
  partnerID: string | undefined;
  setDefaultTab: (arg0: string) => void;
  handleNotification: (type: string, msg: string) => void;
  permissions: any;
  isSuperAdmin: boolean;
}) {
  const params = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ rows: [] });
  const [flag, setFlag] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isBrandEmployee, setIsBrandEmployee] = useState(false);
  const [next, setNext] = useState(false);
  const [user, setUser] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [ID, setID] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [countryCodes, setCountryCodes] = useState<any[]>([]);
  const [email, setEmail] = useState<string | undefined>("");
  const [partnerIsActive, setPartnerIsActive] = useState<boolean>(false);
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const partnerID = params?.partnerid ? params.partnerid : props.partnerID;

  const routeToForm = () => {
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const handleCloseDeletePopup = () => setShowDelete(false);

  const handleDelete = async (userID: string) => {
    setFlag(false);
    await DeletePartnerUser(userID);
    handleCloseDeletePopup();
    setFlag(true);
  };

  const handlePartnerInvite = async () => {
    // InvitePartner(partnerID);
    try {
      const partnerData = await GetPartner(partnerID);
      const partnerName = partnerData?.data?.company_name;
      const activity = await CreateActivity(
        `Admin invited partner: ${partnerName}.`
      );
      console.log(activity);
    } catch (ex: any) {
      console.log(ex);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await GetPartnerUsers(partnerID);
      const partnerData = await GetPartner(partnerID);
      setData(res.data);
      setPartnerIsActive(partnerData?.data?.is_active);
      console.log(partnerData?.data?.is_active);
    })();
  }, [flag, partnerID]);

  useEffect(() => {
    (async () => {
      setCountryCodes([]);
      const res = await GetCountryCodes();
      console.log(res);
      setCountryCodes(res.data);
    })();
  }, []);

  useEffect(() => {
    if (props?.isSuperAdmin) {
      setIsSuperAdmin(true);
    } else {
      setIsBrandEmployee(true);
    }
  }, [props]);

  let rows: any = [];

  if (data) {
    console.log(data);
    rows = data.rows.map(
      (dataItem: {
        id: any;
        first_name: string;
        last_name?: string;
        designation?: string;
        user_roles: { roles: { role_display: any; role_name: string } }[];
        email_id: any;
        contact_number?: any;
        contact_number_code?: string;
        is_active?: boolean;
      }) => ({
        id: dataItem.id,
        name: dataItem.first_name + " " + dataItem?.last_name,
        designation: dataItem?.designation || "",
        role: dataItem?.user_roles[0]?.roles?.role_display
          ? dataItem?.user_roles[0]?.roles?.role_display
          : dataItem?.user_roles[0]?.roles?.role_name,
        email: dataItem?.email_id,
        phone: dataItem?.contact_number || "",
        code: dataItem?.contact_number_code || "",
        is_active: dataItem?.is_active,
      })
    );
  }

  const columns = partnerUserTableColumns(
    setID,
    setShowDelete,
    handlePartnerInvite,
    setShow,
    setUser,
    setPartnerName,
    setEmail,
    setLinkSent,
    props?.permissions
  );

  return (
    <div>
      <div className="tabHeader">
        <div className="column1">
          {(!data?.rows[0] || partnerIsActive) &&
            props?.permissions[0]?.sub_modules[1]?.permissions_actions[0]
              ?.permission[0]?.permission && (
              <div className="adduserBtn">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={routeToForm}
                  className="btn-primary12"
                >
                  Add Team Member
                </Button>
              </div>
            )}
          <AddPartnerTeam
            isModalOpen={show}
            onCancel={handleClose}
            partnerID={partnerID}
            setFlag={setFlag}
            isSuperAdmin={isSuperAdmin}
            isBrandEmployee={isBrandEmployee}
            countryCodes={countryCodes}
            setNext={setNext}
            user={user}
            handleNotification={props.handleNotification}
          />
          <PopupTemplate
            popupHeader="Confirm Deletion."
            popupBody={`Are you sure you want to delete partner ${partnerName}?`}
            handleFunction={handleDelete}
            isModalOpen={showDelete}
            onCancel={handleCloseDeletePopup}
            id={ID}
            setFlag={setFlag}
          />
        </div>
      </div>
      <div className="TableMUIcountainer">
        <TableMUI columns={columns} rows={rows} />
        <SuccessModal
          isModalOpen={linkSent}
          handleCloseModal={() => {
            setEmail("");
            setLinkSent(false);
          }}
          email={email}
          isAdmin={true}
          btn={true}
          link=""
          linkText=""
          title="Code Sent"
          bodyText={`A code to reset your password has been sent to
          ${email?.replace(/(\w{1})(.*)(\w{1})@(.*)/, "$1******$3@$4")}`}
        />
      </div>
      {next && !params.partnerid && (
        <Container fluid className="formButtonsContainer">
          <Link to="/PartnerManagement">
            <Button variant="secondary" className="formButton1">
              Cancel
            </Button>
          </Link>
          <Button
            variant="primary"
            className="formButton2"
            onClick={() => {
              if (
                props?.permissions[0]?.sub_modules[2]?.permissions_actions[1]
                  ?.permission[0]?.permission
              ) {
                props.setDefaultTab("partner-service-capabilities");
              } else {
                navigate("/PartnerManagement");
              }
            }}
          >
            Save and continue
          </Button>
        </Container>
      )}
    </div>
  );
}

export default PartnerTeam;
