import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import AddCountryForm from "../../forms/platformsettings/AddCountryForm";

export interface addCountryProps {
  isModalOpen: boolean;
  onCancel: () => void;
  submitting: boolean;
  onSubmit: (data: any) => void;
  defaultData: any;
}

const AddCountryModal: React.FunctionComponent<addCountryProps> = ({
  isModalOpen,
  onCancel,
  submitting,
  onSubmit,
  defaultData,
}) => {
  const handleClose = () => {
    onCancel();
  };
  return (
    <Container fluid>
      <Row>
        <Col className="p-0">
          <Modal
            show={isModalOpen}
            onHide={handleClose}
            size="lg"
            className="modal-layout"
          >
            <Modal.Header closeButton>
              <Modal.Title className="font16-medium gray-800">
                Update Country
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddCountryForm
                onCancel={handleClose}
                submitting={submitting}
                onSubmit={onSubmit}
                defaultData={defaultData}
              />
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default AddCountryModal;
