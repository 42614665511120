import "./UploadedFile.scss";
import Button from "react-bootstrap/Button";
// import Popover from 'react-bootstrap/Popover';
// import Overlay from 'react-bootstrap/Overlay';
// import { useState, useRef } from "react";
// import Form from 'react-bootstrap/Form';
export interface UploadedFileProps {
    index: any;
    handleDelete: (e: any) => void;
    // handleNotes: (note: any, index: any) => void;
    fileName: string;
}
const UploadedFile: React.FunctionComponent<UploadedFileProps> = ({
    index,
    handleDelete,
    // handleNotes,
    fileName
}) => {
    // const [show, setShow] = useState(false);
    // const [target, setTarget] = useState(null);
    // const ref = useRef(null);

    // const handleClick = (event: any) => {
    //     setShow(!show);
    //     setTarget(event.target);
    // };
    const deleteFile = (index: any) => {
        // console.log("index: ",index)
        handleDelete(index);
    }
    // const documentNoteChanged = (note: any, index: any) => {
    //     handleNotes(note, index);
    // }
    return (
        <div className="uploadedfile-cntr">
            <div className="filehead">
                <div className="img-wraper">
                    <img src="../svgs/file-pic.svg" alt="file-pic" />
                    <Button onClick={() => deleteFile(index)} className='close-btn'>
                        <img src='../svgs/close.svg' alt='close' />
                    </Button>
                </div>
                {/* <div ref={ref}>
                    <div className="note" onClick={handleClick}>Add a note</div>

                    <Overlay
                        show={show}
                        target={target}
                        placement="bottom"
                        container={ref}
                        containerPadding={20}
                    >
                        <Popover >
                            <Popover.Header as="h3">Notes</Popover.Header>
                            <Popover.Body>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Type here..."
                                    onChange={(e) => {
                                        documentNoteChanged(e.target.value, index);
                                    }}
                                />
                            </Popover.Body>
                        </Popover>
                    </Overlay>
                </div> */}
            </div>
            <div className="file-name">{fileName}</div>
        </div>
    )
}

export default UploadedFile;