import "./SidebarNew.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Backdrop from "./Backdrop";
import { GetUserProfile } from "../../../API/endpoints/User";
import { GetPermissionsByRoleID } from "../../../API/endpoints/RolesAndPermissions/Roles";

export interface SidebarNewProps {
  active: string;
  flag?: boolean;
}
const SidebarNew: React.FunctionComponent<SidebarNewProps> = ({
  active,
  flag,
}) => {
  const navigate = useNavigate();
  const [userID, setUserID] = useState<string | undefined>("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isOrgEmployee, setIsOrgEmployee] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [brandDropdown, setBrandDropdown] = useState(false);
  const [roleID, setRoleID] = useState<number>(0);
  const [showSettings, setShowSettings] = useState(false);
  const [showAdvisory, setShowAdvisory] = useState(false);
  const [showDiscovery, setShowDiscovery] = useState(false);
  const [showPartner, setShowPartner] = useState(false);
  const [showOrg, setShowOrg] = useState(false);
  const [showBrand, setShowBrand] = useState(false);
  const [showProduct, setShowProduct] = useState(false);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    const isActivated = Cookies.get("IsActivated");
    if (isActivated === "true") {
      setIsActivated(true);
    }
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        console.log(response);
        setRoleID(response.data?.user_roles[0]?.roles.id);
        if (response?.data?.user_type === "administrative") {
          setIsSuperAdmin(true);
        } else if (response?.data?.user_type === "organization") {
          setIsOrgEmployee(true);
        } else if (response?.data?.user_type === "partner") {
          setIsPartner(true);
        }
      })();
    }
  }, [userID]);

  useEffect(() => {
    (async () => {
      const permissions = await GetPermissionsByRoleID(roleID.toString());
      console.log(permissions);
      permissions?.data?.map((permission: any) => {
        if (isSuperAdmin) {
          if (permission?.modulePermission === true) {
            if (permission?.permissions_list?.id === 7) {
              setShowSettings(true);
            } else if (
              permission?.permissions_list?.id === 6 &&
              permission?.permissions_list?.sub_modules[0]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowAdvisory(true);
            } else if (
              permission?.permissions_list?.id === 5 &&
              permission?.permissions_list?.sub_modules[0]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowDiscovery(true);
            } else if (
              permission?.permissions_list?.id === 4 &&
              permission?.permissions_list?.sub_modules[4]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowPartner(true);
            } else if (
              permission?.permissions_list?.id === 3 &&
              permission?.permissions_list?.sub_modules[3]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowProduct(true);
            } else if (
              permission?.permissions_list?.id === 2 &&
              permission?.permissions_list?.sub_modules[4]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowBrand(true);
            } else if (
              permission?.permissions_list?.id === 1 &&
              permission?.permissions_list?.sub_modules[2]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowOrg(true);
            }
          }
        } else if (isOrgEmployee) {
          if (permission?.modulePermission === true) {
            if (
              permission?.permissions_list?.id === 11 &&
              permission?.permissions_list?.sub_modules[0]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowAdvisory(true);
            } else if (
              permission?.permissions_list?.id === 10 &&
              permission?.permissions_list?.sub_modules[0]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowDiscovery(true);
            } else if (
              permission?.permissions_list?.id === 9 &&
              permission?.permissions_list?.sub_modules[3]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowProduct(true);
            } else if (
              permission?.permissions_list?.id === 8 &&
              permission?.permissions_list?.sub_modules[4]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowBrand(true);
            } else if (
              permission?.permissions_list?.id === 0 &&
              permission?.permissions_list?.sub_modules[2]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowOrg(true);
            }
          }
        } else if (isPartner) {
          if (permission?.modulePermission === true) {
            if (
              permission?.permissions_list?.id === 13 &&
              permission?.permissions_list?.sub_modules[0]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowDiscovery(true);
            } else if (
              permission?.permissions_list?.id === 12 &&
              permission?.permissions_list?.sub_modules[4]
                ?.permissions_actions[1]?.permission[0]?.permission
            ) {
              setShowPartner(true);
            }
          }
        }
        return null;
      });
    })();
  }, [roleID, isOrgEmployee, isPartner, isSuperAdmin]);

  // useEffect(() => {
  //   const userType = Cookies.get("UserType");
  //   const userRole = Cookies.get("UserRole");
  //   const isActivated = Cookies.get("IsActivated");
  //   if (isActivated === "true") {
  //     setIsActivated(true);
  //   }
  //   if (userType === "administrative" && userRole === "super_admin") {
  //     setIsSuperAdmin(true);
  //   } else if (userType === "partner") {
  //     setIsPartner(true);
  //   } else if (userType === "organization") {
  //     setIsOrgEmployee(true);
  //   }
  // }, []);

  const handleToggler = () => {
    setIsExpanded(!isExpanded);
  };
  const activeBrand = () => {
    if (active === "brand") {
      return "brand";
    } else if (active === "brand-org") {
      return "brand-org";
    } else if (active === "brand-products") {
      return "brand-products";
    } else {
      return "";
    }
  };
  return (
    <div>
      <div className={isExpanded ? "SidebarNew" : "SidebarNew collapsed"}>
        <div className="sidebar-header" onClick={handleToggler}>
          <img src="../svgs/logo-sidebar.svg" alt="logo-sidebar" />
          <h1 className="sidebar-logo">iTradeHub</h1>
        </div>
        <div className="sidebar-items">
          <div
            className={active === "dashboard" ? "item active-item" : "item"}
            onClick={() => {
              if (isOrgEmployee) {
                navigate("/BrandDashboard");
              } else if (isSuperAdmin) {
                navigate("/AdminDashboard");
              } else if (isPartner) {
                navigate("/PartnerDashboard");
              }
            }}
          >
            <img
              src={
                active === "dashboard"
                  ? "../svgs/dashboard-icon.svg"
                  : "../svgs/dashboard-icon-1.svg"
              }
              alt="dashboard"
              className="sidebar-icon"
            />
            <span
              className={
                active === "dashboard"
                  ? "font12-medium sidebar-text white"
                  : "font12-medium gray-600 sidebar-text"
              }
            >
              Dashboard
            </span>
          </div>
          {(isOrgEmployee || isSuperAdmin) && isExpanded && (
            <div
              className={
                active === activeBrand()
                  ? "dropdown-item active-dropdown"
                  : "dropdown-item"
              }
              onClick={() => {
                setBrandDropdown(!brandDropdown);
              }}
            >
              <img
                className="sidebar-icon"
                src={
                  active === activeBrand()
                    ? "../svgs/brand-management-active.svg"
                    : "../svgs/brand-management.svg"
                }
                alt="brand"
                onClick={() => setIsExpanded(!isExpanded)}
              />
              <span
                className={
                  active === activeBrand()
                    ? "sidebar-text font12-medium white"
                    : "sidebar-text font12-medium"
                }
              >
                Brand Management
              </span>
              <img
                className="sidebar-text arrow-icon"
                src={
                  active === activeBrand()
                    ? "../svgs/arrow-down-white.svg"
                    : "../svgs/chevron-down.svg"
                }
                alt="chevron-down"
              />
            </div>
          )}
          {(isOrgEmployee || isSuperAdmin) && brandDropdown && (
            <div className="brands-sub-items sidebar-text">
              <div
                className={
                  active === "brand-org"
                    ? "sub-item active-subitem"
                    : "sub-item"
                }
                onClick={() => {
                  navigate("/OrganizationManagement");
                }}
              >
                <img
                  className="sub-icon"
                  src="../svgs/building.svg"
                  alt="building"
                />
                <span className="font12-medium gray-600">Organizations</span>
              </div>

              <div
                className={
                  active === "brand" ? "sub-item active-subitem" : "sub-item"
                }
                onClick={() => navigate("/BrandManagement")}
              >
                <img
                  className="sub-icon"
                  src="../svgs/Brands.svg"
                  alt="brands"
                />
                <span className="font12-medium gray-600">Brands</span>
              </div>
              <div
                className={
                  active === "brand-products"
                    ? "sub-item active-subitem"
                    : "sub-item"
                }
                onClick={() => navigate("/ProductList")}
              >
                <img
                  className="sub-icon"
                  src="../svgs/Products.svg"
                  alt="products"
                />
                <span className="font12-medium gray-600">Products</span>
              </div>
            </div>
          )}
          {(isOrgEmployee || isSuperAdmin) &&
            !isExpanded &&
            (showBrand || showOrg || showProduct) && (
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={
                  <Popover className="subitem-popover">
                    <Popover.Body className="pop-body">
                      <div className="pop-items">
                        {showOrg && (
                          <div
                            className={
                              active === "brand-org"
                                ? "pop-item active-popitem"
                                : "pop-item"
                            }
                            onClick={() => navigate("/OrganizationManagement")}
                          >
                            <img
                              className="sub-icon itm-icon"
                              src="../svgs/building.svg"
                              alt="building"
                            />
                            <span className="font12-medium gray-600">
                              Organizations
                            </span>
                          </div>
                        )}
                        {showBrand && (
                          <div
                            className={
                              active === "brand"
                                ? "pop-item active-popitem"
                                : "pop-item"
                            }
                            onClick={() => navigate("/BrandManagement")}
                          >
                            <img
                              className="sub-icon itm-icon"
                              src="../svgs/Brands.svg"
                              alt="brands"
                            />
                            <span className="font12-medium gray-600">
                              Brands
                            </span>
                          </div>
                        )}
                        {showProduct && (
                          <div
                            className={
                              active === "brand-products"
                                ? "pop-item active-popitem"
                                : "pop-item"
                            }
                            onClick={() => navigate("/ProductList")}
                          >
                            <img
                              className="sub-icon itm-icon"
                              src="../svgs/Products.svg"
                              alt="products"
                            />
                            <span className="font12-medium gray-600">
                              Products
                            </span>
                          </div>
                        )}
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div
                  className={
                    active === activeBrand() ? "item active-item" : "item"
                  }
                >
                  <img
                    src={
                      active === activeBrand()
                        ? "../svgs/brand-management-active.svg"
                        : "../svgs/brand-management.svg"
                    }
                    alt="brand"
                  />
                </div>
              </OverlayTrigger>
            )}
          {(isPartner || isSuperAdmin) && showPartner && (
            <div
              className={active === "partner" ? "item active-item" : "item"}
              onClick={() => navigate("/PartnerManagement")}
            >
              <img
                className="sidebar-icon"
                src={
                  active === "partner"
                    ? "../svgs/partner-management-active.svg"
                    : "../svgs/partner-management.svg"
                }
                alt="avatarsettings"
              />
              <span
                className={
                  active === "partner"
                    ? "font12-medium sidebar-text white"
                    : "font12-medium gray-600 sidebar-text"
                }
              >
                Partner Management
              </span>
            </div>
          )}
          {isActivated && showDiscovery && (
            <div
              className={active === "discovery" ? "item active-item" : "item"}
              onClick={() => {
                if (isOrgEmployee) {
                  navigate("/discoverymanagement/org&&brand");
                } else if (isPartner) {
                  navigate("/discoverymanagement/partner");
                } else if (isSuperAdmin) {
                  navigate("/discoverymanagement/org&&admin");
                }
              }}
            >
              <img
                className="sidebar-icon"
                src={
                  active === "discovery"
                    ? "../svgs/discovery-management-active.svg"
                    : "../svgs/discovery-management.svg"
                }
                alt="discovery"
              />
              <span
                className={
                  active === "discovery"
                    ? "font12-medium sidebar-text white"
                    : "font12-medium gray-600 sidebar-text"
                }
              >
                Discovery Management
              </span>
            </div>
          )}
          {(isOrgEmployee || isSuperAdmin) && isActivated && showAdvisory && (
            <div
              className={active === "advisory" ? "item active-item" : "item"}
              onClick={() => {
                if (isOrgEmployee) {
                  navigate("/advisorymanagement/brand");
                } else if (isSuperAdmin) {
                  navigate("/advisorymanagement/admin");
                }
              }}
            >
              <img
                className="sidebar-icon"
                src={
                  active === "advisory"
                    ? "../svgs/advisory-management-active.svg"
                    : "../svgs/AdvisoryManagement.svg"
                }
                alt="advisory"
              />
              <span
                className={
                  active === "advisory"
                    ? "font12-medium sidebar-text white"
                    : "font12-medium gray-600 sidebar-text"
                }
              >
                Advisory Management
              </span>
            </div>
          )}
          {isSuperAdmin && showSettings && (
            <div
              className={active === "settings" ? "item active-item" : "item"}
              onClick={() => navigate("/SettingsList")}
            >
              <img
                className="sidebar-icon"
                src={
                  active === "settings"
                    ? "../svgs/settings-active.svg"
                    : "../svgs/settings.svg"
                }
                alt="action"
              />
              <span
                className={
                  active === "settings"
                    ? "font12-medium sidebar-text white"
                    : "font12-medium gray-600 sidebar-text"
                }
              >
                Settings
              </span>
            </div>
          )}
          <div
            className="item mt-auto"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <img
              className="sidebar-icon"
              src={
                isExpanded
                  ? "../svgs/brand-icon-export.svg"
                  : "../svgs/expand.svg"
              }
              alt="expand"
            />
            <span className="font12-medium gray-600 sidebar-text">
              Collapse
            </span>
          </div>
          {isExpanded && (
            <div className="item-brand">
              <img
                className="sidebar-icon"
                src="../svgs/globe.svg"
                alt="globe"
              />
              <span className="font12-medium sidebar-text blue-700">
                www.asiaecommgroup.com
              </span>
            </div>
          )}
        </div>
      </div>
      {isExpanded && <Backdrop onClick={handleToggler} />}
    </div>
  );
};

export default SidebarNew;
