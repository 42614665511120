import Modal from "react-bootstrap/Modal";
import "./RolePermissionsModal.scss";
import "../../../../styles/modals/modal700.scss";
import Form from "react-bootstrap/Form";
import EmptyContent from "../../../components/empty/EmptyContent";

export interface RolePermissionsProps {
    isModalOpen: boolean;
    onCancel: () => void;
    roleData: any;
    rolePermissions: any;
}
const RolePermissionsModal: React.FunctionComponent<RolePermissionsProps> = ({
    isModalOpen,
    onCancel,
    rolePermissions,
    roleData
}) => {

    const handleClose = () => {
        onCancel()
    }

    return (
        <div>
            <Modal className="rolepermissions-modal" show={isModalOpen} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title
                        className="font18-medium gray-800"
                    >Role Permissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="font14-medium gray-800 text-capitalize"
                    >{roleData?.role_name}</div>
                    <div
                        className="font13-regular gray-500 mt8px mb20px"
                    >{roleData?.role_description}</div>
                    {rolePermissions.length === 0 &&
                        <EmptyContent message="Permissions not found" />}
                    {rolePermissions.length !== 0 &&
                        <div className="table-wraper">
                            <table>
                                <tbody>
                                    <tr>
                                        <th colSpan={4}>Module</th>
                                        <th colSpan={4}>Permissions</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}></td>
                                        <td className="perm-td">Create</td>
                                        <td className="perm-td">Read</td>
                                        <td className="perm-td">Update</td>
                                        <td className="perm-td">Delete</td>
                                    </tr>
                                    {rolePermissions.map((module: any) => {
                                        return [
                                            <tr key={module.id}>
                                                <td className="module-td" colSpan={4}>
                                                    <Form.Check
                                                        className="checkbox16"
                                                        checked={module.permission}
                                                        type="checkbox"
                                                        label={module.module}
                                                        onChange={(e) => { }}
                                                    />
                                                </td>
                                                <td className="p-td"></td>
                                                <td className="p-td"></td>
                                                <td className="p-td"></td>
                                                <td className="p-td"></td>
                                            </tr>,
                                            (module.sub_modules.map((subModule: any, subIndex: any) => {
                                                return [
                                                    <tr className="sub-module-tr" key={module.id + 1}>
                                                        <td key={subModule.id} className="submodule-name-td" colSpan={4}>
                                                            <Form.Check
                                                                className="checkbox16"
                                                                checked={module.permission === true ? subModule.permission : false}
                                                                disabled={module.permission === true ? false : true}
                                                                type="checkbox"
                                                                label={subModule.sub_module_name}
                                                                onChange={(e) => { }}
                                                            />
                                                        </td>
                                                        {subModule.permissions_actions.map((action: any) => {
                                                            return (
                                                                <td key={action.id} className="perm-check">
                                                                    <Form.Check
                                                                        className="checkbox16"
                                                                        checked={module.permission === true && subModule.permission === true ?
                                                                            action.permission : false}
                                                                        disabled={module.permission === true && subModule.permission === true ?
                                                                            false : true}
                                                                        type="checkbox"
                                                                        label=""
                                                                        onChange={(e) => { }}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                ]
                                            })),
                                            /*dummy row for spacing only*/
                                            <tr key={module.id + 2}>
                                                <td className="p-td" style={{ height: "24px" }} colSpan={4}></td>
                                                <td className="p-td"></td>
                                                <td className="p-td"></td>
                                                <td className="p-td"></td>
                                                <td className="p-td"></td>
                                            </tr>
                                            /*end dummy row*/
                                        ]
                                    })}
                                </tbody>
                            </table>
                        </div>}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RolePermissionsModal;