import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./DiscoveryManagement.scss";
import "../../styles/global.scss";
import "../../styles/pagelayout.scss";

// React Bootstrap components
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Auth from "@aws-amplify/auth";

// Components
import TableMUI from "../components/TableMUI/TableMUI";
import {
  acceptedPartnerRequestsTableColumns,
  recommendPartnerTableColumns,
  SuitablePartnersTableColumns,
} from "../components/TableMUI/TableColumns";
import Sidebar from "../components/sidebar/Sidebar";

import AddPartnerRequest from "../components/modalForms/DiscoveryManagement/PartnerRequest";
import DetailsDisplayModal from "../components/modalForms/DiscoveryManagement/DetailsDisplayModal";
import BrandApprovalPartner from "../components/modalForms/DiscoveryManagement/BrandApprovePartner";
import {
  GetInterestForBrand,
  GetSuitablePartners,
} from "../../API/endpoints/Discovery";
import { GetCountries } from "../../API/endpoints/Common";
import { countriesDTO } from "../dtos/brand.dtos";
import Cookies from "js-cookie";
import Navbar from "../components/header/NavBar";
import { GetUserProfile } from "../../API/endpoints/User";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";
import { GetPublicFiles } from "../../API/endpoints/fileupload/Files";

function RecommendPartner() {
  const navigate = useNavigate();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [flag, setFlag] = useState(false);
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [showApprovePartnerModal, setShowApprovePartnerModal] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [data, setData] = useState<{ rows: any[] }>();
  const [listPage, setListPage] = useState(0);
  const [partnerListPage, setPartnerListPage] = useState(0);
  const [countries, setCountries] = useState<countriesDTO[]>([]);
  const [searchParam, setSearchParam] = useState<string>("");
  const [selectedRequest, setSelectedRequest] = useState();
  const [selectedPartnerID, setSelectedPartnerID] = useState<string>("");
  const [userType, setUserType] = useState<string>("");
  const [defaultTab, setDefaultTab] = useState<any>("");
  const [selectedRequestID, setSelectedRequestID] = useState<
    string | undefined
  >("");
  const [partnerDataCount, setPartnerDataCount] = useState(0);
  const [partnerData, setPartnerData] = useState<{ rows: any[] }>();
  const [newdata, setNewdata] = useState<any>([]);
  const [newDataCount, setNewDataCount] = useState<number | undefined>(0);
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [roleID, setRoleID] = useState<string | undefined>("");
  const [permissions, setPermissions] = useState<any>([]);
  const [publicFiles, setPublicFiles] = useState<any>([]);
  const handleClose = () => setShowPartnerModal(false);
  const handleCloseDetailsModal = () => setIsDetailsModalOpen(false);
  const handleCloseApprovePartner = () => setShowApprovePartnerModal(false);
  const params = useParams();
  useEffect(() => {
    setUserID(Cookies.get("userID"));
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID]);

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID]);

  useEffect(() => {
    if (roleID) {
      (async () => {
        const roleDetails = await GetRoleById(roleID.toString());

        if (roleDetails?.data?.type === "organization") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "10"
          );
          console.log(response);
          setPermissions(response?.data);
        } else if (roleDetails?.data?.type === "administrative") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "5"
          );
          console.log(response);
          setPermissions(response?.data);
        }
      })();
    }
  }, [roleID]);

  const columns1 = recommendPartnerTableColumns(
    navigate,
    setIsDetailsModalOpen,
    setShowApprovePartnerModal,
    setSelectedRequest,
    setUserType,
    setSelectedPartnerID,
    permissions
  );

  const column2 = SuitablePartnersTableColumns(
    setIsDetailsModalOpen,
    setUserType,
    setSelectedPartnerID,
    setSelectedRequest,
    setSelectedRequestID
  );

  const columns3: any = acceptedPartnerRequestsTableColumns(
    navigate,
    setIsDetailsModalOpen,
    setSelectedRequest,
    setSelectedRequestID,
    setUserType,
    setSelectedPartnerID
  );

  const dateFormatter = (date: any) => {
    return `${date?.toString().substring(8, 10)}/${date
      ?.toString()
      .substring(5, 7)}/${date?.toString().substring(0, 4)}`;
  };

  let rows1: {
    id: any;
    requestdate: any;
    partnerName: any;
    partnerId: any;
    brandid: any;
    discovery_brands_id: any;
    focusmarket: any;
    servicerequests: string[];
    servicerequestIds: any;
    partner_services: any;
    status: string;
  }[] = [];

  if (
    data?.rows &&
    permissions[0]?.sub_modules[2]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    console.log(data);
    rows1 = data.rows.map((dataItem) => ({
      id: dataItem.id,
      requestdate: dataItem.created_at
        ? dateFormatter(dataItem.created_at)
        : "Not Available",
      brandid: dataItem.discovery_brand.brand_id,
      partnerName: dataItem.partner.company_name,
      partnerId: dataItem.partner_id,
      discovery_brands_id: dataItem.discovery_brand.id,
      focusmarket: countries.filter(
        (country) => country.id === dataItem.discovery_brand.country_id
      )[0]?.name,
      servicerequests: dataItem.partner_services.map(
        (service: { service_id: number }) => {
          if (service.service_id === 1) return "Requlatory Compliance";
          if (service.service_id === 2) return "Importation and Clearance";
          if (service.service_id === 3) return "Warehousing And Storage";
          if (service.service_id === 4) return "Ecommerce Setup & Management";
          if (service.service_id === 5) return "Retail Distribution";
          if (service.service_id === 6) return "Order Fulfillment";
          if (service.service_id === 7) return "Marketing";
          return "";
        }
      ),
      servicerequestIds: dataItem.partner_services.map(
        (service: { service_id: any }) => {
          return service.service_id;
        }
      ),
      partner_services: dataItem.partner_services,
      status: dataItem.partner_recommendation_status,
    }));
  }

  let row2: {
    id: any;
    partner_id: any;
    company_name: any;
    services: any;
    country: any;
  }[] = [];

  if (
    partnerData?.rows &&
    permissions[0]?.sub_modules[1]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    row2 = partnerData.rows.map((dataItem) => {
      let serviceList: any = [];
      if (dataItem.regulatory_compliance)
        serviceList.push("Regulatory Compliance");
      if (dataItem.importation_clearance)
        serviceList.push("Importation and Clearance");
      if (dataItem.warehousing_storage)
        serviceList.push("Warehousing And Storage");
      if (dataItem.ecom_setup_mgmt)
        serviceList.push("Ecommerce Setup & Management");
      if (dataItem.retail_distribution) serviceList.push("Retail Distribution");
      if (dataItem.order_fulfilment) serviceList.push("Order Fulfillment");
      if (dataItem.marketing) serviceList.push("Marketing");
      return {
        id: dataItem.id,
        partner_id: dataItem.partner_id,
        company_name: dataItem.partner.company_name,
        services: serviceList,
        country: dataItem.regions.name,
      };
    });
  }

  const [rows3, setRows3] = useState<
    {
      id: any;
      completiondate: any;
      partnerName: any;
      partnerId: any;
      brandid: any;
      discovery_brands_id: any;
      focusmarket: any;
      acceptedServices: any[];
      status: any;
    }[]
  >([]);

  useEffect(() => {
    const GetBrandRequestProposals = async () => {
      const res = await GetInterestForBrand(params?.id);
      setNewDataCount(res.data.rows.length);
      setNewdata(res.data);
      console.log(res);
    };

    GetBrandRequestProposals();
  }, [params?.id, flag]);

  useEffect(() => {
    if (
      permissions[0]?.sub_modules[3]?.permissions_actions[1]?.permission[0]
        ?.permission === true
    ) {
      const res = newdata?.rows
        ?.map(
          (dataItem: {
            id: any;
            discovery_brand: {
              match_completion_date: any;
              created_at: any;
              brand_id: any;
              id: any;
              country_id: number;
            };
            partner: { company_name: any };
            partner_id: any;
            partner_services: {
              service_id: number;
              recommendation_status: string;
              status: string;
            }[];
            status: any;
          }) => ({
            id: dataItem.id,
            completiondate: dataItem.discovery_brand.match_completion_date
              ? dateFormatter(dataItem.discovery_brand.match_completion_date)
              : "Not Available",
            brandid: dataItem.discovery_brand.brand_id,
            partnerName: dataItem.partner.company_name,
            partnerId: dataItem.partner_id,
            discovery_brands_id: dataItem.discovery_brand.id,
            focusmarket: countries.filter(
              (country) => country.id === dataItem.discovery_brand.country_id
            )[0]?.name,
            acceptedServices: dataItem.partner_services
              .filter((item) => item.status === "Accepted")
              .map(
                (service: {
                  service_id: number;
                  recommendation_status: string;
                  status: string;
                }) => {
                  if (service.service_id === 1 && service.status === "Accepted")
                    return {
                      name: "Requlatory Compliance",
                      status: service.recommendation_status,
                    };
                  if (service.service_id === 2 && service.status === "Accepted")
                    return {
                      name: "Importation and Clearance",
                      status: service.recommendation_status,
                    };
                  if (service.service_id === 3 && service.status === "Accepted")
                    return {
                      name: "Warehousing And Storage",
                      status: service.recommendation_status,
                    };
                  if (service.service_id === 4 && service.status === "Accepted")
                    return {
                      name: "Ecommerce Setup & Management",
                      status: service.recommendation_status,
                    };
                  if (service.service_id === 5 && service.status === "Accepted")
                    return {
                      name: "Retail Distribution",
                      status: service.recommendation_status,
                    };
                  if (service.service_id === 6 && service.status === "Accepted")
                    return {
                      name: "Order Fulfillment",
                      status: service.recommendation_status,
                    };
                  if (service.service_id === 7 && service.status === "Accepted")
                    return {
                      name: "Marketing",
                      status: service.recommendation_status,
                    };
                  return { name: "", status: "" };
                }
              ),
            status: dataItem.status,
          })
        )
        .filter(
          (dataItem: { status: string }) =>
            dataItem.status === "Completed" ||
            dataItem.status === "In progress" ||
            dataItem.status === "In Progress"
        );

      setRows3(res);
    }
  }, [countries, data, flag, newdata, permissions]);

  useEffect(() => {
    const GetBrandRequestProposals = async () => {
      const res = await GetInterestForBrand(params?.id);
      setDataCount(res.data.rows.length);
      setData(res.data);
    };

    GetBrandRequestProposals();
  }, [params?.id, flag]);

  useEffect(() => {
    setDefaultTab(params?.defaultTab);
  }, [params]);

  useEffect(() => {
    const GetPartners = async () => {
      let data: any = { id: -1 };
      if (params.id)
        data = {
          id: params.id,
        };
      const res = await GetSuitablePartners(data);
      setPartnerDataCount(res.data.rows.length);
      setPartnerData(res.data);
      console.log("part", res);
    };

    GetPartners();
  }, [params?.id, flag]);

  useEffect(() => {
    const GetAllCountries = async () => {
      const res = await GetCountries();
      setCountries(res.data);
    };
    GetAllCountries();
  }, []);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
        } else navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });
    console.log(searchParam);
  }, [navigate, searchParam]);

  useEffect(() => {
    if (isDetailsModalOpen) {
      (async () => {
        try {
          const response = await GetPublicFiles("partner", selectedPartnerID);
          setPublicFiles(response?.data);
        } catch (error) {}
      })();
    }
  }, [isDetailsModalOpen, selectedPartnerID]);

  return (
    <Container fluid className="pge-layout">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="discovery" />
        </Col>
        <Col className="content-section">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item
              onClick={() => {
                navigate(-1);
              }}
            >
              DISCOVERY MANAGEMENT
              {/* <Link to="/discoverymanagement/org">DISCOVERY MANAGEMENT</Link> */}
            </Breadcrumb.Item>
            <Breadcrumb.Item
            // onClick={() => {
            //   navigate(-1);
            // }}
            >
              {params?.brandName?.toLocaleUpperCase()}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>REQUEST </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button size="sm" className="btn-primary12 addfilter">
              Add Filter
            </Button> */}
          </div>
          <div className="tabs-wraper">
            <Tabs
              activeKey={defaultTab}
              onSelect={(k) => {
                setDefaultTab(k);
              }}
              id="fill-tab-example"
              className="tabs-global"
              fill
            >
              {permissions[0]?.sub_modules[1]?.permissions_actions[1]
                ?.permission[0]?.permission === true && (
                <Tab
                  eventKey="suitable-partners"
                  title={
                    <React.Fragment>
                      <div>Suitable Partners</div>
                    </React.Fragment>
                  }
                >
                  <div className="mt16px mb16px">
                    <div className="TableMUIcountainer">
                      <TableMUI
                        columns={column2}
                        rows={row2}
                        dataCount={partnerDataCount}
                        setListPage={setPartnerListPage}
                        listPage={partnerListPage}
                      />
                    </div>
                  </div>
                </Tab>
              )}
              {permissions[0]?.sub_modules[2]?.permissions_actions[1]
                ?.permission[0]?.permission === true && (
                <Tab
                  eventKey="recommendation"
                  title={
                    <React.Fragment>
                      <div>Interested Partners</div>
                    </React.Fragment>
                  }
                >
                  <div className="mt16px mb16px">
                    <div className="TableMUIcountainer">
                      <TableMUI
                        columns={columns1}
                        rows={rows1}
                        dataCount={dataCount}
                        setListPage={setListPage}
                        listPage={listPage}
                      />
                    </div>
                  </div>
                </Tab>
              )}
              {permissions[0]?.sub_modules[3]?.permissions_actions[1]
                ?.permission[0]?.permission === true && (
                <Tab
                  eventKey="interested-partners"
                  title={
                    <React.Fragment>
                      <div>Confirmed Partners</div>
                    </React.Fragment>
                  }
                >
                  <div className="mt16px mb16px">
                    <div className="TableMUIcountainer">
                      <TableMUI
                        columns={columns3}
                        rows={rows3}
                        dataCount={newDataCount}
                        setListPage={setListPage}
                        listPage={listPage}
                      />
                    </div>
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>
        </Col>

        <AddPartnerRequest
          onCancel={handleClose}
          setFlag={setFlag}
          flag={flag}
          isModalOpen={showPartnerModal}
        />
        <DetailsDisplayModal
          isModalOpen={isDetailsModalOpen}
          onCancel={handleCloseDetailsModal}
          selectedRequestID={selectedRequestID}
          selectedRequest={selectedRequest}
          selectedPartnerID={selectedPartnerID}
          userType={userType}
          publicFiles={publicFiles}
        />
        <BrandApprovalPartner
          onCancel={handleCloseApprovePartner}
          setFlag={setFlag}
          flag={flag}
          isModalOpen={showApprovePartnerModal}
          title="Partner Recommendation"
          subtitle="Select services which you would like to recommend to brand:"
          buttonText="Confirm Recommendation"
          userType="Admin"
          reqDetails={selectedRequest}
        />
      </Row>
    </Container>
  );
}

export default RecommendPartner;
