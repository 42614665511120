import {
  MarketPlaceLinksDTO,
  MarketPreferenceDTO,
  SocialLinksDTO,
} from "../../pages/dtos/brand.dtos";
import { getWith, deleteWith, postWith, patchWith } from "../axios";

export interface GetCurrentUserResponse {}
type BrandInfoDTO = {
  org_id: string;
  brand_name: string;
  current_revenue?: string;
  years_of_operation: string;
  owner: string;
  focus_categories: string[];
};

type EditBrandInfoDTO = {
  org_id: string;
  brand_name: string;
  current_revenue?: string;
  years_of_operation: string;
  owner: string;
  focus_categories: string[];
};

type BrandDigitalInfoDTO = {
  website?: string;
  brand_id: string;
  social_media?: SocialLinksDTO[];
  market_place?: MarketPlaceLinksDTO[];
};

type BrandMarketPreferenceDTO = {
  brand_id?: string;
  fund_required: boolean;
  // engagement_model: string;
  market_prefernce: MarketPreferenceDTO[];
  engagement_models_list?: any;
};

// type SubscriptionPlanDTO = {
//   name: string;
//   brand_id: string;
//   amount?: number;
//   payment_type?: string;
//   charge_frequency?: string;
//   plan_deliverables?: string[][];
// };

export async function GetAllBrands(
  listPage: number,
  searchParam?: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/fetch-brands/${
      listPage ? listPage + 1 : 1
    }/${searchParam ? searchParam : ""}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetOrgBrands(
  orgID: string | undefined,
  listPage?: any,
  searchParam?: string
): Promise<any> {
  try {
    const url = `${
      process.env.REACT_APP_API_URL
    }/brand/get-org-brands/${orgID}/${listPage}/${
      searchParam ? searchParam : ""
    }`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteBrand(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/` + id;
    console.log("the url is: " + url);
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function SoftDeleteBrand(brandID: string): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/brand/soft-delete/brand/` + brandID;
    return (await patchWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function RestoreBrand(brandID: string): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/brand/restore/brand/` + brandID;
    return (await patchWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetCategories(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-categories`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetYearsOfOperation(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-years-of-operations`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetCountries(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-countries/contact_number`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function SaveBrandInfo(brand: BrandInfoDTO): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/`;
    console.log(url, brand);
    return (await postWith<any, any>(url, brand)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function EditBrandInfo(
  brand: EditBrandInfoDTO,
  brandID: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/` + brandID;
    return (await patchWith<any, any>(url, brand)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetBrandPreferences(brandID: string): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/brand/brand-preferences/` + brandID;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetBrand(brandID: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/` + brandID;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function SaveBrandDigitalInfo(
  brand: BrandDigitalInfoDTO
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/digital-presence`;
    return (await postWith<any, any>(url, brand)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateBrandDigitalInfo(
  brand: BrandDigitalInfoDTO
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/digital-presence/`;
    return (await postWith<any, any>(url, brand)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetEngagementModels(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/common/fetch-engagement-models`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function SaveBrandPreference(
  brand: BrandMarketPreferenceDTO
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/brand-preferences`;
    return (await postWith<any, any>(url, brand)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateBrandPreference(
  brand: BrandMarketPreferenceDTO,
  brandid: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/brand-preferences/${brandid}`;
    return (await patchWith<any, any>(url, brand)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllPlans(brandID: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans/fetch-all/` + brandID;
    // const url =
    //   `${process.env.REACT_APP_API_URL}/common/fetch-engagement-models/` +
    //   brandID;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetSubscriptionDetails(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans/` + id;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetSubscriptionCategories(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans/subscription-categories`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetDeliverables(category: string): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/plans/deliverables/` + category;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllDeliverables(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans/deliverables`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function FetchPlanDetails(id: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans/` + id;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetSubDeliverables(deliverable: string): Promise<any> {
  try {
    const url =
      `${process.env.REACT_APP_API_URL}/plans/sub-deliverables/` + deliverable;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAllSubDeliverables(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans/sub-deliverables/`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdatePlanStatus(plan: {
  plan_id: string;
  status: string;
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans/plan-action`;
    return (await postWith<any, any>(url, plan)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function AddPlan(plan: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans`;
    return (await postWith<any, any>(url, plan)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdatePlan(plan: any, id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans/` + id;
    return (await patchWith<any, any>(url, plan)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeletePlan(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans/` + id;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetMouData(brand_id: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/mou/` + brand_id;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteMouData(mou: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/mou/` + mou;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function AddMouDetails(
  brand: string | undefined,
  mou: string,
  data: any
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/mou`;
    return (await postWith<any, any>(url, {
      brand_id: brand,
      brand_name: data.brand_name,
      company_name: data.company_name,
      company_registration_number: data.company_registration_number,
      company_address_line1: data.company_address_line1,
      company_address_line2: data.company_address_line2,
      company_address_postal_code: data.company_address_postal_code,
      country_of_origin: data.country_of_origin,
      first_name: data.first_name,
      last_name: data.last_name,
      designation: data.designation,
      email: data.email,
      contact_number: data.contact_number,
      revenue_share: data.revenue_share,
      buy_out: data.buy_out,
      mou_id: mou,
      terms: data.terms,
      amount: data.amount,
    })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPaymentDetails(
  brand_id: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/plans/fetch-all/` + brand_id;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPaymentPageUrl(
  planName: string,
  amount: number,
  brandId: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/stripe/create-checkout-session`;
    return (await postWith<any, any>(url, {
      items: [
        {
          name: planName,
          amount: amount * 100,
        },
      ],
      brand_id: brandId,
    })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateBrandStatus(
  brandid: string | undefined,
  status: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/update-brand-status/${brandid}`;
    return (await patchWith<any, any>(url, { status })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateBrandPlansStatus(
  brandid: string | undefined,
  status: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/update-brand-plans-status/${brandid}`;
    return (await patchWith<any, any>(url, { status })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateBrandMouStatus(
  brandid: string | undefined,
  status: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/update-brand-mou-status/${brandid}`;
    return (await patchWith<any, any>(url, { status })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateBrandPaymentStatus(
  brandid: string | undefined,
  status: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/update-brand-payment-status/${brandid}`;
    return (await patchWith<any, any>(url, { status })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function AcceptTAndC(brandID: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/accept-tandc/${brandID}`;
    return (await postWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function UpdateBrandAgreementStatus(
  brandid: string | undefined,
  status: string
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/brand/agreement-status/${brandid}`;
    return (await patchWith<any, any>(url, { status })) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
