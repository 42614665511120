import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

export interface OtpVerificationFormProps {
  otpSubmitting: boolean;
  onSubmit: (email: string, code: string) => void;
  otpErrorMessage: string;
  isOtpError: boolean;
  showOtpVerification?: boolean;
}

const OtpVerificationForm: React.FunctionComponent<
  OtpVerificationFormProps
> = ({
  otpSubmitting,
  onSubmit,
  otpErrorMessage,
  showOtpVerification,
  isOtpError,
}) => {
  const [submitted, setIsSubmitted] = useState<boolean>(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  console.log("error", errors);
  const onHandleSubmit = (email: string, code: string) => {
    onSubmit(email, code);
  };
  return (
    <div className="otp-varification">
      <div className="font18-medium gray-800">Email Verification</div>
      <div className="font14-regular gray-500">Enter the OTP received.</div>
      <Form
        onSubmit={handleSubmit((data) => {
          setIsSubmitted(true);
          onHandleSubmit(data.email, data.code);
        })}
        data-testid={"otp-form"}
      >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your Email Id"
            {...register("email", {
              required: "* This field is required",
            })}
            name="email"
          />
          <Form.Text muted>
            <span>
              {errors?.email && (
                <p className="error-form">{String(errors.email?.message)}</p>
              )}
            </span>
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCode">
          <Form.Label>Verification Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your Verification Code"
            {...register("code", {
              required: "* This field is required",
              minLength: {
                value: 8,
                message: "* Code should be 8 characters",
              },
              maxLength: {
                value: 8,
                message: "* Code should be 8 characters",
              },
            })}
            name="code"
          />
          <Form.Text muted>
            <span>
              {errors?.code && (
                <p className="error-form">{String(errors.code?.message)}</p>
              )}
            </span>
          </Form.Text>
        </Form.Group>
        <div className="otp-btns">
          {/* <button className="resendotp-btn font12-medium gray-700">
            Resend OTP
          </button> */}
          <button
            className="verifyemail-btn font12-medium white"
            type="submit"
            disabled={submitted}
          >
            Verify Email
          </button>
        </div>
        <Form.Text muted>
          <span>
            {isOtpError && <p className="error-form">{otpErrorMessage}</p>}
          </span>
        </Form.Text>
      </Form>
    </div>
  );
};

export default OtpVerificationForm;
