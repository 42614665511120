import "./Switch.scss";
import Form from 'react-bootstrap/Form';

export interface switchProps {
    label: string;
    change: (data: boolean) => void;
}
const Switch: React.FunctionComponent<switchProps> = ({
    label,
    change
}) => {
    const handleChange = (e: boolean) => {
        change(e);
    }
    return (
        <Form.Check
            className="font14-medium gray-800 switch"
            disabled={false}
            type="switch"
            label={label}
            id="disabled-custom-switch"
            onChange={(e) => {
                handleChange(e.target.checked)
            }}
        />
    )
}

export default Switch;