import { AxiosRequestHeaders } from 'axios';
import { MethodConst, RequestError, fetchWrapper } from "./helpers";

export async function getWith<R>(url: string): Promise<RequestError | R> {
  // console.log(
  //   "Inside get with",
  //   MethodConst.GET,
  //   url,
  //   undefined,
  //   "json",
  //   false
  // );
  const resultGet = await fetchWrapper<undefined, R>(
    MethodConst.GET,
    url,
    undefined,
    "json",
    false
  );

  //console.log("the result", resultGet);
  return resultGet;
}

export async function postWith<T, R>(
  url: string,
  body: T,
  headers?: AxiosRequestHeaders
): Promise<RequestError | R> {
  const resultPost = await fetchWrapper<T, R>(
    MethodConst.POST,
    url,
    body,
    "json",
    false
  );
  return resultPost;
}

export async function updateWith<T, R>(
  url: string,
  body: T
): Promise<RequestError | R> {
  const resultUpdate = await fetchWrapper<T, R>(
    MethodConst.PUT,
    url,
    body,
    "json",
    false
  );
  return resultUpdate;
}

export async function patchWith<T, R>(
  url: string,
  body: T
): Promise<RequestError | R> {
  const resultUpdate = await fetchWrapper<T, R>(
    MethodConst.PATCH,
    url,
    body,
    "json",
    false
  );
  return resultUpdate;
}

export async function deleteWith<T, R>(
  url: string,
  body: T,
  headers?: AxiosRequestHeaders
): Promise<RequestError | R> {
  const resultDelete = await fetchWrapper<T, R>(
    MethodConst.DELETE,
    url,
    body,
    "json",
    false,
  );
  return resultDelete;
}
