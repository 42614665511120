import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./PartnerList.scss";
import "../../styles/global.scss";
// React Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// @ts-ignore
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";

// Components
import PopupTemplate from "../components/PopupTemplate/PopupTemplate";
import { partnerTableColumns } from "../components/TableMUI/TableColumns";
import Sidebar from "../components/sidebar/Sidebar";

// TableMUI components
import TableMUI from "../components/TableMUI/TableMUI";
import {
  DeletePartner,
  GetAllPartners,
  GetPartner,
  RestorePartner,
  SoftDeletePartner,
  AcceptTermsAndConditions,
} from "../../API/endpoints/Partner";
import { partnerListData } from "../dtos/partner.dtos";
// import DetailsDisplayModal from "../components/modalForms/DiscoveryManagement/DetailsDisplayModal";
import { GetUserProfile } from "../../API/endpoints/User";
import Navbar from "../components/header/NavBar";
import { CreateActivity } from "../../API/endpoints/Dashboard";
import { MarkTodoDone } from "../../API/endpoints/Todos";
import PartnerAgreement from "../components/modalForms/partnerAgreement/PartnerAgreement";
import {
  GetPermissionsByRoleAndModuleID,
  GetRoleById,
} from "../../API/endpoints/RolesAndPermissions/Roles";
import { GetAssignedOrgsForUser } from "../../API/endpoints/RolesAndPermissions/AssignTeam";

function PartnerList() {
  const navigate = useNavigate();
  const routeToForm = () => {
    navigate("/addpartner");
  };

  const [data, setData] = useState<any>({});
  const [flag, setFlag] = useState(false);
  const [showDeletePartner, setShowDeletePartner] = useState(false);
  const [partnerID, setPartnerID] = useState<string | undefined>("");
  const [partnerName, setPartnerName] = useState<string>("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [listPage, setListPage] = useState(0);
  const [searchParam, setSearchParam] = useState<string>("");
  // const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  // const [selectedPartnerID, setSelectedPartnerID] = useState<string>("");
  const [userID, setUserID] = useState<string | undefined>("");
  const [userProfile, setUserProfile] = useState<any>({});
  const [popupType, setPopupType] = useState<string>("");
  const [popupHeader, setPopupHeader] = useState<string>("");
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [partnerAgreementPopup, setPartnerAgreementPopup] =
    useState<boolean>(false);
  const [submitting, setSubmitting] = useState(false);
  const [agreementPartnerId, setAgreementPartnerId] = useState<string>("");
  const [agreementPartnerName, setAgreementPartnerName] = useState<string>("");
  const [roleID, setRoleID] = useState<number>(0);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [dataRestriction, setDataRestriction] = useState(false);
  const [restrictedData, setRestrictedData] = useState<any>([]);

  let rows: {
    id: string;
    partnername: string;
    countryoforigin: string;
    partnertype: string;
    serviceCategory: string;
    status: string;
    is_deleted: boolean;
    agreement_status: string;
  }[] = [];

  const handleClose = () => {
    setShowDeletePartner(false);
    setPopupHeader("");
    setPopupMessage("");
  };
  // const handleCloseDetailsModal = () => setShowDetailsModal(false);

  const deleteFunction = async () => {
    setFlag(false);
    await SoftDeletePartner(partnerID);
    const activity = await CreateActivity(
      `Partner: ${partnerName} deleted temporarily.`
    );
    console.log(activity);
    setFlag(true);
  };

  const restoreFunction = async () => {
    setFlag(false);
    await RestorePartner(partnerID);
    const activity = await CreateActivity(`Partner: ${partnerName} restored.`);
    console.log(activity);
    setFlag(true);
  };

  const premanentDeleteFunction = async () => {
    DeletePartner(partnerID).then(() => {
      setFlag(true);
    });
    const activity = await CreateActivity(
      `Partner: ${partnerName} deleted permanently.`
    );
    console.log(activity);
    setFlag((prev: any) => !prev);
  };

  useEffect(() => {
    setUserID(Cookies.get("userID"));
  }, []);

  useEffect(() => {
    (async () => {
      await Auth.currentAuthenticatedUser()
        .then((response) => {})
        .catch((error) => {
          navigate("/");
        });
    })();
    if (userID) {
      (async () => {
        const response = await GetUserProfile(userID);
        if (response?.data) {
          setRoleID(response.data?.user_roles[0]?.roles.id);
          setPartnerID(response.data?.partner_id);
          setUserProfile(response?.data);
        }
      })();
    }
  }, [userID, navigate]);

  useEffect(() => {
    if (roleID) {
      (async () => {
        const roleDetails = await GetRoleById(roleID.toString());
        setDataRestriction(roleDetails?.data?.data_restriction);

        if (roleDetails?.data?.type === "partner") {
          setIsPartner(true);
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "12"
          );
          console.log(response);
          setPermissions(response?.data);
        } else if (roleDetails?.data?.type === "administrative") {
          const response = await GetPermissionsByRoleAndModuleID(
            roleID.toString(),
            "4"
          );
          console.log(response);
          setPermissions(response?.data);
        }
      })();
    }
  }, [roleID]);

  useEffect(() => {
    if (permissions[0]?.permission_type.toString() === "1") {
      setIsSuperAdmin(true);
    }
  }, [permissions]);

  useEffect(() => {
    if (dataRestriction) {
      (async () => {
        try {
          const response = await GetAssignedOrgsForUser(
            "partner",
            userID || "",
            listPage,
            searchParam
          );
          console.log(searchParam, response);
          setRestrictedData(response?.data?.rows);
          setDataCount(response?.data?.rows?.length);
        } catch (error) {}
      })();
    } else {
      if (isSuperAdmin) {
        (async () => {
          const res = await GetAllPartners(listPage, searchParam);
          setData(res.data);
          setDataCount(res.data.count);
        })();
      } else {
        if (partnerID) {
          (async () => {
            const res = await GetPartner(partnerID);
            setData(res.data);
            setDataCount(1);
          })();
        }
      }
    }
  }, [
    flag,
    isSuperAdmin,
    listPage,
    partnerID,
    searchParam,
    dataRestriction,
    userID,
  ]);

  useEffect(() => {
    setPartnerName(
      data?.rows?.filter((partner: any) => {
        return partner.id === partnerID;
      })[0]?.company_name
    );
  }, [popupType, partnerID, showDeletePartner, data]);

  useEffect(() => {
    if (popupType === "restore") {
      setPopupHeader("Confirm Restore");
      setPopupMessage(
        "Are you sure you want to restore partner: " + partnerName + "?"
      );
    } else if (popupType === "delete") {
      setPopupHeader("Confirm Deletion");
      setPopupMessage(
        "Are you sure you want to delete partner: " + partnerName + "?"
      );
    } else if (popupType === "delete-permanently") {
      setPopupHeader("Confirm Deletion");
      setPopupMessage(
        "Are you sure you want to permanently delete partner: " +
          partnerName +
          "?"
      );
    }
  }, [popupType, partnerName]);

  if (
    permissions[0]?.sub_modules[4]?.permissions_actions[1]?.permission[0]
      ?.permission === true
  ) {
    if (data?.rows && isSuperAdmin) {
      rows = data?.rows?.map((dataItem: partnerListData) => ({
        id: dataItem.id,
        partnername: dataItem.company_name || "NA",
        countryoforigin: dataItem.region.name || "NA",
        partnertype: dataItem?.partnertype?.name || "NA",
        serviceCategory: dataItem?.partner_categories[0]?.categories?.name
          ? dataItem?.partner_categories[0]?.categories?.name
          : "NA",
        status: dataItem.status || "NA",
        is_deleted: dataItem.is_deleted,
        agreement_status: dataItem.status,
      }));
    } else if (data?.id && isPartner) {
      rows = [
        {
          id: data?.id,
          partnername: data?.company_name,
          countryoforigin: data?.region.name,
          partnertype: data?.partnertype?.name,
          serviceCategory: data?.partner_categories
            ? data?.partner_categories[0]?.categories?.name
            : "NA",
          status: data?.status,
          is_deleted: data?.is_deleted,
          agreement_status: data?.status,
        },
      ];
    } else if (restrictedData.length !== 0) {
      rows = restrictedData.map((dataItem: any) => ({
        id: dataItem.partner.id,
        partnername: dataItem.partner.company_name || "NA",
        countryoforigin: dataItem.partner.region.name || "NA",
        partnertype: dataItem?.partner.partnertype?.name || "NA",
        serviceCategory: dataItem?.partner.partner_categories[0]?.categories
          ?.name
          ? dataItem?.partner.partner_categories[0]?.categories?.name
          : "NA",
        status: dataItem.partner.status || "NA",
        is_deleted: dataItem.partner.is_deleted,
        agreement_status: dataItem.partner.status,
      }));
    }
  }

  const columns = partnerTableColumns(
    setPartnerID,
    setShowDeletePartner,
    navigate,
    data,
    // setSelectedPartnerID,
    // setShowDetailsModal,
    setPopupType,
    permissions
  );

  const handleCloseAgreementPopup = () => {
    setPartnerAgreementPopup(false);
    setAgreementPartnerId("");
    setAgreementPartnerName("");
  };

  const onAgreementSubmit = async (partnerId: string, partnerName: string) => {
    try {
      setSubmitting(true);
      await AcceptTermsAndConditions(partnerId);
      setPartnerAgreementPopup(false);
      setSubmitting(false);
      setAgreementPartnerId("");
      setAgreementPartnerName("");
      setFlag(true);
      await CreateActivity(
        `Partner ${partnerName} accepted the agreement and is active.`
      );
      try {
        const todoDone = await MarkTodoDone(`partnerKeyTerms:${partnerId}`);
        console.log(todoDone);
      } catch (ex: any) {
        console.log(ex);
      }
      setFlag(false);
      Cookies.set("IsActivated", "true");
    } catch (error) {
      setSubmitting(false);
    }
  };
  return (
    <Container fluid className="adminusers">
      <Row className="row">
        <Col className="sidebar">
          <Sidebar active="partner" />
        </Col>
        <Col className="adminusers-content p-0">
          <Navbar userProfile={userProfile} />
          <Breadcrumb className="font12-medium breadcrumb-nav">
            <Breadcrumb.Item active>
              <Link to="/PartnerManagement">PARTNER MANAGEMENT</Link>{" "}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-panel">
            <InputGroup size="sm" className="input-search">
              <InputGroup.Text id="inputGroup-sizing-sm">
                <img src="../svgs/lenseicon.svg" alt="searchicon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                }}
              />
            </InputGroup>
            {/* <Button size="sm" className="btn-primary12 addfilter">
              Add Filter
            </Button> */}
            {permissions[0]?.sub_modules[0]?.permissions_actions[0]
              ?.permission[0]?.permission === true &&
              permissions[0]?.sub_modules[0]?.permissions_actions[1]
                ?.permission[0]?.permission === true &&
              permissions[0]?.sub_modules[4]?.permissions_actions[0]
                ?.permission[0]?.permission === true && (
                <Button onClick={routeToForm} className="btn-primary12 adduser">
                  Add New Partner
                </Button>
              )}
          </div>

          <div className="adminusertable">
            <div className="TableMUIcountainer">
              <TableMUI
                columns={columns}
                rows={rows}
                dataCount={dataCount}
                setListPage={setListPage}
                listPage={listPage}
              />
            </div>
            <PopupTemplate
              popupHeader={popupHeader}
              popupBody={popupMessage}
              handleFunction={
                popupType === "restore"
                  ? restoreFunction
                  : popupType === "delete"
                  ? deleteFunction
                  : premanentDeleteFunction
              }
              isModalOpen={showDeletePartner}
              onCancel={handleClose}
              id={partnerID}
              setFlag={setFlag}
              isRestore={popupType === "restore" ? true : false}
            />
            {/* <DetailsDisplayModal
              isModalOpen={showDetailsModal}
              onCancel={handleCloseDetailsModal}
              userType={"PartnerList"}
              selectedRequestBrandID={selectedPartnerID}
              publicFiles={[]}
            /> */}
            <PartnerAgreement
              isModalOpen={partnerAgreementPopup}
              onCancel={handleCloseAgreementPopup}
              partnerID={agreementPartnerId}
              partnerName={agreementPartnerName}
              submitting={submitting}
              onSubmit={onAgreementSubmit}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default PartnerList;
