import { deleteWith, getWith, patchWith, postWith } from "../axios";

export async function GetAdminTodos(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/admin`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetAdminTodosByUserID(
  userID: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/admin/${userID}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetOrgTodos(orgID: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/org/${orgID}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetOrgTodosByUserID(
  orgID: string | undefined,
  userID: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/org-user/${orgID}&&${userID}`;
    console.log(url);
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetBrandTodos(brandID: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/brand/${brandID}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPartnerTodos(
  partnerID: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/partner/${partnerID}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function GetPartnerTodosByUserID(
  partnerID: string | undefined,
  userID: string | undefined
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/partner-user/${partnerID}&&${userID}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function FetchTodo(ID: string | undefined): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/${ID}`;
    return (await getWith<any[]>(url)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function DeleteTodo(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/` + id;
    return (await deleteWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function CreateTodo(data: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/`;
    return (await postWith<any, any>(url, data)) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}

export async function MarkTodoDone(id: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_URL}/todo/task/${id}`;
    return (await patchWith<any, any>(url, {})) as any;
  } catch (ex: any) {
    throw ex.response.data;
  }
}
