import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { MarketPlaceLinksDTO, SocialLinksDTO } from "../../dtos/brand.dtos";
import BrandSocialMediaForm from "./brandSocialMediaForm";
import "./brandDigitalPresenceForm.scss";

export interface BrandDigitalPresenceFormProps {
  submitting: boolean;
  socialName: string;
  socialFollowers: string;
  socialLink: string;
  socialLinks: SocialLinksDTO[];
  brandWebsite: string;
  marketplaceName: string;
  marketplaceRating: string;
  marketplaceURL: string;
  marketplaceLinks: MarketPlaceLinksDTO[];
  errors: { rating: string };
  setErrors: (link: { rating: string }) => void;
  isError: boolean;
  setSocialName: (name: string) => void;
  setSocialFollowers: (followers: string) => void;
  setSocialLink: (link: string) => void;
  setSocialLinks: (links: SocialLinksDTO[]) => void;
  setBrandWebsite: (link: string) => void;
  addSocialMedia: () => void;
  deleteSocialLink: (url: string) => void;
  setMarketplaceName: (name: string) => void;
  setMarketplaceRating: (name: string) => void;
  setMarketplaceURL: (url: string) => void;
  addMarketplace: () => void;
  deleteMarketplaceLink: (link: string) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  permissions: any;
}

const BrandDigitalPresenceForm: React.FunctionComponent<
  BrandDigitalPresenceFormProps
> = ({
  submitting,
  brandWebsite,
  socialName,
  socialFollowers,
  socialLink,
  socialLinks,
  marketplaceName,
  marketplaceRating,
  marketplaceURL,
  marketplaceLinks,
  errors,
  setErrors,
  isError,
  setSocialName,
  setSocialFollowers,
  setSocialLink,
  setBrandWebsite,
  addSocialMedia,
  deleteSocialLink,
  setMarketplaceName,
  setMarketplaceRating,
  setMarketplaceURL,
  addMarketplace,
  deleteMarketplaceLink,
  onSubmit,
  permissions,
}) => {
  const params = useParams();

  return (
    <Form onSubmit={onSubmit} className="form-digi-presns">
      <Container fluid className="container-1">
        <BrandSocialMediaForm
          brandWebsite={brandWebsite}
          socialName={socialName}
          socialFollowers={socialFollowers}
          socialLink={socialLink}
          socialLinks={socialLinks}
          setSocialName={setSocialName}
          setSocialFollowers={setSocialFollowers}
          setSocialLink={setSocialLink}
          setBrandWebsite={setBrandWebsite}
          addSocialMedia={addSocialMedia}
          deleteSocialLink={deleteSocialLink}
        />
        {/* <div className="borderbottom style.flexcontainer">
          <p className="topheading"> MARKETPLACE PROFILES </p>
        </div> */}
        <Row className="mt-5">
          <Col xs={12} className="borderbottom mb-3 p-0">
            <div className="font14-medium primary-blue">
              MARKETPLACE PROFILES
            </div>
          </Col>
          <Col md={4} lg={3} xl={2} className="p-0 mr-btm">
            <Form.Group
              className="formgroup mr12px"
              controlId="formBrandMarketplaceName"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMarketplaceName(e.target.value);
              }}
            >
              <Form.Label>Select Marketplace</Form.Label>
              <Form.Select defaultValue={marketplaceName}>
                <option value={""}>Select marketplace</option>
                <option>Amazon</option>
                <option>Alibaba</option>
                <option>Flipkart</option>
                <option>Shoppee</option>
                <option>Nykaa</option>
                <option>1mg</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} lg={3} xl={2} className="p-0 mr-btm">
            <Form.Group
              className="formgroup mr12px"
              controlId="formMarketplaceRating"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMarketplaceRating(e.target.value);
              }}
            >
              <Form.Label>Store Rating</Form.Label>

              <Form.Control
                type="text"
                placeholder="4/5"
                disabled={!marketplaceName}
                value={marketplaceRating}
              />
              <Form.Text muted>
                <span>
                  {errors?.rating && (
                    <p className="error-form">{String(errors.rating)}</p>
                  )}
                </span>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md={3} lg={5} xl={7} className="p-0 mr-btm">
            <Form.Group
              className="formgroup mr12px"
              controlId="formMarketplaceURL"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMarketplaceURL(e.target.value);
              }}
            >
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://www.example.com"
                disabled={!marketplaceName}
                value={marketplaceURL}
              />
            </Form.Group>
          </Col>
          <Col md={1} lg={1} xl={1} className="align-self-end p-0 mr-btm">
            <Button
              variant="primary"
              className="btnblue40 w-100"
              disabled={!marketplaceURL}
              onClick={addMarketplace}
            >
              ADD
            </Button>
          </Col>
          {marketplaceLinks[0] &&
            marketplaceLinks?.map((marketplace) => {
              return (
                <Row className="display-result mr-top" key={marketplace.url}>
                  <Col md={4} lg={2} className="p-0">
                    <div className="input-display mr12px">
                      {marketplace.platform}
                    </div>
                  </Col>
                  <Col md={3} lg={2} className="p-0">
                    <div className="input-display mr12px">
                      {marketplace.store_rating}
                    </div>
                  </Col>
                  <Col md={4} lg={7} className="p-0">
                    <div className="input-display">
                      <a
                        href={`//${marketplace.url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {marketplace.url}
                      </a>
                    </div>
                  </Col>
                  <Col md={1} lg={1} className="pt-1 pl-2 pb-1">
                    <img
                      src="../svgs/trash.svg"
                      alt="deleteicon"
                      onClick={() => {
                        deleteMarketplaceLink(marketplace.url);
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
        </Row>
      </Container>
      <Container fluid className="footer-container">
        <Row className="justify-content-end mb-3 mt-4">
          <Col className="d-flex justify-content-end p-0">
            <Link to="/BrandManagement" className="m-1">
              <Button className="btn-secondary12" variant="secondary">
                Cancel
              </Button>
            </Link>
            {permissions[0]?.sub_modules[1]?.permissions_actions[0]
              ?.permission[0]?.permission === true &&
              !params.brandid && (
                <Button
                  variant="primary"
                  className="m-1 btn-primary12"
                  disabled={submitting}
                  type="submit"
                >
                  Save and continue
                </Button>
              )}
            {permissions[0]?.sub_modules[1]?.permissions_actions[2]
              ?.permission[0]?.permission === true &&
              params.brandid && (
                <Button
                  variant="primary"
                  className="m-1 btn-primary12"
                  disabled={submitting}
                  type="submit"
                >
                  Update
                </Button>
              )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Text muted>
              <span>
                {isError && <p className="error-form">Unable to save Data</p>}
              </span>
            </Form.Text>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default BrandDigitalPresenceForm;
